//
// Name:            Event
//


// ========================================================================
// Component: Event
// ========================================================================


#home-events {

	> .row > .col-xs-8 {
		border-right: 1px solid #aec1d0;
	}
	.event-sidebar {
		padding:100px 50px 0 50px;

		.btn {
			min-width:100%;
			max-width:100%;
		}
	}
	.event {
		display:block;
		width:100%;
		padding:20px 0;
		margin:0;

		.thumb-wrap {
			display:block;
			width:100%;
			max-width:120px;
			height:120px;
			overflow:hidden;

			&:hover .thumb, &:focus .thumb {
				transform:scale(1.1);
			}
		}
		.thumb {
			display:block;
			width:100%;
			max-width:120px;
			height:120px;
			background-size:cover !important;
			transform:scale(1);
		}
		.date {
			display:inline-block;
			width:100%;
			color: #819eb5;
			font-family: $akkurat_bold;
			font-size: 12px;
			letter-spacing: 3px;
			line-height: 16px;
			text-transform: uppercase;
			text-align:left;
			margin:0;
		}
		.event-title {
			margin:5px 0;
		}
		.event-title {
			display:inline-block;
			width:100%;
			color: $purple;
			font-family: $akkurat_bold;
			font-size: 18px;
			line-height: 26px;
			text-align:left;
			text-decoration:none;
		}
		.location {
			display:inline-block;
			width:100%;
			color: #819eb5;
			font-family: $akkurat_reg;
			font-size: 14px;
			line-height: 20px;
			padding-left:20px;
			background:url(../images/location-icon.svg) left center no-repeat;
			text-align:left;
			margin:0;
		}
		.row.is_hover {

			.thumb {
				transform:scale(1.1);
			}
			.event-title span {
				background-size:100% 3px;
			}
		}
	}
}

#calendar {
	display:inline-block;
	position:relative;
	margin-bottom:80px;
	width:100%;

	.fc-toolbar.fc-header-toolbar {
		display:block;
	}
	.fc-left {
		position:absolute;
		top:0;
		left:0;
		width:50%;
		height:80px;
		background-color: #f2f5f8;
	}
	.fc-right {
		position:absolute;
		top:0;
		right:0;
		width:50%;
		height:80px;
		background-color: #f2f5f8;

		.fc-button-group {
			width:100%;
			text-align:right;
			margin-top:20px;
		}
		.btn {
			float:none;
			min-width:170px;
			max-width:170px;
			border-radius:0;
			padding:5px 20px;
			height:auto;
			margin-right:20px;
			text-shadow:none;
			box-shadow:none;
			background-image:none;
			background:#f6f4f9;
			color:$purple;
			transition:background .4s;

			&:hover, &:focus {
				background:$purple;
				color:#fff;
			}
		}
		.fc-state-active {
			background:$purple;
			color:#fff;
		}
	}
	#calendar-search {
		display:block;
		position:relative;
		margin:20px 0 0 20px;
		min-width:400px;

		input[type="text"] {
			border-radius: 2px;
			border: 1px solid #aec1d0;
			background-color: #ffffff;
			max-width:400px;
			line-height:40px;
			margin:0;
		}
		input[type="submit"] {
			position:absolute;
			top:0;
			right:0;
			width:40px;
			height:40px;
			min-width:40px;
			border:none;
			margin:0;
			text-indent:-9999px;
			background:url(../images/calendar-search-icon.svg) 50% no-repeat;
		}
	}
	.fc-center {
		position:absolute;
		width:100%;
		top:80px;
		left:0;
		padding:50px 150px 0 150px;

		.fc-button-group {
			margin-top:20px;

			.fc-prev-button {
				background:transparent;
				border:none;
				box-shadow:none;

				span {
					display:block;
					width:20px;
					height:20px;
					background:url(../images/purple-arrow.svg) 50% no-repeat;
					transform:rotate(90deg);

					&:after {
						display:none;
					}
				}
			}
			.fc-next-button {
				position:relative;
				background:transparent;
				border:none;
				box-shadow:none;

				&:before {
					position:absolute;
					content:'';
					top:-4px;
					left:0;
					width:2px;
					height:40px;
					background:#a18fbc;
				}
				span {
					display:block;
					width:20px;
					height:20px;
					background:url(../images/purple-arrow.svg) 50% no-repeat;
					transform:rotate(-90deg);

					&:after {
						display:none;
					}
				}
			}
		}
		h2 {
			width:auto;
			color: #000000;
			font-family: $periodico;
			font-size: 52px;
			letter-spacing: -1px;
			line-height: 62px;
			margin-left:20px;
		}
	}
	.fc-view-container {
		padding-top:200px;
		padding-left:150px;
		padding-right:150px;
	}
	ul#filters {
		display:inline-block;
		list-style:none;
		width:55%;
		float:right;
		padding:0;
		margin:0;
		position:relative;
		top:20px;
		text-align:right;

		.filter-title {
			display:none;
			color: #000000;
			font-family: $campton;
			font-size: 32px;
			letter-spacing: -1px;
			line-height: 42px;
			text-transform:none;
		}
		li {
			display:inline-block;
			vertical-align:top;
			width:auto;
			color: #507796;
			font-family: $akkurat_bold;
			font-size: 12px;
			letter-spacing: 3px;
			line-height: 50px;
			text-transform: uppercase;
			text-align:left;
			margin-right:20px;

			&:first-child {
				padding-right:20px;
			}
			label {
				margin-top:0 !important;
				color: #4f7796;
				font-family: $akkurat_reg;
				font-size: 14px;
				line-height: 20px;
			}
			input[type=checkbox] {
				cursor:pointer;
				position:absolute;
				top:0;
				left:0;
				border-radius:100%;
				-webkit-appearance:none;
			}
			#festivals-series {
				background-image:none;
				background-color: transparent;
				border: 3px solid #f1951c;
			}
			#recitals-visiting-artists,#recitals-lectures {
				background-image:none;
				background-color: transparent;
				border: 3px solid #4a90e2;
			}
			#bienen-ensembles {
				background-image:none;
				background-color: transparent;
				border: 3px solid #bd10e0;
			}
			#student-recitals {
				background-image:none;
				background-color: transparent;
				border: 3px solid #79cc19;
			}
			#festivals-series:checked {
				background-image:none;
				background-color: #f1951c;
				border: 3px solid #f1951c;
			}
			#recitals-visiting-artists:checked,#recitals-lectures:checked {
				background-image:none;
				background-color: #4a90e2;
				border: 3px solid #4a90e2;
			}
			#bienen-ensembles:checked {
				background-image:none;
				background-color: #bd10e0;
				border: 3px solid #bd10e0;
			}
			#student-recitals:checked {
				background-image:none;
				background-color: #79cc19;
				border: 3px solid #79cc19;
			}
			input[type=checkbox]+label:before {
				display:none;
				border-radius:20px;
				top:50%;
				width:20px;
				height:20px;
				border: 3px solid #819eb5;
				transform:translate(0, -50%);
			}
			input[type=checkbox]:checked+label.bienen-ensembles:before {
				content:'';
				background-image:none;
				background-color: #bd10e0;
				border: 3px solid #bd10e0;
			}
			input[type=checkbox]:checked+label.festivals-series:before {
				content:'';
				background-image:none;
				background-color: #f1951c;
				border: 3px solid #f1951c;
			}
			input[type=checkbox]:checked+label.recitals-lectures:before,
			input[type=checkbox]:checked+label.recitals-visiting-artists:before {
				content:'';
				background-image:none;
				background-color: #4a90e2;
				border: 3px solid #4a90e2;
			}
			input[type=checkbox]:checked+label.student-recitals:before {
				content:'';
				background-image:none;
				background-color: #79cc19;
				border: 3px solid #79cc19;
			}
		}
	}
	.fc-row {
		border:none;
		min-height:0;
	}
	.fc-head-container {
		border-top: 1px solid #aec1d0;
		border-bottom: 5px solid #e0e7ed;
		border-left:none;
		border-right:none;

		th {
			border:none;
			color: #000000;
			font-family: $akkurat_bold;
			font-size: 18px;
			line-height: 26px;
			padding:25px 0;
			text-align:left;
		}
	}
	.fc-widget-content {
		border:none;
	}
	.fc-row td {
		text-align:left;
		color: #000000;
		font-family: $periodico;
		font-size: 52px;
		line-height: 62px;
		border:none;

		span {
			display:block;
			position:relative;
			width:100%;
			height:auto;
			padding:0;
			margin:40px auto 0 auto;
			float:none;
			z-index:1;
		}
	}
	.fc-scroller {
		overflow:visible !important;
		height:auto !important;
	}
	.fc-today {
		background:transparent;
	}
	.fc-event {
		border:none;
		background:transparent;
	}
	.fc-event span {
		background:#e0e7ed;
		border-radius:30px;
		color:#fff;
	}
	.fc-event .fc-content {
		text-align:left;
		margin-bottom:20px;

		.fc-time {
			display:inline;
			font-family: $akkurat_reg;
			font-size: 14px;
			line-height: 20px;
			margin:0 5px 0 0;
			width:auto;
			background:transparent;
			vertical-align:top;
		}
		.fc-title {
			display:inline;
			font-family: $akkurat_reg;
			font-size: 14px;
			line-height: 20px;
			margin:0;
			width:auto;
			background:transparent;
			color: #3f6384;
			white-space:pre-wrap;
			vertical-align:top;
			text-align:left;
		}
	}
	.fc-content-skeleton tbody td {
		font-size:12px;
	}
	.fc-event-container {
		position:relative;

		.past-event {
			opacity:.7;
		}
	}
	.fc-event-container a {

	}
	.bienen-ensembles .fc-time, .violet .fc-time {
		color: #ac00d9;
	}
	.festivals-series .fc-time, .yellow .fc-time {
		color: #f1951c;
	}
	.recitals-lectures .fc-time,.recitals-visiting-artists .fc-time, .blue .fc-time {
		color: #4a90e2;
	}
	.student-recitals .fc-time, .green .fc-time {
		color: #79cc19;
	}
  .fc-bg .tooltip-wrapper,
  .fc-bg .fc-day-number {
    display:none;
  }
  .fc-content-skeleton .has-events {
    position:relative;
  }
  .fc-content-skeleton .has-events .tooltip-wrapper {
    display:none;
    position:absolute;
    top:0;
    left:100%;
    width:300px;
    height:auto;
    padding:20px;
    background:#fff;
    box-shadow:0 0 4px rgba(0, 0, 0, 0.4);
    z-index:9999;

    .fc-day-number {
      display:none;
    }
  }
  .fc-content-skeleton .has-events:hover .tooltip-wrapper,
  .fc-content-skeleton .has-events:focus .tooltip-wrapper {
    display:block;
  }
  .fc-content-skeleton .has-events .tooltip-wrapper {

    .tooltip {
      display:inline-block;
      max-width:100%;
      width:100%;
      text-align:left;
      line-height:16px;

      .date {
        display:inline;
        width:auto;
        font-family: $akkurat_bold;
        font-size: 12px;
        letter-spacing: 2px;
        line-height: 16px;
        padding-right:10px;
        text-transform: uppercase;

        &.bienen-ensembles, &.violet {
          color:#ac00d9;
        }
        &.festivals-series, &.yellow {
          color:#f1951c;
        }
        &.recitals-visiting-artists, &.blue, &.recitals-lectures {
          color:#4a90e2;
        }
        &.student-recitals, &.green {
          color:#79cc19;
        }
      }
      a.title {
        display:inline;
        width:auto;
        color: #4f7796;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

#mini-calendar {
	display:block;
	position:relative;
	z-index:999;
	max-width:295px;
	margin-bottom:50px;

	.fc-left {
		position:relative;
		width:10px;
		height:10px;
		padding:0;
		background:transparent;

		.fc-prev-button {
			background:none;
			border:none;
			box-shadow:none;
			padding:0;

			span {
				display:block;
				width:10px;
				height:10px;
				background:url(../images/purple-arrow.svg) 50% no-repeat;
				transform:rotate(90deg);
				background-size:100%;

				&:after {
					display:none;
				}
			}
		}
	}
	.fc-right {
		position:relative;
		width:10px;
		height:10px;
		padding:0;
		background:transparent;

		.fc-next-button {
			background:none;
			border:none;
			box-shadow:none;
			padding:0;

			span {
				display:block;
				width:10px;
				height:10px;
				background:url(../images/purple-arrow.svg) 50% no-repeat;
				transform:rotate(-90deg);
				background-size:100%;

				&:after {
					display:none;
				}
			}
		}
	}
	.fc-center {
		position:relative;
		top:inherit;
		padding:0;
		width:auto;

		h2 {
			color: #000000;
			font-family: $akkurat_bold;
			font-size: 18px;
			line-height: 26px;
			margin:0;
		}
	}
	.fc-view-container {
		padding-top:0 !important;
		padding-left:0;
		padding-right:0;
	}
	.fc-toolbar.fc-header-toolbar {
		margin:0;
	}
	.fc-row {
		border:none;
		min-height:0;
	}
	.fc-head-container {
		border-top: 1px solid #aec1d0;
		border-bottom: 5px solid #e0e7ed;
		border-left:none;
		border-right:none;

		th {
			border:none;
			color: #4f7796;
			font-family: $akkurat_reg;
			font-size: 14px;
			line-height: 20px;
			padding:10px 0;
		}
	}
	.fc-widget-content {
		border:none;
	}
	.fc-row td {
		text-align:center;
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 14px;
		line-height: 30px;
		border:none;

		span {
			display:block;
			position:relative;
			width:30px;
			height:30px;
			padding:0;
			margin:0 auto;
			float:none;
			z-index:1;
		}
	}
	.fc-scroller {
		overflow:visible !important;
		height:auto !important;
	}
	.fc-today {
		background:transparent;
	}
	.fc-day-grid-event {
		background: #e0e7ed;
		border-radius:30px;
	}
	.fc-event {
		border:none;
		background:transparent;
	}
	.fc-event span {
		background:#e0e7ed;
		border-radius:30px;
		color:#fff;
	}
	.fc-event .fc-content {
		display:none;
	}
	.fc-event-container a:hover .fc-content,
	.fc-event-container a:focus .fc-content {
		display:block;
	}
	.fc-content-skeleton tbody {
		display:none;
	}
	.fc-content-skeleton td span {
		border-radius:30px;
		background-color: transparent;
		cursor:pointer;
		width:30px;
		height:30px;
		border:2px solid transparent;
	}
	.fc-content-skeleton .has-events span {
		border-radius:30px;
		background-color: #e0e7ed;
		cursor:pointer;
		width:30px;
		height:30px;
		transition:.4s;

		&:hover,
		&:focus,
		&.active {
			background:$purple;
			color:#fff;
		}
	}
	.fc-bg .tooltip-wrapper,
	.fc-bg .fc-day-number {
		display:none;
	}
	.fc-content-skeleton .has-events {
		position:relative;
	}
	.fc-content-skeleton .has-events .tooltip-wrapper {
		display:none;
		position:absolute;
		top:0;
		left:100%;
		width:300px;
		height:auto;
		padding:20px;
		background:#fff;
		box-shadow:0 0 4px rgba(0, 0, 0, 0.4);
		z-index:9999;

		.fc-day-number {
			display:none;
		}
	}
	.fc-content-skeleton .has-events:hover .tooltip-wrapper,
	.fc-content-skeleton .has-events:focus .tooltip-wrapper {
		display:block;
	}
	.fc-content-skeleton .has-events .tooltip-wrapper {

		.tooltip {
			display:inline-block;
			max-width:100%;
			width:100%;
			text-align:left;
			line-height:16px;

			.date {
				display:inline;
				width:auto;
				font-family: $akkurat_bold;
			    font-size: 12px;
			    letter-spacing: 2px;
			    line-height: 16px;
			    padding-right:10px;
			    text-transform: uppercase;

				&.bienen-ensembles, &.violet {
					color:#ac00d9;
				}
				&.festivals-series, &.yellow {
					color:#f1951c;
				}
				&.recitals-visiting-artists, &.blue, &.recitals-lectures {
					color:#4a90e2;
				}
				&.student-recitals, &.green {
					color:#79cc19;
				}
			}
			a.title {
				display:inline;
				width:auto;
			    color: #4f7796;
			    font-size: 14px;
			    line-height: 20px;
			}
		}
	}
	.fc-content-skeleton .has-future-events span {
		border-radius:30px;
		background-color: #e0e7ed;
		cursor:pointer;
		width:30px;
		height:30px;
	}
	.fc-today span {
		border:2px solid $purple !important;
		color:$purple;
		font-family: $akkurat_bold;
	}
	.fc-event-container a {
		display:block;
		width:30px;
		height:30px;
		margin:5px auto;
		background: #e0e7ed;
    	border-radius: 30px;
	}
}

.loader,
.loader:after {
	border-radius: 50%;
	width: 10em;
	height: 10em;
}
.loader {
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(63,35,103, 0.2);
	border-right: 1.1em solid rgba(63,35,103, 0.2);
	border-bottom: 1.1em solid rgba(63,35,103, 0.2);
	border-left: 1.1em solid #3f2367;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.no-events {
	display:none;
	width:100%;
	padding:0 150px 0 0;
	text-align:center;
	font-size:16px;
	color: #819eb5;
	font-family: $akkurat_bold;
}

.list-view {
	position:relative;

	.col-xs-4 {
		position:absolute;
		top:220px;
		left:150px;
		z-index:99;
	}
	.col-xs-8 {
	    flex-basis: 100%;
	    max-width: 100%;
	}
	.end-xs .col-xs-8 {
	    flex-basis: 66.6666666667%;
	    max-width: 66.6666666667%;
	}
	.list-events {
		flex-basis: 66.6666666667%;
	    max-width: 66.6666666667%;
		margin-left:525px;
		padding-left: .5rem;
    	padding-right: .5rem;
    	margin-right:150px;
	}
	.fc-view-container {
		padding-top:0;
	}
	.fc-toolbar {
		padding-top:150px;
		padding-left:150px;
		padding-right:150px;
		text-align:right;
	}
	.fc-center {
		position:relative;
		top:0;
		right:0;
		left:inherit;
		padding:0;
		width:66.6666666667%;
	}
	.fc-center .fc-button-group,
	.fc-center h2 {
		display:none;
	}
	aside {
		margin-top:-60px;
	}
	#mini-calendar .fc-center h2 {
		display:inline-block;
	}
	#mini-calendar .fc-toolbar {
		padding:0;
		text-align:center;
	}
	#mini-calendar .fc-center {
		width:auto;
		text-align:center;
	}
	#filters {
		width:70% !important;
	}
	ul#filters .filter-title {
		display:inline-block !important;
		float:left;
	}
	.col-xs-12:first-child .fc-button-group,
	.col-xs-12:first-child h2 {
		display:none;
	}
	ul#filters {
		width:calc(100% - 385px) !important;
		top:inherit;
		float:none;
		text-align:left;
		margin:0 0 40px 0;
		padding:0 0 20px 0;
		border-bottom:5px solid #e0e7ed;
	}
}

.month-view {

	.col-xs-4 {
		display:none;
	}
	.col-xs-8 {
	    flex-basis: 100%;
	    max-width: 100%;
	}
	#filters {
		width:60%;
	}
	ul#filters .filter-title {
		display:none;
	}
	.fc-center .fc-button-group {
		display:inline-block;
	}
	.fc-center h2 {
		display:inline-block;
	}
	.fc-widget-content {
		height:auto !important;
	}
}

#listDay .col-xs-8 {
	position:relative;
}

#aside-filters {
	max-width:295px;
	padding:25px 20px;

	p {
		color: #507796;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		text-transform: uppercase;
	}
	ul {
		list-style:none;
		padding:0;
		margin:30px 0 0 0;
		opacity:.6;

		li {
			position:relative;

			input {
				z-index:999;
				left:0;
				-webkit-appearance:none;
				transition:0s;
			}
			ul {
				padding-left:30px;
				margin:0;
			}
			&.parent {

				label {
					position:relative;
					font-family: $akkurat_bold;

					.ico {
						position:absolute;
						top:50%;
						right:0;
						width:20px;
						height:20px;
						cursor:pointer;
						background:url(../images/purple-arrow.svg) 50% no-repeat;
						transform:translate(0, -50%);
						transition:.4s;
					}
				}
				ul li label {
					font-family: $akkurat_reg;
				}
			}
			&.parent.open .ico {
				transform:translate(0, -50%) rotate(180deg);
			}
		}
		&.checked {
			opacity:1;

			ul {
				opacity:1;
			}
		}
	}
}

.event {
	position:relative;
	margin-bottom:40px;

	.thumb-wrap {
		position:relative;
		width:100%;
		height:230px;
		overflow:hidden;

		span {
			position:absolute;
			bottom:0;
			left:0;
			width:auto;
			height:auto;
			padding:8px 10px 5px 10px;
			color: #ffffff;
			z-index:9;
			font-family: $akkurat_reg;
			font-size: 12px;
			line-height:16px;
			letter-spacing: 4px;
			text-transform: uppercase;

			&.violet,&.bienen-ensembles {
				background-color: #bd10e0;
			}
			&.blue,&.recitals-visiting-artists,&.recitals-lectures {
				background-color: #4e93ce;
			}
			&.yellow,&.festivals-series {
				background-color: #f1951c;
			}
			&.green,&.student-recitals {
				background-color: #79cc19;
			}
		}
		&:hover .thumb, &:focus .thumb {
			transform:scale(1.1);
		}
	}
	.thumb {
		position:relative;
		width:100%;
		height:230px;
		background-size:cover !important;
		transform:scale(1);
		transition:.4s;

		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
	.cnt {
		padding:0 20px;
	}
	.date {
		color: #819eb5;
		font-family: $akkurat_reg;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		text-transform: uppercase;
		margin:0;
	}
	.event-title {
		color: $purple;
		font-family: $campton;
		font-size: 24px;
		line-height: 32px;
		margin:10px 0;
	}
	.location {
		display:inline-block;
		width:100%;
		color: #819eb5;
		font-family: $akkurat_reg;
		font-size: 14px;
		line-height: 20px;
		padding-left:20px;
		background:url(../images/location-icon.svg) left center no-repeat;
		text-align:left;
		margin:0;
	}
	.event-btns {
		margin:20px 0 0 0;
		font-size:0;
		text-align:left;

		a {
			vertical-align:top;
			width:calc(50% - 50px);
			min-width:140px;
			height:40px;
			padding:0 20px;
			line-height:40px;
		}
		.purple-btn {
			margin-right:5px;

			&:hover, &:focus {
				box-shadow:none !important;
			}
		}
		.btn {
			margin-left:5px;
		}
	}
	.row.is_hover {

		.thumb {
			transform:scale(1.1);
		}
		.event-title span {
			background-size:100% 3px;
		}
	}
}

.past-events {

	.col-xs-4 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-4:first-child {
		padding-left:.5rem;
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-4:last-child {
		padding-left:calc(.5rem + 10px);
		padding-right:.5rem;
	}
	.thumb-wrap {
		position:relative;
		width:100%;
		height:165px;
		overflow:hidden;

		span {
			position:absolute;
			bottom:0;
			left:0;
			width:auto;
			height:auto;
			padding:8px 10px 5px 10px;
			color: #ffffff;
			z-index:9;
			font-family: $akkurat_reg;
			font-size: 12px;
			line-height:16px;
			letter-spacing: 4px;
			text-transform: uppercase;

			&.violet,&.bienen-ensembles {
				background-color: #bd10e0;
			}
			&.blue,&.recitals-visiting-artists,&.recitals-lectures {
				background-color: #4e93ce;
			}
			&.yellow,&.festivals-series {
				background-color: #f1951c;
			}
			&.green,&.student-recitals {
				background-color: #79cc19;
			}
		}
	}
	.thumb {
		position:relative;
		width:100%;
		height:165px;
		background-size:cover !important;
		transform:scale(1);
		transition:.4s;
		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
	.date {
		color: #819eb5;
		font-family: $akkurat_reg;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		text-transform: uppercase;
		margin:20px 0 0 0;
	}
	.title {
		color: $purple;
		font-family: $akkurat_bold;
		font-size: 18px;
		line-height: 26px;
		margin:10px 0;
	}
	.location {
		display:inline-block;
		width:100%;
		color: #819eb5;
		font-family: $akkurat_reg;
		font-size: 14px;
		line-height: 20px;
		padding-left:20px;
		background:url(../images/location-icon.svg) left center no-repeat;
		text-align:left;
		margin:0 0 20px 0;
	}
	.col-xs-4.is_hover {

		.thumb {
			transform:scale(1.1);
		}
		.title span {
			background-size:100% 3px;
		}
	}
}

.share {
	display:inline-block;
	position:absolute;
	top:-50px;
	right:20px;
	padding:8px 15px;
	background-color: #e0e7ed;
	border-radius:3px;
	text-align:center;

	li {
		display:inline-block;
		width:auto;
		margin:0 2px;
		color: #507796;
		font-family: $akkurat_bold;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		vertical-align:middle;
		text-transform: uppercase;
		transition:.4s;

		span {
			position:relative;
			top:1px;
		}
		a {
			display:block;
			width:20px;
			height:16px;
			text-indent:-9999px;
		}
		&.facebook {
			background:url(../images/facebook-share.svg) 50% no-repeat;
		}
		&.twitter {
			background:url(../images/twitter-share.svg) 50% no-repeat;
		}
		&.linkedin {
			background:url(../images/linkedin-share.svg) 50% no-repeat;
		}
		&.google {
			background:url(../images/google-share.svg) 50% no-repeat;
		}
		&.facebook:hover, &.facebook:focus {
			background:url(../images/facebook-share-purple.svg) 50% no-repeat;
		}
		&.twitter:hover, &.twitter:focus {
			background:url(../images/twitter-share-purple.svg) 50% no-repeat;
		}
		&.linkedin:hover, &.linkedin:focus {
			background:url(../images/linkedin-share-purple.svg) 50% no-repeat;
		}
		&.google:hover, &.google:focus {
			background:url(../images/google-share-purple.svg) 50% no-repeat;
		}
	}
}

.event-category {
	display:inline-block;
	position:relative;
	width:auto;
	height:auto;
	padding:8px 10px 5px 10px;
	color: #ffffff;
	z-index:9;
	font-family: $akkurat_reg;
	font-size: 12px;
	line-height:16px;
	letter-spacing: 4px;
	text-transform: uppercase;

	&.bienen-ensembles, &.violet {
		background-color:#ac00d9;
	}
	&.festivals-series, &.yellow {
		background-color:#f1951c;
	}
	&.recitals-visiting-artists, &.blue, &.recitals-lectures {
		background-color:#4a90e2;
	}
	&.student-recitals, &.green {
		background-color:#79cc19;
	}
  &.free-event {
    background-color: $dark-purple;
    font-weight: bold;
  }
}

#event-single-calendar {
	display:block;
	margin:80px 0 60px 0;

	.fc-prev-button {
		background:transparent;
		border:none;
		box-shadow:none;

		span {
			display:block;
			width:20px;
			height:20px;
			background:url(../images/purple-arrow.svg) 50% no-repeat;
			transform:rotate(90deg);

			&:after {
				display:none;
			}
		}
	}
	.fc-next-button {
		background:transparent;
		border:none;
		box-shadow:none;

		span {
			display:block;
			width:20px;
			height:20px;
			background:url(../images/purple-arrow.svg) 50% no-repeat;
			transform:rotate(-90deg);

			&:after {
				display:none;
			}
		}
	}
	.fc-center h2 {
		color: #000000;
		font-family: $akkurat_bold;
		font-size: 18px;
		line-height: 35px;
	}
	.fc-toolbar.fc-header-toolbar {
		margin:0;
	}
	.fc-row {
		border:none;
		min-height:0;
	}
	.fc-head-container {
		border-top: 1px solid #aec1d0;
		border-bottom: 5px solid #e0e7ed;
		border-left:none;
		border-right:none;

		th {
			border:none;
			color: #4f7796;
			font-family: $akkurat_reg;
			font-size: 14px;
			line-height: 20px;
			padding:10px 0;
		}
	}
	.fc-widget-content {
		border:none;
	}
	.fc-row td {
		text-align:center;
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 14px;
		line-height: 30px;
		border:none;

		span {
			display:block;
			position:relative;
			width:30px;
			height:30px;
			padding:0;
			margin:0 auto;
			float:none;
			z-index:1;
			border:2px solid transparent;
		}
	}
	.has-events span {
		border-radius:30px;
		background-color: #e0e7ed;
		cursor:pointer;
		width:30px;
		height:30px;
		transition:.4s;

		&:hover,
		&:focus,
		&.active {
			background:$purple;
			color:#fff;
		}
	}
	.fc-scroller {
		overflow:visible !important;
		height:auto !important;
	}
	.fc-today {
		background:transparent;
	}
	.fc-today span {
		border:2px solid $purple !important;
		//background:$purple;
		border-radius:30px;
		color:$purple;
		font-family: $akkurat_bold;
	}
	.fc-event {
		border:none;
		background:transparent;
	}
	.fc-event span {
		background:#e0e7ed;
		border-radius:30px;
		color:#fff;
	}
	.fc-event .fc-content {

		.fc-time,
		.fc-title {
			display:none;
		}
	}
	.fc-event-container {
		position:relative;
	}
	.fc-event-container a {
		position:absolute;
		z-index:9;
		background:transparent;
		color:#fff;
		display:block;
		width:30px;
		height:30px;
		margin:0 auto;
		left:50%;
		top:-31px;
		transform:translate(-50%, 0);
	}
	.fc-content-skeleton .has-events span:hover,
	.fc-content-skeleton .has-events span:focus {
		background:#4e2a84;
		color:#fff;
	}
}

.event-details {
	padding:80px 0 0 40px;

  &.hidden {
    display:none;
    visibility:hidden;
  }

	.location {
		display:inline-block;
		width:100%;
		color: #819eb5;
		font-family: $akkurat_reg;
		font-size: 14px;
		line-height: 20px;
		padding-left:20px;
		background:url(../images/location-icon.svg) left center no-repeat;
		text-align:left;
		margin:10px 0 0 0;
	}

	ul {
		list-style:none;
		padding:0;
		margin:10px 0 0 0;

		li span {
			color:$purple;
			font-family: $akkurat_bold;
		}
	}
}

span.tag-label,h4.tags-header {
	display:inline-block;
	position:relative;
	width:auto;
	font-family: $akkurat_bold;
	font-size: 12px;
	letter-spacing: 3px;
	line-height: 16px;
	color: #507796;
	text-transform: uppercase;
	padding-right:4px;
}

.tags {
	list-style:none;
	padding:0;
	margin:20px 0 0 0 !important;

	li {
		display:inline-block;
		position:relative;
		width:auto;
		font-family: $akkurat_bold;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		color: #507796;
		text-transform: uppercase;
		padding-right:4px;

		&:after {
			position:absolute;
			content:',';
			bottom:0;
			right:-2px;
		}
		&:last-child:after {
			display:none;
		}
		a {
			color:$purple;
		}
	}
}

.more-dates {
	list-style:none;
	padding:0;
	margin:20px 0;

	li {
		color: #507796;
		font-family: $akkurat_bold;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		text-transform: uppercase;

		&:first-child {
			margin-bottom:10px;
		}
		a {
			color: $purple;
			font-family: $akkurat_bold;
			font-size: 16px;
			line-height: 24px;
			text-transform:none;
			letter-spacing:0;
		}
	}
}

.event-location {

	.photo {
		display:block;
		width:100%;
		height:290px;
		margin:30px 0 0 0;
		background-size:cover !important;
	}
}


@media only screen and (max-width: 1500px) and (min-width: 1px) {
	.list-view .col-xs-4 {
		left:50px;
	}
	.list-view .list-events {
		margin-right:50px;
	}
	.list-view .list-events {
		margin-left:425px;
	}
	.list-view #calendar ul#filters {
		width:calc(100% - 285px) !important;
	}
	.list-view #calendar .fc-center {
		padding:50px 50px 0 150px;
	}
	.no-events {
		padding:0 50px 0 0;
	}
}

@media only screen and (max-width: 1420px) and (min-width: 1px) {
	.list-view #calendar ul#filters li.filter-title {
		width:100% !important;
	}
	.list-view #calendar {
		margin-bottom:130px;
	}

}

@media only screen and (max-width: 1360px) and (min-width: 1px) {
	.month-view #calendar .fc-view-container {
		padding:200px 50px 0 50px;
	}
	.month-view #calendar .fc-center {
		padding:50px 50px 0 50px;
	}
}

@media only screen and (max-width: 1210px) and (min-width: 1px) {
	#listDay .event .event-btns .btn {
		margin:10px 0;
	}
	.list-view .list-events {
		margin-top:20px;
	}
	#faculty-calendar {
		min-height:220px !important;
	}
}

@media only screen and (max-width: 1180px) and (min-width: 1px) {
	.month-view #calendar ul#filters {
		width:100%;
	}
	.month-view #calendar .fc-view-container {
		padding:250px 50px 0;
	}
	.list-view #calendar {
		margin-bottom:160px;
	}
	.list-view #calendar ul#filters {
		padding-bottom:20px;
	}
	.list-view #calendar ul#filters li {
		margin:0;
	}
	.list-view #calendar ul#filters li:first-child {
		width:100%;
	}
	.list-view #calendar ul#filters li:nth-child(2) {
		width:100%;
	}
	.list-view #calendar ul#filters li:nth-child(3),
	.list-view #calendar ul#filters li:nth-child(4),
	.list-view #calendar ul#filters li:nth-child(5),
	.list-view #calendar ul#filters li:nth-child(6) {
		width:25%;
	}
}

@media only screen and (max-width: 1080px) and (min-width: 1px) {
	#listDay .col-xs-4 {
		display:none;
	}
	#listDay .col-xs-8 {
		flex-basis: 100%;
	    max-width: 100%;
	}
	.list-view .list-events {
		margin-left:380px;
	}
	.list-view #calendar ul#filters {
		width: calc(100% - 238px)!important;
	}
	// #mini-calendar,
	// #aside-filters {
	// 	max-width:100%;
	// }
	#mini-calendar .fc-head-container th {
		text-align:center;
	}
	.list-events .event .col-xs-5 {
		flex-basis: 100%;
	    max-width: 100%;

	    .thumb {
	    	max-width:320px;
	    }
	}
	.list-events .event .col-xs-7 {
		flex-basis: 100%;
	    max-width: 100%;

	    .cnt {
	    	padding:20px 0;
	    }
	}
}

// @media only screen and (max-width: 1024px) and (min-width: 1px) {
// 	.list-view #calendar .fc-center {
// 		top:160px;
// 	}
// 	.event .event-btns a {
// 		width:100%;
// 		min-width:100%;
// 	}
// 	.event .event-btns .purple-btn {
// 		margin-right:0px;
// 	}
// 	.event .event-btns .btn {
// 		margin-left:0px;
// 	}
// 	.past-events .col-xs-4 {
// 		flex-basis: 50%;
// 	    max-width: 50%;
// 	    margin-bottom:40px;
// 	    padding-left:.5rem !important;
// 	    padding-right:.5rem !important;
// 	}
// 	.past-events .col-xs-4:last-child {
// 		margin-bottom:0;
// 	}
// 	#event-info .col-xs-5,
// 	#event-info .col-xs-7 {
// 		flex-basis: 100%;
// 	    max-width: 100%;
// 	}
// 	.event-details {
// 		padding:30px 0 0 0;
// 	}
// 	#event-single-calendar {
// 		margin:30px 0;
// 	}
// 	.event-location .col-xs-6 {
// 		flex-basis: 100%;
// 	    max-width: 100%;
// 	}
// 	.event-location .map-cnt-half {
// 		margin-top:30px;
// 	}
// 	.list-view #calendar .fc-left,
// 	.list-view #calendar .fc-right {
// 		position:relative;
// 		width:100%;
// 		text-align:center;
// 	}
// 	.list-view #calendar .fc-toolbar {
// 		padding:0;
// 		text-align:center;
// 	}
// 	.list-view #calendar .fc-right .fc-button-group {
// 		text-align:center;
// 		padding:0;
// 	}
// 	#calendar #calendar-search {
// 		margin:20px auto 0 auto;
// 	}
// 	#calendar .fc-center {
// 		top:150px;
// 	}
// 	.list-view #calendar {
// 		margin-bottom:130px;
// 	}
// 	#calendar .fc-right .fc-listDay-button {
// 		margin-right:10px;
// 	}

// }

@media only screen and (max-width: 980px) and (min-width: 1px) {
	#calendar .fc-left, #calendar .fc-right {
		width:100%;
		position:relative;
		text-align:center;
	}
	.list-view .fc-toolbar {
		padding:0;
	}
	.month-view #calendar .fc-center {
		top:150px !important;
	}
	#calendar #calendar-search {
		margin:20px auto !important;
	}
	#calendar .fc-right .fc-button-group {
		text-align:center;
		padding-right:0 !important;
	}
	#calendar .fc-right .fc-listDay-button {
		margin:0 10px !important;
	}
	.past-events .col-xs-4 {
		flex-basis: 50%;
	    max-width: 50%;
	    margin-bottom:40px;
	    padding-left:.5rem !important;
	    padding-right:.5rem !important;
	}
	.past-events .col-xs-4:last-child {
		margin-bottom:0;
	}
	.list-view #calendar {
		margin-bottom:180px;
	}
	#calendar ul#filters {
		width:100%;
		top:20px;
		vertical-align:top;
		text-align:center;
	}
	.list-view #calendar .fc-center {
		top:160px;
	}
	.month-view #calendar .fc-center {
		top:50px;
	}
	.month-view #calendar .fc-view-container {
		padding:250px 50px 0 50px;
	}
	#mini-calendar .fc-center {
		top:0;
	}
	#calendar .fc-toolbar.fc-header-toolbar,
	#calendar #calendar-search {
		float:none;
	}
	#calendar .fc-view-container {
		padding-top:280px;
	}
	#calendar .fc-center h2 {
		float:none;
		margin-left:0;
	}
	#calendar .fc-left, #calendar .fc-right {
		text-align:center;
	}
	#calendar .fc-right .fc-button-group {
		padding-right:50px;
	}
	#calendar #calendar-search {
		max-width:400px;
		margin:20px 0 0 50px;
	}
	#calendar .fc-right .btn {
		margin:0 10px;
	}
	#calendar .fc-right .fc-listDay-button {
		margin-right:0;
	}
	#calendar .fc-head-container th {
		font-size:14px;
		line-height:20px;
	}
	.list-view .col-xs-8 {

	}
	.list-view .col-xs-4 {
		display:none;
	}
	.list-view #calendar .fc-center {
		padding:0 50px 0 50px;
	}
	.list-view #calendar ul#filters {
		width:100% !important;
	}
	.list-view .list-events {
		margin-left:calc(50px - .5rem);
		margin-right:calc(50px - .5rem);
		flex-basis: 100%;
    	max-width: 100%;
	}
	.list-view aside {
		margin-top:50px;
		padding:0 50px;
	}
	#mini-calendar,
	#aside-filters {
		max-width:100%;
	}
	#events-calendar {
		height:auto !important;
	}
	.list-view .end-xs .col-xs-8 {
		flex-basis: 100%;
    	max-width: 100%;
	}
	.no-events {
		padding:0 50px;
	}
	#mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper,
	#mini-calendar .fc-content-skeleton .has-events:hover .tooltip-wrapper,
	#mini-calendar .fc-content-skeleton .has-events:focus .tooltip-wrapper {
		display:none !important;
	}
	.list-view .list-events {
		margin-left:50px;
	}
	.list-view #calendar ul#filters {
		width:100% !important;
	}
	.month-view #calendar ul#filters li {
		margin:0;
	}
	.month-view #calendar ul#filters li:nth-child(2) {
		width:100%;
	}
	.month-view #calendar ul#filters li:nth-child(3),
	.month-view #calendar ul#filters li:nth-child(4),
	.month-view #calendar ul#filters li:nth-child(5),
	.month-view #calendar ul#filters li:nth-child(6) {
		width:25%;
	}
	.month-view #calendar .fc-view-container {
		padding:220px 50px 0;
	}
	.list-events .event .col-xs-5 {
		flex-basis: 41.6666666667%;
    	max-width: 41.6666666667%;

	    .thumb {
	    	max-width:100%;
	    }
	}
	.list-events .event .col-xs-7 {
		flex-basis: 58.3333333333%;
	    max-width: 58.3333333333%;

	    .cnt {
	    	padding:0 20px;
	    }
	}
}

@media only screen and (max-width: 880px) and (min-width: 1px) {
	#mini-calendar .fc-right,
	#mini-calendar .fc-left {
		width:10px;
		top:inherit;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.list-events .event .col-xs-5 {
		flex-basis: 100%;
    	max-width: 100%;

	    .thumb {
	    	max-width:320px;
	    }
	}
	.list-events .event .col-xs-7 {
		flex-basis: 100%;
	    max-width: 100%;

	    .cnt {
	    	padding:20px 0;
	    }
	}
	.event .cnt {
		padding:30px 0;
	}
	.event .event-btns a {
		width: calc(50% - 10px);
    	min-width: 140px;
	}
	.event .event-btns .purple-btn {
		margin-right:5px;
	}
	.event .event-btns .btn {
		margin-left:5px;
	}
	.past-events .col-xs-4 {
		flex-basis: 100%;
	    max-width: 100%;
	}
	.past-events .thumb {
		max-width:265px;
	}
	.event .thumb {
		max-width:230px;
	}
	.event .event-btns a {
		width:50% !important;
	}
	#calendar .fc-center {
		padding:50px 20px 0 20px;
		position:relative;
		top:inherit;
	}
	#calendar .fc-view-container {
		padding:280px 20px 0 20px;
	}
	#mini-calendar .fc-center {
		padding:0;
	}
	#calendar {
		margin-bottom:20px;
	}
	#calendar ul#filters .filter-title {
		margin-bottom:20px;
	}
	#calendar ul#filters li {
		width:100%;
		margin:10px 0;

		br {
			display:none;
		}
	}
	#calendar ul#filters li:nth-child(2) {
		margin:0;
	}
	.list-view #calendar {
		margin-bottom:30px;
	}
	#calendar ul#filters {
		padding-bottom:20px;
	}
	.event {
		border-bottom:1px solid #e0e7ed;
	}
	.list-view #calendar .fc-center {
		top:0;
	}
	#calendar ul#filters li:nth-child(3),
	#calendar ul#filters li:nth-child(4),
	#calendar ul#filters li:nth-child(5),
	#calendar ul#filters li:nth-child(6) {
		width:50% !important;
	}
	.month-view #calendar .fc-center {
		top:0 !important;
	}
	.month-view #calendar .fc-view-container {
		padding:20px 50px 0;
	}
	.event-details {
		padding:80px 0 0 0;
	}

	#event-single-calendar {
		display: none;
	}

	.section-events .fixed-width .col-xs-4 {
		order:1;
		margin-top: 1.5rem;
	}
	.section-events .fixed-width .col-xs-8 {
		order:0;
		margin-top: 2rem;
	}

	.share {
		top: -30px;
		padding: 5px 15px;
	}

	.event-modal {
		position: fixed;
		top: 50%;
		left: 50%;
		width: 100%;
		max-width: 650px;
		max-height: 520px;
		height: 520px; //TODO: Fix for mobile
		padding: 20px 10px;
		background: #fff;
		z-index: 9999999;
		transform: translate(-50%, -50%);

		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 30px;
			height: 30px;
			background: url(../images/close.svg) 50% no-repeat #fff;
			border: 1px solid #637a91;
		}
		.row {

			.col-xs-6:last-child {
				padding-left: 30px;
			}
			.thumb {
				display: inline-block;
				position: relative;
				width: 100%;
				height: 150px;
				background-size: cover !important;
				margin: 0 0 10px 0;

				.cat {
					position: absolute;
					bottom: 0;
					left: 0;
					width: auto;
					height: auto;
					padding: 8px 10px 5px;
					color: #fff;
					z-index: 9;
					font-family: $akkurat_reg;
					font-size: 12px;
					line-height: 16px;
					letter-spacing: 4px;
					text-transform: uppercase;

					&.violet {
						background-color: #bd10e0;
					}
					&.blue {
						background-color: #4e93ce;
					}
					&.yellow {
						background-color: #f1951c;
					}
					&.green {
						background-color: #79cc19;
					}
				}
			}
			.hide-on-desktop {
				display: block;
			}
			.hide-on-mobile {
				display: none;
			}
			p {
				margin: 0 0 10px 0;
			}
			.purple-btn {
				//min-width: 130px;
				padding: 10px 20px;
				margin-top: 5px;
			}
			.date {
				color: #637a91;
				font-family: $akkurat_reg;
				font-size: 12px;
				letter-spacing: 3px;
				line-height: 16px;
				text-transform: uppercase;
				margin: 0;
			}
			h3.post-title {
				color: #4e2a84;
				font-family: $akkurat_bold;
				font-size: 18px;
				line-height: 24px;
				margin: 5px 0;
			}
			p.location {
				display: inline-block;
				width: 100%;
				color: #637a91;
				font-family: $akkurat_reg;
				font-size: 14px;
				line-height: 18px;
				padding-left: 20px;
				background: url(../images/location-icon.svg) 0 no-repeat;
				text-align: left;
				margin: 0px;
			}
			.excerpt {
				margin-top: 10px;

				a.body-link {
					padding: 5px 5px 5px 0;
				}
			}
		}
	}
}

@media only screen and (max-width: 680px) and (min-width: 1px) {

	#calendar ul#filters li {
		width:50%;
		margin:0 0 10px 0;
	}
	#calendar ul#filters li:nth-child(2) {
		width:100%;
	}
	#calendar .fc-view-container {
		padding-top:420px;
	}
	#calendar ul#filters li label br {
		display:none;
	}
	#calendar .fc-button-group {
		float:none;
	}
	#calendar .fc-event-container .fc-content {
		display:none;
	}
	#calendar .fc-event-container .bienen-ensembles, #calendar .fc-event-container .violet {
		width:20px;
		height:20px;
		margin:0 0 5px 0;
		border-radius:20px;
		background:#bd10e0;
	}
	#calendar .fc-event-container .festivals-series, #calendar .fc-event-container .yellow {
		width:20px;
		height:20px;
		margin:0 0 5px 0;
		border-radius:20px;
		background:#f1951c;
	}
	#calendar .fc-event-container .recitals-lectures,
	#calendar .fc-event-container .recitals-visiting-artists, #calendar .fc-event-container .blue {
		width:20px;
		height:20px;
		margin:0 0 5px 0;
		border-radius:20px;
		background:#4a90e2;
	}
	#calendar .fc-event-container .student-recitals, #calendar .fc-event-container .green {
		width:20px;
		height:20px;
		margin:0 0 5px 0;
		border-radius:20px;
		background:#79cc19;
	}
	#calendar .fc-head-container th {
		font-size:12px;
	}
	#faculty-calendar .fc-event:hover .fc-content,
	#calendar .fc-event:hover .fc-content
	#faculty-calendar .fc-event:focus .fc-content,
	#calendar .fc-event:focus .fc-content
	{
	 	display:block;
	 	position:absolute;
	 	z-index:9999;
	 	bottom:-10px;
	 	top:inherit;
	 	background:#fff;
	 	padding:10px;
	 	box-shadow:0 0 5px rgba(0, 0, 0, 0.4);
	}
	#faculty-calendar .fc-event.left .fc-content,
 	#calendar .fc-event.left .fc-content {
 		right:inherit;
 		left:25px;
 	}
 	#faculty-calendar .fc-event.right .fc-content,
 	#calendar .fc-event.right .fc-content {
 		left:inherit;
 		right:25px;
 	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	#calendar ul#filters li:nth-child(3),
	#calendar ul#filters li:nth-child(4),
	#calendar ul#filters li:nth-child(5),
	#calendar ul#filters li:nth-child(6) {
		width:100% !important;
	}
	#calendar .fc-right .fc-listDay-button {
		margin:10px 0 !important;
	}
	.month-view #calendar .fc-view-container {
		padding:20px;
	}
	.month-view #calendar .fc-center {
		padding:50px 20px 0 20px;
	}
	.event .event-btns a {
		width:calc(100% - 40px);
		min-width:calc(100% - 40px);
		margin:5px 0 !important;
		margin-left:0 !important;
		margin-right:0 !important;
	}
	.past-events .thumb {
		max-width:100%;
	}
	.event .thumb {
		max-width:100%;
	}
	.event-details .purple-btn {
		min-width:100%;
	}
	#calendar .fc-right .btn {
		width:100%;
		margin:10px 0;
		max-width:100%;
	}
	#calendar .fc-right .fc-button-group {
		max-width:400px;
	}
	#calendar .fc-left, #calendar .fc-right {
		height:auto;
	}
	#mini-calendar {
		margin-top:60px;
	}
	#mini-calendar .fc-center {
		top:0;
	}
	#calendar .fc-right,
	#calendar .fc-left {
		padding:0 20px;
	}
	#calendar #calendar-search {
		min-width:100%;
	}
	#calendar .fc-head-container th {
		font-size:10px;
	}
	#calendar ul#filters li {
		width:100%;
	}
	#calendar .fc-view-container {
		padding-top:540px;
	}
	#calendar .fc-center h2 {
		font-size:32px;
		line-height:42px;
	}
	#mini-calendar .fc-center h2 {
		font-size:18px;
		line-height:26px;
	}
	#calendar .fc-head-container th {
		font-size:6px;
	}
	#calendar .fc-row td {
		font-size:20px;
	}
	#calendar .fc-row td span {
		margin:10px auto 0 auto;
	}
	#mini-calendar .fc-view-container {
		padding:280px 0 0 0;
	}
	#calendar #calendar-search input[type=text] {
		max-width:100%;
	}
	#calendar .fc-right .fc-button-group {
		max-width:100%;
	}
	.list-view #calendar .fc-center {
		padding:0 20px;
	}
	.list-view .list-events {
		margin-left: calc(20px - .5rem);
    	margin-right: calc(20px - .5rem);
	}
	.event .event-btns a {
		width:100% !important;
	}
	.list-view aside {
		padding:0 20px;
	}
	.month-view #calendar .fc-center h2 {
		width:100%;
	}
}

/* Event mobile stuff */
.event-modal {
  position:fixed;
  top:50%;
  left:50%;
  width:100%;
  max-width:650px;
  height:auto;
  padding:40px;
  background:#fff;
  z-index:9999999;
  transform:translate(-50%, -50%);

  .close {
    position:absolute;
    top:10px;
    right:10px;
    width:30px;
    height:30px;
    background:url(../images/close.svg) 50% no-repeat;
  }
  .row {

    .col-xs-6:last-child {
      padding-left:30px;
    }
    .thumb {
      display:inline-block;
      position:relative;
      width:100%;
      height:165px;
      background-size:cover !important;
      margin:0 0 40px 0;

      .cat {
        position: absolute;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto;
        padding: 8px 10px 5px;
        color: #fff;
        z-index: 9;
        font-family: $akkurat_reg;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 4px;
        text-transform: uppercase;

        &.violet {
          background-color: #bd10e0;
        }
        &.blue {
          background-color: #4e93ce;
        }
        &.yellow {
          background-color: #f1951c;
        }
        &.green {
          background-color: #79cc19;
        }
      }
    }
    p {
      margin:0 0 10px 0;
    }
    .hide-on-desktop {
      display:none;
    }
    .hide-on-mobile {
      display: block;
    }
    .btn {
      min-width:130px;
      padding:10px 20px;
      margin-top:20px;
    }
    .date {
      color: #819eb5;
      font-family: $akkurat_reg;
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      text-transform: uppercase;
      margin: 0;
    }
    h3 {
      color: #4e2a84;
      font-family: $akkurat_bold;
      font-size: 24px;
      line-height: 36px;
      margin: 5px 0;
    }
    .location {
      display: inline-block;
      width: 100%;
      color: #819eb5;
      font-family: $akkurat_reg;
      font-size: 14px;
      line-height: 20px;
      padding-left: 20px;
      background: url(../images/location-icon.svg) 0 no-repeat;
      text-align: left;
      margin: 0 0 20px;
    }
    .excerpt {
      margin-top:10px;
    }
  }
}

.page-overlay {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(78, 42, 132, .5);
  z-index:999999;
}
