//
// Name:            Select Study
//


// ========================================================================
// Component: Select Study
// ========================================================================


.select-study {
	display:block;
	font-size:0;
	//margin-bottom:50px; - SM Removing, add back if needed

	&.top-layer {
		z-index:999;
	}
	form {
		display:inline-block;
		width:calc(65% + 5px);
		margin:0;
		vertical-align:top;

		.cs-select span {
			transition:.4s;
		}
		.cs-select li span {
			padding:5px 22px !important;
		}
		.cs-select > span:hover, .cs-select > span:focus {
			background:$dark_purple;
			color:#fff;
			box-shadow: 0 0 20px rgba(0,0,0,.6);
		}
	}
	form.no-labels .cs-select span {
		padding:17px 22px;
	}
	.btn {
		width:calc(30% - 40px);
		margin:10px 0;
		vertical-align:top;
		color: #000000;
		font-family: $akkurat_bold;
		font-size: 18px;
		line-height: 28px;
		border-radius: 2px;
		text-align:left;
		border: 1px solid #e0e7ed;
		background-color: #f2f5f8;
	}
}


@media only screen and (max-width: 1280px) and (min-width: 1px) {
	.select-study .btn {
		min-width:200px;
		width:200px;
		font-size:14px;
	}
	.select-study form {
		width:calc(100% - 200px);
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	.select-study form {
		width:100%;
	}
	.select-study .btn {
		width:100%;
	}
}
