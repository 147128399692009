//
// Name:            Sidebar Widget
//


// ========================================================================
// Component: Sidebar Widget
// ========================================================================


.widget {
	display:block;
	position:relative;
	border: 1px solid #e0e7ed;
	background-color: #f2f5f8;
	padding:25px;
	margin:0 0 50px 0;
	max-width:265px;

	.header3 {
		border-bottom: 5px solid #e0e7ed;
		padding-bottom:5px;
	}
	a {
		display:inline-block;
		margin:10px 0;
		-ms-word-break: break-all;
		word-break: break-all;
		word-break: break-word;
	}
	.title {
		margin:10px 0 0 0;
		font-family: $akkurat_bold;
	}
	.phone {
		display:block;
		width:100%;
		padding-left:20px;
		background:url(../images/phone-icon.svg) left center no-repeat;
		font-family: $akkurat_bold;
	}
	&.fixed {
	    position: fixed;
	    top: 50px;
	}
	&.bottom {
	    position: absolute;
	    bottom: 0;
	    left:.5rem !important;
	    margin-bottom:0;
	}
	.body-link {
		font-size:15px;
	}
}

article #questions {
	max-width:100%;
	margin:80px 0 60px 0;
	padding:45px 50px;
}


@media only screen and (max-width: 960px) and (min-width: 1px) {
	#questions.fixed,
	#questions.bottom {
		position:relative;
		top:inherit;
		bottom:inherit;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.widget {
		max-width:100%;
	}
	article #questions {
		padding:25px;
		margin:30px 0 !important;
	}
	#questions-bottom #questions {
		margin:0 0 50px 0;
	}
}
