//
// Name:            Recital
//


// ========================================================================
// Component: Recital
// ========================================================================


.recital {
	display:inline-block;
	padding:20px 0 40px 0;
	width:100%;

	.date {
		color: #507796;
		font-family: $akkurat_reg;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		text-transform: uppercase;
		margin:0;		
	}
	h3 {
		margin:10px 0;
	}
	.location {
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 14px;
		line-height: 20px;
		padding-left:20px;
		background:url(../images/location-icon.svg) left 4px no-repeat;
	}
}


@media only screen and (max-width: 580px) and (min-width: 1px) {
	.recital .btns-cnt .btn,
	.recital .btns-cnt .purple-btn {
		width:100%;
		min-width:100%;
		margin:5px 0;
	}
}
