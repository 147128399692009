//
// Name:            Faculty
//


// ========================================================================
// Component: Faculty
// ========================================================================

//Will need to make bio / profile popup JS and CSS more 'generic' for client request to merge Student and Faculty popups - SM

#faculty {
	background-color: #f2f5f8;
	max-width:100%;
	padding:100px 150px;
	margin:100px auto 0 auto;

	.header2 {
		margin-bottom:20px;
	}
	.col-xs-3 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-3:first-child {
		padding-left:.5rem;
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-3:last-child {
		padding-left:calc(.5rem + 10px);
		padding-right:.5rem;
	}
}

#students {
	background-color: #fff;
	max-width:100%;
	margin:0 auto;

	.header2 {
		margin-bottom:20px;
	}
	.col-xs-3 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-3:first-child {
		padding-left:.5rem;
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-3:last-child {
		padding-left:calc(.5rem + 10px);
		padding-right:.5rem;
	}
}
#students.no-popup {
	padding:100px 150px;
}
#students.with-popup {
	padding:100px 0 0 0;

	> .row {
		padding-left:150px;
		padding-right:150px;
	}
}

.student-popup {
	display:none;
	position:relative;
	width:100%;
	overflow:hidden;
	z-index:999;
	background-color: #f2f5f8;

	.close {
		position:absolute;
		top:20px;
		right:20px;
		color: #507796;
		font-family: $akkurat_reg;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		z-index:999;
		text-transform: uppercase;
	}
	.photo {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		min-height:560px;
		background-size:cover !important;
		transform:scale(1) !important;
	}
	.cnt {
		padding:50px 90px;

		.title {
			color: #507796;
			font-family: $akkurat_bold;
			font-size: 12px;
			letter-spacing: 3px;
			line-height: 16px;
			text-transform: uppercase;
			margin:0 0 10px 0;				
		}
		.email {
			display:block;
			color: $purple;
			font-family: $akkurat_bold;
			font-size: 16px;
			line-height: 24px;
			margin:0 0 40px 0;	
		    -ms-word-break: break-all;
		    word-break: break-all;
		    word-break: break-word;						
		}
	}
	.scroll-wrapper {
		overflow-x:hidden;
		overflow-y:scroll;
		max-height:300px;
		padding-right:20px;

		&::-webkit-scrollbar {
			width:5px;
		}
		&::-webkit-scrollbar-track {
			background:transparent;
		}
		&::-webkit-scrollbar-thumb {
			background:$purple;
			border-radius:30px;
		}
	}
}

.faculty-popup {
	display:none;
	position:relative;
	width:100%;
	overflow:hidden;
	z-index:999;
	background-color: #f2f5f8;
	margin-bottom:30px;

	.close {
		position:absolute;
		top:20px;
		right:20px;
		//color: #507796;
		font-family: $akkurat_reg;
		//font-size: 12px;
		letter-spacing: 3px;
		//line-height: 16px;
		z-index:999;
		text-transform: uppercase;
		display: block;
		color: #4e2a84;
		font-size: 16px;
		line-height: 24px;
		margin: 0 0 40px;
	}
	.photo {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		min-height:380px;
		background-size:cover !important;
		transform:scale(1) !important;
	}
	.cnt {
		padding:40px 50px;

		.title {
			color: #507796;
			font-family: $akkurat_bold;
			font-size: 12px;
			letter-spacing: 3px;
			line-height: 16px;
			text-transform: uppercase;
			margin:0 0 10px 0;				
		}
		.email {
			display:block;
			color: $purple;
			font-family: $akkurat_bold;
			font-size: 16px;
			line-height: 24px;
			margin:0 0 40px 0;				
		}
	}
	.scroll-wrapper {
		overflow-x:hidden;
		overflow-y:scroll;
		max-height:300px;
		padding-right:20px;

		&::-webkit-scrollbar {
			width:5px;
		}
		&::-webkit-scrollbar-track {
			background:transparent;
		}
		&::-webkit-scrollbar-thumb {
			background:$purple;
			border-radius:30px;
		}
	}
}

.faculty, .students, .people-bio {
	margin:0 -.5rem 50px -.5rem;

	.col-xs-4 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-4:first-child {
		padding-left:.5rem;
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-4:last-child {
		padding-left:calc(.5rem + 10px);
		padding-right:.5rem;
	}
	.photo-wrap {
		position:relative;
		overflow:hidden;

		.photo {
			position:relative;
			width:100%;
			height:260px;
			background-size:cover !important;
			transform:scale(1);
			transition:.4s;

			a {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
			}
		}
		&:hover .photo, &:focus .photo {
			transform:scale(1.1);
		}
	}
	.thumb-wrap {
		position:relative;
		width:100%;
		height:260px;
		overflow:hidden;
	}
	.thumb {
		position:relative;
		width:100%;
		height:260px;
		background-size:cover !important;
		transform:scale(1);
		transition:.4s;

		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
	.name {
		display:inline-block;
		position:relative;
		color: $purple;
		font-family: $campton;
		font-size: 24px;
		line-height: 32px;
		margin:15px 0 10px 0;
		text-decoration:none;
	}
	.title {
		color: #4f7796;
		font-family: $akkurat_bold;
		font-size: 12px;
		line-height: 20px;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
	.email {
		display:block;
		color: $purple;
		font-family: $akkurat_bold;
		font-size: 14px;
		line-height: 20px;
		margin-top:-15px;
		margin-bottom:15px;
	    -ms-word-break: break-all;
	    word-break: break-all;
	    word-break: break-word;		
	}
	.col-xs-3.is_hover,
	.col-xs-4.is_hover {

		.photo, .thumb {
			transform:scale(1.1);
		}
		.name span {
			background-size: 100% 3px;
		}
	}
}

.faculty .photo-excerpts,
.people-bio .photo-excerpts {
	z-index:9999;
}

.faculty .photo-excerpts .col-xs-4,
.people-bio.with-popup .photo-excerpts .col-xs-4 {
	&:after {
		display:block;
		position:absolute;
		content:'';
		bottom:-20px;
		left:50%;
		width:0;
		height:0;
		z-index:9999;
		border-right:20px solid transparent;
		border-left:20px solid transparent;
		border-top:30px solid #fff;
		transform:translate3d(-50%, 100%, 0);
		opacity:0;
		transition:.4s;
	}
	&.active:after {
		opacity:0; //Hiding for now
	}
}

.students .col-xs-3 {

	&:after {
		display:block;
		position:absolute;
		content:'';
		bottom:-50px;
		left:50%;
		width:0;
		height:0;
		z-index:9999;
		border-right:20px solid transparent;
		border-left:20px solid transparent;
		border-top:30px solid #fff;
		transform:translate3d(-50%, 100%, 0);
		opacity:0;
		transition:.4s;
	}
	&.active:after {
		opacity:1;
	}
}

#faculty-conductor {

	.photo {
		width:100%;
		height:440px;
		background-size:cover !important;
	}
	.cnt {
		padding-left:40px;
	}
	.title {
		color: #507796;
		font-family: $akkurat_reg;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		margin-top:20px;
		text-transform: uppercase;		
	}
}

.faculty-title {
	color: #507796;
	font-family: $akkurat_bold;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
	letter-spacing: 3px;
}

.faculty-social {
	list-style:none;
	padding:0;

	li {
		display:inline-block;
		width:30px;
		height:30px;
		margin-right:10px;

		a {
			display:inline-block;
			width:30px;
			height:30px;
			text-indent:-9999px;
		}
		&.facebook {
			background:url(../images/facebook-single.svg) 50% no-repeat;
			background-size:30px 30px;
		}
		&.twitter {
			background:url(../images/twitter-single.svg) 50% no-repeat;
			background-size:30px 30px;
		}
		&.youtube {
			background:url(../images/youtube-single.svg) 50% no-repeat;
			background-size:30px 30px;
		}
		&.instagram {
			background:url(../images/instagram-single.svg) 50% no-repeat;
			background-size:30px 30px;
		}
		&.soundcloud {
			background:url(../images/soundcloud-single.svg) 50% no-repeat;
			background-size:30px 30px;
		}
	}
}

#people-cards {
	position: relative;
	max-width: 1240px;
	margin: 20px auto;
}


@media only screen and (max-width: 1280px) and (min-width: 1px) {
	#students.with-popup>.row {
		padding-left:0;
		padding-right:0;
	}
}

@media only screen and (max-width: 1024px) and (min-width: 1px) {
	// .faculty .col-xs-4 {
	// 	flex-basis: 50%;
	//     max-width: 50%;
	//     margin-bottom:40px;
	//     padding-left:.5rem !important;
	//     padding-right:.5rem !important;		
	// }
	#faculty {
		padding:100px 50px;
	}
	#faculty-conductor .photo {
		height:300px;
	}
	#faculty-conductor .cnt {
		padding-left:0;
	}
	#faculty-conductor .btn {
		min-width:100%;
	}
	#faculty-detail-resources {
		margin:0 auto;
	}
	#faculty-detail-resources > .row > .col-xs-6 {
	    flex-basis: 100%;
	    max-width: 100%;		
	}
	#students .col-xs-3 {
	    flex-basis: 50%;
	    max-width: 50%;
	    padding-left:.5rem !important;		
	    padding-right:.5rem !important;
	    margin-bottom:50px;	
	}
	.students {
		margin-bottom:0;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.faculty .col-xs-4, .people-bio .col-xs-4 {
		flex-basis: 100%;
	    max-width: 100%;		
	}
	.faculty .thumb, .students .thumb, .people-bio .thumb {
		max-width:260px;
	}
	#faculty .col-xs-3,
	#students .col-xs-3 {
		margin-bottom:50px;
	}
	#faculty .col-xs-3,
	#faculty .col-xs-3:first-child,
	#faculty .col-xs-3:last-child,
	#faculty .col-xs-4,
	#faculty .col-xs-4:first-child,
	#faculty .col-xs-4:last-child,
	#students .col-xs-3,
	#students .col-xs-3:first-child,
	#students .col-xs-3:last-child {
	    padding-left: .5rem;
	    padding-right: .5rem;		
	}
	.faculty, .students, .people-bio {
		margin:0;
	}
	#faculty-conductor .photo {
		max-width:380px;
		margin-bottom:30px;
	}
	#faculty-conductor .btn {
		max-width:280px;
		min-width:280px;
	}
	#about-faculty img {
		margin-bottom:30px;
	}
	.faculty-popup, .bio-popup {
		margin-bottom:30px;
	}
	#admin .faculty-popup {
		margin-left:.5rem;
		margin-right:.5rem;
	}
	.faculty-popup .col-xs-4, .bio-popup .col-xs-4 {
		flex-basis: 50% !important;
	    max-width: 50% !important;		
	}
	.faculty-popup .col-xs-8, .bio-popup .col-xs-8 {
		flex-basis: 50% !important;
	    max-width: 50% !important;		
	}
	.faculty-popup .photo, .bio-popup .photo {
		height:100% !important;
		max-width:100% !important;
	}
	.faculty .col-xs-4:first-child,
	.students .col-xs-4:first-child,
	.people-bio .col-xs-4:first-child {
		padding:0 .5rem !important;
	}
	.faculty .photo-excerpts .col-xs-4:after,
	.people-bio .photo-excerpts .col-xs-4:after {
		display:none !important;
	}
	.student-popup .photo {
		position:relative;
	}
	.section-faculty .fixed-width .col-xs-4 {
		order:1;
	}
	.section-faculty .fixed-width .col-xs-8 {
		order:0;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	#faculty-conductor {
		padding:0;
	}
	#faculty-conductor .btn {
		max-width:100%;
		min-width:100%;
	}
	.faculty-popup .col-xs-4 {
		flex-basis: 100% !important;
	    max-width: 100% !important;		
	}
	.faculty-popup .col-xs-8 {
		flex-basis: 100% !important;
	    max-width: 100% !important;		
	}
	.faculty-popup .photo {
		position:relative;
	}
	.faculty-popup .cnt {
		padding:30px !important;
	}
	.student-popup .photo {
		min-height:460px;
	}
	.student-popup .cnt {
		padding:30px;
	}
	#students .col-xs-3 {
	    flex-basis: 100%;
	    max-width: 100%;
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	#faculty {
		padding:50px 20px;
		margin:20px auto 0 auto;
	}
	#students.with-popup > .row {
		padding-left: 0px;
    	padding-right: 0px;
	}
	.faculty .thumb, .students .thumb {
		max-width:100%;
	}
	#area-of-study {
		padding:50px 20px 0 20px;
		margin:0 auto 20px auto;
	}
	.student-popup .photo {
		min-height:360px;
	}
}
