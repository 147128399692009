//
// Name:            Sliders
//


// ========================================================================
// Component: Sliders
// ========================================================================


.events-slider {
	display:block;
	position:relative;
	width:100%;
	padding:70px 0 70px 150px;
	background-color: #f2f5f8;

	.slider-header {
		padding-right:150px;
		margin-bottom:40px;

		.all-btn {
			position:absolute;
			bottom:0;
			right:128px;
			max-width:140px;
			min-width:140px;
			margin:0;
		}
		.transparent-btn {
			position:absolute;
			bottom:0;
			right:128px;
			margin:0;
		}
		.next,
		.video-next {
			position:absolute;
			bottom:0;
			right:0;
			width:60px;
			height:60px;
			border:1px solid $purple;

			&:after {
				position:absolute;
				content:'';
				top:50%;
				left:50%;
				width:25px;
				height:25px;
				background:url(../images/purple-arrow.svg) 50% no-repeat;
				background-size:100%;
				transform:translate(-50%, -50%) rotate(-90deg);
			}
			&:hover, &:focus {
				background:$dark_purple;

				&:after {
					background:url(../images/white-arrow.svg) 50% no-repeat;
				}
			}
		}
		.prev,
		.video-prev {
			position:absolute;
			bottom:0;
			right:59px;
			width:60px;
			height:60px;
			border:1px solid $purple;

			&:after {
				position:absolute;
				content:'';
				top:50%;
				left:50%;
				width:25px;
				height:25px;
				background:url(../images/purple-arrow.svg) 50% no-repeat;
				background-size:100%;
				transform:translate(-50%, -50%) rotate(90deg);
			}
			&:hover, &:focus {
				background:$dark_purple;

				&:after {
					background:url(../images/white-arrow.svg) 50% no-repeat;
				}
			}
		}
	}
	.event-slide {

		.video-thumb {
			position:relative;
			width:100%;
			height:230px;
			background:#000;
			overflow:hidden;

			.play {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:9;

				&:before {
					position:absolute;
					content:'';
					top:50%;
					left:50%;
					width:51px;
					height:51px;
					background:url(../images/play-btn.png) 50% no-repeat;
					z-index:99;
					transform:translate(-50%, -50%);
				}
			}
			.thumb {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				z-index:8;
				opacity:.6;
				background-size:cover !important;
			}
		}
		.thumb-wrap {
			position:relative;
			width:100%;
			height:230px;
			overflow:hidden;

			span {
				position:absolute;
				bottom:0;
				left:0;
				width:auto;
				height:auto;
				padding:8px 10px 5px 10px;
				color: #ffffff;
				z-index:9;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height:16px;
				letter-spacing: 4px;
				text-transform: uppercase;

		        &.violet,&.bienen-ensembles {
		          background-color: #bd10e0;
		        }
		        &.blue,&.recitals-visiting-artists, &.recitals-lectures {
		          background-color: #4e93ce;
		        }
		        &.yellow,&.festivals-series {
		          background-color: #f1951c;
		        }
		        &.green,&.student-recitals {
		          background-color: #79cc19;
		        }
			}
			&:hover .thumb, &:focus .thumb {
				transform:scale(1.1);
			}
			.thumb a {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
			}
		}
		.thumb {
			position:relative;
			width:100%;
			height:230px;
			background-size:cover !important;
			transform:scale(1);
			transition:.4s;

			.play {
				position:absolute;
				top:50%;
				left:50%;
				width:51px;
				height:51px;
				background:url(../images/play-btn.png) 50% no-repeat;
				z-index:99;
				transform:translate(-50%, -50%);
			}
		}
		.date {
			color: #819eb5;
			font-family: $akkurat_reg;
			font-size: 12px;
			letter-spacing: 3px;
			line-height: 16px;
			text-transform: uppercase;
			margin:20px 0 0 0;
		}
		.event-title {
			color: #4e2a84;
			font-family: $campton;
			font-size: 22px;
			line-height: 32px;
			margin:10px 0;

			a {
				position:relative;

				span {
					width: calc(100%);
					background-image: linear-gradient(transparent calc(100% - 2px), $purple 2px);
					background-repeat: no-repeat;
					background-size: 0% 3px;
					background-position:left bottom;
  					transition: background-size .4s;
				}
				&:hover span, &:focus span {
				    background-size: 100% 3px;
				}
				&:hover, &:focus {
					color:$purple;
				}
			}
		}
		.players {
			display:inline-block;
			width:100%;
			color: #819eb5;
			font-family: $akkurat_reg;
			font-size: 14px;
			line-height: 20px;
			text-align:left;
			margin:0;
		}
		.location {
			display:inline-block;
			width:100%;
			color: #819eb5;
			font-family: $akkurat_reg;
			font-size: 14px;
			line-height: 20px;
			padding-left:20px;
			background:url(../images/location-icon.svg) left center no-repeat;
			text-align:left;
			margin:0;
		}
		.event-btns {
			margin:40px 0 0 0;
			font-size:0;
			text-align:left;

			a {
				width:calc(50% - 10px);
				min-width:140px;
				height:40px;
				line-height:12px !important;
			}
			.purple-btn {
				margin-right:5px;

				&:hover, &:focus {
					box-shadow:none !important;
				}
			}
			.btn {
				margin-left:5px;
			}
		}
		&.is_hover {

			.thumb {
				transform:scale(1.1);
			}
			.event-title span {
				background-size:100% 3px;
			}
		}
	}
	.swiper-slide {
		opacity:.5;
	}
	.swiper-slide-next,
	.swiper-slide-active,
	.swiper-slide-next + div {
		opacity:1 !important;
	}
}

.video-slider {
  //.video-slide refactor later - SM
  .event-slide {
    .video-credit {
      display:inline-block;
      width:100%;
      color: #819eb5;
      font-family: $akkurat_reg;
      font-size: 14px;
      line-height: 20px;
      //padding-left:20px;
      //background:url(../images/location-icon.svg) left center no-repeat;
      text-align:left;
      margin:0;
    }
  }
}

.pdf-slider {
	display:block;
	position:relative;
	width:100%;
	padding:70px 0 70px 0;

	a.permalink {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:999;
	}
	.slider-header {
		padding:0 150px;
		margin-bottom:40px;

		.all-btn {
			position:absolute;
			bottom:0;
			right:128px;
			max-width:140px;
			min-width:140px;
			margin:0;
		}
		.pdf-next {
			position:absolute;
			bottom:0;
			right:0;
			width:60px;
			height:60px;
			border:1px solid $purple;

			&:after {
				position:absolute;
				content:'';
				top:50%;
				left:50%;
				width:25px;
				height:25px;
				background:url(../images/purple-arrow.svg) 50% no-repeat;
				background-size:100%;
				transform:translate(-50%, -50%) rotate(-90deg);
			}
			&:hover, &:focus {
				background:$dark_purple;

				&:after {
					background:url(../images/white-arrow.svg) 50% no-repeat;
				}
			}
		}
		.pdf-prev {
			position:absolute;
			bottom:0;
			right:59px;
			width:60px;
			height:60px;
			border:1px solid $purple;

			&:after {
				position:absolute;
				content:'';
				top:50%;
				left:50%;
				width:25px;
				height:25px;
				background:url(../images/purple-arrow.svg) 50% no-repeat;
				background-size:100%;
				transform:translate(-50%, -50%) rotate(90deg);
			}
			&:hover, &:focus {
				background:$dark_purple;

				&:after {
					background:url(../images/white-arrow.svg) 50% no-repeat;
				}
			}
		}
	}
	.news-post h3 a {
		text-decoration:none;
	}
	.event-slide {

		.thumb {
			display:block;
			margin:0 auto;
			position:relative;
			width:100%;
			max-width:360px;
			height:520px;
			background-size:cover !important;

			span {
				position:absolute;
				top:0;
				left:0;
				width:auto;
				height:auto;
				padding:8px 10px 5px 10px;
				color: #ffffff;
				z-index:9;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height:16px;
				letter-spacing: 4px;
				text-transform: uppercase;

				&.purple {
					background-color: $purple;
				}
		        &.violet,&.bienen-ensembles {
		          background-color: #bd10e0;
		        }
		        &.blue,&.recitals-visiting-artists, &.recitals-lectures {
		          background-color: #4e93ce;
		        }
		        &.yellow,&.festivals-series {
		          background-color: #f1951c;
		        }
		        &.green,&.student-recitals {
		          background-color: #79cc19;
		        }
			}
		}
		.tertiary-btn {
			display:block;
			margin:20px auto;
			max-width:120px;
		}
	}
	.swiper-slide {
		opacity:.5;
	}
	.swiper-slide-prev,
	.swiper-slide-active,
	.swiper-slide-next {
		opacity:1 !important;
	}
}

#related-news-slider,
#home-news-slider {

	.news-post {
		margin:10px 0;
	}
	.news-post .cnt {
		top:0;
		transform:translate(0, 0);
	}
	.swiper-wrapper {
		padding-bottom:20px;
	}
	.swiper-slide {
		height:auto;
	}
}

.photo-content-slider {
	display:block;
	position:relative;
	width:100%;
	padding:70px 0 70px 0;

	.slider-header {
		padding-right:calc(33.3333% + .85rem);
		margin-bottom:0;

		h1 {
			padding:0 50px 30px 50px;
		}
		.pagination {
			display:none;
			width:auto;

			.swiper-pagination-bullet {
				display:inline-block;
				width:10px;
				height:10px;
				border-radius:10px;
				margin:0 5px;
				background-color: #f6f4f9;
				border:1px solid #aaa;
			}
			.swiper-pagination-bullet-active {
				background:$purple;
				border:1px solid $purple;
			}
		}
		.next,
		.events-next {
			position:absolute;
			bottom:0;
			right:0;
			width:60px;
			height:60px;
			border:1px solid $purple;

			&:after {
				position:absolute;
				content:'';
				top:50%;
				left:50%;
				width:25px;
				height:25px;
				background:url(../images/purple-arrow.svg) 50% no-repeat;
				background-size:100%;
				transform:translate(-50%, -50%) rotate(-90deg);
			}
			&:hover, &:focus {
				background:$dark_purple;

				&:after {
					background:url(../images/white-arrow.svg) 50% no-repeat;
				}
			}
		}
		.prev,
		.events-prev {
			position:absolute;
			bottom:0;
			right:59px;
			width:60px;
			height:60px;
			border:1px solid $purple;

			&:after {
				position:absolute;
				content:'';
				top:50%;
				left:50%;
				width:25px;
				height:25px;
				background:url(../images/purple-arrow.svg) 50% no-repeat;
				background-size:100%;
				transform:translate(-50%, -50%) rotate(90deg);
			}
			&:hover, &:focus {
				background:$dark_purple;

				&:after {
					background:url(../images/white-arrow.svg) 50% no-repeat;
				}
			}
		}
	}
	.event-slide {

		a.permalink {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:999;

			&:hover + .thumb-wrap .thumb, &:focus + .thumb-wrap .thumb {
				transform:scale(1.1);
			}
		}
		&.is_hover .slide-caption h3 a span {
			background-size:100% 3px;
		}
		.thumb-wrap {
			position:relative;
			width:100%;
			height:500px;
			overflow:hidden;
		}
		.thumb {
			position:relative;
			width:100%;
			height:500px;
			max-height: 500px;
			z-index:1;
			background-size:cover !important;
			transform:scale(1);
			transition:.4s;

			.play {
				position:absolute;
				top:50%;
				left:50%;
				width:51px;
				height:51px;
				background:url(../images/play-btn.png) 50% no-repeat;
				z-index:99;
				transform:translate(-50%, -50%);
			}
			span.cat {
				position:absolute;
				bottom:0;
				right:0;
				width:auto;
				height:auto;
				padding:8px 30px 5px 30px;
				color: #ffffff;
				z-index:9;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height:16px;
				letter-spacing: 4px;
				text-transform: uppercase;

				&.purple {
					background-color: $purple;
				}
        &.violet,&.bienen-ensembles {
          background-color: #bd10e0;
        }
        &.blue,&.recitals-visiting-artists, &.recitals-lectures {
          background-color: #4e93ce;
        }
        &.yellow,&.festivals-series {
          background-color: #f1951c;
        }
        &.green,&.student-recitals {
          background-color: #79cc19;
        }
			}
		}
		.slide-caption {
			display:inline-block;
			position:absolute;
			bottom:-50px;
			right:0;
			max-width:80%;
			background:#fff;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
			padding:40px;
			z-index:9999;

			h3 {
				margin-bottom:10px;

				a {
					color: $purple;
					font-family: $campton;
					font-size: 24px;
					font-weight: 500;
					line-height: 32px;
				}
			}
		}
		.cnt {
			padding:0 30px;

			.secondary {
				letter-spacing:3px;
			}

			.btn {
				width: 100%;
				min-width: 180px;
			}
		}
		.event-desc {
			color: #000000;
			font-family: $akkurat_reg;
			font-size: 24px;
			line-height: 36px;
		}
		.event-copy {
			color: #000000;
			font-family: $akkurat_reg;
			font-size: 16px;
			line-height: 28px;
		}
	}
}

#home-events-slider {
	padding-right:50px;

	.slider-header {
		padding-right:0;

		.events-prev {
			right:67px;
		}
		.events-next {
			right:8px;
		}
	}
	.event-slide {
		padding-bottom:55px;
	}
	@media only screen and (max-width: 580px) and (min-width: 1px) {
		.row {
			margin-left: 0;
			margin-right: 0;
			width: 100%;

			.col-xs-12 {
				padding: 0;
			}
		}
	}
	.slide-caption {
		width:80%;
		right:8px;
		
		@media only screen and (max-width: 580px) and (min-width: 1px) {
			right: 0;
		}

		.date {
			position:absolute;
			top:-34px;
			left:0;
			width:auto;
			background-color: #4f7796;
			color: #ffffff;
			font-family: $akkurat_bold;
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: 3px;
			text-align:center;
			padding:5px 15px;
		}
		.location {
			display:inline-block;
			width:100%;
			color: #819eb5;
			font-family: $akkurat_reg;
			font-size: 14px;
			line-height: 20px;
			padding-left:20px;
			background:url(../images/location-icon.svg) left center no-repeat;
			text-align:left;
			margin:0;
		}
	}
}

#post-slider {

	.slider-header {
		padding-right:.5rem;
	}
	.event-slide .thumb .slide-caption {

	}
}

#upcoming-events-slider {
	background-image: linear-gradient(145deg, rgba(224, 232, 238, 0.25) 0%, #e0e8ee 100%);
}
#featured-events-slider,
#davee-media-library {
	background:#fff;
}
.events-slider .ensembles-single,
.page-template-template-faculty-detail .transparent-btn {
	display:none;
}
.page-template-template-performing-ensembles-single .events-slider .ensemble-single {
	display:block;
	position:absolute;
	right:150px;
	bottom:0;
	margin:0;
}
.page-template-template-resources-students .events-slider .resource-all {
	display:block;
	position:absolute;
	right:125px;
	bottom:0;
	margin:0;
	min-width:140px;
}
.page-template-template-performing-ensembles-single .events-slider {
	background:#fff;
	padding:0 0 0 150px;
}

#series-slider {
	position:relative;
	height:600px;
	background:transparent;
	overflow:hidden;
	margin-left:0;
	margin-right:0;

	.col-xs-7,
	.col-xs-5,
	.col-xs-8,
	.col-xs-4 {
		padding-left:0;
		padding-right:0;
	}
	.swiper-container {
		width:100%;
		height:100%;
		background:$purple;
	}
	.swiper-slide {
		padding:100px 65px;
		text-align:left;

		.slider-title {
		    font-family: $akkurat_reg;
		    font-size: 12px;
		    letter-spacing: 3px;
		    line-height: 16px;
		    color:#fff;
		    text-transform: uppercase;
		}
		.series-copy {
			color: #ffffff;
			font-size: 24px;
			line-height: 36px;
		}
		.event-title {
		    font-family: $akkurat_bold;
		    font-size: 36px;
		    line-height: 44px;
		    color:#fff;
		    margin:40px 0;
		}
		a {
			display:inline-block;
			position:relative;
		    font-family: $akkurat_bold;
		    color:#fff;
		    font-size:16px;
		    padding-right:30px;
		    text-decoration:none;

		    &:hover, &:focus {
		    	opacity:.5;
		    }
			// span.text {
			// 	width: calc(100%);
			// 	background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);
			// 	background-repeat: no-repeat;
			// 	background-size: 0% 100%;
			// 	transition: background-size .4s;
			// }
			// &:hover span.text {
			//     background-size: 100% 100%;
			// }
			// &:hover {
			// 	color:#fff;
			// }
		    .ico {
		    	display:inline-block;
		    	position:absolute;
		    	width:16px;
		    	height:16px;
		    	top:4px;
		    	right:0;
			    background:url(../images/white-arrow.svg) 50% no-repeat;
			    background-size:100%;
			    transform:rotate(-90deg);
		    }
		}
	}
	.series-pagination {
		position:absolute;
		width:auto;
		bottom:25px;
		left:65px;
		z-index:999;

		.swiper-pagination-bullet {
			display:inline-block;
			width:10px;
			height:10px;
			background:#fff;
			margin:0 15px 0 0;
			opacity:.5;
		}
		.swiper-pagination-bullet-active {
			opacity:1;
		}
	}
	.prev,
	.series-prev {
		position: absolute;
	    bottom: 0;
	    right: 59px;
	    width: 60px;
	    height: 60px;
	    z-index:999;
	    border: 1px solid #fff;
	    opacity:.5;

	    &:after {
			position: absolute;
		    content: "";
		    top: 50%;
		    left: 50%;
		    width: 25px;
		    height: 25px;
		    background: url(../images/white-arrow.svg) 50% no-repeat;
		    background-size: 100%;
		    transform: translate(-50%,-50%) rotate(90deg);
	    }
		&:hover, &:focus {
			background:#fff;
			opacity:1;

			&:after {
				background:url(../images/purple-arrow.svg) 50% no-repeat;
			}
		}
	}
	.next,
	.series-next {
		position: absolute;
	    bottom: 0;
	    right: 0;
	    width: 60px;
	    height: 60px;
	    z-index:999;
	    border: 1px solid #fff;
	    opacity:.5;

	    &:after {
			position: absolute;
		    content: "";
		    top: 50%;
		    left: 50%;
		    width: 25px;
		    height: 25px;
		    background: url(../images/white-arrow.svg) 50% no-repeat;
		    background-size: 100%;
		    transform: translate(-50%,-50%) rotate(-90deg);
	    }
		&:hover, &:focus {
			background:#fff;
			opacity:1;

			&:after {
				background:url(../images/purple-arrow.svg) 50% no-repeat;
			}
		}
	}
	.series-photos {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1;
		background:#000;

		.photo {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:5;
			opacity:0;
			transition:.4s;
			background-size:cover !important;
			background-attachment:fixed !important;

			&.active {
				opacity:1;
			}
		}
	}
}

@media only screen and (max-width: 1180px) and (min-width: 1px) {
	#photo-content-slider .btn {
		min-width:100%;
	}
	#series-slider .swiper-slide .series-copy {
		font-size:18px;
		line-height:24px;
	}
}

@media only screen and (max-width: 1080px) and (min-width: 1px) {
	.page-template-template-performing-ensembles-single .events-slider .header2 {
		position:relative;
		width:100%;
		display:inline-block;
		margin-bottom:20px;
	}
	.page-template-template-performing-ensembles-single .events-slider .ensemble-single {
		position:relative;
		right:inherit;
		max-width:220px;
	}

}

@media only screen and (max-width: 1024px) and (min-width: 1px) {
	.events-slider {
		padding:70px 0 70px 50px;
	}
	.events-slider .slider-header {
		padding-right:50px;
	}
	.photo-content-slider .slider-header {
		padding-right:0;
		text-align:center;
	}
	.photo-content-slider .slider-header .prev,
	.photo-content-slider .slider-header .next {
		position:relative;
		right:0;
		display:inline-block;
	}
	.photo-content-slider .event-slide .cnt {
		padding:0 20px;
	}
	.photo-content-slider .slider-header .pagination {
		display:none;
	}
}

@media only screen and (max-width: 960px) and (min-width: 1px) {
	#events-slider .slider-header,
	#featured-events-slider .slider-header,
	#upcoming-events-slider .slider-header {
		padding-right:30px;
	}
	.pdf-slider .slider-header {
		padding:0 50px;
	}
	#video-slider {
		padding:70px 20px;
	}
	#video-slider.events-slider .slider-header {
		padding-right:0;
	}
	#series-slider > .col-xs-8 {
	    flex-basis: 50%;
	    max-width: 50%;
	}
	#series-slider > .col-xs-4 {
	    flex-basis: 50%;
	    max-width: 50%;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	#resources-slider.events-slider {
		margin-bottom:50px;
	}
	#resources-slider .swiper-container {
		margin-right:50px;
	}
	.photo-content-slider .event-slide .cnt {
		padding:50px 20px 0 20px;
	}
	#photo-content-slider {
		margin-bottom:0;
	}
	#series-slider > .col-xs-8,
	#series-slider > .col-xs-4 {
	    flex-basis: 100%;
	    max-width: 100%;
	}
	#home-events-slider {
		padding:0;
	}
	#home-events-slider .slide-caption {
		width:90%;
		max-width:90%;
		bottom:-30px;
	}
	.photo-content-slider .slider-header h1 {
		line-height:120px;
	}
}

@media only screen and (max-width: 767px) and (min-width: 1px) {
	#events-slider,
	#featured-events-slider,
	#upcoming-events-slider {
		padding:50px 40px;
	}
	#events-slider .slider-header,
	#featured-events-slider .slider-header,
	#upcoming-events-slider .slider-header {
		padding-right:0;
	}
	.event-btns a {
		width:100% !important;
		margin:0 0 10px 0 !important;
	}
	.events-slider h2 {
		margin-bottom:30px;
	}
	.events-slider .slider-header .all-btn {
		position:relative;
		right:inherit;
		vertical-align:top;
	}
	.events-slider .slider-header .prev {
		position:relative;
		right:inherit;
		display:inline-block;
		vertical-align:top;
	}
	.events-slider .slider-header .next {
		position:relative;
		right:inherit;
		display:inline-block;
		vertical-align:top;
	}
	#faculty-conductor {
		padding:0 20px;
	}
	.page-template-template-performing-ensembles-single .events-slider .ensemble-single {
		margin-bottom:20px;
	}
	.photo-content-slider .slider-header h1 {
		padding:0 20px;
	}
	.events-slider .slider-header .transparent-btn {
		right:0;
	}
	.photo-content-slider .slider-header h1 {
		//line-height:32px;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	#events-slider .slider-header .prev,
	#featured-events-slider .slider-header .prev,
	#upcoming-events-slider .slider-header .prev {
		position:relative;
	}
	#events-slider .slider-header .next,
	#featured-events-slider .slider-header .next,
	#upcoming-events-slider .slider-header .next {
		position:relative;
	}
	#events-slider .slider-header,
	#featured-events-slider .slider-header,
	#upcoming-events-slider .slider-header {
		position:static;
		text-align:center;
	}
	#events-slider .slider-header h2,
	#featured-events-slider .slider-header h2,
	#upcoming-events-slider .slider-header h2 {
		padding:0 20px;
	}
	#events-slider,
	#featured-events-slider,
	#upcoming-events-slider {
		padding:50px 0;
	}
	#events-slider .swiper-container,
	#featured-events-slider .swiper-container,
	#upcoming-events-slider .swiper-container {
		margin:0 50px;
	}
	.page-template-template-resources-students .events-slider .resource-all {
		position:relative;
		right:0;
		margin-bottom:30px;
	}
	#resources-slider.events-slider .slider-header {
		text-align:center;
	}
	.pdf-slider .slider-header .all-btn {
		position:relative;
		right:0;
	}
	.photo-content-slider .event-slide .thumb {
		height:300px;
	}
	.photo-content-slider .event-slide .thumb .slide-caption {
		max-width:100%;
	}
	#photo-content-slider {
		padding:30px 0;
	}
	.photo-content-slider .event-slide .thumb .slide-caption {
		box-shadow:none;
	}
	#series-slider .series-prev,
	#series-slider .series-next {
		display:none;
	}
	#series-slider .series-pagination {
		left:30px;
		width:auto !important;
	}
	#series-slider .series-pagination .swiper-pagination-bullet {
		margin:0 10px;
	}
	#home-events-slider .slider-header .events-prev {
		right:inherit;
		left:0;
	}
	.pdf-slider .slider-header {
		padding:0 20px;
	}
	#home-events-slider .slide-caption {
		width:100%;
		max-width:100%;
	}
	.events-slider .slider-header .transparent-btn {
		position:relative;
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	.events-slider {
		padding:70px 20px;
	}
	.events-slider .slider-header .all-btn {
		max-width:100%;
		min-width:100%;
		margin-bottom:30px;
	}
	#events-slider .transparent-btn {
		max-width:calc(100% - 40px);
		min-width:calc(100% - 40px);
		margin-left:20px;
	}
	.events-slider .slider-header {
		padding-right:0;
	}
	#resources-slider .swiper-container {
		margin-right:0;
	}
	.pdf-slider .slider-header {
		padding:0 20px;
	}
	.photo-content-slider .slider-header h1 {
		margin-bottom:30px;
	}
	.photo-content-slider .event-slide .thumb .slide-caption {
		padding:20px;
	}
	.events-slider h2 {
		text-align:center;
	}
	.events-slider .slider-header .transparent-btn {
		margin-bottom:20px;
	}
	#events-slider .swiper-container, #featured-events-slider .swiper-container, #upcoming-events-slider .swiper-container {
		margin:0 20px;
	}
	.events-slider .slider-header {
		text-align:center;
	}
	.events-slider .slider-header .next, .events-slider .slider-header .video-next,
	.events-slider .slider-header .prev, .events-slider .slider-header .video-prev {
		position:relative;
		display:inline-block;
		right:inherit;
	}
}

.page-node-type-faculty-details #upcoming-events-slider {
    background: #fff;
}