//
// Name:            Box Office
//


// ========================================================================
// Component: Box Office
// ========================================================================


.box-office {

	.col-xs-4 {
		background:$purple;
	}
	.col-xs-8 {
		position:relative;
	}
	.cnt {
		padding:100px 65px;

		.title {
			color: #ffffff;
			font-family: $campton;
			font-size: 24px;
			line-height: 32px;
		}
		.location {
			color: #ffffff;
			font-family: $akkurat_reg;
			font-size: 16px;
			line-height: 28px;
			padding-left:20px;
			background:url(../images/footer-location.svg) left 7px no-repeat;
		}
		.phone {
			color: #ffffff;
			font-family: $akkurat_reg;
			font-size: 16px;
			line-height: 28px;
			padding-left:20px;
			background:url(../images/footer-phone.svg) left 7px no-repeat;

			a {
				color: #ffffff;
				font-family: $akkurat_reg;
				font-size: 16px;
				line-height: 28px;
				text-decoration:underline;

				&:hover {
					opacity:.5;
					//color:$lt-purple;
				}
			}
		}
		.hours, .email {
			color: #ffffff;
			font-family: $akkurat_bold;
			font-size: 16px;
			line-height: 28px;
			padding-left:20px;
			margin:20px 0 0 0;
			background:url(../images/clock-icon.svg) left 7px no-repeat;
			
			&.email {
				background:url(../images/footer-email.svg) left 11px no-repeat;
				
				a {
					color: #fff;
					text-decoration: underline;
					
					&:hover {
						opacity: 0.5;
					}
				}
			}
		}
		ul {
			list-style:none;
			padding:0;
			margin:0 0 0 20px;

			li {
				color: #ffffff;
				font-family: $akkurat_reg;
				font-size: 16px;
				line-height: 28px;
				margin:0;

				span {
					float:right;
				}
			}
		}
	}
	.photo {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		min-height:150px;
		z-index:2;
		background-size:cover !important;
	}
}

#performance-venues .col-xs-6:nth-child(2),
#ticket-office .col-xs-6:first-child {
	padding-right:calc(.5rem + 10px);
}

#performance-venues .col-xs-6:last-child,
#ticket-office .col-xs-6:last-child {
	padding-left:calc(.5rem + 10px);
}


@media only screen and (max-width: 1280px) and (min-width: 1px) {
	.box-office .col-xs-4 {
	    flex-basis: 50%;
	    max-width: 50%;		
	}
	.box-office .col-xs-8 {
	    flex-basis: 50%;
	    max-width: 50%;		
	}
}

@media only screen and (max-width: 1024px) and (min-width: 1px) {
	.spaces .col-xs-4 {
		flex-basis: 50%;
	    max-width: 50%;
	    padding-left:.5rem;
	    padding-right:.5rem;
	    margin-bottom:40px;
	}
	.spaces .col-xs-4:first-child {
	    padding-right:.5rem;
	}
	.spaces .col-xs-4:last-child {
	    padding-left:.5rem;
	}
	.spaces .col-xs-4:last-child {
		margin-bottom:0;
	}
}

@media only screen and (max-width: 880px) and (min-width: 1px) {
	.box-office .col-xs-4 {
	    flex-basis: 60%;
	    max-width: 60%;		
	}
	.box-office .col-xs-8 {
	    flex-basis: 40%;
	    max-width: 40%;		
	}
	#series-slider .col-xs-5 {
	    flex-basis: 50%;
	    max-width: 50%;		
	}
	#series-slider .col-xs-7 {
	    flex-basis: 50%;
	    max-width: 50%;		
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.box-office .col-xs-4 {
	    flex-basis: 100%;
	    max-width: 100%;
	    order:1;	
	}
	.box-office .col-xs-8 {
	    flex-basis: 100%;
	    max-width: 100%;
	    order:0;	
	}
	.box-office .photo {
		display:none;
		position:relative;
		height:200px;
		min-height:200px;
	}
	#series-slider .col-xs-5 {
	    flex-basis: 100%;
	    max-width: 100%;		
	}
	#series-slider .col-xs-7 {
	    flex-basis: 100%;
	    max-width: 100%;		
	}
	#series-slider {
		height:auto;
	}
	#series-slider .series-photos {
		position:relative;
		height:350px;
	}
	#series-slider .series-photos .photo {
		height:350px;
	}
	#performance-venues .col-xs-6:nth-child(2),
	#ticket-office .col-xs-6:first-child {
		padding-right:.5rem;
	}

	#performance-venues .col-xs-6:last-child,
	#ticket-office .col-xs-6:last-child {
		padding-left:.5rem;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	.box-office .photo {
		min-height:300px;
	}
	.box-office .cnt {
		padding:40px;
	}
	.box-office .reverse-btn {
		min-width:100%;
	}
	#series-slider .series-photos,
	#series-slider .series-photos .photo {
		//position:relative;
		height:250px;
	}
	#series-slider .series-photos .photo {
		background-attachment:scroll !important;
	}
	#series-slider .swiper-slide {
		padding:40px 40px 80px 40px;
	}
	#series-slider .swiper-slide .event-title {
		font-size:24px;
		line-height:32px;
	}
	.spaces .col-xs-4 {
		flex-basis: 100%;
	    max-width: 100%;		
	}
}
