//
// Name:            Jobs
//


// ========================================================================
// Component: Jobs
// ========================================================================


.jobs {

	.col-xs-4 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
		margin-bottom:30px;
	}
	.col-xs-4:first-child {
		padding-left:.5rem;
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-4:last-child {
		padding-left:calc(.5rem + 10px);
		padding-right:.5rem;
	}
	.thumb-wrap {
		position:relative;
		width:100%;
		height:230px;
		overflow:hidden;

		.thumb {
			position:relative;
			width:100%;
			height:230px;
			background-size:cover !important;
			transform:scale(1);
			transition:.4s;

			&:hover, &:focus {
				transform:scale(1.1);
			}
			a {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
			}
		}
	}
	a.job-title {
		display:block;
		color: $purple;
		font-family: $campton;
		font-size: 24px;
		line-height: 32px;
		margin:20px 0 0 0;		
	}
	.col-xs-4.is_hover {

		.thumb {
			transform:scale(1.1);
		}
		.job-title span {
			background-size:100% 3px;
		}
	}
}


@media only screen and (max-width: 1024px) and (min-width: 1px) {
	.jobs a.job-title {
		font-size:18px;
		line-height:26px;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.jobs .col-xs-4,
	.jobs .col-xs-4:first-child,
	.jobs .col-xs-4:last-child {
	    flex-basis: 100%;
	    max-width: 100%;
	    padding-left:.5rem;
	    padding-right:.5rem;	
	}
	.jobs .thumb-wrap {
		max-width:230px;
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	.jobs .thumb-wrap {
		max-width:100%;
	}
	.page-template-template-student-employment-position .btn {
		width:100%;
	}
}
