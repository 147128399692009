//
// Name:            Home
//


// ========================================================================
// Component: Home
// ========================================================================





@media only screen and (max-width: 1024px) and (min-width: 1px) {
	#home-events > .top-row > .col-xs-8,
	#home-events > .top-row > .col-xs-4 {
		flex-basis: 100%;
	    max-width: 100%;
	}
	#home-events .event-sidebar {
		padding:0 50px 50px 50px;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 1px) {
	#media-library .col-xs-6 {
	    flex-basis: 100%;
	    max-width: 100%;		
	}
	#media-library .text-left .col-xs-6 {
	    flex-basis: 50%;
	    max-width: 50%;		
	}
	#media-library {
		padding:100px 50px 0 50px;
	}
	#media-library .all {
		display:block;
		max-width:260px;
		margin:10px auto;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.event .col-xs-4 {
	    flex-basis: 25%;
	    max-width: 25%;		
	}
	.event .col-xs-8 {
	    flex-basis: 75%;
	    max-width: 75%;		
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.event .col-xs-4,
	.event .col-xs-8 {
	    flex-basis: 100%;
	    max-width: 100%;		
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	#media-library {
		padding:50px 0 0 0;
	}
	#media-library .text-left {
		padding:0 20px;
	}
	#media-library .text-left .col-xs-6 {
	    flex-basis: 100%;
	    max-width: 100%;		
	}
	#media-library .featured-video .video-caption {
		width:100%;
	}
	#media-library .featured-video .video-thumb,
	#media-library .featured-video .video-thumb-wrap {
		height:300px;
	}
	#media-library .col-xs-6 .media-video {
		padding:0 !important;
	}
	#media-library .featured-video .video-caption .info {
		padding-left:0;
	}
	#media-library .featured-video .video-caption .play {
		display:block;
		position:relative;
		top:-10px;
		left:50%;
		transform:translate(-50%, 0);
	}
	#home-events .event-sidebar {
		padding:0 20px 50px 20px;
	}
	#home-events-slider {
		margin-bottom:40px;
	}
}


