@import "common/variables";
// Automatically injected Bower dependencies
// via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/sass-flex-mixin/_flex.scss";
@import "../../bower_components/flexboxgrid-sass/flexboxgrid.scss";
@import "../../bower_components/css-hamburgers/_sass/hamburgers/hamburgers.scss";
// endbower
// bower:css
@import "../../bower_components/swiper/dist/css/swiper.css";
@import "../../bower_components/fullcalendar/dist/fullcalendar.css";
// endbower

// common
@import "common/mixins";
@import "common/fonts";
@import "common/type";

// sacffolding/primary
@import "common/reset";
@import "template-parts/layouts";

// components
@import "components/base";
@import "components/animation";
@import "components/button";
@import "components/form";
@import "components/hamburger";

// template parts
@import "template-parts/header";
@import "template-parts/nav";
@import "template-parts/footer";
@import "template-parts/banners";
@import "template-parts/visit";
@import "template-parts/sliders";
@import "template-parts/widget";
@import "template-parts/table";
@import "template-parts/gray-box";
@import "template-parts/lists";
@import "template-parts/faq";
@import "template-parts/alert";
@import "template-parts/box-office";
@import "template-parts/studies";
@import "template-parts/select-study";
@import "template-parts/event";
@import "template-parts/video";
@import "template-parts/student-faculty";
@import "template-parts/recital";
@import "template-parts/pagination";
@import "template-parts/jobs";
@import "template-parts/academics";
@import "template-parts/cta-rows";
@import "template-parts/graduates";
@import "template-parts/ensembles";
@import "template-parts/resources";
@import "template-parts/search";
@import "template-parts/post";
@import "template-parts/classes";
@import "template-parts/about";
@import "template-parts/history";
@import "template-parts/home";
