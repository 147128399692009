//
// Name:            FAQ
//


// ========================================================================
// Component: FAQ
// ========================================================================


.question {
	display:block;
	color: #000000;
	font-family: $akkurat_reg;
	font-size: 24px;
	line-height: 36px;
	margin:50px 0 30px 0;
}

.answer {
	border: 1px solid #e0e7ed;
	background-color: #f2f5f8;	
	padding:30px;
	height:100px;
	color: #000000;
	font-family: $akkurat_reg;
	font-size: 16px;
	line-height: 28px;

	.toggle .close {
		display:none;
	}
	&.opened {
		height:auto;

		.toggle .close {
			display:inline-block;
		}
		.toggle .open {
			display:none;
		}
	}
	p {
		color: #000000;
		font-family: $akkurat_reg;
		font-size: 16px;
		line-height: 28px;
		margin:0;
	}
	a {
		position:relative;
		color: #4e2a84;
		font-family: $akkurat_bold;
		font-size: 14px;
		line-height: 20px;

		span {
			display:inline-block;
			position:relative;
			padding-right:20px;

			&:after {
				position:absolute;
				content:'';
				top:3px;
				right:0;
				width:10px;
				height:10px;
				background:url(../images/purple-arrow.svg) 50% no-repeat;
				background-size:100%;
				transform:rotate(0deg);
			}
		}		
	}
}

.toggle-box {
	width:100%;
	height:auto;
	padding:20px 30px;
	margin:0 0 50px 0;
	border: 1px solid #e0e7ed;
	background-color: #f2f5f8;

	&.active {

		.toggle-link .ico {
			transform:rotate(180deg);
		}
	}
	.title {
		color: #000000;
		font-family: $akkurat_bold;
		font-size: 18px;
		line-height: 26px;		
	}
	.toggle-cnt {
		display:none;
		width:100%;
		color: #000000;
		font-family: $akkurat_reg;
		font-size: 16px;
		line-height: 28px;
		max-width:100%;		
	}
	.toggle-link {
		position:relative;
		color: $purple;
		font-family: $akkurat_bold;
		font-size: 14px;
		line-height: 20px;
		padding-right:15px;
		text-decoration:none;

		&:hover:after, &:focus:after {
			width:calc(100% - 15px);
		}
		&:after {
			position:absolute;
			content:'';
			bottom:0;
			left:0;
			width:0;
			height:2px;
			background:$purple;
			transition:.4s;
		}
		.ico {
			position:absolute;
			top:2px;
			right:0;
			width:10px;
			height:10px;
			background:url(../images/Down_Arrow5.svg) 50% no-repeat;
			transform:rotate(0deg);
			transition:.4s;
		}		
	}	
}


@media only screen and (max-width: 580px) and (min-width: 1px) {
	.answer {
		height:200px;
	}
	#search-faq {
		width:100%;

		input[type="text"] {
			padding:0 42px 0 22px;
		}
	}
}
