//
// Name:            Video
//

// ========================================================================
// Component: Video
// ========================================================================

#media-library {
  padding: 100px 50px 100px 0;
  background-color: #f2f5f8;

  .all {
    min-width: 140px;
    max-width: 140px;
    margin-bottom: 20px;
  }
  h1 {
    text-align: center;
  }
  .featured-video {

    a.permalink {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
    &:hover, &:focus {
      .video-thumb {
        transform: scale(1.1);
      }
      h3 a span {
        background-size: 100% 3px;
      }
    }
    .video-thumb-wrap {
      display: inline-block;
      width: 100%;
      height: 435px;
      overflow: hidden;

      .video-btn.play {
          position: relative;
          display: block;
          top: 50%;
          width: 100px;
          height: 100px;
          color: #fff;
          font-family: AkkuratPro-Bold;
          font-size: 12px;
          line-height: 125px;
          text-transform: uppercase;
          letter-spacing: 2px;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

          &:before {
            position: absolute;
            top: 0;
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background: url("/themes/basic/dist/images/large-play.png") 50% no-repeat;
            background-color: rgba(0, 0, 0, 0);
            background-image: url("/themes/basic/dist/images/large-play.png");
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-clip: border-box;
            background-origin: padding-box;
            background-position-x: 50%;
            background-position-y: center;
            background-size: auto auto;
            background-size: 100% !important;
          }
      }
    }
    .video-thumb {
      display: inline-block;
      width: 100%;
      height: 435px;
      background-size: cover !important;
      transform: scale(1);
      transition: .4s;
    }
    .video-caption {
      position: relative;
      top: -20px;
      float: right;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      padding: 40px;
      width: 90%;

      .play {
        position: absolute;
        top: 50%;
        left: 40px;
        width: 55px;
        height: 55px;
        border-radius: 55px;
        border: 5px solid #4e2a84;
        transform: translate(0, -50%);

        &:after {
          position: absolute;
          content: '';
          top: 50%;
          left: calc(50% + 2px);
          width: 30px;
          height: 30px;
          transform: translate(-50%, -50%);
          background: url(../images/purple-play.svg) 50% no-repeat;
        }
      }
      .info {
        padding-left: 75px;

        p {
          color: #4f7796;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  .media-video {
    margin-bottom: 50px;

    &.is_hover {

      .video-thumb {
        transform: scale(1.1);
      }
      a span {
        background-size: 100% 3px;
      }
    }
    .video-thumb-link {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 155px;
      overflow: hidden;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .4;
        z-index: 9;
      }
      .video-thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-size: cover !important;
        transform: scale(1);
        transition: .4s;
      }
      &:hover .video-thumb, &:focus .video-thumb {
        transform: scale(1.1);
      }
      .play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        z-index: 99;
        transform: translate(-50%, -50%);
        background: url(../images/play-btn.png) 50% no-repeat;
      }
    }
    p {
      color: #4f7796;
      font-size: 14px;
      line-height: 24px !important;
      margin-top: 10px;
    }
  }
  .col-xs-6:first-child .media-video {
    padding: 0 15px 0 0;
  }
  .col-xs-6:last-child .media-video {
    padding: 0 0 0 15px;
  }
}

//Davee media search landing
#media-library-search {
  .all {
    min-width: 140px;
    max-width: 140px;
    margin-bottom: 20px;
  }
  h1 {
    text-align: center;
  }
  .featured-video {
    &.video-thumbnail-sm {
           max-width: 480px;
           .video-thumb-wrap {
             max-height: 320px;
             .video-thumb {
               height: 100% !important;
             }
           }
         }

    &.video-thumbnail-med {
      max-width: 640px;
      .video-thumb-wrap {
        max-height: 320px;
        .video-thumb {
          height: 100% !important;
        }
      }
    }

    &.video-thumbnail-lg {
      max-width: 1024px;
      .video-thumb-wrap {
        max-height: 620px;
        .video-thumb {
          height: 100% !important;
        }
      }
    }

    a.permalink {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
    &:hover, &:focus {
      .video-thumb {
        transform: scale(1.1);
      }
      h3 a span {
        background-size: 100% 3px;
      }
    }
    .video-thumb-wrap {
      display: inline-block;
      width: 100%;
      height: 435px;
      overflow: hidden;

      .video-btn.play {
        position: relative;
        display: block;
        top: 50%;
        width: 100px;
        height: 100px;
        color: #fff;
        font-family: AkkuratPro-Bold;
        font-size: 12px;
        line-height: 125px;
        text-transform: uppercase;
        letter-spacing: 2px;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        &:before {
          position: absolute;
          top: 0;
          content: "";
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background: url(/themes/basic/dist/images/large-play.png) 50% no-repeat;
          background-color: rgba(0, 0, 0, 0);
          background-image: url("/themes/basic/dist/images/large-play.png");
          background-repeat: no-repeat;
          background-attachment: scroll;
          background-clip: border-box;
          background-origin: padding-box;
          background-position-x: 50%;
          background-position-y: center;
          background-size: auto auto;
          background-size: 100% !important;
        }
      }
    }
    .video-thumb {
      display: inline-block;
      width: 100%;
      height: 435px;
      background-size: cover !important;
      transform: scale(1);
      transition: .4s;
    }
    .video-caption {
      position: relative;
      top: -20px;
      float: right;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      padding: 40px;
      width: 90%;

      .play {
        position: absolute;
        top: 50%;
        left: 40px;
        width: 55px;
        height: 55px;
        border-radius: 55px;
        border: 5px solid #4e2a84;
        transform: translate(0, -50%);

        &:after {
          position: absolute;
          content: '';
          top: 50%;
          left: calc(50% + 2px);
          width: 30px;
          height: 30px;
          transform: translate(-50%, -50%);
          background: url(../images/purple-play.svg) 50% no-repeat;
        }
      }
      .info {
        padding-left: 75px;

        p {
          color: #4f7796;
          font-size: 14px;
          line-height: 24px !important;
        }
      }
    }
  }
  .media-video {
    margin-bottom: 50px;

    &.is_hover {

      .video-thumb {
        transform: scale(1.1);
      }
      a span {
        background-size: 100% 3px;
      }
    }
    .video-thumb-link {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 205px;
      overflow: hidden;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .4;
        z-index: 9;
      }
      .video-thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-size: cover !important;
        transform: scale(1);
        transition: .4s;
      }
      &:hover .video-thumb, &:focus .video-thumb {
        transform: scale(1.1);
      }
      .play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        z-index: 99;
        transform: translate(-50%, -50%);
        background: url(../images/play-btn.png) 50% no-repeat;
      }
    }
    p {
      color: #4f7796;
      font-size: 14px;
      line-height: 24px !important;
      margin-top: 10px;
    }
  }
  .col-xs-6:first-child .media-video {
    padding: 0 15px 0 0;
  }
  .col-xs-6:last-child .media-video {
    padding: 0 0 0 15px;
  }
}

#about-video {
  .lightbox {
    .cnt {
      padding:10px 0px;
    }
  }
}

.featured-video {
  .link {
    display: inline-block;
    position: absolute;
    bottom: 30px;
    right: 0;
    color: $purple;
    font-family: $akkurat_bold;
    font-size: 16px;
    line-height: 24px;
    padding-right: 40px;

    &:hover:after, &:focus:after {
      width: calc(100% - 40px);
    }
    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: $purple;
      transition: .4s;
    }
    .ico {
      position: absolute;
      top: 6px;
      right: 0;
      width: 20px;
      height: 20px;
      background: url(../images/purple-arrow.svg) right center no-repeat;
      transform: rotate(-90deg);
      background-size: 60%;
    }
  }
  .video-cnt {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    height:auto !important;

    &:hover, &:focus {
      .video-thumb {
        transform: scale(1.1);
      }
      .meta .wrap .video-title span {
        background-size: 100% 3px;
      }
    }
    .permalink {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
    .video-thumb-wrap {
      position: relative;
      width: 100%;
      height: 515px;
      overflow: hidden;
    }
    .video-thumb {
      position: relative;
      width: 100%;
      height: 515px;
      background-size: cover !important;
      transform: scale(1);
      transition: .4s;
    }
    .meta {
      position: relative !important;
      padding: 35px !important;

      .wrap {
        display: inline-block;
        position: static !important;
        width: 100%;
      }
      .play {
        display: block;
        position: absolute;
        top: 50%;
        left: 35px !important;
        width: 50px !important;
        height: 50px !important;
        border-radius: 50px !important;
        border: 5px solid $purple !important;
        background: url(../images/purple-play.svg) 16px 50% no-repeat !important;
        transform: translate(0, -50%);
      }
      .video-title {
        display: block;
        color: #000000 !important;
        font-family: $campton;
        font-size: 24px;
        line-height: 32px;
        padding-left: 80px !important;
        margin: 0 0 0 0 !important;
        text-decoration:none;

        span {
          width: calc(100%);
          background-image: linear-gradient(transparent calc(100% - 2px), #000 2px) !important;
          background-repeat: no-repeat;
          background-size: 0% 3px;
          transition: background-size .4s;
          background-position: bottom;
        }
        &:hover span, &:focus span {
          background-size: 0 !important;
        }
      }
      p {
        color: #4f7796 !important;
        font-family: $akkurat_reg;
        font-size: 14px;
        line-height: 24px !important;
        padding-left: 80px !important;
        margin-top: 20px !important;
      }
    }
  }
}

.video-grid-item {
    position: relative;
    width: 100%;
    height: auto;
    margin:0 0 40px 0;

  p {
    margin-top:15px;
    font-size: 14px;
    line-height: 24px !important;
  }
    &:hover, &:focus {
      .video-thumb {
        transform: scale(1.1);
      }
      .video-title span {
        background-size: 100% 3px;
      }
    }
    .video-cnt {
      position: relative;
      width: 100%;
      height: auto;
      margin:0;

      a.permalink {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
      }
      .video-thumb-wrap {
        display: block;
        position: relative;
        width: 100%;
        height: 210px;
        overflow: hidden;
      }
      .video-thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 210px;
        background-size: cover !important;
        transform: scale(1);
        transition: .4s;
      }
      .meta {
        position: absolute;
        padding: 35px;
        bottom: 0;
        left: 0;
        z-index: 9;

        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          z-index: 99;
          -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
          background: url(../images/play-btn.png) 50% no-repeat;
        }

        .video-title {
          display: block;
          position: relative;
          color: #fff;
          font-family: $campton;
          font-size: 24px;
          line-height: 32px;
          padding-left: 120px;
          margin: 0 0 20px 0;

          span {
            width: calc(100%);
            background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);
            background-repeat: no-repeat;
            background-size: 0% 3px;
            transition: background-size .4s;
          }
          &:hover span, &:focus span {
            background-size: 100% 3px;
          }
        }
      }
    }
  }

.video-thumbnail-link {
  position: relative;
  width: 90%;

  .video-cnt {
    position: relative;
    width: 100%;
    height: 515px;

    &:hover, &:focus {
      .video-thumb {
        transform: scale(1.1);
      }
      .meta .video-title span,
      .meta .video-title {
        background-size: 100% 3px;
        text-decoration: none;
      }
    }
    a.permalink {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
    .video-thumb-wrap {
      display: block;
      position: relative;
      width: 100%;
      height: 515px;
      overflow: hidden;
      background: #000;

      .video-btn.play {
        position: relative;
        display: block;
        top: 50%;
        width: 100px;
        height: 100px;
        color: #fff;
        font-family: AkkuratPro-Bold;
        font-size: 12px;
        line-height: 125px;
        text-transform: uppercase;
        letter-spacing: 2px;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        &:before {
          position: absolute;
          top: 0;
          content: "";
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background: url(../images/large-play.png) 50% no-repeat;
          background-color: rgba(0, 0, 0, 1);
          background-image: url("../images/large-play.png");
          background-repeat: no-repeat;
          background-attachment: scroll;
          background-clip: border-box;
          background-origin: padding-box;
          background-position-x: 50%;
          background-position-y: center;
          background-size: auto auto;
          background-size: 100% !important;
        }
      }
    }
    .video-thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 515px;
      background-size: cover !important;
      transform: scale(1);
      transition: .4s;
      opacity: .75;
    }
    .meta {
      position: absolute;
      padding: 35px;
      bottom: 0;
      left: 0;
      z-index: 9;

      .play {
        display: block;
        position: absolute;
        top: 50%;
        left: 35px;
        width: 103px;
        height: 103px;
        border-radius: 103px;
        border: 5px solid #fff;
        background: url(../images/large-play.png) 50% no-repeat;
        transform: translate(0, -50%);
      }
      .video-title {
        display: block;
        position: relative;
        color: #fff;
        font-family: $campton;
        font-size: 24px;
        line-height: 32px;
        padding-left: 120px;
        margin: 0 0 20px 0;

        span {
          width: calc(100%);
          background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);
          background-repeat: no-repeat;
          background-size: 0% 3px;
          transition: background-size .4s;
        }
        &:hover span, &:focus span {
          background-size: 0 !important;
        }
      }
      p {
        color: #fff;
        font-family: $akkurat_reg;
        font-size: 14px;
        line-height: 20px;
        padding-left: 120px;
      }
    }
  }

  &.video-thumbnail-sm {
    height: 280px;
    max-width: 480px;
    margin: 20px auto !important;
    .video-cnt {
      height: 280px;
    }
    .video-thumb-wrap {
      height: 100%;
      .video-thumb {
        height: 100% !important;
      }
    }
  }

  &.video-thumbnail-med {
    height: 420px;
    max-width: 640px;
    margin: 20px auto !important;
    .video-cnt {
      height: 420px;
    }
    .video-thumb-wrap {
      height: 100%;
      .video-thumb {
        height: 100% !important;
      }
    }
  }

  &.video-thumbnail-lg {
    max-width: 1024px;
    margin: 20px auto !important;
    .video-cnt {
      height: 515px;
    }
    .video-thumb-wrap {
      height: 100%;
      .video-thumb {
        height: 100% !important;
      }
    }
  }
}

.video-cnt {
  position: relative;
  width: 100%;
  height: 515px;

  &:hover, &:focus {
    .video-thumb {
      transform: scale(1.1);
    }
    .meta .video-title span,
    .meta .video-title {
      background-size: 100% 3px;
      text-decoration: none;
    }
  }
  a.permalink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  .video-thumb-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 515px;
    overflow: hidden;
    background: #000;

    .video-btn.play {
      position: relative;
      display: block;
      top: 50%;
      width: 100px;
      height: 100px;
      color: #fff;
      font-family: AkkuratPro-Bold;
      font-size: 12px;
      line-height: 125px;
      text-transform: uppercase;
      letter-spacing: 2px;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      &:before {
        position: absolute;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: url(/themes/basic/dist/images/play-btn.png) 50% no-repeat;
        background-image: url("/themes/basic/dist/images/play-btn.png");
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-clip: border-box;
        background-origin: padding-box;
        background-position-x: 50%;
        background-position-y: center;
        background-size: auto auto;
      }
    }
  }
  .video-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 515px;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s;
    opacity: .75;
  }
  .meta {
    position: absolute;
    padding: 35px;
    bottom: 0;
    left: 0;
    z-index: 9;

    .play {
      display: block;
      position: absolute;
      top: 50%;
      left: 35px;
      width: 103px;
      height: 103px;
      border-radius: 103px;
      border: 5px solid #fff;
      background: url(../images/large-play.png) 50% no-repeat;
      transform: translate(0, -50%);
    }
    .video-title {
      display: block;
      position: relative;
      color: #fff;
      font-family: $campton;
      font-size: 24px;
      line-height: 32px;
      padding-left: 120px;
      margin: 0 0 20px 0;

      span {
        width: calc(100%);
        background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);
        background-repeat: no-repeat;
        background-size: 0% 3px;
        transition: background-size .4s;
      }
      &:hover span, &:focus span {
        background-size: 0 !important;
      }
    }
    p {
      color: #fff;
      font-family: $akkurat_reg;
      font-size: 14px;
      line-height: 24px !important;
      padding-left: 120px;
    }
  }
}

.video-thumbnail-link .video-cnt:hover .meta .video-title span {
  background-size: 0 !important;
}

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .video-thumbnail .video-cnt {
    height: 350px;

    .video-thumb-wrap, .video-thumb {
      height: 350px;
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  .featured-video .col-xs-6 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .featured-video .link {
    position: relative;
    bottom: inherit;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .featured-video .video-cnt .video-thumb,
  .featured-video .video-cnt .video-thumb-wrap {
    height: 350px;
  }
  .video-banner .sound-btn {
    left: 20px;
    width: calc(100% - 40px);
    transform: translate(0, 0);
    padding-left: 0;
  }

  #about-video {
    .lightbox {
      min-height: 320px;
      .cnt {
        padding:10px 0px;
      }
    }
  }
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .video-thumbnail .video-cnt .meta .play {
    position: relative;
    top: inherit;
    transform: translate(-50%, 0);
    left: 50%;
    margin-bottom: 30px;
  }
  .video-thumbnail .video-cnt .meta .video-title {
    padding-left: 0;
  }
  .video-thumbnail .video-cnt .meta p {
    padding-left: 0;
  }
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
  .featured-video .video-cnt .meta .play {
    position: relative;
    top: inherit;
    transform: translate(-50%, 0);
    left: 50%;
  }
  .featured-video .video-cnt .meta .video-title,
  .featured-video .video-cnt .meta p {
    padding-left: 0;
  }
  .featured-video .video-cnt .video-thumb-wrap,
  .featured-video .video-cnt .video-thumb {
    height: 250px;
  }
}
