//
// Name:            Banners
//


// ========================================================================
// Component: Banners
// ========================================================================


// Home Banner
.home-banner {
  font-size: 0;
  position: relative;

  #slider {
    display: inline-block;
    position: relative;
    width: 75%;
    height: 650px;
    vertical-align: top;
	animation-play-state: running;

    #bgPhoto {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 650px;
      z-index: 1;
      transform: scale(1.1);
      background-size: cover !important;
    }

    .swiper-container {
      height: 100%;
      width: 100%;
    }

    .swiper-wrapper {
      z-index: 9;
    }

    .swiper-slide {
      transform-style: preserve-3d;

      @keyframes scale_animation {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(1.1);
        }
      }
      @-webkit-keyframes scale_animation {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(1.1);
        }
      }

      .cnt {
        height: 100%;
      }

      .sec-1 {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip: rect(0px, 0, 650px, 0px);
        transform: perspective(0);
        transition: transform 1s 100ms;
        transform: translate3d(0, -100%, 0);

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover !important;
        }
      }

      .sec-2 {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip: rect(0px, 0px, 650px, 0px);
        transform: perspective(0);
        transition: transform 1s 200ms;
        transform: translate3d(0, 100%, 0);

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover !important;
        }
      }

      .sec-3 {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip: rect(0px, 0px, 650px, 0px);
        transform: perspective(0);
        transition: transform 1s 300ms;
        transform: translate3d(0, -100%, 0);

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover !important;
        }
      }

      .sec-4 {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip: rect(0px, 0px, 650px, 0px);
        transform: perspective(0);
        transition: transform 1s 400ms;
        transform: translate3d(0, 100%, 0);

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover !important;
        }
      }

      &-active {

        .sec-1,
        .sec-2,
        .sec-3,
        .sec-4 {
          transform: translate3d(0, 0, 0);
        }
      }

      .cnt.active .bg {
        animation-name: scale_animation;
        animation-duration: 8000ms;
        transform-origin: 50% 50%;
        animation-timing-function: linear;
      }
    }
	
	&.paused * {
		animation-play-state: paused;
	}
  }

  #slider-content {
    display: inline-block;
    position: relative;
    width: 25%;
    height: 650px;
    background-image: linear-gradient(145deg, rgba(224, 232, 238, .25), #e0e8ee);
    vertical-align: top;

    .content-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 100px 50px;
      transform: translate3d(0, -80px, 0);
      opacity: 0;
      z-index: 1;
      transition: .4s;

      &.on {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        z-index: 9;
      }

      .secondary {
        letter-spacing: 3px;
        margin-bottom: 30px;
      }

      .intro {
        color: #4e2a84;
        font-family: $akkurat_reg;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 30px;
      }

      .body-link {
        font-size: 16px;
        text-decoration: none;
      }
    }

    #content-1 {
      display: block;
    }

    .next {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60px;
      height: 60px;
      z-index: 99;
      border: 1px solid $purple;

      &:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(-90deg);
      }

      &:hover {
        background: $dark_purple;

        &:after {
          background: url(../images/white-arrow.svg) 50% no-repeat;
        }
      }
    }
	.pause {
		border-radius: 50%;
		position: absolute;
		bottom: 15px;
		height: 50px;
		width: 50px;
		left: -65px;
		position: a;
		z-index: 100;
		background: url(../images/pause.svg) no-repeat center;
		background-size: contain;
		opacity: 0.8;
		&:focus {
			color: #fff;
		}
		&.play {
			background: url(../images/play.svg) no-repeat center;
			background-size: contain;
		}
	}
    .prev {
      position: absolute;
      bottom: 0;
      right: 59px;
      width: 60px;
      height: 60px;
      z-index: 99;
      border: 1px solid $purple;

      &:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(90deg);
      }

      &:hover {
        background: $dark_purple;

        &:after {
          background: url(../images/white-arrow.svg) 50% no-repeat;
        }
      }
    }
  }

  .pagination {
    display: inline-block;
    position: absolute;
    z-index: 99;
    bottom: 20px;
    left: 50px;
    width: auto;
    height: 30px;

    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      cursor: pointer;
      margin-right: 20px;
      border-radius: 10px;
      background-color: rgba(78, 42, 132, 0.75);

      &.swiper-pagination-bullet-active {
        background-color: rgba(78, 42, 132, 1);
      }
    }
  }

  .slider-lines {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 650px;
    z-index: 9999;

    .line1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background: #fff;
      opacity: .3;
    }

    .line2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background: #fff;
      opacity: .3;
    }

    .line3 {
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background: #fff;
      opacity: .3;
    }
  }
}

// Inside Banners
.banner {
  display: block;
  position: relative;
  width: 100%;
  height: 400px;

  .banner-cnt {
    display: block;
    position: absolute;
    z-index: 9;
    bottom: 35px;
    left: 50px;

    .page-title {
      position: relative;
      bottom: 0;
      left: 0;
      transform: translate(0, 0);
    }

    .section-title {
      display: inline-block;
      color: #ffffff;
      font-family: $akkurat_bold;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      text-transform: uppercase;
      padding: 8px 20px 6px 20px;
      margin: 0 0 15px 0;

      &.violet, &.bienen-ensembles {
        background-color: #bd10e0;
      }

      &.blue, &.recitals-visiting-artists, &.recitals-lectures {
        background-color: #4e93ce;
      }

      &.yellow, &.festivals-series {
        background-color: #f1951c;
      }

      &.green, &.student-recitals {
        background-color: #79cc19;
      }
    }
  }

  .page-title {
    display: block;
    position: absolute;
    z-index: 9;
    bottom: 35px;
    left: 50px;
    color: #ffffff;
    font-family: $periodico;
    font-size: 52px;
    letter-spacing: -1px;
    line-height: 62px;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 2;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  }

  .photo-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    .photo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 600px;
      z-index: 1;
      background-size: cover !important;
      transform: translate3d(0, 0, 0);
    }
  }

  .purple-box-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 460px;
    height: 100%;
    z-index: 99;
    background: #3f2367;

    .cnt {
      position: relative;
      top: 50%;
      padding: 0 60px;
      transform: translate(0, -50%);

      p {
        font-size: 16px;
        color: #fff;
        font-family: $akkurat_reg;
      }

      a {
        font-size: 16px;
        color: #fff;
        font-family: $akkurat_bold;

        span {
          width: calc(100%);
          background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);
          background-repeat: no-repeat;
          background-size: 0% 3px;
          background-position: left bottom;
          transition: background-size .4s;
        }

        span {
          background-size: 100% 3px;
        }

        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.event-banner {
  display: block;
  position: relative;
  width: 100%;
  height: 400px;

  .page-title {
    display: block;
    position: absolute;
    z-index: 9;
    bottom: 35px;
    left: 50px;
    color: #ffffff;
    font-family: $periodico;
    font-size: 52px;
    letter-spacing: -1px;
    line-height: 62px;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 2;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  }

  .photo-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    .photo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 600px;
      z-index: 1;
      background-size: cover !important;
      transform: translate3d(0, 0, 0);
    }
  }

  .event-box {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 460px;
    height: 100%;
    z-index: 99;
    padding: 30px 40px;
    background-color: #3f2367;

    .row {
      margin-bottom: 20px;
    }

    .thumb-wrap {
      width: 120px;
      height: 120px;
      overflow: hidden;

      &:hover .thumb {
        transform: scale(1.1);
      }
    }

    .thumb {
      width: 120px;
      height: 120px;
      transform: scale(1);
      background-size: cover !important;
      transition: .4s;

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .cnt {
      padding-left: 20px;
      text-align: left;

      .title {
        color: #ffffff;
        font-family: $akkurat_bold;
        font-size: 18px;
        line-height: 26px;

        span {
          width: calc(100%);
          background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);
          background-repeat: no-repeat;
          background-size: 0% 340px;
          background-position: left bottom;
          transition: background-size .4s;
        }

        &:hover span {
          background-size: 100% 3px;
        }

        &:hover {

        }
      }

      .date {
        color: #ffffff;
        font-family: $akkurat_bold;
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 16px;
        text-transform: uppercase;
        margin: 5px 0 15px 0;
      }

      .location {
        color: #ffffff;
        font-family: $akkurat_reg;
        font-size: 14px;
        line-height: 20px;
        padding-left: 20px;
        background: url(../images/location-white.svg) left 3px no-repeat;
      }
    }

    .row.is_hover {

      .thumb {
        transform: scale(1.1);
      }

      .title span {
        background-size: 100% 100%;
      }
    }

    .all {
      display: inline-block;
      position: relative;
      color: #ffffff;
      font-family: $akkurat_bold;
      font-size: 18px;
      line-height: 26px;
      padding-right: 20px;
      margin-top: 40px;
      text-decoration: none;

      &:hover {
        opacity: .5;
      }

      .ico {
        position: absolute;
        top: 4px;
        right: 0;
        width: 15px;
        height: 15px;
        background: url(../images/Up_Arrow1.svg) 50% no-repeat;
        transform: rotate(90deg);
      }
    }
  }
}

.split-banner {
  height: 500px;

  .row {
    margin: 0;
  }

  .col-xs-6 {
    padding: 0;
  }

  .breadcrumbs {
    list-style: none;
    padding: 30px 0 30px 50px;
    width: 50%;

    li {
      display: inline-block;
      position: relative;
      width: auto;
      padding-right: 40px;
      margin: 0;

      &:after {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        top: 4px;
        right: 6px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        transform: rotate(-90deg);
      }

      &:last-child {
        padding-right: 0;
      }

      &:last-child:after {
        display: none;
      }

      a {
        display: inline-block;
        font-family: $akkurat_bold;
        font-size: 16px;
        line-height: 28px;
        color: #3c1971;
        text-decoration: none;
      }
    }
  }

  .purple-box {
    display: block;
    position: relative;
    margin: 0 0 0 50px;
    background: $purple;
    width: 100%;
    height: 340px;
    transform: scale(.9);
    transition: .4s;

    &.on {
      transform: scale(1);

      .page-parent,
      .page-title,
      .faculty-name,
      .faculty-area,
      .faculty-title,
      .faculty-email,
      .faculty-phone {
        opacity: 1;
      }
    }

    .page-parent {
      padding: 30px 0 0 30px;
      color: #ffffff;
      font-family: $akkurat_reg;
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      text-transform: uppercase;
      opacity: 0;
      transition: .4s;
      transition-delay: .4s;
    }

    .page-title {
      color: #ffffff;
      font-family: $periodico;
      font-size: 52px;
      letter-spacing: -1px;
      line-height: 62px;
      bottom: inherit;
      top: 50%;
      left: 60px;
      margin: 0;
      max-width: 550px;
      transform: translate(0, -50%);
      opacity: 0;
      transition: .4s;
      transition-delay: .4s;
    }

    .faculty-name {
      position: relative;
      color: #ffffff;
      font-family: $periodico;
      font-size: 52px;
      letter-spacing: -1px;
      line-height: 62px;
      left: 60px;
      margin: 0;
      max-width: 550px;
      opacity: 0;
      transition: .4s;
      transition-delay: .4s;
    }

    .faculty-area {
      position: relative;
      color: #ffffff;
      font-family: $campton;
      font-size: 32px;
      font-weight: 500;
      line-height: 42px;
      letter-spacing: -1px;
      left: 60px;
      margin: 0 0 10px 0;
      opacity: 0;
      transition: .4s;
      transition-delay: .4s;
    }

    .faculty-title {
      position: relative;
      color: #ffffff;
      font-family: $akkurat_bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 3px;
      left: 60px;
      opacity: 0;
      transition: .4s;
      transition-delay: .4s;
    }

    .faculty-link-wrap {
      display: inline-block;
      width: 100%;
      margin: 0;
    }

    .faculty-email {
      display: inline-block;
      width: auto;
      position: relative;
      color: #ffffff;
      font-family: $akkurat_bold;
      font-size: 16px;
      line-height: 24px;
      left: 60px;
      opacity: 0;
      text-decoration: none;
      transition: .4s;
      transition-delay: .4s;

      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: #fff;
        transition: .4s;
      }

      &:hover:after {
        width: 100%;
      }
    }

    .faculty-phone {
      clear: both;
      display: inline-block;
      width: auto;
      position: relative;
      color: #ffffff;
      font-family: $akkurat_bold;
      font-size: 16px;
      line-height: 24px;
      left: 60px;
      opacity: 0;
      text-decoration: none;
      transition: .4s;
      transition-delay: .4s;

      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: #fff;
        transition: .4s;
      }

      &:hover:after {
        width: 100%;
      }
    }
  }

  .gray-box {
    display: block;
    position: relative;
    margin: 0 0 0 25px;
    padding: 0;
    border: none;
    background-color: #f2f5f8;
    width: 100%;
    height: 340px;
    transform: scale(.9);
    transition: .4s;

    &.on {
      transform: scale(1);

      .cnt .page-title,
      .cnt .date,
      .page-parent,
      .cnt p,
      .cnt .purple-btn {
        opacity: 1;
      }
    }

    .page-parent {
      position: absolute;
      padding: 30px 0 0 30px;
      color: #000;
      font-family: $akkurat_reg;
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      text-transform: uppercase;
      opacity: 0;
      transition: .4s;
      transition-delay: .4s;
    }

    .cnt {
      position: relative;
      top: 50%;
      left: 25px;
      transform: translate(0, -50%);

      .page-title {
        position: relative;
        color: #000;
        font-family: $periodico;
        font-size: 48px;
        letter-spacing: -1px;
        line-height: 62px;
        bottom: inherit;
        top: inherit;
        left: 0;
        margin: 0 0 20px 0;
        max-width: 40%;
        transform: translate(0, 0);
        opacity: 0;
        transition: .4s;
        transition-delay: .4s;
        word-wrap: break-word;
      }

      p {
        color: #000;
        font-family: $akkurat_reg;
        font-size: 16px;
        line-height: 28px;
        opacity: 0;
        width: 45%;
        transition: .4s;
        transition-delay: .4s;
      }

      .date {
        color: #000;
        font-family: $akkurat_bold;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 3px;
      }

      .purple-btn {
        min-width: 190px;
        margin-right: 50px;
        height: auto;
        padding: 15px 20px;
        opacity: 0;
        transition: .4s;
        transition-delay: opacity .4s;
      }
    }
  }

  .photo {
    position: absolute;
    max-width: 50%;
    max-height: 80%;
    left: inherit;
    right: 0;
    top: -1px;
    opacity: 0;
    transform: scale(.8);
    transition: .4s;
    //background-position: right top !important;
    //background-size:cover !important;

    //For hidden FB image
    img.fb-offscreen {
      position: relative;
      //left: -999em;
    }

    &.on {
      opacity: 1;
      transform: scale(1);
    }

    .caption {
      position: absolute;
      top: 520px;
      left: 0;
      color: #4f7796;
      font-family: $akkurat_reg;
      font-size: 14px;
      line-height: 20px;
      padding: 0 80px;
    }
  }

  &.pdf-banner .photo {
    width: 360px;
  }

  /* Fixing issue with image size being too large */
  .faculty-photo.photo {
    max-height: 560px;
    img {
      max-height: inherit;
    }
  }

  .language {
    padding: 30px 0 0 50px;
  }

  .news-share {
    position: relative;
    padding: 20px 0 0 80px;

    ul {
      list-style: none;
      padding: 0;

      li {
        display: inline-block;
        width: auto;
        margin: 0 5px;
        vertical-align: top;

        a {
          display: inline-block;
          width: 40px;
          height: 30px;
          text-indent: -9999px;
        }

        &.facebook {
          background: url(../images/facebook-single.svg) 50% no-repeat;
          background-size: auto;
        }

        &.twitter {
          background: url(../images/twitter-single.svg) 50% no-repeat;
          background-size: auto;
        }

        &.email {
          background: url(../images/email-single.svg) 50% no-repeat;
          background-size: auto;
        }
      }

      .title {
        display: inline-block;
        color: #507796;
        font-family: $akkurat_bold;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 3px;
        vertical-align: top;
      }
    }
  }
}

.firefox .split-banner .news-share ul li.facebook,
.firefox .split-banner .news-share ul li.twitter,
.firefox .split-banner .news-share ul li.email {
  background-size: auto !important;
}

.drop-banner {
  position: relative;
  width: 100%;
  z-index: 9;
  height: 580px;

  .photo-wrap {
    display: block;
    position: absolute;
    width: 100%;
    height: 400px;
    overflow: hidden;

    .photo {
      display: block;
      position: absolute;
      width: 100%;
      height: 600px;
      background-size: cover !important;
      transform: translate3d(0, 0, 0);
    }
  }

  .overlay {
    position: absolute;
    bottom: 180px;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 2;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  }
}

.drop-box {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 1240px;
  padding: 30px;
  z-index: 99;
  transform: translate(-50%, 0) scale(.9);
  transition: .4s;

  &.on {
    transform: translate(-50%, 0) scale(1);

    .page,
    .title,
    .subtitle {
      opacity: 1;
    }
  }

  .page {
    color: #ffffff;
    font-family: $akkurat_reg;
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase;
    transition: .4s;
    opacity: 0;
    transition-delay: .4s;
  }

  .title {
    color: #ffffff;
    font-family: $periodico;
    font-size: 52px;
    letter-spacing: -1px;
    line-height: 62px;
    margin: 40px 0 40px 0;
    padding: 0 30px;
    transition: .4s;
    opacity: 0;
    transition-delay: .4s;
  }

  .subtitle {
    color: #ffffff;
    font-family: $akkurat_reg;
    font-size: 24px;
    line-height: 36px;
    padding: 0 30px 30px 30px;
    transition: .4s;
    opacity: 0;
    transition-delay: .4s;
  }
}

.purple-90 {
  background: $purple_90;
}

.slider-banner {
  position: relative;
  height: 600px;

  .next {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    z-index: 999;
    border: 1px solid $purple;

    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 25px;
      height: 25px;
      background: url(../images/purple-arrow.svg) 50% no-repeat;
      background-size: 100%;
      transform: translate(-50%, -50%) rotate(-90deg);
    }

    &:hover {
      background: $dark_purple;

      &:after {
        background: url(../images/white-arrow.svg) 50% no-repeat;
      }
    }
  }

  .prev {
    position: absolute;
    bottom: 0;
    right: 59px;
    width: 60px;
    height: 60px;
    z-index: 999;
    border: 1px solid $purple;

    &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 25px;
      height: 25px;
      background: url(../images/purple-arrow.svg) 50% no-repeat;
      background-size: 100%;
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &:hover {
      background: $dark_purple;

      &:after {
        background: url(../images/white-arrow.svg) 50% no-repeat;
      }
    }
  }

  .progress-bar {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 12px;
    background: #fff;
    z-index: 9999;

    .swiper-pagination-progressbar {
      background: #819eb5;
    }
  }

  .photo-wrap {
    display: block;
    position: relative;
    width: 50%;
    height: 600px;
    overflow: hidden;

    .photo {
      display: block;
      position: absolute;
      width: 100%;
      height: 600px;
      background-size: cover !important;
      transform: translate3d(0, 0, 0);
    }
  }

  .news-box {
    background-color: #f2f5f8;
    padding: 0 120px;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 50%;
    height: 100%;
    z-index: 999;

    .cnt {
      position: relative;
      top: 50%;
      transform: translate(0, -50%);

      h1 {
        color: #000;
      }

      p {
        color: #819eb5;
      }

      .secondary {
        letter-spacing: 3px;
      }

      .purple-btn {
        margin-top: 50px !important;
        min-width: 220px;
        max-width: 220px;
      }
    }
  }
}

.video-banner {
  display: block;
  position: relative;
  width: 100%;
  height: 580px;
  overflow: hidden;

  .cnt {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    z-index: 9;
    text-align: center;
    transform: translate(-50%, -50%);

    h1 {
      color: #fff;
      font-size: 72px;
      line-height: 82px;
      margin: 0 0 60px 0;
    }
  }

  .overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: #000;
    opacity: .1;
  }

  .photo-wrap {
    display: block;
    position: absolute;
    width: 100%;
    height: 600px;
    overflow: hidden;

    .photo {
      display: block;
      position: absolute;
      width: 100%;
      height: 900px;
      background-size: cover !important;
      transform: translate3d(0, 0, 0);
    }
  }

  .video-wrapper {
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    height: auto;
    z-index: 0;
    object-fit: cover !important;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    #banner-video {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      background-color: black;
      background-position: center center;
      background-size: contain;
      background-attachment: fixed;
      object-fit: cover;
      z-index: 3;
      overflow: hidden;
    }
  }

  .sound-btn {
    display: inline-block;
    position: absolute;
    bottom: 40px;
    left: 50%;
    z-index: 9;
    transform: translate(-50%, 0);
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: $campton;
    letter-spacing: 2px;
    text-decoration: none;

    &:hover {
      opacity: .5;
    }

    .song-title {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding-left: 20px;
      text-align: center;
      width: calc(100% - 60px);
    }

    .ico {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      width: 40px;
      height: 40px;
      background: url(../images/volume.svg) 50% no-repeat;
      background-size: 100% !important;
    }

    &.off .ico:before {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      background: url(../images/volume_line.svg) 50% no-repeat;
      background-size: 100% !important;
    }
  }

  .video-btn {
    position: relative;
    display: block;
    left: 50%;
    top: -30px;
    width: 100px;
    height: 65px;
    color: #ffffff;
    font-family: $akkurat_bold;
    font-size: 12px;
    line-height: 125px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transform: translate(-50%, 0);
    text-decoration: none;

    &:before {
      position: absolute;
      top: 0;
      left: 50%;
      content: '';
      width: 43px;
      height: 43px;
      border-radius: 43px;
      background: url(../images/large-play.png) 50% no-repeat;
      transform: translate(-50%, 0);
      background-size: 100% !important;
    }
  }
}

.banner-half-photo-tall {
  width: 100%;
  max-width: 510px;
  height: 500px;
  background-size: cover !important;
}

.banner-half-photo-short {
  width: 100%;
  max-width: 510px;
  height: 350px;
  background-size: cover !important;
}


@media only screen and (max-width: 1340px) and (min-width: 1px) {
  .split-banner .purple-box .page-title {
    max-width: 450px;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .drop-box {
    width: calc(100% - 120px)
  }
  .split-banner {

    .gray-box {
      .cnt {
        .page-title {
          max-width: 45%;
        }
      }
    }

    .photo {
      max-width: 50%;
    }
  }
}

@media only screen and (max-width: 1180px) and (min-width: 1px) {
  .banner-half-photo {
    width: 33%;
  }
  .slider-banner .news-box {
    padding: 0 50px;
  }
  .home-banner #slider {
    width: 66.666666%
  }
  .home-banner #slider-content {
    width: 33.333333%
  }
}

@media only screen and (max-width: 1080px) and (min-width: 1px) {
  .event-banner .event-box {
    position: relative;
    max-width: 100%;
  }
  .event-banner {
    height: auto;
  }
  .event-banner .photo-wrap {
    height: 400px;
    position: relative;
  }
  .event-box .col-xs-4 {
    flex-basis: 20%;
    max-width: 20%;
  }
  .event-box .col-xs-8 {
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  .drop-box .title {
    font-size: 32px;
    line-height: 40px;
  }
  .drop-box .subtitle {
    font-size: 18px;
    line-height: 26px;
  }
  #page-banner .col-xs-6 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .banner-half-photo-tall {
    width: 100%;
    max-width: 100%;
    margin: 30px 0;
  }

  /* News page */
  .split-banner {
    height: inherit;

    .gray-box {
      margin: 0 0 0 5px;

      .cnt .page-title {
        font-size: 32px;
        max-width: 45%;
      }

    }

    .photo {
      //max-width: 400px;
      //height: 450px;
      max-width: 50%;
      height: 80%;
      top: inherit;
      bottom: 0;
      background-size: cover !important;
    }
  }

  /*.split-banner .gray-box {
    height: auto;
    padding: 30px 0;
  }
  .split-banner .gray-box .cnt {
    top: 0;
    transform: translate(0, 0);
  }*/
}

@media only screen and (max-width: 1023px) and (min-width: 1px) {
  .home-banner #slider {
    width: 50%;
  }
  .home-banner #slider-content {
    width: 50%;
  }

  .split-banner .blue-box .page-parent {
    position: relative;
  }
  .split-banner .photo .caption {
    padding: 0 20px 0 0;
  }
  .split-banner .btns-cnt {
    padding-bottom: 20px;
  }

  .slider-banner {
    height: auto;
  }
  .slider-banner .news-box {
    position: relative;
    max-width: 100%;
  }
  .slider-banner .photo-wrap {
    width: 100%;
    height: 300px;
  }
  .slider-banner .news-box {
    padding: 30px 50px;
    height: auto;

    .secondary {
      margin-bottom: 30px !important;
    }
  }
  .slider-banner .news-box .cnt {
    top: 0;
    transform: translate(0, 0);
  }
  .slider-banner .prev {
    top: 240px;
    left: 0;
    right: inherit;
  }
  .slider-banner .next {
    top: 240px;
  }
  .slider-banner .progress-bar {
    width: 100%;
  }
}

@media only screen and (max-width: 880px) and (min-width: 1px) {
  .half-purple-banner {
    height: auto;

    .photo-wrap {
      position: relative;
      height: 300px;
    }

    .purple-box-right {
      position: relative;
      max-width: 100%;
      padding: 30px;

      .cnt {
        top: 0;
        transform: translate(0, 0);
      }
    }

    .banner-cnt {
      bottom: inherit;
      top: 220px;
    }
  }

  /* News page */
  .split-banner {

    .photo {
      max-width: 50%;
      height: 80%;
      top: inherit;
      bottom: 0;
      background-size: cover;
    }

    .gray-box {
      height: auto;
      padding: 30px 0;

      .cnt {
        left: 30px;
        top: 0;
        transform: translate(0, 0);
      }

      .cnt .page-title {
        font-size: 28px;
        max-width: 45%;
        line-height: 52px;
      }

      .page-parent {
        position: relative;
      }
    }

  }
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .split-banner {
    .gray-box {
      .cnt .page-title {
        max-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .home-banner #slider {
    width: 100%;
    height: 350px;
  }
  .home-banner .slider-lines {
    height: 350px;
  }
  .home-banner #slider-content {
    width: 100%;
    height: 350px;
  }
  .home-banner #slider-content .content-box {
    padding: 50px;
  }
  .home-banner #slider .swiper-slide .sec-1,
  .home-banner #slider .swiper-slide .sec-2,
  .home-banner #slider .swiper-slide .sec-3,
  .home-banner #slider .swiper-slide .sec-4 {
    height: 350px;
    clip: rect(0, 0, 350px, 0);
  }
  .banner .photo-wrap .photo {
    height: 400px;
  }

  /*  .split-banner .gray-box {
      margin: 0 0 0 15px;
    }*/

  /*  .split-banner .photo {
      max-width: 300px;
      height: 350px;
    }*/
}

@media only screen and (max-width: 767px) and (min-width: 1px) {
  .split-banner {
    height: auto;
    margin-bottom: 50px;
  }

  .split-banner .purple-box,
  .split-banner .gray-box {
    margin: 0;
  }
  .split-banner .btns-cnt,
  .split-banner .gray-box .cnt p {
    width: 100% !important;
  }
  .split-banner .breadcrumbs {
    width: 100%;
    padding: 30px 0 30px 30px;
  }
  .split-banner .purple-box .page-title {
    max-width: calc(100% - 40px);
    left: 20px;
  }
  .split-banner .language {
    padding: 30px 0 0 20px;
  }
  .full-banner {
    height: 300px;
  }
  .video-banner {
    height: 350px;
  }
  .video-banner .cnt h1 {
    font-size: 36px;
    line-height: 44px;
  }
  // .video-banner .sound-btn {
  // 	font-size:10px;
  // 	line-height:0px;
  // }
  .event-box .col-xs-4 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .event-box .col-xs-8 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .event-banner .event-box .cnt {
    padding-left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .banner-half-photo-tall {
    height: 300px;
  }
  .half-purple-banner {
    height: auto !important;
  }
  .split-banner .news-share {
    padding: 20px 0 20px 20px;
  }

  .split-banner .gray-box .cnt {
    left: 15px;
  }
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .drop-box .title {
    font-size: 24px;
    line-height: 34px;
    padding: 0;
  }
  .drop-box .subtitle {
    padding: 0;
  }
  .drop-box {
    width: calc(100% - 40px;)
  }
  .drop-banner .photo {
    height: 150px;
  }
  .drop-box .title {
    margin: 30px 0;
  }

  .split-banner {

    height: auto;

    img.fb-offscreen {
      position: relative;
      left: 0px;
    }

    .photo {
      position: relative;
      left: 20px;
      max-width: 100%;
      height: auto;
    }

    .purple-box {
      height: auto;
      padding: 0 0 50px 0;

      .page-title {
        font-size: 24px;
        line-height: 34px;
        position: relative;
        left: 30px;
        margin-top: 20px;
        transform: translate(0, 0);
      }

      .language {
        padding: 30px 0 0 20px;
      }
    }
  }

  .full-banner {
    height: 150px;

    .page-title {
      font-size: 24px;
      line-height: 34px;
      bottom: 20px;
      left: 20px;
    }

  }

  .video-banner .sound-btn {
    width: 100%;
    bottom: 10px;
  }
  .banner-half-photo-short {
    height: 250px;
  }
  .event-banner .page-title {
    font-size: 24px;
    line-height: 34px;
    left: 20px;
    max-width: 580px;
    width: 80%;
  }
  .event-banner .photo-wrap {
    height: 250px;
  }
  .event-banner .photo-wrap .photo {
    height: 600px;
  }
  .event-banner .event-box {
    padding: 30px 20px;
  }
  .banner .photo-wrap .photo {
    height: 400px;
  }
  .video-banner .sound-btn .song-title {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0;
  }
  .video-banner .cnt h1 {
    margin: 0 0 20px 0;
  }
  .split-banner .gray-box .cnt .purple-btn {
    margin: 10px auto;
  }
  .split-banner .gray-box .cnt {
    left: 0;
    padding: 0 30px;
  }
  .split-banner .purple-box {
    padding: 0 20px 50px 20px;
  }
  .split-banner .purple-box * {
    left: 0 !important;
  }
  .split-banner .purple-box .page-parent {
    padding: 30px 0 0 0;
  }
  .video-banner .video-btn {
    top: 0;
  }
  .half-purple-banner .purple-box-right .cnt {
    padding: 0;
  }
  .half-purple-banner .photo-wrap {
    height: 150px;
  }
  .half-purple-banner .banner-cnt {
    top: 100px;
  }
  .slider-banner .photo-wrap {
    height: 150px;
  }
  .slider-banner .photo-wrap .photo {
    height: 300px;
  }
  .slider-banner .prev {
    top: 90px;
  }
  .slider-banner .next {
    top: 90px;
  }
  .slider-banner .news-box .cnt .white-btn {
    min-width: 100%;
  }

  .split-banner .gray-box {
    height: auto;
    padding: 30px 0;

    .cnt {
      top: 0;
      transform: translate(0, 0);

      .page-title {
        font-size: 32px;
        line-height: 40px;
      }
    }

  }

  .events-slider .slider-header .transparent-btn {
    margin: 20px auto !important;
  }
  #events-slider .swiper-container, #featured-events-slider .swiper-container, #upcoming-events-slider .swiper-container {
    margin: 0 20px !important;
  }
  .banner .banner-cnt {
    left: 20px;
  }
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
  #banner .drop-box .white-btn {
    min-width: 100%;
  }
  .home-banner #slider {
    width: 100%;
    height: 200px;
  }
  .home-banner .slider-lines {
    height: 200px;
  }
  .home-banner #slider-content {
    width: 100%;
  }
  .home-banner #slider-content .content-box .intro {
    font-size: 18px;
    line-height: 24px;
  }
  .home-banner #slider-content .content-box {
    padding: 50px;
  }
  .home-banner #slider .swiper-slide .sec-1,
  .home-banner #slider .swiper-slide .sec-2,
  .home-banner #slider .swiper-slide .sec-3,
  .home-banner #slider .swiper-slide .sec-4 {
    height: 200px;
    clip: rect(0, 0, 200px, 0);
  }
  .home-banner #slider-content .prev {
    display: none;
    left: 0;
    right: inherit;
    bottom: inherit;
    top: 50%;
    transform: translate(0, -50%);
  }
  .home-banner #slider-content .next {
    display: none;
    top: 50%;
    bottom: inherit;
    transform: translate(0, -50%);
  }
  .home-banner .pagination {
    left: 50%;
    transform: translate(-50%, 0);
  }
  .split-banner .photo {
    left: 0;
    max-width: 100%;
  }
}

@media only screen and (max-width: 380px) and (min-width: 1px) {
  .home-banner #slider-content .content-box {
    padding: 50px 20px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .home-banner #slider-content .pause {
    top: -65px;
    left: unset;
    right: 15px;
  }
}