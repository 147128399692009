//
// Name:            Visit
//


// ========================================================================
// Component: Visit
// ========================================================================


#visit {
	display:block;
	position:relative;
	width:100%;
	height:500px;

	.overlay {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:2;
		background:#000;
		opacity:.5;
	}
	.photo-wrap {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1;
		overflow:hidden;

		.photo {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:900px;
			z-index:1;
			background-size:cover !important;
			transform:translate3d(0, 0, 0) !important;
		}
	}
	.cnt {
		position:absolute;
		top:50%;
		left:0;
		z-index:9;
		padding:0 150px;
		transform:translate(0, -50%);
	}
	.header1 {
		color:#fff;
	}
}


@media only screen and (max-width: 768px) and (min-width: 1px) {
	#visit .cnt {
		padding:0 50px;
	}
}

@media only screen and (max-width: 380px) and (min-width: 1px) {
	#visit {
		height:300px;

		.photo {
			height:500px !important;
		}
	}
	#visit .cnt {
		padding:0 20px;
	}
}
