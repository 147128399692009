	//
	// Hamburger
	// ==================================================

	// ========================================================================
	// Variables
	// ========================================================================

	$hamburger-padding-x                       : 15px !default;
	$hamburger-padding-y                       : 15px !default;
	$hamburger-layer-width                     : 40px !default;
	$hamburger-layer-height                    : 2px !default;
	$hamburger-layer-spacing                   : 6px !default;
	$hamburger-layer-color                     : #fff !default;
	$hamburger-layer-border-radius             : 0px !default;
	$hamburger-hover-opacity                   : 0.7 !default;
	$hamburger-hover-transition-duration       : 0.15s !default;
	$hamburger-hover-transition-timing-function: linear !default;

	// To use CSS filters as the hover effect instead of opacity,
	// set $hamburger-hover-use-filter as true and
	// change the value of $hamburger-hover-filter accordingly.
	$hamburger-hover-use-filter: false !default;
	$hamburger-hover-filter    : opacity(50%) !default;

	// Types (Remove or comment out what you don’t need)
	// ==================================================
	$hamburger-types: (
		3dx,
		3dx-r,
		3dy,
		3dy-r,
		arrow,
		arrow-r,
		arrowalt,
		arrowalt-r,
		boring,
		collapse,
		collapse-r,
		elastic,
		elastic-r,
		emphatic,
		emphatic-r,
		slider,
		slider-r,
		spring,
		spring-r,
		stand,
		stand-r,
		spin,
		spin-r,
		squeeze,
		vortex,
		vortex-r
	) !default;

	// Base Hamburger (We need this)
	// ==================================================
	// @import "base";

	// Hamburger types/import
	// ==================================================
	// @import "types/3dx";
	// @import "types/3dx-r";
	// @import "types/3dy";
	// @import "types/3dy-r";
	// @import "types/arrow";
	// @import "types/arrow-r";
	// @import "types/arrowalt";
	// @import "types/arrowalt-r";
	// @import "types/boring";
	// @import "types/collapse";
	// @import "types/collapse-r";
	// @import "types/elastic";
	// @import "types/elastic-r";
	// @import "types/emphatic";
	// @import "types/emphatic-r";
	// @import "types/slider";
	// @import "types/slider-r";
	// @import "types/spring";
	// @import "types/spring-r";
	// @import "types/stand";
	// @import "types/stand-r";
	// @import "../common/types/spin";
	// @import "types/spin-r";
	@import "../common/types/squeeze";
	// @import "types/vortex";
	// @import "types/vortex-r";

	.hamburger {
		display: none;
		cursor: pointer;

		transition-property: opacity, filter;
		transition-duration: $hamburger-hover-transition-duration;
		transition-timing-function: $hamburger-hover-transition-timing-function;

		// Normalize (<button>)
		font: inherit;
		color: inherit;
		text-transform: none;
		background-color: transparent;
		border: 0;
		margin: 0;
		overflow: visible;

		&-box {
			width: $hamburger-layer-width;
			height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
			display: inline-block;
			position: relative;
			top: 4px;
		}

		&-inner {
			display: block;
			top: 50%;
			margin-top: $hamburger-layer-height / -2;

			&,
			&:before,
			&:after {
				width: $hamburger-layer-width;
				height: $hamburger-layer-height;
				background-color: $hamburger-layer-color;
				border-radius: $hamburger-layer-border-radius;
				position: absolute;
				transition-property: transform;
				transition-duration: 0.15s;
				transition-timing-function: ease;
			}

			&:before,
			&:after {
				content: "";
				display: block;
			}

			&:before {
				top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
			}

			&:after {
				bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
			}
		}
	}
