@if index($hamburger-types, squeeze) {

	// Squeeze
	.hamburger--squeeze {
		.hamburger-inner {
			transition-duration: 0.1s;
			transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

			&:before {
				transition: top 0.1s 0.14s ease,
							opacity 0.1s ease;
			}

			&:after {
				transition: bottom 0.1s 0.14s ease,
							transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
			}
		}

		&.is-active {
			.hamburger-inner {
				transform: rotate(45deg);
				transition-delay: 0.14s;
				transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

				&:before {
					top: 0;
					opacity: 0;
					transition: top 0.1s ease,
								opacity 0.1s 0.14s ease;
				}

				&:after {
					bottom: 0;
					transform: rotate(-90deg);
					transition: bottom 0.1s ease,
								transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
				}
			}
		}
	}
}
