//
// Name:            Navigation
//
// Description:     Contains all navigation styles
//

// ========================================================================
// Component: Navigation
// ========================================================================


nav.main-nav {
	display:inline-block;
	width:auto;
	float:right;
	margin:0 140px 0 0;

	> ul {
		list-style:none;
		padding:0;
		margin:0;

		> li {
			display:inline-block;
			width:auto;
			height:120px;
			vertical-align:top;
			margin:0;

			> a {
				display:block;
				position:relative;
				width:100%;
				height:120px;
				font-family: $akkurat_bold;
				font-size: 20px;
				line-height: 120px;
				//Making white unless hovered over for accessability reasons - color: #a18fbc;
				color: #fff;
				padding:0 20px;
				text-decoration:none;

				&:after {
					position:absolute;
					content:'';
					bottom:0;
					left:0;
					width:100%;
					height:5px;
					background:#819eb5;
					opacity:0;
					transform:translate3d(0, 5px, 0);
					transition:.4s;
				}
			}
			&:hover a, &:focus a {
				//Flipped colors for accessability reasons
				color:#a18fbc;
			}
			&:hover a:after, &:focus a:after, a:focus {
				opacity:1;
				transform:translate3d(0, 0, 0);
			}
			&.active a, &:focus a {
				color:#ffffff;
			}
		    &.open nav, &:focus nav {
				max-height:999px;
				z-index:9999;
			    transition-property: max-height;
			    -webkit-transition-duration: 1s;
			    -moz-transition-duration: 1s;
			    -o-transition-duration: 1s;
			    transition-duration: 1s;
		    }
		}
	}
}

.nav-overview {
	display:block;
	overflow:hidden;
	position:absolute;
	top:120px;
	left:50%;
	background:#fff;
	width:100%;
	max-width:1340px;
	z-index:-1;
	margin-bottom:-20px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	transform:translate3d(-50%, 0, 0);
    -webkit-transition-property: max-height;
    -moz-transition-property: max-height;
    -o-transition-property: max-height;
    transition-property: max-height;
    -webkit-transition-duration: .4s;
    -moz-transition-duration: .4s;
    -o-transition-duration: .4s;
    transition-duration: .4s;
    -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    -o-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    background-color: #f6f6f6;
    max-height: 0;

    .overview-title {
		color: #000000;
		font-family: $campton;
		font-size: 32px;
		letter-spacing: -1px;
		line-height: 42px; 
		margin:60px 0 40px 0;
		text-align:left; 

		a {
			color: #000000 !important;
			font-family: $campton;
			font-size: 32px;
			letter-spacing: -1px;
			line-height: 42px; 
			margin:60px 0 40px 0;
			text-align:left; 
			text-decoration:none;
		} 	
    }
    .row {
    	margin:0;
    }
    [class*="col-"] {
    	padding:0;
    }
    .col-xs-4 ul {
    	margin-bottom:0;
    }
    &.admission-overview .cnt {
    	padding:0 30px;
    }
    &.academics-overview .cnt {
    	padding:0 30px 0 60px;
    }
    &.faculty-overview .cnt {
    	padding:0 30px 0 60px;
    }
    &.concerts-overview .cnt {
    	padding:0 30px 0 60px;
    }
    &.davee-overview .cnt {
    	padding:0 30px 0 60px;
    }
    &.davee-overview .col-xs-4 .cnt {
    	padding:140px 30px 0 60px;
    }
    &.about-overview .cnt {
    	padding:0 30px 0 60px;
    }
    .cnt + .cnt {
    	margin-top:65px;
    	padding-top:20px;
    	border-top:1px solid #aec1d0;
    }
    ul {
    	list-style:none;
    	padding:0 20px 0 0;
    	margin:0 0 20px 0;

    	li {
    		display:inline-block;
    		width:100%;
    		margin:5px 0 5px 0;
			color: #819eb5;
			font-family: $akkurat_reg;
			font-size: 12px;
			letter-spacing: 3px;
			line-height: 16px;
			text-transform: uppercase;
			text-align:left;

    		a {
    			display:block;
    			width:100%;
				color: $purple !important;
				font-family: $akkurat_reg;
				font-size: 18px;
				line-height: 26px;
				text-align:left;
				text-transform:none;
				letter-spacing:0;
				text-decoration:none;

				&:hover, &:focus {
					color:#A18FBC !important;
				}
				&.top-link {
					font-family: $akkurat_bold;
				}			
    		}
    	}
    }
	.admission-overview-photo {
		height:440px;
		background-size:cover !important;
	}
	.academics-overview-photo {
		height:670px;
		background-size:cover !important;
	}
	.faculty-overview-photo {
		height:500px;
		background-size:cover !important;
	}
	.recent-events {
		height:670px;
		border-left: 1px solid #aec1d0;
		padding:40px 80px;

		.btn {
			display:block;
			margin:20px auto 0 auto;
			color:$purple;

			&:hover, &:focus {
				color:#fff;
			}
		}
		.event {
			display:block;
			width:100%;
			padding:20px 0;
			margin:0;

			.thumb-wrap {
				display:block;
				width:100%;
				max-width:120px;
				height:120px;
				overflow:hidden;
			}
			.thumb {
				display:block;
				width:100%;
				max-width:120px;
				height:120px;
				background-size:cover !important;
				transform:scale(1);
				transition:.4s;

				a {
					position:absolute;
					top:0;
					left:0;
					width:100%;
					height:100%;
					text-decoration:none;
				}
			}
			.date {
				display:inline-block;
				width:100%;
				color: #819eb5;
				font-family: $akkurat_bold;
				font-size: 12px;
				letter-spacing: 3px;
				line-height: 16px;
				text-transform: uppercase;
				text-align:left;
				margin:0;
			}
			.event-title {
				margin:5px 0;
			}
			.event-title a {
				display:inline-block;
				width:100%;
				color: #4e2a84;
				font-family: $akkurat_bold;
				font-size: 18px;
				line-height: 26px;
				text-align:left;
				text-decoration:none;
			}
			.location {
				display:inline-block;
				width:100%;
				color: #819eb5;
				font-family: $akkurat_reg;
				font-size: 14px;
				line-height: 20px;
				padding-left:20px;
				background:url(../images/location-icon.svg) left center no-repeat;
				text-align:left;
				margin:0;
			}
			.row.is_hover {

				.thumb {
					transform:scale(1.1);
				}
				.event-title a span {
					background-size:100% 3px;
				}
			}
		}
	}
	.video-thumb {
		display:block;
		position:relative;
		width:100%;
		max-width:240px;
		height:145px;
		background-size:cover !important;

		&:before {
			position:absolute;
			content:'';
			top:50%;
			left:50%;
			width:55px;
			height:55px;
			background:url(../images/play-btn.png) 50% no-repeat;
			transform:translate(-50%, -50%);
		}
	}
	.video-title {
		display:block;
		width:90%;
		color: #4e2a84 !important;
		font-family: $akkurat_bold;
		font-size: 14px;
		line-height: 20px;
		text-align:left;
		margin:10px 0 40px 0;
		text-decoration:none;		
	}
	.about-overview-photo {
		height:500px;
		background-size:cover !important;
	}
}

#mobile-nav {
	display:none;
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:auto;
	z-index:9999;
	background:$dark-purple;
	transform:translate3d(0, -100%, 0);
	transition: transform .4s cubic-bezier(.225,.46,.32,1);

	> ul {
		list-style:none;
		padding:0;
		margin:0;

		> li {
			display:block;
			position:relative;
			z-index:999;
			width:100%;
			border-bottom:1px solid #28104c;
			background:$purple;
			overflow:hidden;
			margin:0;

			> a {
				display:block;
				position:relative;
				width:100%;
				color: #ffffff;
				font-family: $akkurat_bold;
				font-size: 16px;
				line-height:50px;
				padding:0 30px;
				background:$purple;
				text-decoration:none;

				&:hover, &:focus {
					color:#A18FBC;
				}
				.ico {
					position:absolute;
					top:0;
					right:0;
					width:50px;
					height:50px;
					border-left:1px solid #28104c;

					&:hover, &:focus {
						background:#3f2367;
					}
					&.open:after {
						transform:rotate(180deg);
					}
					&:after {
						position:absolute;
						content:'';
						width:14px;
						height:14px;
						top:18px;
						left:18px;
						background:url(../images/white-arrow.svg) 50% no-repeat;
						background-size:100% !important;
						transition:.4s;
					}
				}
			}
			> ul {
				display:none;
				list-style:none;
				padding:0;
				margin:0;
				border-top:1px solid #28104c;

				.row {
					padding:20px 60px 30px 60px;
					background:$dark-purple;
				}
				.row + .row {
					border-top:1px solid #28104c;
					padding:10px 60px;
				}
				ul {
					list-style:none;
					padding:0;
					margin:0 0 30px 0;

					li {
						display:block;
						width:100%;
						margin:10px 0;
						color: #ffffff;
						font-family: $akkurat_reg;
						font-size: 12px;
						line-height: 22px;
						text-transform:uppercase;
						letter-spacing:3px;							

						a {
							display:block;
							width:100%;
							color: #ffffff;
							font-family: $akkurat_reg;
							font-size: 14px;
							line-height: 22px;
							text-transform:none;
							letter-spacing:0;							
							text-decoration:none;

							&:hover, &:focus {
								color:#A18FBC;
							}
						}
					}
				}
			}
		}
	}
	.utility {
		background:$purple;

		nav {
			display:inline-block;
			width:calc(100% - 125px);
			padding-left:30px;

			ul {
				list-style:none;
				padding:0;
				margin:0;

				li {
					display:inline-block;
					width:auto;
					margin:0 20px 0 0;

					a {
						display:block;
						width:100%;
						text-align:center;
						color: #ffffff;
						font-family: $akkurat_bold;
						font-size: 14px;
						line-height: 50px;
						text-transform: uppercase;
						letter-spacing: 2px;
						text-decoration:none;
					}
				}
			}
		}
		.donate {
			display:inline-block;
			width:120px;
			padding:0;
			text-align:center;
			color: #ffffff;
			font-family: $akkurat_bold;
			font-size: 14px;
			line-height: 50px;
			text-transform: uppercase;
			letter-spacing: 2px;
			background:rgba(0, 0, 0, 0.5);
		}
	}
}


@media only screen and (max-width: 1380px) and (min-width: 1px) {
	nav.main-nav ul li a {
		font-size:16px;
	}
	.nav-overview .recent-events {
		padding:40px;
	}
	.nav-overview .video-thumb {
		width:90%;
	}
}

@media only screen and (max-width: 1280px) and (min-width: 1px) {
	nav.main-nav ul li a {
		font-size:14px;
	}
	.recent-events .btn {
		font-size:16px;
		line-height:30px;
	}
}

@media only screen and (max-width: 1180px) and (min-width: 1px) {
	nav.main-nav > ul > li > a {
		padding:0 10px;
	}
	.nav-overview .recent-events {
		padding:40px 20px;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 1px) {
	nav.main-nav {
		display:none;
	}
	header .hamburger,
	header .brand .search-btn {
		border-left:1px solid #28104c;
	}
	#mobile-nav {
		display:block;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	#mobile-nav.open {
		transform: translate3d(0,70px,0);
	}
	#mobile-nav > ul > li > ul .col-xs-4 {
	    flex-basis: 33.3333333333%;
	    max-width: 33.3333333333%;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	#mobile-nav .utility nav {
		width:100%;
		padding-left:0;
	}
	#mobile-nav .utility .donate {
		width:100%;
	}
	#mobile-nav .utility nav ul li {
		width:24%;
		margin:0;
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	#mobile-nav > ul > li > ul .row,
	#mobile-nav > ul > li > ul .row + .row {
		padding:20px;
	}
	#mobile-nav .utility nav ul li {
		width:49%;
	}
}
