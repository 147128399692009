//
// Name:            Classes
//


// ========================================================================
// Component: Classes
// ========================================================================


.class-row {
	display:inline-block;
	width:100%;
	padding:50px 0;

	ul li {
		margin:15px 0;
	}
	img {
		display:block;
		margin:0 auto;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.class-row img {
		margin:30px 0;
	}
}
