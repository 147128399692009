//
// Name:            Pagination
//


// ========================================================================
// Component: Pagination
// ========================================================================


.pagination {
	display:inline-block;
	position:relative;
	width:100%;
	text-align:right;

	.prev, .news-prev {
		display:inline-block;
		width:20px;
		height:20px;
		vertical-align:top;
		background:url(../images/purple-arrow.svg) 50% no-repeat;
		background-size:100%;
		transform:rotate(90deg);

		&:hover {
			opacity:.5;
		}
	}
	.next, .news-next {
		display:inline-block;
		width:20px;
		height:20px;
		vertical-align:top;
		background:url(../images/purple-arrow.svg) 50% no-repeat;
		background-size:100%;
		transform:rotate(-90deg);

		&:hover {
			opacity:.5;
		}
	}
	ul {
		display:inline-block;
		list-style:none;
		vertical-align:top;
		padding:0 40px;
		margin:0;

		li {
			display:inline-block;
			position:relative;
			width:auto;
			color:$purple;
			font-family: $akkurat_bold;
			font-size: 16px;
			line-height: 24px;
			margin:0;

			&.current {
				padding:0 10px;
			}
			&.current:after {
				position:absolute;
				content:'';
				width:4px;
				height:4px;
				background:$purple;
				border-radius:4px;
				bottom:-5px;
				left:50%;
				transform:translate(-50%, 0);
			}
			a {
				display:inline-block;
				width:100%;
				font-family: $akkurat_reg;
				font-size: 16px;
				line-height: 24px;
				color: #6f8ca6;
				padding:0 10px;
				margin:0;

				&:hover, &:focus {
					color:$purple;
					font-family: $akkurat_bold;
				}		
			}
			&:hover:after, &:focus:after {
				position:absolute;
				content:'';
				width:4px;
				height:4px;
				background:$purple;
				border-radius:4px;
				bottom:-5px;
				left:50%;
				transform:translate(-50%, 0);
			}
		}
	}
}

.pager__item a {
	text-decoration:none;
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.pagination {
		text-align:center;
	}
	.pagination ul {
		padding:0 20px;
	}
	.pagination ul li {
		margin:0 2px;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	.pagination .prev,
	.pagination .next,
	.pagination .news-prev,
	.pagination .news-next {
		display:none;
	}
	.pagination ul li {
		margin-bottom:10px;
	}
}
