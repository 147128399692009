//
// Name:            Type
//
// Description:     Contains all typography
//


// ========================================================================
// Component: Type
// ========================================================================


#style {
	padding:20px 60px;

	.section-title {
		display:block;
		width:100%;
		padding:0 0 20px 0;
		margin:60px 0 40px 0;
		border-bottom:1px solid #819eb5;
	}
	.style-label {
		color: #819eb5;
		font-family: $akkurat_reg;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		text-transform: uppercase;
		margin-top:40px;
	}
}

// main message
.main-message {
	color: #000000;
	font-family: $periodico;
	font-size: 80px;
	line-height: 96px;
	letter-spacing: -1px;
}

// h1
.header1,h1 {
  display:inline-block;
  width:100%;
  color: #000000;
  font-family: $periodico;
  font-size: 52px;
  letter-spacing: -1px;
  line-height: 62px;
}

// h2
.header2,h2 {
  display:inline-block;
  width:100%;
  color: #000000;
  font-family: $campton;
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 42px;

	a.scrollto {
		display:inline-block;
		position:relative;
		float:right;
		margin-top:15px;
		color: $purple;
		font-family: $akkurat_bold;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		text-transform: uppercase;
		text-decoration:none;

		&:hover:after,
		&:focus:after
		{
			width:100%;
		}
		&:after {
			position:absolute;
			content:'';
			bottom:0;
			left:0;
			width:0;
			height:2px;
			background:$purple;
			transition:.4s;
		}
	}
}

// h3
.header3,h3 {
	display:inline-block;
	width:100%;
	color: #000000;
	font-family: $campton;
	font-size: 24px;
	line-height: 32px;
}

/* SM - Will probably add more headers here later */

.bottom-border {
	padding-bottom:15px;
	border-bottom: 5px solid #e0e7ed;
	margin-bottom: 15px;
}

.no-border {
	border:none !important;
}

// intro paragraph
.intro {
	color: #000000;
	font-family: $akkurat_reg;
	font-size: 24px;
	line-height: 36px;
}

// subhead
.subhead {
	color: #000000;
	font-family: $akkurat_bold;
	font-size: 18px;
	line-height: 26px;
}

// body copy
p {
	color: #000000;
	font-family: $akkurat_reg;
	font-size: 16px;
	line-height: 28px;
}

// secondary
.secondary {
	color: #819eb5;
	font-family: $akkurat_reg;
	font-size: 14px;
	line-height: 20px;
}

// All Caps
.caps {
	text-transform:uppercase;
	font-family: $akkurat_bold;
}

// labels
label {
	display:block;
	color: #000;
	font-family: $akkurat_bold;
	font-size: 16px;
	line-height: 20px;
	//text-transform: uppercase;
	//letter-spacing: 2px;
	margin-top:20px !important;
}

// quotes
.quote {
	color: #000000;
	font-family: $periodico;
	font-size: 24px;
	line-height: 34px;
}

li {
	color: #000000;
	font-family: $akkurat_reg;
	font-size: 16px;
	line-height: 28px;
	margin:15px 0;
}

// paragraph text content
.paragraph--type--text-content {

	h1 {
		margin-top:20px;
		margin-bottom:40px;
	}
	.intro {
		margin-top:20px;
		margin-bottom:40px;
	}
	h1 + .intro,
	h2 + .intro,
	h3 + .intro,
	h1 + h2,
	h1 + h3,
	h2 + h3 {
		margin-top:0;
	}
	p + .intro {
		margin-top:30px;
	}
	h2 {
		margin-top:20px;
		margin-bottom:40px;
	}
	h3 {
		margin-top:20px;
		margin-bottom:30px;
	}
}


@media only screen and (max-width: 768px) and (min-width: 1px) {
	// main message
	.main-message {
		font-size: 36px;
		line-height: 44px;
	}

	// h1
	.header1,h1 {
		font-size: 32px;
		line-height: 40px;
	}

	// h2
	.header2,h2 {
		font-size: 24px;
		line-height: 32px;
	}

	// h3
	.header3,h3 {
		font-size: 18px;
		line-height: 26px;
	}

	// intro paragraph
	.intro {
		font-size: 18px;
		line-height: 26px;
	}

	// subhead
	.subhead {
		font-size: 16px;
		line-height: 24px;
	}

	// body copy
	p, li {
		font-size: 14px;
		line-height: 22px;
	}

	// secondary
	.secondary {
		font-size: 14px;
		line-height: 20px;
	}

	// labels
	label {
		font-size: 12px;
		line-height: 16px;
	}

	// quotes
	.quote {
		font-size: 18px;
		line-height: 26px;
	}
}


@media only screen and (max-width: 480px) and (min-width: 1px) {
	// main message
	.main-message {
		font-size: 28px;
		line-height: 36px;
	}

	// h1
	.header1,h1 {
		font-size: 24px;
		line-height: 32px;
	}

	// h2
	.header2,h2 {
		font-size: 18px;
		line-height: 26px;
	}

	// h3
	.header3,h3 {
		font-size: 16px;
		line-height: 24px;
	}

	// subhead
	.subhead,h4 {
		font-size: 14px;
		line-height: 22px;
	}

	.header2 a.scrollto, h2 a.scrollto {
		float:none;
		width:100%;
		text-align:center;
	}
}
