//
// Name:            History
//


// ========================================================================
// Component: History
// ========================================================================


#timeline {
	position:relative;
	margin-bottom:120px;
}

#rule {
	display:inline-block;
	padding:80px 0;
	width:100%;
}

.history-btn {
	position:absolute;
	top:20px;
	right:20px;
}

.down-line {
	position:absolute;
	left:calc(50% - 2.5px);
	top:95px;
	width:5px;
	height:0;
	background:#e0e7ed;
	z-index:9999;
	transform:translate(-50%, 0);
	transition:height 1s;
}

.history-slider {
	position:relative;

	h1 {
		display:block;
		margin:80px 0 80px 0;
	}
	.swiper-container {
		padding-bottom:120px;
		margin-bottom:120px;
	}
	&.left {

		&.on {

			.down-line {
				height:100%;
			}
		}
		.slider-header {
			position:absolute;
			top:62px;
			left:0;
			width:calc(100% - 400px);
			height:80px;

			.prev-2000,
			.prev-1900,
			.prev-1800 {
				position:absolute;
				bottom:0;
				right:59px;
				width:60px;
				height:60px;
				border:1px solid $purple;

				&:after {
					position:absolute;
					content:'';
					top:50%;
					left:50%;
					width:25px;
					height:25px;
					background:url(../images/purple-arrow.svg) 50% no-repeat;
					background-size:100%;
					transform:translate(-50%, -50%) rotate(90deg);
				}
				&:hover, &:focus {
					background:$dark-purple;
					opacity:1;

					&:after {
						background:url(../images/white-arrow.svg) 50% no-repeat;
					}
				}
			}
			.next-2000,
			.next-1900,
			.next-1800 {
				position:absolute;
				bottom:0;
				right:0;
				width:60px;
				height:60px;
				border:1px solid $purple;

				&:after {
					position:absolute;
					content:'';
					top:50%;
					left:50%;
					width:25px;
					height:25px;
					background:url(../images/purple-arrow.svg) 50% no-repeat;
					background-size:100%;
					transform:translate(-50%, -50%) rotate(-90deg);
				}			
				&:hover, &:focus {
					background:$dark-purple;
					opacity:1;

					&:after {
						background:url(../images/white-arrow.svg) 50% no-repeat;
					}
				}
			}
		}
		.date-box {
			position:absolute;
			bottom:-100px;
			right:0;
			width:580px;
			height:auto;
			z-index:999;
			background:#f2f5f8;
			padding:80px 40px 80px 40px;

			h2 {
				margin-bottom:10px;
			}
			ul {
				list-style:none;
				padding:0;

				li {
					display:inline-block;
					width:100%;
					margin:10px 0;
					font-size:14px;
					line-height:22px;
				}
			}
			p {
				font-size:14px;
				line-height:22px;
			}
			.date-line {
				display:inline-block;
				position:absolute;
				top:50px;
				right:480px;
				width:0;
				height:1px;
				z-index:999;
				background:#aec1d0;
				transition:.4s;
				transition-delay:.8s;

				&:after {
					display:inline-block;
					position:absolute;
					right:0;
					top:-7px;
					content:'';
					width:15px;
					height:15px;
					border-radius:15px;
					background:#aec1d0;
				}
			}
		}
		.photo {
			display:block;
			position:relative;
			float:left;
			width:calc(100% - 400px);
			height:580px;
			background-size:cover !important;

			span {
				position:absolute;
				top:0;
				left:0;
				width:auto;
				height:auto;
				padding:8px 30px 5px 30px;
				color: #ffffff;
				background:$purple;
				z-index:9;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height:16px;
				letter-spacing: 4px;
				text-transform: uppercase;
			}
		}
	}
	&.right {

		&.on {

			.down-line {
				height:100%;
			}
		}
		.slider-header {
			position:absolute;
			top:62px;
			right:0;
			width:calc(100% - 400px);
			height:80px;

			.prev-2000,
			.prev-1900,
			.prev-1800 {
				position:absolute;
				bottom:0;
				left:0;
				width:60px;
				height:60px;
				border:1px solid $purple;

				&:after {
					position:absolute;
					content:'';
					top:50%;
					left:50%;
					width:25px;
					height:25px;
					background:url(../images/purple-arrow.svg) 50% no-repeat;
					background-size:100%;
					transform:translate(-50%, -50%) rotate(90deg);
				}
				&:hover, &:focus {
					background:$dark-purple;
					opacity:1;

					&:after {
						background:url(../images/white-arrow.svg) 50% no-repeat;
					}
				}
			}
			.next-2000,
			.next-1900,
			.next-1800 {
				position:absolute;
				bottom:0;
				left:59px;
				width:60px;
				height:60px;
				border:1px solid $purple;

				&:after {
					position:absolute;
					content:'';
					top:50%;
					left:50%;
					width:25px;
					height:25px;
					background:url(../images/purple-arrow.svg) 50% no-repeat;
					background-size:100%;
					transform:translate(-50%, -50%) rotate(-90deg);
				}			
				&:hover, &:focus {
					background:$dark-purple;
					opacity:1;

					&:after {
						background:url(../images/white-arrow.svg) 50% no-repeat;
					}
				}
			}
		}
		.date-box {
			position:absolute;
			bottom:-100px;
			left:0;
			width:580px;
			height:auto;
			z-index:999;
			background:#f2f5f8;
			padding:120px 80px 80px 80px;

			h2 {
				margin-bottom:10px;
			}
			ul {
				list-style:none;
				padding:0;

				li {
					display:inline-block;
					width:100%;
					margin:10px 0;
				}
			}
			.date-line {
				display:inline-block;
				position:absolute;
				top:50px;
				left:80px;
				width:0;
				height:1px;
				z-index:999;
				background:#aec1d0;
				transition:.4s;
				transition-delay:.8s;

				&:after {
					display:inline-block;
					position:absolute;
					left:0;
					top:-7px;
					content:'';
					width:15px;
					height:15px;
					border-radius:15px;
					background:#aec1d0;
				}
			}
		}
		.photo {
			display:block;
			position:relative;
			float:right;
			width:calc(100% - 400px);
			height:580px;
			background-size:cover !important;

			span {
				position:absolute;
				top:0;
				right:0;
				width:auto;
				height:auto;
				padding:8px 30px 5px 30px;
				color: #ffffff;
				background:$purple;
				z-index:9;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height:16px;
				letter-spacing: 4px;
				text-transform: uppercase;
			}
		}
	}
}

.pagination-2000 {
	width:calc(100% - 700px) !important;
	padding:20px 150px;
	max-width:900px;

	.swiper-pagination-bullet {
		display:inline-block;
		width:14.2857142857%;
		height:auto;
		margin:0 10px;
		color:#819eb5;
		background:transparent;
		opacity:1;
		font-size:14px;
		text-transform:uppercase;
		letter-spacing:1px;
		font-family: $akkurat_bold;

		span {
			width: calc(100%);
			background-image: linear-gradient(transparent calc(100% - 2px), $purple 2px);
			background-repeat: no-repeat;
			background-size: 0% 3px;
			background-position:left bottom;
			transition: background-size .4s;
		}
		&:hover span, &:focus span {
		    background-size: 100% 3px;
		}
		&:hover, &:focus {
			color:$purple;
		}
	}
	.swiper-pagination-bullet-active {
		color:$purple;
	}
}

.pagination-1900 {
	width:calc(100% - 700px) !important;
	padding:20px 150px;
	float:right;
	max-width:900px;

	.swiper-pagination-bullet {
		display:inline-block;
		width:14.2857142857%;
		height:auto;
		margin:0 10px;
		color:#819eb5;
		background:transparent;
		opacity:1;
		font-size:14px;
		text-transform:uppercase;
		letter-spacing:1px;
		font-family: $akkurat_bold;
	}
	.swiper-pagination-bullet-active {
		color:$purple;
	}
}

.pagination-1800 {
	width:calc(100% - 700px) !important;
	padding:20px 150px;
	max-width:900px;

	.swiper-pagination-bullet {
		display:inline-block;
		width:14.2857142857%;
		height:auto;
		margin:0 10px;
		color:#819eb5;
		background:transparent;
		opacity:1;
		font-size:14px;
		text-transform:uppercase;
		letter-spacing:1px;
		font-family: $akkurat_bold;
	}
	.swiper-pagination-bullet-active {
		color:$purple;
	}
}


@media only screen and (max-width: 1300px) and (min-width: 1px) {
	.pagination-2000,
	.pagination-1900,
	.pagination-1800 {
		padding:20px 50px;
	}
}

@media only screen and (max-width: 1200px) and (min-width: 1px) {
	.history-slider.right .date-box,
	.history-slider.left .date-box {
		padding:80px 30px 30px;
		width:400px;
		bottom:0;
		height:100%;
	}
	.history-slider.left .date-box .date-line {
		right:300px;
	}
	.history-slider.right .slider-header,
	.history-slider.left .slider-header {
		width:100%;
	}
	.history-slider .swiper-container {
		padding-bottom:0;
	}
}

@media only screen and (max-width: 1080px) and (min-width: 1px) {
	.pagination-1800, .pagination-1900, .pagination-2000 {
		width:calc(100% - 500px) !important;
	}
}

@media only screen and (max-width: 880px) and (min-width: 1px) {
	.pagination-1800, .pagination-1900, .pagination-2000 {
		padding:20px;
		width:50% !important;
	}
	.history-slider.left .photo {
		width:50%;
	}
	.history-slider.right .photo {
		width:50%;
	}
	.history-slider.left .date-box {
		width:50%;
	}
	.history-slider.right .date-box {
		width:50%;
	}
	.history-slider .date-box .date-line {
		display:none !important;
	}
	.down-line {
		width:6px;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.history-slider.left .slider-header,
	.history-slider.right .slider-header {
		top:40px;
	}
	.pagination-2000 .swiper-pagination-bullet,
	.pagination-1900 .swiper-pagination-bullet,
	.pagination-1800 .swiper-pagination-bullet {
		width:25%;
		margin:0 !important;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	.history-slider.left .date-box,
	.history-slider.left .photo,
	.history-slider.right .date-box,
	.history-slider.right .photo {
		width:100%;
		position:relative;
	}
	.down-line {
		display:none !important;
	}
	.history-slider.left .slider-header,
	.history-slider.right .slider-header {
		text-align:center;
	}
	.history-slider.left .slider-header .prev-1800,
	.history-slider.left .slider-header .prev-1900,
	.history-slider.left .slider-header .prev-2000,
	.history-slider.left .slider-header .next-1800,
	.history-slider.left .slider-header .next-1900,
	.history-slider.left .slider-header .next-2000,
	.history-slider.right .slider-header .prev-1800,
	.history-slider.right .slider-header .prev-1900,
	.history-slider.right .slider-header .prev-2000,
	.history-slider.right .slider-header .next-1800,
	.history-slider.right .slider-header .next-1900,
	.history-slider.right .slider-header .next-2000 {
		position:relative;
		display:inline-block;
		right:0;
		left:0;
	}
	.history-slider.left .date-box,
	.history-slider.left .photo,
	.history-slider.right .date-box,
	.history-slider.right .photo {
		padding:30px;
	}
	.history-slider.right .photo,
	.history-slider.left .photo {
		height:380px;
	}
	.swiper-container-horizontal > .swiper-pagination-bullets,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		width:100% !important;
	}
	.pagination-2000,
	.pagination-1900,
	.pagination-1800 {
		text-align:center;
	}
	#rule {
		display:none;
	}
}

@media only screen and (max-width: 380px) and (min-width: 1px) {
	.history-slider.right .photo,
	.history-slider.left .photo {
		height:280px;
	}
	.history-slider {
		border-bottom:1px solid #aec1d0 !important;
	}
}
