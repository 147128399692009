//
// Name:            Lists
//


// ========================================================================
// Component: Lists
// ========================================================================


ul {
	margin:-10px 0 20px 0;

	li {
		margin:0;
	}
}

article ul li {
	margin:15px 0;
}

ul.hours {
	list-style:none;
	padding:0 40px 0 0;
	margin:0;

	li {
		margin:0;

		span {
			float:right;
		}
	}
}

ol {
	margin:-10px 0 40px 0;

	li {
		margin:0 0 10px 0;
	}
}

.list-title {
	margin:0;
}

.blue-list {
	list-style:none;
	padding:0;
	margin:10px 0;

	li {
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin:10px 0;		
	}
}

.audition-list {

	.head {
		padding-bottom:10px;
		color: #819eb5;
		font-family: $akkurat_reg;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		text-transform: uppercase;
	}
	> [class*=col-] {
		border-bottom: 5px solid #e0e7ed;
	}
	.body {
		color: #4e2a84;
		font-family: $akkurat_reg;
		font-size: 24px;
		line-height: 36px;
		padding:25px 0;
		border-bottom:1px solid #aec1d0;
		transition:.4s;

		&:hover, &:focus {
			background:#f2f5f8;
			a:hover span, a:focus span {
			    background-size: 100% 3px;
			}
		}
		a {
			position:relative;

			span {
				width: calc(100%);
				background-image: linear-gradient(transparent calc(100% - 2px), $purple 2px);
				background-repeat: no-repeat;
				background-size: 0% 3px;
				background-position:left bottom;
				transition: background-size .4s;
			}
			&:hover, &:focus {
				color:$purple;
			}

		}	
	}
}

.expense-list {

	.row {
		padding:20px 0 0 0;
		border-bottom: 1px solid #aec1d0;
	}
	p {
		padding-bottom:20px;
	}
	.header3 {
		border-bottom:none;
		padding-bottom:0;
		margin-bottom:10px;
	}
	.header3 span {
		float:right;
		font-family: $akkurat_reg;
	}
	.total p {
		border: 1px solid #e0e7ed;
		background-color: #f2f5f8;
		padding:25px;
	}
}

.link-list {
	list-style:none;
	padding:0;

	li {
		width:100%;
		margin:10px 0;		
	}
}

.alpha-list {
	list-style:none;
	padding:0;
	text-align:center;

	li {
		display:inline-block;
		width:auto;
		margin:0;
		text-align:center;

		a {
			display:inline-block;
			position:relative;
			color: $purple;
			font-family: $akkurat_bold;
			font-size: 18px;
			line-height: 26px;
			text-transform: uppercase;
			text-align:center;
			padding:0 8px;
			text-decoration:none;

			&:after {
				position:absolute;
				content:'';
				bottom:0;
				left:0;
				width:0;
				height:2px;
				background:$purple;
				transition:.4s;
			}
			&:hover:after, &:focus:after {
				width:100%;
			}
		}
	}
}


@media only screen and (max-width: 768px) and (min-width: 1px) {
	.alpha-list li a {
		font-size:18px;
		line-height:26px;
	}
	ul {
		margin:0 0 20px 0;
	}
}