//
// Name:            Alert
//


// ========================================================================
// Component: Alert
// ========================================================================


#alert {
	display:none;
	position:relative;
	z-index:9999999;
	width:100%;
	padding:0 30px;
	background:#ed3024;
	color: #ffffff;
	font-family: $akkurat_reg;
	font-size: 16px;
	line-height: 28px;
	color:#fff;

	p {
		color:#fff;
		padding:10px 40px 10px 0;
	}
	span {
		font-family: $akkurat_bold;
	}
	.close {
		position:absolute;
		top:50%;
		right:30px;
		width:30px;
		height:30px;
		background:url(../images/white-close.png) 50% no-repeat;
		transform:translate(0, -50%);
	}
}


@media only screen and (max-width: 580px) and (min-width: 1px) {
	#alert {
		padding:10px 20px;
	}
	#alert .close {
		right:20px;
	}
}
