//
// Name:            Graduates
//


// ========================================================================
// Component: Graduates
// ========================================================================


#graduates {
	padding:100px 0 100px 150px;

	.photo {
		width:100%;
		height:500px;
		background-size:cover !important;
	}
	.cnt {
		padding-right:150px;
	}
	ul {
		list-style:none;
		padding:0;
		margin:40px 0;

		li {
			color: #000000;
			font-family: $akkurat_reg;
			font-size: 16px;
			line-height: 28px;
			margin:10px 0;
		}
	}
}


@media only screen and (max-width: 1024px) and (min-width: 1px) {
	#graduates {
		padding:100px 0 0 50px;
	}
	#graduates .cnt {
		padding-right:50px;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	#graduates {
		padding:100px 50px;
	}
	#graduates .cnt {
		padding-right:0;
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	#graduates {
		padding:100px 20px;
	}
	#graduates .photo {
		height:300px;
	}
}
