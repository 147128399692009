//
// Component:       Variables
// Description:     Defines all color and style related values as variables
//                  to allow easy customization for the most common cases.
//
// ========================================================================

// ========================================================================
// Component: Variables
// ========================================================================


// Fonts
$campton: 'Poppins-Medium';
$periodico: 'PeriodicoDisplay-XtLg';
$akkurat_bold: 'AkkuratPro-Bold';
$akkurat_reg: 'AkkuratPro-Regular';

// Colors
$dark_purple: #3f2367;
$purple: #4e2a84;
$purple_90: rgba(63, 35, 103, 0.9);

// Forms
$form-field-border-radius:0;
$form-field-background:transparent;
$form-field-border:1px solid #979797;
$form-field-font-size:16px;
$form-field-color:#fff;
$form-field-padding:0 20px;
$form-field-height:44px;
