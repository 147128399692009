//
// Name:            Base
//
// Description:     Contains all base styles, including basic resets
//

// ========================================================================
// Component
// ========================================================================


body {
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	font-size: 16px;
	line-height: 24px;
}

a {
	color:$purple;
	transition:.6s;
	text-decoration:underline;
}

.body-link {
	position:relative;
	font-family: $akkurat_bold;
	text-decoration:none;

	span {
		width: calc(100%);
		background-image: linear-gradient(transparent calc(1% - 2px), $purple 2px);
		background-repeat: no-repeat;
		background-size: 0% 3px;
		background-position:left bottom;
		transition: background-size .4s;
	}
	&:hover span, &:focus span {
	    background-size: 100% 3px;
	}
	&:hover, &:focus {
		color:$purple;
	}
	&.location-link:hover, &.location-link:focus {
		text-decoration:underline;
	}
	.ico {
		display:inline-block;
		width:12px;
		height:12px;
		background:url(../images/purple-arrow.svg) 50% no-repeat;
		background-size:100% !important;
		transform:rotate(-90deg);
	}
}

.firefox,
.edge,
.ie {
	.body-link:hover, .body-link:focus {
		text-decoration:underline;
	}
	.body-link span,
	.body-link:hover span,
	.body-link:focus span {
		background-image:none !important;
		background-size:0px 0px !important;
	}
}

video::-webkit-media-controls {
	display: none !important;
}

h1, h2, h3, h4 {
	margin:0;
	font-weight: normal;
}

p {
	margin:0 0 20px 0;
}

:focus {
	outline: thin dotted;
}

//For when an element is clicked
:focus:active {
	outline: hidden;
}
#highlighted {
	ul.tabs {
		padding-left: 10px;
		margin: 0;
		
		li {
			border-right: 1px solid gray !important;
			padding-right: 10px;
			margin-right: 10px;
			
			a {
				border: none !important;
				text-decoration: none;
				border-radius: 0;
			}
		}
	}
}