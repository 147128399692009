//
// Name:            Academics
//


// ========================================================================
// Component: Academics
// ========================================================================


#academics {

	.col-xs-6 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	// .col-xs-6:first-child {
	// 	padding-left:.5rem;
	// 	padding-right:calc(.5rem + 10px);
	// }
	// .col-xs-6:last-child {
	// 	padding-left:calc(.5rem + 10px);
	// 	padding-right:.5rem;
	// }
	.col-xs-4 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	// .col-xs-4:first-child {
	// 	padding-left:.5rem;
	// 	padding-right:calc(.5rem + 10px);
	// }
	// .col-xs-4:last-child {
	// 	padding-left:calc(.5rem + 10px);
	// 	padding-right:.5rem;
	// }
}
.academic-box {
	position:relative;
	width:100%;
	height:280px;
	background:#000;
	margin-bottom:40px;
	overflow:hidden;

	&:hover .bg {
		transform:scale(1.1);
		filter:grayscale(0);
		filter: none;
	}
	a {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:9;
	}
	.bg {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:7;
		opacity:.6;
		background-size:cover !important;
		transform:scale(1);
		filter:grayscale(100%);
		filter: gray;
		transition:.4s;
	}
	span {
		position:absolute;
		left:20px;
		bottom:20px;
		z-index:8;
		color: #ffffff;
		font-family: $campton;
		font-size: 24px;
		line-height: 32px;		
	}
}


@media only screen and (max-width: 1024px) and (min-width: 1px) {
	.academic-box span {
		font-size:18px;
		line-height:26px;
		padding-right:20px;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.academic-box span {
		font-size:24px;
		line-height:32px;
	}
	#academics .col-xs-6,
	#academics .col-xs-6:first-child,
	#academics .col-xs-6:last-child {
		padding-left: .5rem;
    	padding-right: .5rem;
	}
	#academics .col-xs-4,
	#academics .col-xs-4:first-child,
	#academics .col-xs-4:last-child {
		padding-left: .5rem;
    	padding-right: .5rem;
	}
}