//
// Name:            About
//


// ========================================================================
// Component: About
// ========================================================================


#about {
	max-width:1660px;
	margin:0 auto;

	.row {
		margin-left:0;
		margin-right:0;
	}
	.row > [class*=col-] {
		padding-left:0;
		padding-right:0;
	}
	#dean {
		padding:100px 0 100px 0;

		.cnt {
			padding-right:150px;
			padding-left:150px;
		}
		img {
			width:100%;
		}
	}

	.located-box {
		display:block;
		position:relative;
		width:100%;
		height:480px;

		.blue-box {
			position:absolute;
			bottom:0;
			left:50px;
			z-index:999;
			padding:30px;
			width:260px;
			height:300px;
			background-color: rgba(129, 158, 181, 0.9);

			p {
				color: #ffffff;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height: 16px;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
			.stat {
				color: #ffffff;
				font-family: $periodico;
				font-size: 52px;
				font-weight: 200;
				line-height: 62px;
				text-transform:none;
				letter-spacing: -1px;
				margin:0;
			}
		}
		.bg {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			background-size:cover !important;
		}
	}
	.events-box {
		display:block;
		position:relative;
		width:100%;
		height:720px;
		top:-240px;
		margin-bottom:-240px;

		.blue-box {
			position:absolute;
			bottom:120px;
			right:0;
			z-index:999;
			padding:30px;
			width:260px;
			height:145px;
			background-color: rgba(129, 158, 181, 0.9);

			p {
				color: #ffffff;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height: 16px;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
			.stat {
				color: #ffffff;
				font-family: $periodico;
				font-size: 52px;
				font-weight: 200;
				line-height: 62px;
				text-transform:none;
				letter-spacing: -1px;
				margin:0;
			}
		}
		.bg {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			background-size:cover !important;
		}
	}
	.founded-box {
		display:block;
		position:relative;
		width:100%;
		height:920px;

		.blue-box {
			position:absolute;
			top:320px;
			left:0;
			z-index:999;
			padding:30px;
			width:460px;
			height:160px;
			background-color: rgba(129, 158, 181, 0.9);

			p {
				color: #ffffff;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height: 16px;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
			.stat {
				color: #ffffff;
				font-family: $periodico;
				font-size: 52px;
				font-weight: 200;
				line-height: 62px;
				text-transform:none;
				letter-spacing: -1px;
				margin:0;
			}
		}
		.bg {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			background-size:cover !important;
		}
	}
	.founded-copy {
		padding:80px 120px 0 120px;
	}
	.students-box {
		display:block;
		position:relative;
		width:100%;
		height:840px;

		.blue-box {
			position:absolute;
			bottom:0;
			right:0;
			z-index:999;
			padding:200px 30px 30px 30px;
			width:260px;
			height:450px;
			background-color: rgba(129, 158, 181, 0.9);

			p {
				color: #ffffff;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height: 16px;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
			.stat {
				color: #ffffff;
				font-family: $periodico;
				font-size: 52px;
				font-weight: 200;
				line-height: 62px;
				text-transform:none;
				letter-spacing: -1px;
				margin:0;
			}
		}
		.bg {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			background-size:cover !important;
		}
	}
	.faculty-box {
		display:block;
		position:relative;
		width:100%;
		height:480px;
		top:-280px;
		margin-bottom:-280px;

		.blue-box {
			position:absolute;
			top:0;
			left:0;
			z-index:999;
			padding:30px;
			width:360px;
			height:140px;
			background-color: rgba(129, 158, 181, 0.9);

			p {
				color: #ffffff;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height: 16px;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
			.stat {
				color: #ffffff;
				font-family: $periodico;
				font-size: 52px;
				font-weight: 200;
				line-height: 62px;
				text-transform:none;
				letter-spacing: -1px;
				margin:0;
			}
		}
		.bg {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			background-size:cover !important;
		}
	}
	.history-cnt {
		padding:80px 120px 0 120px;
	}
	#history {
		padding:120px 0 0 0;
	}
	.visit-cnt {
		padding:80px 120px 0 120px;
	}
	.programs-box {
		display:block;
		position:relative;
		width:100%;
		height:580px;
		margin-bottom:120px;

		.blue-box {
			position:absolute;
			bottom:80px;
			right:0;
			z-index:999;
			padding:30px;
			width:250px;
			height:160px;
			background-color: rgba(129, 158, 181, 0.9);

			p {
				color: #ffffff;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height: 16px;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
			.stat {
				color: #ffffff;
				font-family: $periodico;
				font-size: 52px;
				font-weight: 200;
				line-height: 62px;
				text-transform:none;
				letter-spacing: -1px;
				margin:0;
			}
		}
		.bg {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			background-size:cover !important;
		}
	}
	.guests-box {
		display:block;
		position:relative;
		width:100%;
		height:480px;

		.blue-box {
			position:absolute;
			top:0;
			right:80px;
			z-index:999;
			padding:30px;
			width:180px;
			height:300px;
			background-color: rgba(129, 158, 181, 0.9);

			p {
				color: #ffffff;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height: 16px;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
			.stat {
				color: #ffffff;
				font-family: $periodico;
				font-size: 52px;
				font-weight: 200;
				line-height: 62px;
				text-transform:none;
				letter-spacing: -1px;
				margin:0;
			}
		}
		.bg {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			background-size:cover !important;
		}
	}
	.degrees-box {
		display:block;
		position:relative;
		width:100%;
		height:600px;
		top:-120px;
		margin-bottom:-120px;

		.blue-box {
			position:absolute;
			bottom:80px;
			right:0;
			z-index:999;
			padding:30px;
			width:240px;
			height:160px;
			background-color: rgba(129, 158, 181, 0.9);

			p {
				color: #ffffff;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height: 16px;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
			.stat {
				color: #ffffff;
				font-family: $periodico;
				font-size: 52px;
				font-weight: 200;
				line-height: 62px;
				text-transform:none;
				letter-spacing: -1px;
				margin:0;
			}
		}
		.bg {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			background-size:cover !important;
		}
	}
	.prize-box {
		display:block;
		position:relative;
		width:100%;
		height:880px;

		.blue-box {
			position:absolute;
			top:0;
			left:0;
			z-index:999;
			padding:30px;
			width:520px;
			height:180px;
			background-color: rgba(129, 158, 181, 0.9);

			p {
				color: #ffffff;
				font-family: $akkurat_reg;
				font-size: 12px;
				line-height: 16px;
				text-transform: uppercase;
				letter-spacing: 2px;
			}
			.stat {
				color: #ffffff;
				font-family: $periodico;
				font-size: 52px;
				font-weight: 200;
				line-height: 62px;
				text-transform:none;
				letter-spacing: -1px;
				margin:0;
			}
		}
		.bg {
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:1;
			background-size:cover !important;
		}
	}
	.contact-cnt {
		padding:0 120px;
	}
}


@media only screen and (max-width: 1280px) and (min-width: 1px) {
	#about #dean .cnt {
		padding-left:50px;
		padding-right:50px;
	}
	#about .founded-copy {
		padding:30px 30px 0 30px;
	}
	#about .history-cnt,
	#about .visit-cnt {
		padding:30px 30px 0 30px;
	}
	#about .contact-cnt {
		padding:0 30px;
	}
	#about .prize-box .blue-box {
		width:100%;
	}
}

@media only screen and (max-width: 980px) and (min-width: 1px) {
	#about .founded-box .blue-box {
		width:100%;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	#about #dean img {
		width:calc(100% - 100px);
		margin:50px 50px 0 50px;
	}
	#dean a {
		margin:0 50px;
	}
	#about .events-box {
		top:0;
		margin-bottom:0;
	}
	#about .students-box {
		margin-top:50px;
	}
	#about .faculty-box,
	#about .degrees-box {
		top:0;
		margin-bottom:0;
	}
	#about .contact-cnt {
		padding:30px;
	}
	#about .programs-box {
		margin-bottom:0;
	}
	#about .visit-cnt {
		padding:30px;
	}
	#about #history {
		padding:30px 0 0 0;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	#about .founded-box {
		height:700px;
	}
	#about .prize-box {
		height:680px;
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	#about .students-box {
		height:550px;
	}
	#about .degrees-box {
		height:400px;
	}
	#about .students-box .blue-box {
		padding:100px 30px 30px 30px;
		height:350px;
	}
	#about #dean .cnt {
		padding-left:20px;
		padding-right:20px;
	}
	#about #dean img {
		width:calc(100% - 40px);
		margin:50px 20px 0 20px;
	}
	#dean a {
		margin:0 20px;
	}
	#about #dean {
		padding:30px 0;
	}
}

@media only screen and (max-width: 380px) and (min-width: 1px) {
	#about .events-box {
		height:520px;
	}
	#about .founded-box {
		height:500px;
	}
	#history .btn {
		min-width:100%;
	}
}
