//
// Name:            Fonts
//
// Description:     Contains all fonts
//


// ========================================================================
// Component: Fonts
// ========================================================================


@font-face {
	font-family: 'Poppins-Medium';
	src: url('../fonts/Poppins-Medium.eot');
    src: url('../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Medium.woff2') format('woff2'),
        url('../fonts/Poppins-Medium.woff') format('woff'),
        url('../fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'PeriodicoDisplay-XtLg';
  src: url('../fonts/noto-serif-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/noto-serif-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/noto-serif-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-serif-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-serif-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/noto-serif-v20-latin-regular.svg#NotoSerif') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'AkkuratPro-Bold';
	src: url('../fonts/AkkuratPro-Bold.woff');
}

@font-face {
	font-family: 'AkkuratPro-Regular';
	src: url('../fonts/AkkuratPro-Regular.woff');
}
