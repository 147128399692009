//
// Name:            Table
//


// ========================================================================
// Component: Table
// ========================================================================


.table-wrapper {
  display:block;
  padding:20px 0 0 0;

  .table {

    .table-header {
      visibility:hidden;
      height:0;

      &.show {
        visibility:visible;
        height:auto;
        color: #507796;
        font-family: $akkurat_bold;
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 16px;
        text-transform: uppercase;
        padding:10px 0;
        margin-bottom:20px;
        border-bottom: 5px solid #e0e7ed;
      }
    }
    .table-body {
      position:relative;

      .download {
        position:absolute;
        top:-10px;
        right:0;
        z-index:999;
      }
      .row {
        display:table;
        margin:0;
        width:100%;
        border: 1px solid #e0e7ed;
      }
      .table-blue {
        display:table-cell;
        width:25%;
        background-color: #819eb5;
        color: #ffffff;
        font-family: $akkurat_bold;
        font-size: 18px;
        line-height: 26px;
        padding:20px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        vertical-align:middle;

        p {
          color: #ffffff;
          font-family: $akkurat_bold;
          font-size: 18px;
          line-height: 26px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          vertical-align:middle;
        }
      }
      .table-gray {
        display:table-cell;
        width:75%;
        color: #000000;
        font-family: $akkurat_reg;
        font-size: 16px;
        line-height: 28px;
        padding:20px;
        background-color: #f2f5f8;
        vertical-align:middle;

        p {
          color: #000000;
          font-family: $akkurat_reg;
          font-size: 16px;
          line-height: 28px;
          vertical-align:middle;
        }
      }
    }
    .table-footer {
      padding:20px 0;
    }
  }
}

.list-table-header {
  color: #507796;
  font-family: $akkurat_bold;
  font-size: 12px;
  letter-spacing: 3px;
  line-height: 16px;
  text-transform: uppercase;
  padding:10px 0;
  border-bottom: 5px solid #e0e7ed;
}
.list-table-header + .list-table {
  border-top:none;
}
.list-table {
  padding:20px 0;
  border-top:1px solid #aec1d0;

  .title {
    display:inline-block;
    color: #4e2a84;
    font-family: $akkurat_bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom:10px;
  }
  .secondary {
    letter-spacing:3px;
  }
  p {
    margin:5px 0;
  }
  h2 {
    font-size: 16px;
    line-height:28px;
  }
  a {
    color: $purple;
    font-family: $akkurat_reg;
    font-size: 16px;
    line-height: 28px;
    text-decoration:none;
  }
  .date {

    span {
      display:inline-block;
      width:100%;
    }
  }
}
.list-table-footer {
  padding:30px 0;
  border-top:1px solid #aec1d0;
}

.data-table-header {
  color: #507796;
  font-family: $akkurat_bold;
  font-size: 12px;
  letter-spacing: 3px;
  line-height: 16px;
  text-transform: uppercase;
  padding:10px 0;
}
.data-table {
  padding:15px 0;
  border-top:1px solid #aec1d0;
  color: #000000;
  font-family: $akkurat_reg;
  font-size: 16px;
  line-height: 28px;

  .null {
    display:inline-block;
    width:20px;
    height:2px;
    background:#000;
    vertical-align:middle;
  }
  .label {
    display:none;
  }
}
.data-table-footer {
  padding:15px 0;
  border-top:1px solid #aec1d0;
}

.tabbed-table {

  .tab-link {
    display:inline-block;
    position:relative;
    font-family: $akkurat_bold;
    font-size: 16px;
    line-height: 24px;
    color: #4e2a84;
    width:100%;
    margin:10px 0;
    text-decoration:none;

    &:first-child {
      margin-top:0;
    }
    .ico {
      position:absolute;
      width:20px;
      height:20px;
      top:0;
      right:0;
      background:url(../images/purple-arrow.svg) 50% no-repeat;
      transition:.4s;
    }
    &.active {
      color:#000;

      .ico {
        transform:rotate(180deg);
      }
    }
  }
  .tab-cnt {
    display:none;

    .table-wrapper {
      padding:0;
    }
  }
  #tab1 {
    display:block;
  }
}

//WYSIWYG Tables
/*table:not(.calendar-table) {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%;

  thead {
    visibility:visible;
    height:auto;
    color: #507796;
    text-transform: uppercase;
    padding:10px 0;
    margin-bottom:20px;
    border-bottom: 5px solid #e0e7ed;

    tr {
      th {
        font-family: $akkurat_bold;
        font-size: 16px;
        letter-spacing: 3px;
        line-height: 16px;
      }
    }
  }

   tbody {
    tr {
      border: 1px solid #e0e7ed;
      color: #000000;
      font-family: $akkurat_reg;
      font-size: 16px;
      line-height: 28px;
      vertical-align:middle;

      td {
        border: 1px solid #e0e7ed;
      }

      td+td {
        //border: none;
      }
    }
  }

   tfoot {

  }
}*/


@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .data-table-header .col-xs-4,
  .data-table-header .col-xs-2,
  .data-table .col-xs-4,
  .data-table .col-xs-2 {
    flex-basis: 100%;
    max-width: 100%;
  }
  .data-table-header {
    display:none;
  }
  .data-table .label {
    display:inline-block;
    width:250px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 1px) {
  .table-wrapper .table .table-body .download {
    position:relative;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 1px) {
  .table-blue,
  .table-gray {
    width:50% !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .table-wrapper {
    padding:10px 0 0 0;
  }
  .table-wrapper .table .table-body .table-blue,
  .table-wrapper .table .table-body .table-gray {
    padding:5px 10px;
  }
  .table-wrapper .table .table-body .row {
    display:flex;
  }
  .table-blue,
  .table-gray {
    width:100% !important;
    display:flex;
  }
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
  .data-table .label {
    width:100%;
  }
  .data-table-header .col-xs-4,
  .data-table-header .col-xs-2,
  .data-table .col-xs-4,
  .data-table .col-xs-2 {
    text-align:center;
  }
}
