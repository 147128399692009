//
// Name:            Gray Box
//


// ========================================================================
// Component: Gray Box
// ========================================================================


.gray-box {
	display:block;
	position:relative;
	border: 1px solid #e0e7ed;
	background-color: #f2f5f8;
	padding:50px;
	margin:40px 0;

	&.shadow {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	}
	.title {
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 12px;
		//letter-spacing: 2px;
		line-height: 16px;
		//text-transform: uppercase;		
	}
	.col-xs-4 .purple-btn {
		max-width:300px;
		display:block;
		margin:20px auto;
	}
	.download {
		position:absolute;
		top:0;
		right:0;
		margin:0;
	}
	.header3 {
		padding-bottom:10px;
		border-bottom: 5px solid #e0e7ed;
	}
	hr {
		margin:20px 0;
	}
	.score {
		padding:40px 0;

		.title {
			color: #000000;
			font-family: $akkurat_bold;
			font-size: 18px;
			line-height: 26px;
		}
		p {
			color: #000000;
			font-size: 16px;
			line-height: 28px;
			font-family: $akkurat_reg;
			margin:0;
		}
		.number {
			color: #000000;
			font-family: $campton;
			font-size: 32px;
			letter-spacing: -1px;
			line-height: 42px;
			margin-bottom:20px;
		}	
	}
	ul.col-4, div.col-4 {
		display:inline-block;
		width:24%;
		list-style:none;
		padding:0;

		li {
			display:inline-block;
			width:100%;
			margin:10px 0;
			padding:0 10px;
		}
	}
	.toggle-box {
		display:none;
		width:100%;
		max-width:100%;
		height:auto;
		border:none;
		padding:0;

		.subtitle {
			color: #4f7796;
			font-family: $akkurat_bold;
			font-size: 12px;
			letter-spacing: 2px;
			line-height: 16px;
			text-transform: uppercase;			
		}
		p {
			margin:0;
		}
	}
	.toggle-link {
		position:relative;
		color: $purple;
		font-family: $akkurat_bold;
		font-size: 14px;
		line-height: 20px;
		padding-right:15px;
		text-decoration:none;

		.ico {
			position:absolute;
			top:2px;
			right:0;
			width:10px;
			height:10px;
			background:url(../images/Down_Arrow5.svg) 50% no-repeat;
			transform:rotate(0deg);
			transition:.4s;
		}		
		&.active {

			.ico {
				transform:rotate(180deg);
			}
		}	
	}
}

aside nav .gray-box {
	max-width:265px;
}


@media only screen and (max-width: 1280px) and (min-width: 1px) {
	.gray-bar {
		padding:10px 60px;
	}
}

@media only screen and (max-width: 1180px) and (min-width: 1px) {
	.gray-box .col-xs-4 .purple-btn {
		min-width:100%;
	}
}

@media only screen and (max-width: 1024px) and (min-width: 1px) {
	.gray-box ul.col-4, .gray-box dev.col-4 {
		width:49%;
		margin-bottom:0;
	}
	#board-members .fixed-width {
		padding:0;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.gray-box .toggle-box .col-xs-4 {
		flex-basis: 33.3333333333%;
	    max-width: 33.3333333333%;
	}
	.gray-box .toggle-box .col-xs-6 {
		flex-basis: 50%;
	    max-width: 50%;
	}
	#honor-roll {
		margin:0;
	}
	.gray-box ul.col-4 li, .gray-box div.col-4 ul li {
		margin:10px 0;
	}
	#honor-roll .toggle-box .col-xs-6 {
		flex-basis: 100% !important;
	    max-width: 100% !important;
	}
	.gray-box .fixed-width {
		padding:0 60px !important;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	.gray-box {
		padding:50px 20px;
	}
	.gray-box .toggle-box .col-xs-4 {
		flex-basis: 100%;
	    max-width: 100%;
	    -webkit-box-pack: start;
	    -ms-flex-pack: start;
	    -moz-justify-content: flex-start;
	    justify-content: flex-start;
	    text-align: start;
	}
	.gray-box .toggle-box .col-xs-4.end-xs {
		margin-bottom:30px;
	}
	.gray-box .fixed-width.top {
		padding:0;
	}
	.gray-box ul.col-4, .gray-box dev.col-4 {
		width:100%;
	}
	#area-of-study .gray-box {
		margin-bottom:0 !important;
	}
	.gray-bar {
		padding:10px 20px;
	}
	.gray-box .fixed-width {
		padding:0 0px !important;
	}
}
