//
// Name:            Studies
//


// ========================================================================
// Component: Studies
// ========================================================================


#area-of-study {
	max-width:1240px;

	.degrees {
		padding-left:100px;

		.title {
			color: #507796;
			font-family: $akkurat_reg;
			font-size: 12px;
			letter-spacing: 3px;
			line-height: 16px;
			text-transform: uppercase;
		}
		ul {
			list-style:none;
			padding:0;
			margin:20px 0;

			li {
				margin:10px 0;

				a {
					position:relative;
					color: $purple;
					font-family: $akkurat_bold;
					font-size: 16px;
					line-height: 24px;
				}
			}
		}
	}
}

#studies {
	margin: auto;
	padding:100px 0 50px 0;

	.studies-box {

		.photo-wrap {
			display:block;
			position:relative;
			width:100%;
			height:230px;
			overflow:hidden;
		}
		.photo {
			display:block;
			position:relative;
			width:100%;
			height:230px;
			background-size:cover !important;
			transform:scale(1);
			transition:.4s;

			&:hover, &:focus {
				transform:scale(1.1);
			}
			a {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
			}
		}
		.study-title {
			color: #000000;
			font-family: $campton;
			font-size: 24px;
			line-height: 32px;
			margin:30px 0 20px 0;

			a {
				position:relative;
				padding-bottom:10px;
				color: #000000;
				text-decoration:none;

				&:after {
					position:absolute;
					content:'';
					bottom:0;
					left:0;
					width:0;
					height:3px;
					background:$purple;
					transition:.4s;
				}
				&:hover, &:focus {
					color:$purple;

					&:after {
						width:100%;
					}
				}
			}
		}
		&.is_hover {

			.photo {
				transform:scale(1.1);
			}
			.study-title a {
				color:$purple;
			}
			.study-title a:after {
				width:100%;
			}
		}
		.toggle-btns {
			max-width:100%;
			margin-bottom:40px;
		}
	}
}

#directory a {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;		
}


@media only screen and (max-width: 1280px) and (min-width: 1px) {
	#studies {
		margin:40px auto 0 auto;
		padding:100px 0;
	}
}

@media only screen and (max-width: 1024px) and (min-width: 1px) {
	#area-of-study {
		padding:0 50px;
	}
	#area-of-study .degrees {
		padding-left:50px;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	#area-of-study .degrees {
		padding:50px 0 0 0;
	}
	#studies {
		padding:30px 50px;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	#studies .studies-box .study-title {
		font-size: 16px;
		line-height: 24px;
		font-weight:700;	
	}
	#studies {
		padding:30px 20px;
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	.box-office .cnt ul li span {
		width:100%;
		margin-bottom:20px;
	}
	.box-office .cnt .hours {
		display:inline-block;
	}
}
