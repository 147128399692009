/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 * 
 * http://www.idangero.us/swiper/
 * 
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 * 
 * Licensed under MIT
 * 
 * Released on: March 10, 2017
 */
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height; }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  /* Firefox 16+, IE10, Opera 12.50+ */ }

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px; }

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center; }

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

/*!
 * FullCalendar v3.10.5
 * Docs & License: https://fullcalendar.io/
 * (c) 2019 Adam Shaw
 */
.fc {
  direction: ltr;
  text-align: left; }

.fc-rtl {
  text-align: right; }

body .fc {
  /* extra precedence to overcome jqui */
  font-size: 1em; }

/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-highlight {
  /* when user is selecting cells */
  background: #bce8f1;
  opacity: .3; }

.fc-bgevent {
  /* default look for background events */
  background: #8fdf82;
  opacity: .3; }

.fc-nonbusiness {
  /* default look for non-business-hours areas */
  /* will inherit .fc-bgevent's styles */
  background: #d7d7d7; }

/* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/
.fc button {
  /* force height to include the border and padding */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* dimensions */
  margin: 0;
  height: 2.1em;
  padding: 0 .6em;
  /* text & cursor */
  font-size: 1em;
  /* normalize */
  white-space: nowrap;
  cursor: pointer; }

/* Firefox has an annoying inner border */
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0; }

.fc-state-default {
  /* non-theme */
  border: 1px solid; }

.fc-state-default.fc-corner-left {
  /* non-theme */
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.fc-state-default.fc-corner-right {
  /* non-theme */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

/* icons in buttons */
.fc button .fc-icon {
  /* non-theme */
  position: relative;
  top: -0.05em;
  /* seems to be a good adjustment across browsers */
  margin: 0 .2em;
  vertical-align: middle; }

/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/
.fc-state-default {
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  color: #333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #333333;
  background-color: #e6e6e6; }

.fc-state-hover {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear; }

.fc-state-down,
.fc-state-active {
  background-color: #cccccc;
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05); }

.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  box-shadow: none; }

/* Buttons Groups
--------------------------------------------------------------------------------------------------*/
.fc-button-group {
  display: inline-block; }

/*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/
.fc .fc-button-group > * {
  /* extra precedence b/c buttons have margin set to zero */
  float: left;
  margin: 0 0 0 -1px; }

.fc .fc-button-group > :first-child {
  /* same */
  margin-left: 0; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); }

.fc-popover .fc-header {
  /* TODO: be more consistent with fc-head/fc-body */
  padding: 2px 4px; }

.fc-popover .fc-header .fc-title {
  margin: 0 2px; }

.fc-popover .fc-header .fc-close {
  cursor: pointer; }

.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left; }

.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right; }

/* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/
.fc-divider {
  border-style: solid;
  border-width: 1px; }

hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  /* height is unreliable across browsers, so use padding */
  border-width: 1px 0; }

.fc-clear {
  clear: both; }

.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  /* these element should always cling to top-left/right corners */
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

.fc-bg {
  bottom: 0;
  /* strech bg to bottom edge */ }

.fc-bg table {
  height: 100%;
  /* strech bg to bottom edge */ }

/* Tables
--------------------------------------------------------------------------------------------------*/
.fc table {
  width: 100%;
  box-sizing: border-box;
  /* fix scrollbar issue in firefox */
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  /* normalize cross-browser */ }

.fc th {
  text-align: center; }

.fc th,
.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top; }

.fc td.fc-today {
  border-style: double;
  /* overcome neighboring borders */ }

/* Internal Nav Links
--------------------------------------------------------------------------------------------------*/
a[data-goto] {
  cursor: pointer; }

a[data-goto]:hover {
  text-decoration: underline; }

/* Fake Table Rows
--------------------------------------------------------------------------------------------------*/
.fc .fc-row {
  /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */
  /* no visible border by default. but make available if need be (scrollbar width compensation) */
  border-style: solid;
  border-width: 0; }

.fc-row table {
  /* don't put left/right border on anything within a fake row.
     the outer tbody will worry about this */
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  /* no bottom borders on rows */
  border-bottom: 0 hidden transparent; }

.fc-row:first-child table {
  border-top: 0 hidden transparent;
  /* no top border on first row */ }

/* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/
.fc-row {
  position: relative; }

.fc-row .fc-bg {
  z-index: 1; }

/* highlighting cells & background event skeleton */
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
  /* stretch skeleton to bottom of row */ }

.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
  /* stretch skeleton to bottom of row */ }

.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
  border-color: transparent; }

.fc-row .fc-bgevent-skeleton {
  z-index: 2; }

.fc-row .fc-highlight-skeleton {
  z-index: 3; }

/*
row content (which contains day/week numbers and events) as well as "helper" (which contains
temporary rendered events).
*/
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px;
  /* matches the space above the events */ }

.fc-row .fc-helper-skeleton {
  z-index: 5; }

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  /* see-through to the background below */
  /* extra precedence to prevent theme-provided backgrounds */
  background: none;
  /* in case <td>s are globally styled */
  border-color: transparent; }

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  /* don't put a border between events and/or the day number */
  border-bottom: 0; }

.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  /* don't put a border between event cells */
  border-top: 0; }

/* Scrolling Container
--------------------------------------------------------------------------------------------------*/
.fc-scroller {
  -webkit-overflow-scrolling: touch; }

/* TODO: move to agenda/basic */
.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
  position: relative;
  /* re-scope all positions */
  width: 100%;
  /* hack to force re-sizing this inner element when scrollbars appear/disappear */ }

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.fc-event {
  position: relative;
  /* for resize handle and other inner positioning */
  display: block;
  /* make the <a> tag block */
  font-size: .85em;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #3a87ad;
  /* default BORDER color */ }

.fc-event,
.fc-event-dot {
  background-color: #3a87ad;
  /* default BACKGROUND color */ }

.fc-event,
.fc-event:hover {
  color: #fff;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */ }

.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer;
  /* give events with links and draggable events a hand mouse pointer */ }

.fc-not-allowed,
.fc-not-allowed .fc-event {
  /* to override an event's custom cursor */
  cursor: not-allowed; }

.fc-event .fc-bg {
  /* the generic .fc-bg already does position */
  z-index: 1;
  background: #fff;
  opacity: .25; }

.fc-event .fc-content {
  position: relative;
  z-index: 2; }

/* resizer (cursor AND touch devices) */
.fc-event .fc-resizer {
  position: absolute;
  z-index: 4; }

/* resizer (touch devices) */
.fc-event .fc-resizer {
  display: none; }

.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
  /* only show when hovering or selected (with touch) */
  display: block; }

/* hit area */
.fc-event.fc-selected .fc-resizer:before {
  /* 40x40 touch area */
  content: "";
  position: absolute;
  z-index: 9999;
  /* user of this util can scope within a lower z-index */
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px; }

/* Event Selection (only for touch devices)
--------------------------------------------------------------------------------------------------*/
.fc-event.fc-selected {
  z-index: 9999 !important;
  /* overcomes inline z-index */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); }

.fc-event.fc-selected.fc-dragging {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3); }

/* Horizontal Events
--------------------------------------------------------------------------------------------------*/
/* bigger touch area when selected */
.fc-h-event.fc-selected:before {
  content: "";
  position: absolute;
  z-index: 3;
  /* below resizers */
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0; }

/* events that are continuing to/from another week. kill rounded corners and butt up against edge */
.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  /* replace the border with padding */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  /* replace the border with padding */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* resizer (cursor AND touch devices) */
/* left resizer  */
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px;
  /* overcome border */ }

/* right resizer */
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px;
  /* overcome border */ }

/* resizer (mouse devices) */
.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 7px;
  top: -1px;
  /* overcome top border */
  bottom: -1px;
  /* overcome bottom border */ }

/* resizer (touch devices) */
.fc-h-event.fc-selected .fc-resizer {
  /* 8x8 little dot */
  border-radius: 4px;
  border-width: 1px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* vertically center */
  top: 50%;
  margin-top: -4px; }

/* left resizer  */
.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -4px;
  /* centers the 8x8 dot on the left edge */ }

/* right resizer */
.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -4px;
  /* centers the 8x8 dot on the right edge */ }

/* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-day-grid-event {
  margin: 1px 2px 0;
  /* spacing between events and edges */
  padding: 0 1px; }

tr:first-child > td > .fc-day-grid-event {
  margin-top: 2px;
  /* a little bit more space before the first event */ }

.fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  /* same z-index as fc-bg, behind text */
  /* overcome the borders */
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  /* darkening effect */
  background: #000;
  opacity: .25; }

.fc-day-grid-event .fc-content {
  /* force events to be one-line tall */
  white-space: nowrap;
  overflow: hidden; }

.fc-day-grid-event .fc-time {
  font-weight: bold; }

/* resizer (cursor devices) */
/* left resizer  */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -2px;
  /* to the day cell's edge */ }

/* right resizer */
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -2px;
  /* to the day cell's edge */ }

/* Event Limiting
--------------------------------------------------------------------------------------------------*/
/* "more" link that represents hidden events */
a.fc-more {
  margin: 1px 3px;
  font-size: .85em;
  cursor: pointer;
  text-decoration: none; }

a.fc-more:hover {
  text-decoration: underline; }

.fc-limited {
  /* rows and cells that are hidden because of a "more" link */
  display: none; }

/* popover that appears when "more" link is clicked */
.fc-day-grid .fc-row {
  z-index: 1;
  /* make the "more" popover one higher than this */ }

.fc-more-popover {
  z-index: 2;
  width: 220px; }

.fc-more-popover .fc-event-container {
  padding: 10px; }

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-now-indicator {
  position: absolute;
  border: 0 solid red; }

/* Utilities
--------------------------------------------------------------------------------------------------*/
.fc-unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

/*
TODO: more distinction between this file and common.css
*/
/* Colors
--------------------------------------------------------------------------------------------------*/
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #ddd; }

.fc-unthemed .fc-popover {
  background-color: #fff; }

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td {
  background: #eee; }

.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666; }

.fc-unthemed td.fc-today {
  background: #fcf8e3; }

.fc-unthemed .fc-disabled-day {
  background: #d7d7d7;
  opacity: .3; }

/* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/
.fc-icon {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  font-family: "Courier New", Courier, monospace;
  /* don't allow browser text-selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/*
Acceptable font-family overrides for individual icons:
  "Arial", sans-serif
  "Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/
.fc-icon:after {
  position: relative; }

.fc-icon-left-single-arrow:after {
  content: "\2039";
  font-weight: bold;
  font-size: 200%;
  top: -7%; }

.fc-icon-right-single-arrow:after {
  content: "\203A";
  font-weight: bold;
  font-size: 200%;
  top: -7%; }

.fc-icon-left-double-arrow:after {
  content: "\AB";
  font-size: 160%;
  top: -7%; }

.fc-icon-right-double-arrow:after {
  content: "\BB";
  font-size: 160%;
  top: -7%; }

.fc-icon-left-triangle:after {
  content: "\25C4";
  font-size: 125%;
  top: 3%; }

.fc-icon-right-triangle:after {
  content: "\25BA";
  font-size: 125%;
  top: 3%; }

.fc-icon-down-triangle:after {
  content: "\25BC";
  font-size: 125%;
  top: 2%; }

.fc-icon-x:after {
  content: "\D7";
  font-size: 200%;
  top: 6%; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid; }

.fc-unthemed .fc-popover .fc-header .fc-close {
  font-size: .9em;
  margin-top: 2px; }

/* List View
--------------------------------------------------------------------------------------------------*/
.fc-unthemed .fc-list-item:hover td {
  background-color: #f5f5f5; }

/* Colors
--------------------------------------------------------------------------------------------------*/
.ui-widget .fc-disabled-day {
  background-image: none; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0;
  /* where they meet, let the header have the border */ }

/* Global Event Styles
--------------------------------------------------------------------------------------------------*/
.ui-widget .fc-event {
  /* overpower jqui's styles on <a> tags. TODO: more DRY */
  color: #fff;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */
  /* undo ui-widget-header bold */
  font-weight: normal; }

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.ui-widget td.fc-axis {
  font-weight: normal;
  /* overcome bold */ }

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats .ui-widget-content {
  background: none;
  /* see through to fc-bg */ }

.fc.fc-bootstrap3 a {
  text-decoration: none; }

.fc.fc-bootstrap3 a[data-goto]:hover {
  text-decoration: underline; }

.fc-bootstrap3 hr.fc-divider {
  border-color: inherit; }

.fc-bootstrap3 .fc-today.alert {
  border-radius: 0; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap3 .fc-popover .panel-body {
  padding: 0; }

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap3 .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none; }

.fc.fc-bootstrap4 a {
  text-decoration: none; }

.fc.fc-bootstrap4 a[data-goto]:hover {
  text-decoration: underline; }

.fc-bootstrap4 hr.fc-divider {
  border-color: inherit; }

.fc-bootstrap4 .fc-today.alert {
  border-radius: 0; }

.fc-bootstrap4 a.fc-event:not([href]):not([tabindex]) {
  color: #fff; }

.fc-bootstrap4 .fc-popover.card {
  position: absolute; }

/* Popover
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap4 .fc-popover .card-body {
  padding: 0; }

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-bootstrap4 .fc-time-grid .fc-slats table {
  /* some themes have background color. see through to slats */
  background: none; }

/* Toolbar
--------------------------------------------------------------------------------------------------*/
.fc-toolbar {
  text-align: center; }

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1em; }

.fc-toolbar.fc-footer-toolbar {
  margin-top: 1em; }

.fc-toolbar .fc-left {
  float: left; }

.fc-toolbar .fc-right {
  float: right; }

.fc-toolbar .fc-center {
  display: inline-block; }

/* the things within each left/right/center section */
.fc .fc-toolbar > * > * {
  /* extra precedence to override button border margins */
  float: left;
  margin-left: .75em; }

/* the first thing within each left/center/right section */
.fc .fc-toolbar > * > :first-child {
  /* extra precedence to override button border margins */
  margin-left: 0; }

/* title text */
.fc-toolbar h2 {
  margin: 0; }

/* button layering (for border precedence) */
.fc-toolbar button {
  position: relative; }

.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2; }

.fc-toolbar .fc-state-down {
  z-index: 3; }

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4; }

.fc-toolbar button:focus {
  z-index: 5; }

/* View Structure
--------------------------------------------------------------------------------------------------*/
/* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
/* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.fc-view,
.fc-view > table {
  /* so dragged elements can be above the view's main element */
  position: relative;
  z-index: 1; }

/* BasicView
--------------------------------------------------------------------------------------------------*/
/* day row structure */
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  /* there may be week numbers in these views, so no padding-top */
  padding-bottom: 1em;
  /* ensure a space at bottom of cell for user selecting/clicking */ }

.fc-basic-view .fc-body .fc-row {
  min-height: 4em;
  /* ensure that all rows are at least this tall */ }

/* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
.fc-row.fc-rigid {
  overflow: hidden; }

.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }

/* week and day number styling */
.fc-day-top.fc-other-month {
  opacity: 0.3; }

.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
  padding: 2px; }

.fc-basic-view th.fc-week-number,
.fc-basic-view th.fc-day-number {
  padding: 0 2px;
  /* column headers can't have as much v space */ }

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: right; }

.fc-rtl .fc-basic-view .fc-day-top .fc-day-number {
  float: left; }

.fc-ltr .fc-basic-view .fc-day-top .fc-week-number {
  float: left;
  border-radius: 0 0 3px 0; }

.fc-rtl .fc-basic-view .fc-day-top .fc-week-number {
  float: right;
  border-radius: 0 0 0 3px; }

.fc-basic-view .fc-day-top .fc-week-number {
  min-width: 1.5em;
  text-align: center;
  background-color: #f2f2f2;
  color: #808080; }

/* when week/day number have own column */
.fc-basic-view td.fc-week-number {
  text-align: center; }

.fc-basic-view td.fc-week-number > * {
  /* work around the way we do column resizing and ensure a minimum width */
  display: inline-block;
  min-width: 1.25em; }

/* AgendaView all-day area
--------------------------------------------------------------------------------------------------*/
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
  /* so the "more.." popover will be over the time grid */ }

.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
  /* all-day section will never get shorter than this */ }

.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 1em;
  /* give space underneath events for clicking/selecting days */ }

/* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
.fc .fc-axis {
  /* .fc to overcome default cell styles */
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap; }

.fc-ltr .fc-axis {
  text-align: right; }

.fc-rtl .fc-axis {
  text-align: left; }

/* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/
.fc-time-grid-container,
.fc-time-grid {
  /* so slats/bg/content/etc positions get scoped within here */
  position: relative;
  z-index: 1; }

.fc-time-grid {
  min-height: 100%;
  /* so if height setting is 'auto', .fc-bg stretches to fill height */ }

.fc-time-grid table {
  /* don't put outer borders on slats/bg/content/etc */
  border: 0 hidden transparent; }

.fc-time-grid > .fc-bg {
  z-index: 1; }

.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  /* the <hr> AgendaView injects when grid is shorter than scroller */
  position: relative;
  z-index: 2; }

.fc-time-grid .fc-content-col {
  position: relative;
  /* because now-indicator lives directly inside */ }

.fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0; }

/* divs within a cell within the fc-content-skeleton */
.fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1; }

.fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2; }

.fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3; }

.fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4; }

.fc-time-grid .fc-now-indicator-line {
  z-index: 5; }

.fc-time-grid .fc-helper-container {
  /* also is fc-event-container */
  position: relative;
  z-index: 6; }

/* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-slats td {
  height: 1.5em;
  border-bottom: 0;
  /* each cell is responsible for its top border */ }

.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted; }

/* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-highlight-container {
  /* a div within a cell within the fc-highlight-skeleton */
  position: relative;
  /* scopes the left/right of the fc-highlight to be in the column */ }

.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
  /* top and bottom will be in by JS */ }

/* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/
.fc-ltr .fc-time-grid .fc-event-container {
  /* space on the sides of events for LTR (default) */
  margin: 0 2.5% 0 2px; }

.fc-rtl .fc-time-grid .fc-event-container {
  /* space on the sides of events for RTL */
  margin: 0 2px 0 2.5%; }

.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  /* scope inner z-index's */ }

.fc-time-grid .fc-bgevent {
  /* background events always span full width */
  left: 0;
  right: 0; }

/* Generic Vertical Event
--------------------------------------------------------------------------------------------------*/
.fc-v-event.fc-not-start {
  /* events that are continuing from another day */
  /* replace space made by the top border with padding */
  border-top-width: 0;
  padding-top: 1px;
  /* remove top rounded corners */
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.fc-v-event.fc-not-end {
  /* replace space made by the top border with padding */
  border-bottom-width: 0;
  padding-bottom: 1px;
  /* remove bottom rounded corners */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

/* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
.fc-time-grid-event {
  overflow: hidden;
  /* don't let the bg flow over rounded corners */ }

.fc-time-grid-event.fc-selected {
  /* need to allow touch resizers to extend outside event's bounding box */
  /* common fc-selected styles hide the fc-bg, so don't need this anyway */
  overflow: visible; }

.fc-time-grid-event.fc-selected .fc-bg {
  display: none;
  /* hide semi-white background, to appear darker */ }

.fc-time-grid-event .fc-content {
  overflow: hidden;
  /* for when .fc-selected */ }

.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 1px; }

.fc-time-grid-event .fc-time {
  font-size: .85em;
  white-space: nowrap; }

/* short mode, where time and title are on the same line */
.fc-time-grid-event.fc-short .fc-content {
  /* don't wrap to second line (now that contents will be inline) */
  white-space: nowrap; }

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  /* put the time and title on the same line */
  display: inline-block;
  vertical-align: top; }

.fc-time-grid-event.fc-short .fc-time span {
  display: none;
  /* don't display the full time text... */ }

.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
  /* ...instead, display only the start time */ }

.fc-time-grid-event.fc-short .fc-time:after {
  content: "\A0-\A0";
  /* seperate with a dash, wrapped in nbsp's */ }

.fc-time-grid-event.fc-short .fc-title {
  font-size: .85em;
  /* make the title text the same size as the time */
  padding: 0;
  /* undo padding from above */ }

/* resizer (cursor device) */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize; }

.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "="; }

/* resizer (touch device) */
.fc-time-grid-event.fc-selected .fc-resizer {
  /* 10x10 dot */
  border-radius: 5px;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* horizontally center */
  left: 50%;
  margin-left: -5px;
  /* center on the bottom edge */
  bottom: -5px; }

/* Now Indicator
--------------------------------------------------------------------------------------------------*/
.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0; }

/* arrow on axis */
.fc-time-grid .fc-now-indicator-arrow {
  margin-top: -5px;
  /* vertically center on top coordinate */ }

.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  /* triangle pointing right... */
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent; }

.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  right: 0;
  /* triangle pointing left... */
  border-width: 5px 6px 5px 0;
  border-top-color: transparent;
  border-bottom-color: transparent; }

/* List View
--------------------------------------------------------------------------------------------------*/
/* possibly reusable */
.fc-event-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px; }

/* view wrapper */
.fc-rtl .fc-list-view {
  direction: rtl;
  /* unlike core views, leverage browser RTL */ }

.fc-list-view {
  border-width: 1px;
  border-style: solid; }

/* table resets */
.fc .fc-list-table {
  table-layout: auto;
  /* for shrinkwrapping cell content */ }

.fc-list-table td {
  border-width: 1px 0 0;
  padding: 8px 14px; }

.fc-list-table tr:first-child td {
  border-top-width: 0; }

/* day headings with the list */
.fc-list-heading {
  border-bottom-width: 1px; }

.fc-list-heading td {
  font-weight: bold; }

.fc-ltr .fc-list-heading-main {
  float: left; }

.fc-ltr .fc-list-heading-alt {
  float: right; }

.fc-rtl .fc-list-heading-main {
  float: right; }

.fc-rtl .fc-list-heading-alt {
  float: left; }

/* event list items */
.fc-list-item.fc-has-url {
  cursor: pointer;
  /* whole row will be clickable */ }

.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px; }

/* make the dot closer to the event title */
.fc-ltr .fc-list-item-marker {
  padding-right: 0; }

.fc-rtl .fc-list-item-marker {
  padding-left: 0; }

.fc-list-item-title a {
  /* every event title cell has an <a> tag */
  text-decoration: none;
  color: inherit; }

.fc-list-item-title a[href]:hover {
  /* hover effect only on titles with hrefs */
  text-decoration: underline; }

/* message when no events */
.fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.fc-list-empty-wrap1 {
  width: 100%;
  height: 100%;
  display: table; }

.fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center; }

.fc-unthemed .fc-list-empty {
  /* theme will provide own background */
  background-color: #eee; }

@import url(../../bower_components/swiper/dist/css/swiper.css);
@import url(../../bower_components/fullcalendar/dist/fullcalendar.css);
.wrapper {
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto; }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.row.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -webkit-box-direction: reverse;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse; }

.col-xs {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: auto;
  -moz-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto; }

.col-xs-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 8.3333333333%;
  -moz-flex-basis: 8.3333333333%;
  -ms-flex-preferred-size: 8.3333333333%;
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%; }

.col-xs-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 16.6666666667%;
  -moz-flex-basis: 16.6666666667%;
  -ms-flex-preferred-size: 16.6666666667%;
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%; }

.col-xs-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 25%;
  -moz-flex-basis: 25%;
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 33.3333333333%;
  -moz-flex-basis: 33.3333333333%;
  -ms-flex-preferred-size: 33.3333333333%;
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%; }

.col-xs-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 41.6666666667%;
  -moz-flex-basis: 41.6666666667%;
  -ms-flex-preferred-size: 41.6666666667%;
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%; }

.col-xs-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 50%;
  -moz-flex-basis: 50%;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 58.3333333333%;
  -moz-flex-basis: 58.3333333333%;
  -ms-flex-preferred-size: 58.3333333333%;
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%; }

.col-xs-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 66.6666666667%;
  -moz-flex-basis: 66.6666666667%;
  -ms-flex-preferred-size: 66.6666666667%;
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%; }

.col-xs-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 75%;
  -moz-flex-basis: 75%;
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 83.3333333333%;
  -moz-flex-basis: 83.3333333333%;
  -ms-flex-preferred-size: 83.3333333333%;
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%; }

.col-xs-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 91.6666666667%;
  -moz-flex-basis: 91.6666666667%;
  -ms-flex-preferred-size: 91.6666666667%;
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%; }

.col-xs-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-1 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 8.3333333333%; }

.col-xs-offset-2 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 16.6666666667%; }

.col-xs-offset-3 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 25%; }

.col-xs-offset-4 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 33.3333333333%; }

.col-xs-offset-5 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 41.6666666667%; }

.col-xs-offset-6 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 50%; }

.col-xs-offset-7 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 58.3333333333%; }

.col-xs-offset-8 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 66.6666666667%; }

.col-xs-offset-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 75%; }

.col-xs-offset-10 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 83.3333333333%; }

.col-xs-offset-11 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 91.6666666667%; }

.col-xs-offset-12 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -moz-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-left: 100%; }

.col-xs {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 0;
  -moz-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%; }

.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start; }

.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center; }

.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end; }

.around-xs {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around; }

.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem; }
  .col-sm {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-sm-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-sm-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-sm-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-sm-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-sm-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-sm-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-sm-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-sm-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-sm-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.3333333333%; }
  .col-sm-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.6666666667%; }
  .col-sm-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-sm-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.3333333333%; }
  .col-sm-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.6666666667%; }
  .col-sm-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-sm-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.3333333333%; }
  .col-sm-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.6666666667%; }
  .col-sm-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-sm-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.3333333333%; }
  .col-sm-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.6666666667%; }
  .col-sm-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-sm {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-sm {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 62em) {
  .container {
    width: 61rem; }
  .col-md {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-md-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-md-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-md-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-md-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-md-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-md-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-md-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-md-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-md-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.3333333333%; }
  .col-md-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.6666666667%; }
  .col-md-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-md-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.3333333333%; }
  .col-md-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.6666666667%; }
  .col-md-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-md-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.3333333333%; }
  .col-md-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.6666666667%; }
  .col-md-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-md-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.3333333333%; }
  .col-md-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.6666666667%; }
  .col-md-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-md {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-md {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 75em) {
  .container {
    width: 71rem; }
  .col-lg {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: auto;
    -moz-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .col-lg-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 8.3333333333%;
    -moz-flex-basis: 8.3333333333%;
    -ms-flex-preferred-size: 8.3333333333%;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%; }
  .col-lg-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 16.6666666667%;
    -moz-flex-basis: 16.6666666667%;
    -ms-flex-preferred-size: 16.6666666667%;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%; }
  .col-lg-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 25%;
    -moz-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 33.3333333333%;
    -moz-flex-basis: 33.3333333333%;
    -ms-flex-preferred-size: 33.3333333333%;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%; }
  .col-lg-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 41.6666666667%;
    -moz-flex-basis: 41.6666666667%;
    -ms-flex-preferred-size: 41.6666666667%;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%; }
  .col-lg-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 50%;
    -moz-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 58.3333333333%;
    -moz-flex-basis: 58.3333333333%;
    -ms-flex-preferred-size: 58.3333333333%;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%; }
  .col-lg-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 66.6666666667%;
    -moz-flex-basis: 66.6666666667%;
    -ms-flex-preferred-size: 66.6666666667%;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%; }
  .col-lg-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 75%;
    -moz-flex-basis: 75%;
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 83.3333333333%;
    -moz-flex-basis: 83.3333333333%;
    -ms-flex-preferred-size: 83.3333333333%;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%; }
  .col-lg-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 91.6666666667%;
    -moz-flex-basis: 91.6666666667%;
    -ms-flex-preferred-size: 91.6666666667%;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%; }
  .col-lg-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-1 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 8.3333333333%; }
  .col-lg-offset-2 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 16.6666666667%; }
  .col-lg-offset-3 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 25%; }
  .col-lg-offset-4 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 33.3333333333%; }
  .col-lg-offset-5 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 41.6666666667%; }
  .col-lg-offset-6 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 50%; }
  .col-lg-offset-7 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 58.3333333333%; }
  .col-lg-offset-8 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 66.6666666667%; }
  .col-lg-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 75%; }
  .col-lg-offset-10 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 83.3333333333%; }
  .col-lg-offset-11 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 91.6666666667%; }
  .col-lg-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 100%; }
  .col-lg {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 0;
    -moz-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    align-items: flex-start; }
  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center; }
  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    align-items: flex-end; }
  .around-lg {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    justify-content: space-around; }
  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -80px;
      top: -80px;
      transform: translate3d(80px, 80px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -80px;
      top: -80px;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -80px;
      top: 80px;
      transform: translate3d(80px, -80px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -80px;
      top: 80px;
      transform: translate3d(-80px, -80px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.7142857143px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    transform: rotate(45deg) translate3d(5.7142857143px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

@font-face {
  font-family: 'Poppins-Medium';
  src: url("../fonts/Poppins-Medium.eot");
  src: url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Medium.woff2") format("woff2"), url("../fonts/Poppins-Medium.woff") format("woff"), url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'PeriodicoDisplay-XtLg';
  src: url("../fonts/noto-serif-v20-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/noto-serif-v20-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/noto-serif-v20-latin-regular.woff2") format("woff2"), url("../fonts/noto-serif-v20-latin-regular.woff") format("woff"), url("../fonts/noto-serif-v20-latin-regular.ttf") format("truetype"), url("../fonts/noto-serif-v20-latin-regular.svg#NotoSerif") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'AkkuratPro-Bold';
  src: url("../fonts/AkkuratPro-Bold.woff"); }

@font-face {
  font-family: 'AkkuratPro-Regular';
  src: url("../fonts/AkkuratPro-Regular.woff"); }

#style {
  padding: 20px 60px; }
  #style .section-title {
    display: block;
    width: 100%;
    padding: 0 0 20px 0;
    margin: 60px 0 40px 0;
    border-bottom: 1px solid #819eb5; }
  #style .style-label {
    color: #819eb5;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 40px; }

.main-message {
  color: #000000;
  font-family: "PeriodicoDisplay-XtLg";
  font-size: 80px;
  line-height: 96px;
  letter-spacing: -1px; }

.header1, h1 {
  display: inline-block;
  width: 100%;
  color: #000000;
  font-family: "PeriodicoDisplay-XtLg";
  font-size: 52px;
  letter-spacing: -1px;
  line-height: 62px; }

.header2, h2 {
  display: inline-block;
  width: 100%;
  color: #000000;
  font-family: "Poppins-Medium";
  font-size: 32px;
  letter-spacing: -1px;
  line-height: 42px; }
  .header2 a.scrollto, h2 a.scrollto {
    display: inline-block;
    position: relative;
    float: right;
    margin-top: 15px;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none; }
    .header2 a.scrollto:hover:after, .header2 a.scrollto:focus:after, h2 a.scrollto:hover:after, h2 a.scrollto:focus:after {
      width: 100%; }
    .header2 a.scrollto:after, h2 a.scrollto:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: #4e2a84;
      transition: .4s; }

.header3, h3 {
  display: inline-block;
  width: 100%;
  color: #000000;
  font-family: "Poppins-Medium";
  font-size: 24px;
  line-height: 32px; }

/* SM - Will probably add more headers here later */
.bottom-border {
  padding-bottom: 15px;
  border-bottom: 5px solid #e0e7ed;
  margin-bottom: 15px; }

.no-border {
  border: none !important; }

.intro {
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 24px;
  line-height: 36px; }

.subhead {
  color: #000000;
  font-family: "AkkuratPro-Bold";
  font-size: 18px;
  line-height: 26px; }

p {
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 28px; }

.secondary {
  color: #819eb5;
  font-family: "AkkuratPro-Regular";
  font-size: 14px;
  line-height: 20px; }

.caps {
  text-transform: uppercase;
  font-family: "AkkuratPro-Bold"; }

label {
  display: block;
  color: #000;
  font-family: "AkkuratPro-Bold";
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px !important; }

.quote {
  color: #000000;
  font-family: "PeriodicoDisplay-XtLg";
  font-size: 24px;
  line-height: 34px; }

li {
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 28px;
  margin: 15px 0; }

.paragraph--type--text-content h1 {
  margin-top: 20px;
  margin-bottom: 40px; }

.paragraph--type--text-content .intro {
  margin-top: 20px;
  margin-bottom: 40px; }

.paragraph--type--text-content h1 + .intro,
.paragraph--type--text-content h2 + .intro,
.paragraph--type--text-content h3 + .intro,
.paragraph--type--text-content h1 + h2,
.paragraph--type--text-content h1 + h3,
.paragraph--type--text-content h2 + h3 {
  margin-top: 0; }

.paragraph--type--text-content p + .intro {
  margin-top: 30px; }

.paragraph--type--text-content h2 {
  margin-top: 20px;
  margin-bottom: 40px; }

.paragraph--type--text-content h3 {
  margin-top: 20px;
  margin-bottom: 30px; }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .main-message {
    font-size: 36px;
    line-height: 44px; }
  .header1, h1 {
    font-size: 32px;
    line-height: 40px; }
  .header2, h2 {
    font-size: 24px;
    line-height: 32px; }
  .header3, h3 {
    font-size: 18px;
    line-height: 26px; }
  .intro {
    font-size: 18px;
    line-height: 26px; }
  .subhead {
    font-size: 16px;
    line-height: 24px; }
  p, li {
    font-size: 14px;
    line-height: 22px; }
  .secondary {
    font-size: 14px;
    line-height: 20px; }
  label {
    font-size: 12px;
    line-height: 16px; }
  .quote {
    font-size: 18px;
    line-height: 26px; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  .main-message {
    font-size: 28px;
    line-height: 36px; }
  .header1, h1 {
    font-size: 24px;
    line-height: 32px; }
  .header2, h2 {
    font-size: 18px;
    line-height: 26px; }
  .header3, h3 {
    font-size: 16px;
    line-height: 24px; }
  .subhead, h4 {
    font-size: 14px;
    line-height: 22px; }
  .header2 a.scrollto, h2 a.scrollto {
    float: none;
    width: 100%;
    text-align: center; } }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html,
body {
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  box-sizing: border-box; }

:focus {
  outline: 0; }

:last-child {
  margin-bottom: 0; }

:first-child {
  margin-top: 0; }

a {
  background-color: transparent; }

a,
input,
button {
  -webkit-tap-highlight-color: fade(#000, 0%); }

a {
  color: #000;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #000;
    text-decoration: none; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

:not(pre) > code, :not(pre) >
kbd, :not(pre) >
samp {
  white-space: nowrap;
  padding: 0; }

em {
  font-style: italic; }

ins {
  text-decoration: none; }

q {
  font-style: italic; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

blockquote,
figure {
  margin: 0; }

p,
ul,
ol,
dl,
blockquote,
pre,
address,
fieldset,
figure {
  margin: 0; }
  * + p, * +
  ul, * +
  ol, * +
  dl, * +
  blockquote, * +
  pre, * +
  address, * +
  fieldset, * +
  figure {
    margin-top: 20px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal; }
  * + h1, * +
  h2, * +
  h3, * +
  h4, * +
  h5, * +
  h6 {
    margin-top: 20px; }

ul,
ol {
  padding-left: 30px; }
  ul > li > ul,
  ul > li > ol,
  ol > li > ul,
  ol > li > ol {
    margin: 0; }

dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

hr {
  box-sizing: content-box;
  height: 0;
  margin: 0;
  border: 0;
  border-top: 1px solid #000; }
  * + hr {
    margin-top: 20px; }

address {
  font-style: normal; }

pre {
  overflow: auto; }
  pre > code {
    margin: 0;
    padding: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block; }

progress {
  vertical-align: baseline; }

audio:not([controls]) {
  display: none; }

[hidden],
template {
  display: none; }

iframe {
  border: 0; }

.shell {
  overflow-x: hidden;
  margin: 0 auto;
  display: block;
  max-width: none;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4); }
  .shell #main-content {
    max-width: 1920px;
    margin: 0 auto; }

.conditional-message {
  background-color: #e4e0ee;
  padding: 20px; }
  .conditional-message span {
    position: absolute;
    right: 0;
    font-size: 2.5em;
    float: right;
    width: auto;
    margin: auto 15px;
    padding: 5px;
    pointer-events: none;
    display: none; }
  .conditional-message h2, .conditional-message h3 {
    font-family: "AkkuratPro-Bold";
    color: #4e2a84; }
  .conditional-message p {
    font-size: 16px;
    color: #4e2a84; }
    .conditional-message p a:hover {
      color: #4e2a84; }

body.page-coronavirus .conditional-message {
  display: none; }

.main {
  padding: 0;
  position: relative;
  z-index: 99; }

.container {
  padding-left: 20px;
  padding-right: 20px;
  width: auto; }

.wrapper {
  max-width: 1140px;
  margin: 0 auto; }

#slate {
  padding: 100px 120px;
  min-height: 600px; }

article > *:not(.select-study):not(div),
aside > *,
.studies-box,
.cta-box,
.photo-cta,
.events-slider,
.photo-content-slider,
.box-office,
.events-wrapper .event,
.reveal {
  opacity: 0;
  transform: translate3d(0, 25%, 0);
  transition: transform 0.4s cubic-bezier(0.225, 0.46, 0.32, 1), opacity 0.4s 0s, background 0.4s, color 0.4s; }
  article > *:not(.select-study):not(div).on:not(.select-study),
  aside > *.on:not(.select-study),
  .studies-box.on:not(.select-study),
  .cta-box.on:not(.select-study),
  .photo-cta.on:not(.select-study),
  .events-slider.on:not(.select-study),
  .photo-content-slider.on:not(.select-study),
  .box-office.on:not(.select-study),
  .events-wrapper .event.on:not(.select-study),
  .reveal.on:not(.select-study) {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

.select-study {
  position: relative;
  z-index: 99;
  transform: translate3d(0, 0, 1px); }

form {
  opacity: 1 !important;
  transform: translate3d(0, 0, 0) !important; }
  form li {
    opacity: 1;
    transition: transform 0.4s cubic-bezier(0.225, 0.46, 0.32, 1), opacity 0.4s 0s; }
    form li.on {
      opacity: 1; }

.wrap {
  position: relative;
  max-width: 1340px;
  margin: 0 auto; }

.row {
  margin-left: -.5rem;
  margin-right: -.5rem; }
  .row,
  .row > [class*="col-"] {
    position: relative; }
  .row > [class*="col-"] {
    padding-left: .5rem;
    padding-right: .5rem; }
  .row .box {
    padding: 20px; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.indent {
  display: inline-block;
  padding-left: 30px; }

.margin-top-lg {
  margin-top: 70px !important; }

.margin-top {
  margin-top: 40px !important; }

.margin-top-sm {
  margin-top: 20px !important; }

.margin-bottom-lg {
  margin-bottom: 70px !important; }

.margin-bottom {
  margin-bottom: 40px !important; }

.margin-bottom-sm {
  margin-bottom: 20px !important; }

.row.margin-top-lg {
  padding-top: 70px !important;
  margin-top: 0 !important; }

.row.margin-top {
  padding-top: 40px !important;
  margin-top: 0 !important; }

.row.margin-top-sm {
  padding-top: 20px !important;
  margin-top: 0 !important; }

.row.margin-bottom-lg {
  padding-bottom: 70px !important;
  margin-bottom: 0 !important; }

.row.margin-bottom {
  padding-bottom: 40px !important;
  margin-bottom: 0 !important; }

.row.margin-bottom-sm {
  padding-bottom: 20px !important;
  margin-bottom: 0 !important; }

.no-margin {
  margin: 0 !important; }

.no-padding {
  padding: 0 !important; }

.three-fourths {
  width: 75%; }

.label {
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #819eb5;
  font-family: "AkkuratPro-Regular";
  font-size: 12px;
  margin-top: 20px; }

.end-xs .label {
  float: right; }

.video-cover {
  position: relative;
  z-index: 9;
  background: #fff;
  width: 100%; }

.note {
  color: #819eb5 !important;
  font-family: "AkkuratPro-Regular" !important;
  font-size: 14px !important;
  line-height: 20px !important;
  margin-top: 20px !important; }

.fixed-width {
  position: relative;
  max-width: 1240px;
  margin: 60px auto; }
  .fixed-width.top {
    margin: 0 auto 100px auto; }
  .fixed-width.bottom {
    margin: 100px auto 0 auto; }
  .fixed-width.top.bottom {
    margin: 0 auto 0 auto; }

.fixed-width-lg {
  max-width: 1140px;
  margin: 50px auto; }

#questions-bottom.fixed-width.top {
  margin-bottom: 0 !important; }

aside .header3 {
  max-width: 265px;
  margin-bottom: 20px !important; }

.spaces .col-xs-4 {
  padding-left: calc(.5rem + 10px);
  padding-right: calc(.5rem + 10px); }

.spaces .col-xs-4:first-child {
  padding-left: .5rem;
  padding-right: calc(.5rem + 10px); }

.spaces .col-xs-4:last-child {
  padding-left: calc(.5rem + 10px);
  padding-right: .5rem; }

.spaces .thumb {
  display: block;
  width: 100%;
  height: 160px;
  background-size: cover !important;
  margin-bottom: 20px; }

hr {
  display: block;
  background: #e0e7ed;
  width: 100%;
  height: 5px;
  margin: 80px 0;
  border: none; }

.concert-photo {
  width: 100%;
  height: 290px;
  margin: 40px 0;
  background-size: cover !important; }

.regenstein-photo {
  width: 100%;
  height: 290px;
  margin: 40px 0;
  background-size: cover !important; }

.gray-bg {
  background-color: #f2f5f8; }

.gray-bar {
  display: block;
  width: 100%;
  background-color: #f2f5f8;
  padding: 10px 0; }
  .gray-bar .row {
    max-width: 1240px;
    margin: 0 auto; }
  .gray-bar #search-resources,
  .gray-bar #results-search {
    float: right; }
  .gray-bar #results-search input[type="text"] {
    background: #fff; }
  .gray-bar input[type="submit"] {
    text-indent: -9999px; }

.form-list-row {
  padding-top: 20px;
  padding-bottom: 20px; }
  .form-list-row p {
    padding-right: 20px; }

.map-cnt-full {
  display: block;
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  border: 1px solid #a18fbc; }
  .map-cnt-full a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999; }
  .map-cnt-full div {
    left: 0;
    height: calc(100% + 30px); }

.map-cnt-half {
  display: block;
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border: 1px solid #a18fbc; }
  .map-cnt-half a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999; }
  .map-cnt-half div {
    left: 0;
    height: calc(100% + 30px); }

.lightbox {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  width: 100%;
  height: auto;
  max-width: 720px;
  min-height: 480px;
  z-index: 99999999999; }
  .lightbox .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    width: 30px;
    height: 30px;
    background: url(../images/close.svg) 50% no-repeat; }
  .lightbox .cnt {
    position: absolute;
    padding: 40px;
    width: 100%;
    height: 100%; }
    .lightbox .cnt iframe {
      width: 100%;
      height: 100%; }

.lightbox-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999999999; }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .fixed-width {
    padding: 20px 60px !important; }
  .fixed-width-lg {
    padding: 0 50px !important; } }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  #application-content.col-xs-5 {
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .fixed-width {
    margin: 80px auto; }
  .fixed-width-lg {
    margin: 50px 0 0 0;
    padding: 0 20px !important; }
  .margin-top-lg {
    margin-top: 40px !important; }
  .margin-top {
    margin-top: 20px !important; }
  .margin-top-sm {
    margin-top: 10px !important; }
  .margin-bottom-lg {
    margin-bottom: 40px !important; }
  .margin-bottom {
    margin-bottom: 20px !important; }
  .margin-bottom-sm {
    margin-bottom: 10px !important; }
  .intro {
    margin: 0; }
  hr {
    margin: 50px auto; }
  aside {
    padding: 0; }
  .map-cnt-full {
    margin: 30px 0 0 0; }
  .map-cnt-half {
    margin: 30px 0 0 0;
    height: 400px; }
  .gray-bar h2 {
    text-align: center; }
  .gray-bar .btns-cnt {
    text-align: center; }
  .gray-bar #results-search,
  .gray-bar #search-resources {
    float: none;
    margin: 0 auto; }
  .row > [class*="col-"] {
    flex-basis: 100%;
    max-width: 100%; }
  .section.fixed-width > .row > .col-xs-4 {
    order: 1;
    margin-top: 2rem; }
  .section.fixed-width > .row > .col-xs-8 {
    order: 0;
    margin-top: 2rem; }
  .first-xs {
    order: 1;
    -ms-flex-order: 1; }
  .last-xs {
    order: 0;
    -ms-flex-order: 0; }
  .fixed-width {
    margin: 30px auto;
    padding: 0 20px !important; }
  aside .header3 {
    max-width: 265px;
    margin-bottom: 20px !important; }
  hr {
    margin: 50px auto; }
  .table-wrapper .table .table-body .table-gray,
  .table-wrapper .table .table-body .table-blue {
    margin: 0; }
  #questions .no-margin {
    margin-top: 30px !important; }
  #questions .phone {
    margin-top: 0 !important; }
  #library img {
    margin-top: 50px; }
  #slate {
    padding: 40px; } }

@media only screen and (max-width: 767px) and (min-width: 1px) {
  article > *:not(.select-study):not(div),
  aside > *,
  .studies-box,
  .cta-box,
  .photo-cta,
  .events-slider,
  .photo-content-slider,
  .box-office,
  .events-wrapper .event,
  .reveal,
  .paragraph--type--text-content > * {
    opacity: 1 !important;
    transform: translate3d(0, 0, 0) !important; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .fixed-width {
    padding: 20px !important; }
  .fixed-width-lg {
    margin: 40px auto !important; }
  .fixed-width.top {
    padding-top: 30px !important; }
  .map-cnt-full,
  .map-cnt-half {
    margin: 30px 0 0 0;
    height: 200px; }
  .concert-photo,
  .regenstein-photo {
    height: 200px; }
  .fixed-width.top {
    margin: 0 auto 20px auto; } }

/* Some extra stuff for sitewide alerts plugin */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px; }

.alert h4 {
  margin-top: 0;
  color: inherit; }

.alert .alert-link {
  font-weight: bold; }

.alert > p,
.alert > ul {
  margin-bottom: 0; }

.alert > p + p {
  margin-top: 5px; }

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px; }

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit; }

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d; }

.alert-success hr {
  border-top-color: #c9e2b3; }

.alert-success .alert-link {
  color: #2b542c; }

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f; }

.alert-info hr {
  border-top-color: #a6e1ec; }

.alert-info .alert-link {
  color: #245269; }

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b; }

.alert-warning hr {
  border-top-color: #f7e1b5; }

.alert-warning .alert-link {
  color: #66512c; }

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442; }

.alert-danger hr {
  border-top-color: #e4b9c0; }

.alert-danger .alert-link {
  color: #843534; }

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 24px; }

a {
  color: #4e2a84;
  transition: .6s;
  text-decoration: underline; }

.body-link {
  position: relative;
  font-family: "AkkuratPro-Bold";
  text-decoration: none; }
  .body-link span {
    width: calc(100%);
    background-image: linear-gradient(transparent calc(1% - 2px), #4e2a84 2px);
    background-repeat: no-repeat;
    background-size: 0% 3px;
    background-position: left bottom;
    transition: background-size .4s; }
  .body-link:hover span, .body-link:focus span {
    background-size: 100% 3px; }
  .body-link:hover, .body-link:focus {
    color: #4e2a84; }
  .body-link.location-link:hover, .body-link.location-link:focus {
    text-decoration: underline; }
  .body-link .ico {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url(../images/purple-arrow.svg) 50% no-repeat;
    background-size: 100% !important;
    transform: rotate(-90deg); }

.firefox .body-link:hover, .firefox .body-link:focus,
.edge .body-link:hover,
.edge .body-link:focus,
.ie .body-link:hover,
.ie .body-link:focus {
  text-decoration: underline; }

.firefox .body-link span,
.firefox .body-link:hover span,
.firefox .body-link:focus span,
.edge .body-link span,
.edge .body-link:hover span,
.edge .body-link:focus span,
.ie .body-link span,
.ie .body-link:hover span,
.ie .body-link:focus span {
  background-image: none !important;
  background-size: 0px 0px !important; }

video::-webkit-media-controls {
  display: none !important; }

h1, h2, h3, h4 {
  margin: 0;
  font-weight: normal; }

p {
  margin: 0 0 20px 0; }

:focus {
  outline: thin dotted; }

:focus:active {
  outline: hidden; }

#highlighted ul.tabs {
  padding-left: 10px;
  margin: 0; }
  #highlighted ul.tabs li {
    border-right: 1px solid gray !important;
    padding-right: 10px;
    margin-right: 10px; }
    #highlighted ul.tabs li a {
      border: none !important;
      text-decoration: none;
      border-radius: 0; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

.breadcrumbs {
  list-style: none;
  padding: 20px 0 40px 0;
  width: 100%; }
  .breadcrumbs li {
    display: inline-block;
    position: relative;
    width: auto;
    padding-right: 40px;
    margin: 0;
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top; }
    .breadcrumbs li:after {
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      top: 4px;
      right: 6px;
      opacity: .5;
      background: url(../images/purple-arrow.svg) 50% no-repeat;
      background-size: 60% !important;
      transform: rotate(-90deg); }
    .breadcrumbs li:last-child:after {
      display: none; }
    .breadcrumbs li a {
      display: inline-block;
      font-family: "AkkuratPro-Bold";
      font-size: 16px;
      line-height: 28px;
      color: #3c1971;
      max-width: 180px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-decoration: none; }

.btn {
  display: inline-block;
  position: relative;
  width: auto;
  min-width: 280px;
  height: auto;
  background-color: #f6f4f9;
  border: 1px solid #a18fbc;
  color: #4e2a84;
  font-family: "AkkuratPro-Bold";
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  padding: 15px 20px;
  margin: 10px 0;
  text-decoration: none; }
  .btn:hover, .btn:focus {
    background: #4e2a84 !important;
    color: #fff !important; }

.transparent-btn {
  display: inline-block;
  position: relative;
  width: auto;
  min-width: 280px;
  height: auto;
  background: transparent;
  border: 1px solid #a18fbc;
  color: #4e2a84;
  font-family: "AkkuratPro-Bold";
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  padding: 15px 20px;
  margin: 10px 0;
  text-decoration: none; }
  .transparent-btn:hover, .transparent-btn:focus {
    background: #4e2a84 !important;
    color: #fff !important; }

.reverse-btn {
  display: inline-block;
  position: relative;
  width: auto;
  min-width: 280px;
  height: auto;
  background-color: #4e2a84;
  border: 1px solid #a18fbc;
  color: #fff;
  font-family: "AkkuratPro-Bold";
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  padding: 15px 20px;
  margin: 10px 0;
  text-decoration: none; }
  .reverse-btn:hover, .reverse-btn:focus {
    color: #fff !important;
    background: #3f2367 !important; }

.purple-btn {
  display: inline-block;
  width: auto;
  min-width: 280px;
  height: auto;
  background-color: #4e2a84;
  border: 1px solid #4e2a84;
  color: #fff;
  font-family: "AkkuratPro-Bold";
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  padding: 15px 20px;
  margin: 10px 0;
  text-decoration: none; }

.purple-btn:hover, .purple-btn:focus {
  background: #3f2367 !important;
  color: #fff !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6); }

.purple-btn.free-disabled {
  cursor: default; }
  .purple-btn.free-disabled:hover, .purple-btn.free-disabled:focus {
    background: #4e2a84 !important;
    color: #fff !important;
    box-shadow: none !important;
    cursor: default; }

.white-btn {
  display: inline-block;
  width: auto;
  min-width: 280px;
  height: 60px;
  background-color: #fff;
  border: 1px solid #4e2a84;
  color: #4e2a84;
  font-family: "AkkuratPro-Bold";
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  padding: 15px 20px;
  margin: 10px 0;
  text-decoration: none; }
  .white-btn:hover, .white-btn:focus {
    background: #4e2a84 !important;
    color: #fff !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6); }

.tertiary-btn {
  display: inline-block;
  width: auto;
  min-width: 160px;
  height: auto;
  background-color: #e0e7ed;
  border: 1px solid #e0e7ed;
  color: #4e2a84;
  font-family: "AkkuratPro-Bold";
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 10px 20px;
  margin: 10px 0;
  text-decoration: none; }
  .tertiary-btn:hover, .tertiary-btn:focus {
    background: #4e2a84 !important;
    color: #fff !important; }

.anchor-links {
  display: block;
  max-width: 270px;
  border: 1px solid #e0e7ed;
  background-color: #f2f5f8;
  list-style: none;
  padding: 25px 30px;
  margin: 20px 0; }
  .anchor-links li {
    display: inline-block;
    width: 100%;
    margin: 5px 0; }
    .anchor-links li a {
      color: #4e2a84;
      font-family: "AkkuratPro-Bold";
      font-size: 16px;
      line-height: 24px;
      text-decoration: none; }

.toggle-cnt {
  max-width: 265px;
  width: 100%;
  margin: 0 0 10px 0; }
  .toggle-cnt .section-parent {
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 8px 0 6px 40px;
    color: #507796;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none;
    transition: .4s; }
    .toggle-cnt .section-parent .ico {
      position: absolute;
      width: 15px;
      height: 15px;
      top: 50%;
      left: 10px;
      background: url(../images/purple-arrow.svg) 50% no-repeat;
      transform: translate(0, -50%) rotate(-90deg);
      transition: .4s; }
  .toggle-cnt .toggle-btns {
    margin: 0 0 50px 0;
    display: none; }
  .toggle-cnt.open .section-parent {
    background-color: #e0e7ed; }
    .toggle-cnt.open .section-parent .ico {
      transform: translate(0, -50%) rotate(0deg); }
  .toggle-cnt.open .toggle-btns {
    display: block; }

.toggle-btns {
  list-style: none;
  padding: 0;
  margin: 10px 0 50px 0;
  max-width: 265px;
  width: 100%;
  border-bottom: 1px solid #dcd2ea; }
  .toggle-btns > li {
    display: block;
    width: 100%;
    height: auto;
    margin: 0; }
    .toggle-btns > li > a {
      display: block;
      position: relative;
      width: 100%;
      color: #4e2a84;
      font-family: "AkkuratPro-Bold";
      font-size: 16px;
      line-height: 24px;
      padding: 8px 40px 8px 15px;
      border-top: 1px solid #dcd2ea;
      border-left: 1px solid #dcd2ea;
      border-right: 1px solid #dcd2ea;
      text-decoration: none; }
      .toggle-btns > li > a:hover, .toggle-btns > li > a:focus {
        background-color: #f2f5f8;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
      .toggle-btns > li > a .ico {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 100%;
        border-left: 1px solid #dcd2ea; }
        .toggle-btns > li > a .ico:after {
          position: absolute;
          content: '';
          width: 14px;
          height: 14px;
          top: 50%;
          left: 50%;
          background: url(../images/purple-arrow.svg) 50% no-repeat;
          background-size: 100% !important;
          transform: translate(-50%, -50%);
          transition: .4s; }
      .toggle-btns > li > a.active, .toggle-btns > li > a.is-active {
        background-color: #f6f4f9; }
        .toggle-btns > li > a.active .ico:after, .toggle-btns > li > a.is-active .ico:after {
          transform: translate(-50%, -50%) rotate(180deg); }
    .toggle-btns > li.active ul, .toggle-btns > li.is-active ul,
    .toggle-btns > li.active-child ul {
      display: block; }
    .toggle-btns > li.active > a, .toggle-btns > li.is-active > a {
      background: #4e2a84;
      color: #fff; }
      .toggle-btns > li.active > a .ico:after, .toggle-btns > li.is-active > a .ico:after {
        background: url(../images/white-arrow.svg) 50% no-repeat; }
    .toggle-btns > li.active > a.active .ico:after, .toggle-btns > li.is-active > a.is-active .ico:after {
      transform: translate(-50%, -50%) rotate(180deg); }
    .toggle-btns > li > ul {
      display: none;
      list-style: none;
      padding: 25px 0;
      margin: 0;
      border-top: 1px solid #dcd2ea;
      border-left: 1px solid #dcd2ea;
      border-right: 1px solid #dcd2ea;
      background-color: #f6f4f9; }
      .toggle-btns > li > ul > li {
        display: block;
        position: relative;
        width: 100%;
        padding: 0 25px;
        margin: 10px 0; }
        .toggle-btns > li > ul > li > a {
          display: block;
          position: relative;
          width: 100%;
          color: #4e2a84;
          font-family: "AkkuratPro-Bold";
          font-size: 16px;
          line-height: 24px;
          text-decoration: none; }
          .toggle-btns > li > ul > li > a span {
            width: calc(100%);
            background-image: linear-gradient(transparent calc(100% - 2px), #4e2a84 2px);
            background-repeat: no-repeat;
            background-size: 0% 3px;
            background-position: left bottom;
            transition: background-size .4s; }
          .toggle-btns > li > ul > li > a:hover, .toggle-btns > li > ul > li > a:focus span {
            background-size: 100% 3px; }
          .toggle-btns > li > ul > li > a:hover, .toggle-btns > li > ul > li > a:focus {
            color: #4e2a84; }
        .toggle-btns > li > ul > li.active > a, .toggle-btns > li > ul > li.is-active > a {
          color: #000;
          font-family: "AkkuratPro-Regular"; }
        .toggle-btns > li > ul > li.active > a:before, .toggle-btns > li > ul > li.is-active > a:before {
          position: absolute;
          content: '';
          top: 0;
          left: -25px;
          width: 6px;
          height: 100%;
          background-color: #819eb5; }
        .toggle-btns > li > ul > li > ul {
          list-style: none; }
          .toggle-btns > li > ul > li > ul > li {
            margin: 15px 0;
            line-height: 20px; }
            .toggle-btns > li > ul > li > ul > li > a {
              position: relative;
              font-family: "AkkuratPro-Bold";
              font-size: 16px;
              line-height: 20px;
              color: #4e2a84;
              text-decoration: none; }
              .toggle-btns > li > ul > li > ul > li > a span {
                width: calc(100%);
                background-image: linear-gradient(transparent calc(100% - 2px), #4e2a84 2px);
                background-repeat: no-repeat;
                background-size: 0% 3px;
                background-position: left bottom;
                transition: background-size .4s; }
              .toggle-btns > li > ul > li > ul > li > a:hover, .toggle-btns > li > ul > li > ul > li > a:focus span {
                background-size: 100% 3px; }
              .toggle-btns > li > ul > li > ul > li > a:hover, .toggle-btns > li > ul > li > ul > li > a:focus {
                color: #4e2a84; }
            .toggle-btns > li > ul > li > ul > li.active > a, .toggle-btns > li > ul > li > ul > li.is-active > a {
              color: #000;
              font-family: "AkkuratPro-Regular"; }
            .toggle-btns > li > ul > li > ul > li.active > a:before, .toggle-btns > li > ul > li > ul > li.is-active > a:before {
              position: absolute;
              content: '';
              top: 0;
              left: -55px;
              width: 6px;
              height: 100%;
              background-color: #819eb5; }

.btn-cnt {
  position: relative;
  width: 100%; }
  .btn-cnt .btn {
    background: transparent; }
  .btn-cnt a:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 0;
    height: 1px;
    z-index: 99;
    background: #4e2a84;
    transform: translate(0, -50%);
    transition: 1.4s; }

.photo-cta .btn-cnt.on a:before {
  width: 200px;
  left: -200px; }

.right-photo-cta.on .btn-cnt a:before {
  width: 200px;
  right: -200px; }

.left-photo-cta.on .btn-cnt a:before {
  width: 200px;
  left: -200px; }

.btns-cnt {
  display: block;
  width: 100%; }

.btns-cnt-lg {
  display: block;
  width: 100%; }
  .btns-cnt-lg .btn {
    margin-right: 40px; }
  .btns-cnt-lg .btn:last-child {
    margin-right: 0; }

.tab-btn.active {
  background: #4e2a84;
  color: #fff; }

.center-btn {
  position: relative;
  margin: 0 auto;
  display: block;
  max-width: 260px; }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  .tertiary-btn {
    min-width: 100%; }
  .history-btn {
    min-width: 120px; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .toggle-btns {
    max-width: 100%; }
  .toggle-cnt {
    max-width: 100%; }
  .anchor-links {
    max-width: 100%; }
  .tertiary-btn {
    max-width: 160px;
    min-width: 160px; }
  .history-btn {
    min-width: 200px !important;
    max-width: 200px !important; }
  .breadcrumbs li {
    line-height: 28px; }
  .breadcrumbs li a {
    font-size: 14px; } }

@media only screen and (max-width: 767px) and (min-width: 1px) {
  .btn-cnt a:before {
    display: none; }
  .breadcrumbs {
    padding: 20px 0 40px 0; }
  #questions .tertiary-btn {
    max-width: 100%;
    min-width: 100%; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .btn,
  .purple-btn {
    min-width: 220px; }
  .btn-cnt a {
    min-width: 100%;
    margin-top: 0 !important; }
  article .btn {
    width: 100%; }
  .history-btn {
    position: relative !important;
    min-width: calc(100% - 40px) !important;
    max-width: calc(100% - 40px) !important;
    left: 20px;
    top: 0 !important;
    margin-bottom: 30px; }
  .breadcrumbs {
    display: none; } }

form {
  margin: 40px 0;
  position: relative;
  z-index: 9; }

fieldset {
  border: none;
  padding: 0;
  margin: 50px 0 20px 0; }
  fieldset legend {
    width: 100%;
    border-bottom: 5px solid #e0e7ed;
    padding: 0 0 10px 0;
    margin: 10px 0 0 0;
    color: #000000;
    font-family: "Poppins-Medium";
    font-size: 24px;
    line-height: 32px; }

.one-third-field {
  width: 33.3333% !important; }

.one-third-field:nth-child(2) {
  width: calc(33.3333% - 50px) !important;
  margin-right: 50px; }

.one-third-field:nth-child(3) {
  width: calc(33.3333% - 50px) !important;
  margin-right: 25px;
  margin-left: 25px; }

.one-third-field:last-child {
  width: calc(33.3333% - 50px) !important;
  margin-left: 50px; }

input[type="text"],
input[type="email"],
input[type="password"],
input[type="phone"],
input[type="tel"],
input[type="date"] {
  display: inline-block;
  width: 100%;
  background-color: rgba(153, 153, 153, 0);
  border: 1px solid #819eb5;
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 28px;
  margin: 10px 0 10px 0;
  padding: 0 22px; }

textarea {
  display: inline-block;
  width: 100%;
  height: 220px;
  background-color: rgba(153, 153, 153, 0);
  border: 1px solid #819eb5;
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 28px;
  margin: 10px 0 10px 0;
  padding: 12px 22px; }

input:focus {
  background-color: rgba(153, 153, 153, 0);
  border: 1px solid #000000;
  box-shadow: 0 0 10px rgba(78, 42, 132, 0.2); }

input:disabled {
  background-color: #ecedf1;
  border: 1px solid #b5bec5; }

input.error {
  background-color: rgba(153, 153, 153, 0);
  border: 1px solid #ed3024;
  box-shadow: 0 0 10px rgba(78, 42, 132, 0.2); }

input.success {
  background-color: rgba(153, 153, 153, 0);
  border: 1px solid #417505;
  box-shadow: 0 0 10px rgba(78, 42, 132, 0.2); }

/* default custom select styles */
div.form-select,
div.cs-select {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  z-index: 100;
  width: 100%;
  margin: 10px 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

div.form-select:focus,
div.cs-select:focus {
  outline: none;
  /* For better accessibility add a style for this in your skin */ }

div.form-select select,
.cs-select select {
  display: none; }

div.form-select span,
.cs-select span {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 5px 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* placeholder and selected option */
div.form-select > span,
.cs-select > span {
  padding-right: 3em; }

div.form-select > span::after,
div.form-select .cs-selected span::after,
.cs-select > span::after,
.cs-select .cs-selected span::after {
  speak: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

div.cs-active {
  z-index: 200; }

/* options */
div.form-select .cs-options,
.cs-select .cs-options {
  position: absolute;
  width: 100%;
  background: transparent;
  visibility: hidden;
  opacity: 0;
  transition: .4s;
  max-height: 200px;
  overflow: auto; }

div.form-select.cs-active .cs-options,
.cs-select.cs-active .cs-options {
  visibility: visible;
  opacity: 1; }

div.form-select ul,
.cs-select ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  border: 1px solid #aec1d0;
  background-color: #ffffff; }

div.form-select ul span,
.cs-select ul span {
  padding: 5px 22px; }

div.form-select ul li.cs-focus span,
.cs-select ul li.cs-focus span {
  background-color: #f2f5f8; }

.cs-skin-elastic .cs-options ul li {
  transition: .4s; }

.cs-skin-elastic .cs-options ul li:hover,
.cs-skin-elastic .cs-options ul li:focus {
  background-color: #f2f5f8; }

div.form-select li.cs-optgroup > span,
.cs-select li.cs-optgroup > span {
  cursor: default; }

.cs-skin-elastic > span::after {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.cs-skin-elastic.cs-active .cs-options {
  pointer-events: auto; }

.cs-skin-elastic .cs-options > ul::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transform: scale3d(1, 0, 1);
  transform: scale3d(1, 0, 1);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s; }

.cs-skin-elastic.cs-active .cs-options > ul::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: none;
  transition: none;
  -webkit-animation: expand 0.6s ease-out;
  animation: expand 0.6s ease-out; }

.cs-skin-elastic .cs-options ul li {
  opacity: 0;
  -webkit-transform: translate3d(0, -25px, 0);
  transform: translate3d(0, -25px, 0);
  -webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
  transition: opacity 0.15s, transform 0.15s; }

.cs-skin-elastic.cs-active .cs-options ul li {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  -webkit-transition: none;
  transition: none;
  -webkit-animation: bounce 0.6s ease-out;
  animation: bounce 0.6s ease-out; }

.cs-skin-elastic .cs-options .cs-selected span::after {
  content: ''; }

@-webkit-keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1); }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1); }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1); }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1); } }

@keyframes expand {
  0% {
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1); }
  25% {
    -webkit-transform: scale3d(1, 1.2, 1);
    transform: scale3d(1, 1.2, 1); }
  50% {
    -webkit-transform: scale3d(1, 0.85, 1);
    transform: scale3d(1, 0.85, 1); }
  75% {
    -webkit-transform: scale3d(1, 1.05, 1);
    transform: scale3d(1, 1.05, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    opacity: 0; }
  25% {
    -webkit-transform: translate3d(0, 10px, 0); }
  50% {
    -webkit-transform: translate3d(0, -6px, 0); }
  75% {
    -webkit-transform: translate3d(0, 2px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
    opacity: 0; }
  25% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  50% {
    -webkit-transform: translate3d(0, -6px, 0);
    transform: translate3d(0, -6px, 0); }
  75% {
    -webkit-transform: translate3d(0, 2px, 0);
    transform: translate3d(0, 2px, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

.js-form-item {
  position: relative; }

.checkboxes li {
  position: relative; }

input[type="checkbox"] + label {
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0px;
  padding-left: 35px;
  text-transform: none; }

input[type="checkbox"] {
  position: absolute;
  content: '';
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  top: 3px;
  left: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: #fff;
  border-radius: 0;
  background-clip: padding-box;
  border: 1px solid #819eb5;
  text-align: center; }

input[type="checkbox"]:disabled {
  border: 1px solid #aec1d0;
  background-color: #f2f5f8; }

input[type="checkbox"]:checked {
  content: '';
  background: url(../images/checkmark.svg) 50% no-repeat;
  color: #4e2a84; }

input[type="checkbox"]:disabled:checked {
  content: '';
  background: url(../images/checkmark.svg) 50% no-repeat;
  color: #aec1d0; }

.radios li {
  position: relative; }

input[type="radio"] {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0; }

input[type="radio"] + label {
  padding-left: 35px;
  line-height: 20px;
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 22px;
  padding-left: 35px;
  text-transform: none; }

input[type="radio"] + label:before {
  top: 4px;
  right: 4px;
  top: 0;
  left: 4px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  content: '';
  text-align: center;
  line-height: 20px;
  border: 1px solid #819eb5;
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

input[type="radio"]:disabled + label:before {
  border: 1px solid #aec1d0; }

input[type="radio"]:checked + label:before {
  color: #819eb5;
  content: "\2022";
  font-size: 40px; }

input[type="radio"]:disabled:checked + label:before {
  color: #aec1d0;
  opacity: .5; }

form ul {
  list-style: none;
  padding: 0;
  margin: 0; }

form.views-exposed-form li,
form.no-labels li, form .no-labels li {
  position: relative; }
  form.views-exposed-form li:hover,
  form.no-labels li:hover, form .no-labels li:hover {
    background: #e7ebee; }

form.views-exposed-form label,
form.no-labels label, form .no-labels label {
  height: 0;
  visibility: hidden;
  margin-top: 0 !important; }

form.views-exposed-form .radios-label,
form.views-exposed-form .checkboxes-label,
form.no-labels .radios-label,
form.no-labels .checkboxes-label, form .no-labels .radios-label,
form .no-labels .checkboxes-label {
  height: auto;
  visibility: visible;
  margin-top: 20px !important; }

form.views-exposed-form .radios label,
form.views-exposed-form .checkboxes label,
form.no-labels .radios label,
form.no-labels .checkboxes label, form .no-labels .radios label,
form .no-labels .checkboxes label {
  height: auto;
  visibility: visible;
  margin-top: 20px !important; }

form.views-exposed-form input[type="text"],
form.views-exposed-form input[type="email"],
form.views-exposed-form input[type="password"],
form.views-exposed-form input[type="phone"],
form.views-exposed-form input[type="tel"],
form.no-labels input[type="text"],
form.no-labels input[type="email"],
form.no-labels input[type="password"],
form.no-labels input[type="phone"],
form.no-labels input[type="tel"], form .no-labels input[type="text"],
form .no-labels input[type="email"],
form .no-labels input[type="password"],
form .no-labels input[type="phone"],
form .no-labels input[type="tel"] {
  height: 60px; }

form.views-exposed-form div.form-select,
form.views-exposed-form div.cs-select,
form.no-labels div.form-select,
form.no-labels div.cs-select, form .no-labels div.form-select,
form .no-labels div.cs-select {
  height: 60px;
  background: #4e2a84; }

form.views-exposed-form div.form-select span,
form.views-exposed-form .cs-select span,
form.no-labels div.form-select span,
form.no-labels .cs-select span, form .no-labels div.form-select span,
form .no-labels .cs-select span {
  padding: 16px 22px; }

form.views-exposed-form div.form-select > span,
form.no-labels div.form-select > span, form .no-labels div.form-select > span {
  color: #fff; }

form.views-exposed-form div.form-select > span::after,
form.views-exposed-form .cs-select > span::after,
form.no-labels div.form-select > span::after,
form.no-labels .cs-select > span::after, form .no-labels div.form-select > span::after,
form .no-labels .cs-select > span::after {
  content: '';
  width: 16px;
  height: 16px;
  background: url(../images/white-arrow.svg) 50% no-repeat;
  background-size: 100% !important;
  right: 17px;
  transform: translateY(-50%);
  transition: .4s; }

form.views-exposed-form div.form-select .cs-selected span,
form.views-exposed-form .cs-select .cs-selected span,
form.no-labels div.form-select .cs-selected span,
form.no-labels .cs-select .cs-selected span, form .no-labels div.form-select .cs-selected span,
form .no-labels .cs-select .cs-selected span {
  background-color: #f2f5f8; }

form.views-exposed-form div.cs-skin-elastic,
form.no-labels div.cs-skin-elastic, form .no-labels div.cs-skin-elastic {
  background: transparent;
  color: #fff;
  font-family: "AkkuratPro-Bold";
  font-size: 18px;
  line-height: 26px; }

form.views-exposed-form .cs-skin-elastic > span,
form.no-labels .cs-skin-elastic > span, form .no-labels .cs-skin-elastic > span {
  background: #4e2a84;
  z-index: 100; }

form.views-exposed-form div.form-select .cs-options span,
form.views-exposed-form .cs-select .cs-options span,
form.no-labels div.form-select .cs-options span,
form.no-labels .cs-select .cs-options span, form .no-labels div.form-select .cs-options span,
form .no-labels .cs-select .cs-options span {
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 28px;
  padding: 5px 22px; }

form.views-exposed-form div.form-select.cs-active > span::after,
form.views-exposed-form .cs-select.cs-active > span::after,
form.no-labels div.form-select.cs-active > span::after,
form.no-labels .cs-select.cs-active > span::after, form .no-labels div.form-select.cs-active > span::after,
form .no-labels .cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg); }

form.labels li, form .labels li {
  position: relative; }

form.labels input[type="text"],
form.labels input[type="email"],
form.labels input[type="password"],
form.labels input[type="phone"],
form.labels input[type="tel"], form .labels input[type="text"],
form .labels input[type="email"],
form .labels input[type="password"],
form .labels input[type="phone"],
form .labels input[type="tel"] {
  height: 40px; }

form.labels div.cs-select, form .labels div.cs-select {
  height: 40px;
  border: 1px solid #aec1d0;
  background-image: linear-gradient(180deg, #ffffff 0%, #e0e8ee 100%); }

form.labels .cs-select > span::after, form .labels .cs-select > span::after {
  content: '';
  width: 14px;
  height: 14px;
  background: url(../images/dropdown-arrow.svg) 50% no-repeat;
  background-size: 100% !important;
  right: 17px;
  transform: translateY(-50%);
  transition: .4s; }

form.labels .cs-select .cs-selected span, form .labels .cs-select .cs-selected span {
  background-color: #f2f5f8; }

form.labels div.cs-skin-elastic, form .labels div.cs-skin-elastic {
  background: transparent;
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 28px; }

form.labels .cs-skin-elastic > span, form .labels .cs-skin-elastic > span {
  background-image: linear-gradient(180deg, #ffffff 0%, #e0e8ee 100%);
  z-index: 100; }

form.labels .cs-select.cs-active > span::after, form .labels .cs-select.cs-active > span::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg); }

#submit-news textarea {
  height: 380px; }

.tooltip {
  position: relative;
  max-width: 220px; }

.tooltip-hover {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #4e2a84;
  border-radius: 20px;
  text-align: center;
  line-height: 23px;
  color: #fff;
  font-size: 14px;
  font-family: "AkkuratPro-Bold"; }
  .tooltip-hover:hover, .tooltip-hover:focus {
    color: #fff; }
  .tooltip-hover:hover + div, .tooltip-hover:focus + div {
    display: block; }

.tooltip-box {
  display: none;
  position: absolute;
  top: 35px;
  left: calc(50% - 100px);
  z-index: 99;
  max-width: 220px;
  width: 100%;
  padding: 22px;
  background-color: #ecedf1;
  border-radius: 3px;
  color: #819eb5;
  font-family: "AkkuratPro-Regular";
  font-size: 14px;
  line-height: 20px;
  transform: translate(-50%, 0); }
  .tooltip-box:before {
    position: absolute;
    content: '';
    top: -10px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 15px solid #ecedf1;
    transform: translate(-50%, 0); }

.file-upload {
  height: auto !important;
  visibility: visible !important;
  width: 220px;
  font-size: 16px;
  font-family: "AkkuratPro-Bold";
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 15px 30px;
  color: #4e2a84;
  cursor: pointer;
  background: transparent;
  border: 1px solid #819eb5;
  transition: .4s; }

.form-managed-file {
  position: relative; }

.inputfile, input[type="file"] {
  width: 220px;
  height: 50px;
  opacity: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  z-index: 9;
  cursor: pointer; }

input[type="file"] + .file-upload,
.inputfile:hover + .file-upload,
.inputfile:focus + .file-upload {
  background: #4e2a84;
  border: 1px solid #4e2a84;
  color: #fff; }

.js-form-type-managed-file .description {
  font-size: 12px;
  line-height: 16px;
  padding-top: 10px; }

input[type="submit"] {
  display: inline-block;
  position: relative;
  width: auto;
  min-width: 280px;
  height: auto;
  background-color: #f6f4f9;
  border: 1px solid #a18fbc;
  color: #4e2a84;
  font-family: "AkkuratPro-Bold";
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  padding: 15px 20px;
  margin: 10px 0;
  cursor: pointer;
  transition: .4s; }
  input[type="submit"]:hover, input[type="submit"]:focus {
    background: #4e2a84;
    color: #fff; }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .one-third-field,
  .one-third-field:nth-child(2),
  .one-third-field:nth-child(3),
  .one-third-field:nth-child(4) {
    width: 100% !important;
    margin: 10px 0 !important; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  input[type=submit] {
    min-width: 100%; } }

.hide-label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

#filterby {
  width: 100%;
  display: block; }
  #filterby > .col-xs-4 {
    width: 100%;
    max-width: 100%; }

#filterby label[for=edit-field-issue-publication-date-value],
#filterby label[for=edit-tid],
.field--name-field-embed-view label[for=edit-field-issue-publication-date-value],
.field--name-field-embed-view label[for=edit-tid] {
  width: calc(30% - 40px);
  margin: 10px 0;
  vertical-align: top;
  color: #000;
  font-family: AkkuratPro-Bold;
  font-size: 18px;
  line-height: 28px;
  border-radius: 2px;
  text-align: left;
  border: 1px solid #e0e7ed;
  position: relative;
  background-color: #f2f5f8;
  min-width: 280px;
  height: auto;
  padding: 15px 20px;
  visibility: visible;
  margin-top: 10px !important; }
  @media only screen and (max-width: 580px) and (min-width: 1px) {
    #filterby label[for=edit-field-issue-publication-date-value],
    #filterby label[for=edit-tid],
    .field--name-field-embed-view label[for=edit-field-issue-publication-date-value],
    .field--name-field-embed-view label[for=edit-tid] {
      width: 100%; } }

#filterby .js-form-type-select,
.field--name-field-embed-view .js-form-type-select {
  width: 100%;
  display: flex;
  float: none; }
  @media only screen and (max-width: 580px) and (min-width: 1px) {
    #filterby .js-form-type-select,
    .field--name-field-embed-view .js-form-type-select {
      display: block; } }

#filterby .cs-placeholder,
.field--name-field-embed-view .cs-placeholder {
  font-family: AkkuratPro-Bold;
  font-size: 18px;
  line-height: 26px; }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  #filterby .form-select,
  .field--name-field-embed-view .form-select {
    width: 100%; } }

.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner:before {
    transition: top 0.1s 0.14s ease, opacity 0.1s ease; }
  .hamburger--squeeze .hamburger-inner:after {
    transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner:before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease, opacity 0.1s 0.14s ease; }
  .hamburger--squeeze.is-active .hamburger-inner:after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger {
  display: none;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
    top: 4px; }
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
    .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
      width: 40px;
      height: 4px;
      background-color: #000;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease; }
    .hamburger-inner:before, .hamburger-inner:after {
      content: "";
      display: block; }
    .hamburger-inner:before {
      top: -10px; }
    .hamburger-inner:after {
      bottom: -10px; }

header {
  position: relative;
  width: 100%;
  height: 170px;
  background: #4e2a84;
  z-index: 500; }
  header.slate {
    height: 125px; }
  header .utility {
    position: relative;
    z-index: 99;
    width: 100%;
    height: auto;
    background: #3f2367;
    height: 50px;
    padding: 0; }
    header .utility p {
      display: inline-block;
      width: auto;
      margin-top: 17px;
      color: #fff;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding-left: 35px;
      vertical-align: top; }
    header .utility nav {
      display: inline-block;
      width: auto;
      vertical-align: top; }
      header .utility nav ul {
        list-style: none;
        padding: 0;
        margin: 0; }
        header .utility nav ul li {
          display: inline-block;
          width: auto;
          margin: 0 10px; }
          header .utility nav ul li a {
            color: #fff;
            font-family: "AkkuratPro-Bold";
            font-size: 14px;
            line-height: 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
            text-decoration: none; }
            header .utility nav ul li a:hover, header .utility nav ul li a:focus {
              color: #a18fbc; }
    header .utility .end-xs nav {
      float: right;
      margin-right: calc(120px + .5rem); }
    header .utility .donate {
      display: inline-block;
      position: absolute;
      right: .5rem;
      width: 120px;
      padding: 0;
      text-align: center;
      color: #fff;
      font-family: "AkkuratPro-Bold";
      font-size: 14px;
      line-height: 50px;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-decoration: none;
      background: rgba(0, 0, 0, 0.5); }
      header .utility .donate:hover, header .utility .donate:focus {
        color: #a18fbc; }
  header .brand {
    position: relative;
    height: 120px;
    z-index: 99;
    background: #4e2a84; }
    header .brand .logo {
      display: block;
      position: absolute;
      width: 200px;
      height: 55px;
      background-size: 100%;
      top: 30px;
      left: 35px;
      text-align: left;
      text-indent: -9999px;
      background: url(../images/logo.svg) 50% no-repeat;
      background-size: 100%; }
    header .brand .search-btn {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      width: 128px;
      height: 120px;
      border-left: 1px solid #3b1d68;
      margin: 0;
      background: url(../images/search-icon.svg) 50% no-repeat; }
      header .brand .search-btn:hover, header .brand .search-btn:focus, header .brand .search-btn.open {
        background: url(../images/search-icon.svg) #3f2367 50% no-repeat; }
  header form#search {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 70px;
    margin: 0;
    z-index: 6;
    border-top: 1px solid #33185b;
    background: #3f2367;
    transform: translate3d(0, 0, 0);
    transition: transform 0.4s cubic-bezier(0.225, 0.46, 0.32, 1); }
    header form#search.open {
      transform: translate3d(0, 170px, 0) !important; }
    header form#search input[type="text"] {
      display: block;
      width: 100%;
      background: transparent;
      padding: 10px 22px;
      border: none;
      color: #fff;
      font-size: 18px; }
      header form#search input[type="text"]::-webkit-input-placeholder {
        color: #fff;
        opacity: .5; }
      header form#search input[type="text"]::-moz-placeholder {
        color: #fff;
        opacity: .5; }
      header form#search input[type="text"]:-ms-input-placeholder {
        color: #fff;
        opacity: .5; }
      header form#search input[type="text"]:-moz-placeholder {
        color: #fff;
        opacity: .5; }
    header form#search input[type="submit"] {
      display: none; }

@media only screen and (max-width: 1220px) and (min-width: 1024px) {
  header .utility p {
    padding-left: 20px; }
  header .utility nav ul li {
    margin: 0 5px; }
    header .utility nav ul li a {
      font-size: 13px; } }

@media only screen and (max-width: 1080px) and (min-width: 1px) {
  header .utility .donate,
  header .utility nav ul li a,
  header .utility p {
    font-size: 12px; } }

@media only screen and (max-width: 1023px) and (min-width: 1px) {
  header {
    height: 81px; }
    header .row {
      margin: 0; }
  header .utility {
    display: none; }
  header .brand {
    height: 81px;
    border-bottom: 1px solid #28104c; }
  header .hamburger {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    border-left: 1px solid #3f2367; }
    header .hamburger:hover, header .hamburger:focus {
      opacity: 1; }
  header .brand .search-btn {
    right: 80px;
    width: 80px;
    height: 80px; }
  .hamburger-box {
    width: 34px;
    height: 28px; }
  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background: #fff;
    border-radius: 0;
    height: 3px;
    width: 34px; }
  .hamburger-inner:before {
    top: -15px; }
  .hamburger-inner:after {
    bottom: -15px; }
  header .brand .logo {
    top: 13px; }
  header form#search.open {
    transform: translate3d(0, 80px, 0) !important; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  header {
    height: 71px; }
  header .brand {
    height: 71px; }
  header .brand .search-btn {
    right: 70px;
    width: 70px;
    height: 71px; }
  header .hamburger {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;
    border-left: 1px solid #3f2367; }
    header .hamburger:hover, header .hamburger:focus {
      opacity: 1; }
  header form#search.open {
    transform: translate3d(0, 70px, 0) !important; }
  header .brand .logo {
    top: 17px;
    left: 20px;
    width: 113px;
    height: 31px; } }

.nav-overview .col-xs-4 .mainadmissionutility {
  margin-top: 36px; }

#mobile-nav .mainadmissionutility {
  top: 11px;
  position: relative; }

nav.main-nav {
  display: inline-block;
  width: auto;
  float: right;
  margin: 0 140px 0 0; }
  nav.main-nav > ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    nav.main-nav > ul > li {
      display: inline-block;
      width: auto;
      height: 120px;
      vertical-align: top;
      margin: 0; }
      nav.main-nav > ul > li > a {
        display: block;
        position: relative;
        width: 100%;
        height: 120px;
        font-family: "AkkuratPro-Bold";
        font-size: 20px;
        line-height: 120px;
        color: #fff;
        padding: 0 20px;
        text-decoration: none; }
        nav.main-nav > ul > li > a:after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 100%;
          height: 5px;
          background: #819eb5;
          opacity: 0;
          transform: translate3d(0, 5px, 0);
          transition: .4s; }
      nav.main-nav > ul > li:hover a, nav.main-nav > ul > li:focus a {
        color: #a18fbc; }
      nav.main-nav > ul > li:hover a:after, nav.main-nav > ul > li:focus a:after, nav.main-nav > ul > li a:focus {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
      nav.main-nav > ul > li.active a, nav.main-nav > ul > li:focus a {
        color: #ffffff; }
      nav.main-nav > ul > li.open nav, nav.main-nav > ul > li:focus nav {
        max-height: 999px;
        z-index: 9999;
        transition-property: max-height;
        -webkit-transition-duration: 1s;
        -moz-transition-duration: 1s;
        -o-transition-duration: 1s;
        transition-duration: 1s; }

.nav-overview {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 120px;
  left: 50%;
  background: #fff;
  width: 100%;
  max-width: 1340px;
  z-index: -1;
  margin-bottom: -20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transform: translate3d(-50%, 0, 0);
  -webkit-transition-property: max-height;
  -moz-transition-property: max-height;
  -o-transition-property: max-height;
  transition-property: max-height;
  -webkit-transition-duration: .4s;
  -moz-transition-duration: .4s;
  -o-transition-duration: .4s;
  transition-duration: .4s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  background-color: #f6f6f6;
  max-height: 0; }
  .nav-overview .overview-title {
    color: #000000;
    font-family: "Poppins-Medium";
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 42px;
    margin: 60px 0 40px 0;
    text-align: left; }
    .nav-overview .overview-title a {
      color: #000000 !important;
      font-family: "Poppins-Medium";
      font-size: 32px;
      letter-spacing: -1px;
      line-height: 42px;
      margin: 60px 0 40px 0;
      text-align: left;
      text-decoration: none; }
  .nav-overview .row {
    margin: 0; }
  .nav-overview [class*="col-"] {
    padding: 0; }
  .nav-overview .col-xs-4 ul {
    margin-bottom: 0; }
  .nav-overview.admission-overview .cnt {
    padding: 0 30px; }
  .nav-overview.academics-overview .cnt {
    padding: 0 30px 0 60px; }
  .nav-overview.faculty-overview .cnt {
    padding: 0 30px 0 60px; }
  .nav-overview.concerts-overview .cnt {
    padding: 0 30px 0 60px; }
  .nav-overview.davee-overview .cnt {
    padding: 0 30px 0 60px; }
  .nav-overview.davee-overview .col-xs-4 .cnt {
    padding: 140px 30px 0 60px; }
  .nav-overview.about-overview .cnt {
    padding: 0 30px 0 60px; }
  .nav-overview .cnt + .cnt {
    margin-top: 65px;
    padding-top: 20px;
    border-top: 1px solid #aec1d0; }
  .nav-overview ul {
    list-style: none;
    padding: 0 20px 0 0;
    margin: 0 0 20px 0; }
    .nav-overview ul li {
      display: inline-block;
      width: 100%;
      margin: 5px 0 5px 0;
      color: #819eb5;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      text-transform: uppercase;
      text-align: left; }
      .nav-overview ul li a {
        display: block;
        width: 100%;
        color: #4e2a84 !important;
        font-family: "AkkuratPro-Regular";
        font-size: 18px;
        line-height: 26px;
        text-align: left;
        text-transform: none;
        letter-spacing: 0;
        text-decoration: none; }
        .nav-overview ul li a:hover, .nav-overview ul li a:focus {
          color: #A18FBC !important; }
        .nav-overview ul li a.top-link {
          font-family: "AkkuratPro-Bold"; }
  .nav-overview .admission-overview-photo {
    height: 440px;
    background-size: cover !important; }
  .nav-overview .academics-overview-photo {
    height: 670px;
    background-size: cover !important; }
  .nav-overview .faculty-overview-photo {
    height: 500px;
    background-size: cover !important; }
  .nav-overview .recent-events {
    height: 670px;
    border-left: 1px solid #aec1d0;
    padding: 40px 80px; }
    .nav-overview .recent-events .btn {
      display: block;
      margin: 20px auto 0 auto;
      color: #4e2a84; }
      .nav-overview .recent-events .btn:hover, .nav-overview .recent-events .btn:focus {
        color: #fff; }
    .nav-overview .recent-events .event {
      display: block;
      width: 100%;
      padding: 20px 0;
      margin: 0; }
      .nav-overview .recent-events .event .thumb-wrap {
        display: block;
        width: 100%;
        max-width: 120px;
        height: 120px;
        overflow: hidden; }
      .nav-overview .recent-events .event .thumb {
        display: block;
        width: 100%;
        max-width: 120px;
        height: 120px;
        background-size: cover !important;
        transform: scale(1);
        transition: .4s; }
        .nav-overview .recent-events .event .thumb a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          text-decoration: none; }
      .nav-overview .recent-events .event .date {
        display: inline-block;
        width: 100%;
        color: #819eb5;
        font-family: "AkkuratPro-Bold";
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 16px;
        text-transform: uppercase;
        text-align: left;
        margin: 0; }
      .nav-overview .recent-events .event .event-title {
        margin: 5px 0; }
      .nav-overview .recent-events .event .event-title a {
        display: inline-block;
        width: 100%;
        color: #4e2a84;
        font-family: "AkkuratPro-Bold";
        font-size: 18px;
        line-height: 26px;
        text-align: left;
        text-decoration: none; }
      .nav-overview .recent-events .event .location {
        display: inline-block;
        width: 100%;
        color: #819eb5;
        font-family: "AkkuratPro-Regular";
        font-size: 14px;
        line-height: 20px;
        padding-left: 20px;
        background: url(../images/location-icon.svg) left center no-repeat;
        text-align: left;
        margin: 0; }
      .nav-overview .recent-events .event .row.is_hover .thumb {
        transform: scale(1.1); }
      .nav-overview .recent-events .event .row.is_hover .event-title a span {
        background-size: 100% 3px; }
  .nav-overview .video-thumb {
    display: block;
    position: relative;
    width: 100%;
    max-width: 240px;
    height: 145px;
    background-size: cover !important; }
    .nav-overview .video-thumb:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 55px;
      height: 55px;
      background: url(../images/play-btn.png) 50% no-repeat;
      transform: translate(-50%, -50%); }
  .nav-overview .video-title {
    display: block;
    width: 90%;
    color: #4e2a84 !important;
    font-family: "AkkuratPro-Bold";
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin: 10px 0 40px 0;
    text-decoration: none; }
  .nav-overview .about-overview-photo {
    height: 500px;
    background-size: cover !important; }

#mobile-nav {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 9999;
  background: #3f2367;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s cubic-bezier(0.225, 0.46, 0.32, 1); }
  #mobile-nav > ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    #mobile-nav > ul > li {
      display: block;
      position: relative;
      z-index: 999;
      width: 100%;
      border-bottom: 1px solid #28104c;
      background: #4e2a84;
      overflow: hidden;
      margin: 0; }
      #mobile-nav > ul > li > a {
        display: block;
        position: relative;
        width: 100%;
        color: #ffffff;
        font-family: "AkkuratPro-Bold";
        font-size: 16px;
        line-height: 50px;
        padding: 0 30px;
        background: #4e2a84;
        text-decoration: none; }
        #mobile-nav > ul > li > a:hover, #mobile-nav > ul > li > a:focus {
          color: #A18FBC; }
        #mobile-nav > ul > li > a .ico {
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          border-left: 1px solid #28104c; }
          #mobile-nav > ul > li > a .ico:hover, #mobile-nav > ul > li > a .ico:focus {
            background: #3f2367; }
          #mobile-nav > ul > li > a .ico.open:after {
            transform: rotate(180deg); }
          #mobile-nav > ul > li > a .ico:after {
            position: absolute;
            content: '';
            width: 14px;
            height: 14px;
            top: 18px;
            left: 18px;
            background: url(../images/white-arrow.svg) 50% no-repeat;
            background-size: 100% !important;
            transition: .4s; }
      #mobile-nav > ul > li > ul {
        display: none;
        list-style: none;
        padding: 0;
        margin: 0;
        border-top: 1px solid #28104c; }
        #mobile-nav > ul > li > ul .row {
          padding: 20px 60px 30px 60px;
          background: #3f2367; }
        #mobile-nav > ul > li > ul .row + .row {
          border-top: 1px solid #28104c;
          padding: 10px 60px; }
        #mobile-nav > ul > li > ul ul {
          list-style: none;
          padding: 0;
          margin: 0 0 30px 0; }
          #mobile-nav > ul > li > ul ul li {
            display: block;
            width: 100%;
            margin: 10px 0;
            color: #ffffff;
            font-family: "AkkuratPro-Regular";
            font-size: 12px;
            line-height: 22px;
            text-transform: uppercase;
            letter-spacing: 3px; }
            #mobile-nav > ul > li > ul ul li a {
              display: block;
              width: 100%;
              color: #ffffff;
              font-family: "AkkuratPro-Regular";
              font-size: 14px;
              line-height: 22px;
              text-transform: none;
              letter-spacing: 0;
              text-decoration: none; }
              #mobile-nav > ul > li > ul ul li a:hover, #mobile-nav > ul > li > ul ul li a:focus {
                color: #A18FBC; }
  #mobile-nav .utility {
    background: #4e2a84; }
    #mobile-nav .utility nav {
      display: inline-block;
      width: calc(100% - 125px);
      padding-left: 30px; }
      #mobile-nav .utility nav ul {
        list-style: none;
        padding: 0;
        margin: 0; }
        #mobile-nav .utility nav ul li {
          display: inline-block;
          width: auto;
          margin: 0 20px 0 0; }
          #mobile-nav .utility nav ul li a {
            display: block;
            width: 100%;
            text-align: center;
            color: #ffffff;
            font-family: "AkkuratPro-Bold";
            font-size: 14px;
            line-height: 50px;
            text-transform: uppercase;
            letter-spacing: 2px;
            text-decoration: none; }
    #mobile-nav .utility .donate {
      display: inline-block;
      width: 120px;
      padding: 0;
      text-align: center;
      color: #ffffff;
      font-family: "AkkuratPro-Bold";
      font-size: 14px;
      line-height: 50px;
      text-transform: uppercase;
      letter-spacing: 2px;
      background: rgba(0, 0, 0, 0.5); }

@media only screen and (max-width: 1380px) and (min-width: 1px) {
  nav.main-nav ul li a {
    font-size: 16px; }
  .nav-overview .recent-events {
    padding: 40px; }
  .nav-overview .video-thumb {
    width: 90%; } }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  nav.main-nav ul li a {
    font-size: 14px; }
  .recent-events .btn {
    font-size: 16px;
    line-height: 30px; } }

@media only screen and (max-width: 1180px) and (min-width: 1px) {
  nav.main-nav > ul > li > a {
    padding: 0 10px; }
  .nav-overview .recent-events {
    padding: 40px 20px; } }

@media only screen and (max-width: 1023px) and (min-width: 1px) {
  nav.main-nav {
    display: none; }
  header .hamburger,
  header .brand .search-btn {
    border-left: 1px solid #28104c; }
  #mobile-nav {
    display: block; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  #mobile-nav.open {
    transform: translate3d(0, 70px, 0); }
  #mobile-nav > ul > li > ul .col-xs-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  #mobile-nav .utility nav {
    width: 100%;
    padding-left: 0; }
  #mobile-nav .utility .donate {
    width: 100%; }
  #mobile-nav .utility nav ul li {
    width: 24%;
    margin: 0; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  #mobile-nav > ul > li > ul .row,
  #mobile-nav > ul > li > ul .row + .row {
    padding: 20px; }
  #mobile-nav .utility nav ul li {
    width: 49%; } }

.home-reveal-cols {
  display: block;
  position: relative;
  width: 100%;
  height: 500px;
  background: #000; }
  .home-reveal-cols .sec-1 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-size: cover !important;
    clip: rect(0px, 0px, 500px, 0px);
    border-right: 1px solid #dfdfdf;
    transition: transform 1s, clip .4s; }
    .home-reveal-cols .sec-1 a, .home-reveal-cols .sec-1 .col-title {
      display: none; }
  .home-reveal-cols .sec-2 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-size: cover !important;
    clip: rect(0px, 0px, 500px, 0px);
    border-right: 1px solid #dfdfdf;
    transition: transform 1s, clip .4s; }
    .home-reveal-cols .sec-2 a, .home-reveal-cols .sec-2 .col-title {
      display: none; }
  .home-reveal-cols .sec-3 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-size: cover !important;
    clip: rect(0px, 0px, 500px, 0px);
    border-right: 1px solid #dfdfdf;
    transition: transform 1s, clip .4s; }
    .home-reveal-cols .sec-3 a, .home-reveal-cols .sec-3 .col-title {
      display: none; }
  .home-reveal-cols .sec-4 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-size: cover !important;
    clip: rect(0px, 0px, 500px, 0px);
    transition: transform 1s, clip .4s; }
    .home-reveal-cols .sec-4 a, .home-reveal-cols .sec-4 .col-title {
      display: none; }
  .home-reveal-cols .trigger-reveal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: 99999; }
    .home-reveal-cols .trigger-reveal .row {
      height: 500px; }
    .home-reveal-cols .trigger-reveal .col-xs-3 {
      height: 100%;
      transition: .4s; }
    .home-reveal-cols .trigger-reveal a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999; }
    .home-reveal-cols .trigger-reveal .col-title {
      position: absolute;
      bottom: 100px;
      left: 50px;
      z-index: 5;
      color: #ffffff;
      font-family: "AkkuratPro-Bold";
      font-size: 18px;
      line-height: 26px;
      opacity: 1;
      transition: .4s; }
      .home-reveal-cols .trigger-reveal .col-title.on {
        opacity: 1; }
        .home-reveal-cols .trigger-reveal .col-title.on:after {
          width: calc(100% + 100px); }
      .home-reveal-cols .trigger-reveal .col-title.off {
        opacity: 0; }
      .home-reveal-cols .trigger-reveal .col-title:after {
        position: absolute;
        content: '';
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 3px;
        background: #fff;
        transition: .4s; }
  .home-reveal-cols .lines {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: 999; }
    .home-reveal-cols .lines .line1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background: #fff;
      opacity: .3; }
    .home-reveal-cols .lines .line2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background: #fff;
      opacity: .3; }
    .home-reveal-cols .lines .line3 {
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background: #fff;
      opacity: .3; }

footer {
  position: relative;
  width: 100%;
  background: #4e2a84;
  padding: 80px 50px 45px 50px; }
  footer .row + .row {
    padding-top: 80px; }
  footer .footer-logo {
    display: inline-block;
    width: 235px;
    height: 30px;
    text-indent: -9999px;
    background: url(../images/footer-logo.png) 50% no-repeat; }
  footer .school {
    display: inline-block;
    width: 100%;
    margin: 50px 0 20px 0;
    color: #ffffff;
    font-family: "AkkuratPro-Bold";
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 30px;
    text-transform: uppercase; }
  footer .location {
    display: inline-block;
    width: 100%;
    position: relative;
    padding-left: 25px;
    background: url(../images/footer-location.svg) left center no-repeat;
    color: #ffffff;
    font-family: "AkkuratPro-Bold";
    font-size: 16px;
    line-height: 28px;
    margin: 0; }
  footer .address {
    display: inline-block;
    width: 100%;
    color: #ffffff;
    font-family: "AkkuratPro-Regular";
    font-size: 16px;
    line-height: 28px;
    padding-left: 25px;
    margin: 0; }
  footer .phone {
    display: inline-block;
    width: 100%;
    position: relative;
    padding-left: 25px;
    background: url(../images/footer-phone.svg) left center no-repeat;
    color: #ffffff;
    font-family: "AkkuratPro-Regular";
    font-size: 16px;
    line-height: 28px;
    margin: 5px 0 0 0; }
    footer .phone a {
      color: #ffffff;
      font-family: "AkkuratPro-Regular";
      font-size: 16px;
      line-height: 28px;
      text-decoration: underline; }
      footer .phone a:hover, footer .phone a:focus {
        opacity: .5; }
  footer .email {
    display: inline-block;
    width: 100%;
    position: relative;
    padding-left: 25px;
    background: url(../images/footer-email.svg) left center no-repeat;
    color: #ffffff;
    font-family: "AkkuratPro-Regular";
    font-size: 16px;
    line-height: 28px;
    margin: 5px 0 0 0; }
    footer .email a {
      color: #ffffff;
      font-family: "AkkuratPro-Regular";
      font-size: 16px;
      line-height: 28px;
      text-decoration: underline; }
      footer .email a:hover, footer .email a:focus {
        opacity: .5; }
  footer .social {
    list-style: none;
    padding: 0;
    margin: 0; }
    footer .social li {
      display: inline-block;
      width: 25px;
      height: 25px;
      margin: 0 30px 0 0;
      opacity: .6;
      transition: .4s; }
      footer .social li:hover, footer .social li:focus {
        opacity: 1; }
      footer .social li a {
        display: inline-block;
        width: 25px;
        height: 25px;
        text-indent: -9999px; }
      footer .social li.facebook {
        background: url(../images/facebook-white.svg) 50% no-repeat; }
      footer .social li.twitter {
        background: url(../images/twitter-white.svg) 50% no-repeat; }
      footer .social li.x {
        background: url(../images/x-white.svg) 50% no-repeat; }
      footer .social li.youtube {
        background: url(../images/youtube-white.svg) 50% no-repeat; }
      footer .social li.instagram {
        background: url(../images/instagram-white.svg) 50% no-repeat; }
      footer .social li.linkedin {
        background: url(../images/icon-linkedin.svg) 50% no-repeat; }
  footer .footer-nav ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    footer .footer-nav ul li {
      display: inline-block;
      width: 100%;
      margin: 0 0 15px 0; }
      footer .footer-nav ul li a {
        display: inline-block;
        width: 100%;
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -1px;
        text-decoration: none; }
        footer .footer-nav ul li a:hover, footer .footer-nav ul li a:focus {
          color: #A18FBC; }
  footer .utility-footer ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    footer .utility-footer ul li {
      display: inline-block;
      width: auto;
      margin: 0 15px; }
      footer .utility-footer ul li a {
        display: inline-block;
        width: 100%;
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 14px;
        line-height: 20px;
        text-decoration: none; }
        footer .utility-footer ul li a:hover, footer .utility-footer ul li a:focus {
          color: #A18FBC; }

.mobile-parent {
  display: none; }

#ctas {
  position: relative;
  z-index: 99; }
  #ctas .cta-box {
    display: block;
    position: relative;
    margin: 0 auto -30px auto;
    padding: 0 0 80px 0;
    width: 100%;
    max-width: 1340px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #ffffff;
    background-image: linear-gradient(181deg, #f2f5f8 0%, #e0e7ed 100%); }
    #ctas .cta-box:after {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      background: #fff;
      transform: translate(-50%, 0); }
    #ctas .cta-box h4 {
      color: #000000;
      font-family: "Poppins-Medium";
      font-size: 32px;
      letter-spacing: -1px;
      line-height: 42px;
      margin: 80px 0 40px 0;
      padding: 0 80px; }

#testimonial {
  position: relative;
  width: 100%;
  height: 500px; }
  #testimonial .row {
    height: 500px; }
  #testimonial .quote-box {
    position: absolute;
    right: 50px;
    bottom: -40px;
    width: 650px;
    padding: 60px 50px;
    background: #fff;
    z-index: 99;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
    #testimonial .quote-box .quote {
      color: #000000;
      font-family: "PeriodicoDisplay-XtLg";
      font-size: 24px;
      line-height: 34px; }
    #testimonial .quote-box .name-wrap {
      display: inline-block;
      position: relative;
      width: 100%; }
      #testimonial .quote-box .name-wrap .avatar {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background-size: cover !important; }
      #testimonial .quote-box .name-wrap .name {
        display: inline-block;
        width: calc(100% - 70px);
        padding-left: 70px;
        color: #000000;
        font-family: "AkkuratPro-Regular";
        font-size: 16px;
        line-height: 50px; }
  #testimonial .bg-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden; }
    #testimonial .bg-wrap .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 900px;
      z-index: 1;
      background-size: cover !important;
      transform: translate3d(0, 0, 0); }

@media only screen and (max-width: 1180px) and (min-width: 1px) {
  footer .footer-nav ul li a {
    font-size: 18px;
    line-height: 26px; }
  .photo-cta .cnt {
    padding: 0 40px; }
  .home-reveal-cols .trigger-reveal .col-title.on:after {
    width: calc(100% + 50px); }
  .home-reveal-cols .trigger-reveal .col-title {
    left: 20px; } }

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  #ctas .cta-box h4 {
    font-size: 24px;
    line-height: 32px; }
  #ctas .cta-box {
    margin: 0 30px -20px 30px;
    width: calc(100% - 60px); } }

@media only screen and (max-width: 1023px) and (min-width: 1px) {
  footer > .main-footer > .col-xs-6:first-child {
    order: 1;
    flex-basis: 100%;
    max-width: 100%; }
  footer > .main-footer > .col-xs-6:last-child {
    order: 0;
    flex-basis: 100%;
    max-width: 100%; }
  footer .school {
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px; }
  footer .footer-nav {
    margin-top: 40px; }
  footer .footer-nav ul li {
    margin: 0 0 10px 0; }
  footer > .row .start-xs.col-xs-6:first-child {
    flex-basis: 100%;
    max-width: 100%; }
  footer > .row .end-xs.col-xs-6:last-child {
    flex-basis: 100%;
    max-width: 100%; }
  .utility-footer {
    text-align: left;
    margin-top: 60px; }
  footer .row + .row {
    padding-top: 30px; }
  footer .utility-footer ul li:first-child {
    margin: 0 15px 0 0; }
  .photo-cta .cnt {
    padding: 30px 20px; }
  .home-reveal-cols .trigger-reveal .col-title {
    font-size: 16px; }
  .home-reveal-cols {
    height: auto;
    position: relative; }
  .home-reveal-cols .trigger-reveal {
    display: none; }
  .home-reveal-cols .sec-1,
  .home-reveal-cols .sec-2,
  .home-reveal-cols .sec-3,
  .home-reveal-cols .sec-4 {
    position: relative;
    height: 350px;
    border-right: none; }
    .home-reveal-cols .sec-1:hover .col-title:after, .home-reveal-cols .sec-1:focus .col-title:after,
    .home-reveal-cols .sec-2:hover .col-title:after,
    .home-reveal-cols .sec-2:focus .col-title:after,
    .home-reveal-cols .sec-3:hover .col-title:after,
    .home-reveal-cols .sec-3:focus .col-title:after,
    .home-reveal-cols .sec-4:hover .col-title:after,
    .home-reveal-cols .sec-4:focus .col-title:after {
      width: calc(100% + 100px); }
  .home-reveal-cols .trigger-reveal .col-xs-3 {
    flex-basis: 100%;
    max-width: 100%; }
  .home-reveal-cols .sec-1 .col-title,
  .home-reveal-cols .sec-2 .col-title,
  .home-reveal-cols .sec-3 .col-title,
  .home-reveal-cols .sec-4 .col-title {
    display: inline-block;
    position: absolute;
    bottom: 50px;
    left: 50px;
    font-size: 32px;
    line-height: 42px;
    color: #fff;
    font-family: "AkkuratPro-Bold"; }
    .home-reveal-cols .sec-1 .col-title:after,
    .home-reveal-cols .sec-2 .col-title:after,
    .home-reveal-cols .sec-3 .col-title:after,
    .home-reveal-cols .sec-4 .col-title:after {
      position: absolute;
      content: '';
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 3px;
      background: #fff;
      transition: .4s; }
  .home-reveal-cols .sec-1 .col-title.on:after,
  .home-reveal-cols .sec-2 .col-title.on:after,
  .home-reveal-cols .sec-3 .col-title.on:after,
  .home-reveal-cols .sec-4 .col-title.on:after {
    width: calc(100% + 100px); }
  .home-reveal-cols .sec-1 a,
  .home-reveal-cols .sec-2 a,
  .home-reveal-cols .sec-3 a,
  .home-reveal-cols .sec-4 a {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999; } }

@media only screen and (max-width: 767px) and (min-width: 1px) {
  footer {
    padding: 40px 0 45px 0; }
  footer .footer-logo {
    display: block;
    margin: 0 auto 40px auto; }
  .main-footer {
    border-top: 1px solid #3f2367; }
  .mobile-parent {
    display: block;
    position: relative;
    width: 100%;
    color: #ffffff;
    font-family: "AkkuratPro-Bold";
    font-size: 16px;
    line-height: 24px;
    padding: 8px 20px;
    border-bottom: 1px solid #3f2367; }
    .mobile-parent:hover, .mobile-parent:focus {
      color: #fff; }
    .mobile-parent .ico {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-left: 1px solid #3f2367; }
      .mobile-parent .ico.open:after {
        transform: rotate(180deg); }
      .mobile-parent .ico:after {
        position: absolute;
        content: '';
        top: 12px;
        left: 12px;
        width: 14px;
        height: 14px;
        background: url(../images/white-arrow.svg) 50% no-repeat;
        background-size: 100% !important;
        transition: .4s; }
  footer .footer-nav {
    margin-top: 0; }
  footer .footer-nav ul,
  footer .mobile-wrapper {
    padding: 20px;
    display: none;
    border-bottom: 1px solid #3f2367; }
  footer .col-xs-6 {
    margin: 0 !important; }
  footer .school {
    margin: 0; }
  footer .social {
    text-align: center; }
  footer .social li {
    margin: 0 15px; }
  footer .utility-footer {
    margin-top: 20px; }
  footer .utility-footer ul li {
    width: 100%;
    text-align: center;
    margin: 10px 0 !important; }
  .photo-cta .cnt {
    padding: 30px 40px; }
  #testimonial .quote-box {
    bottom: -20px; }
  #ctas .cta-box:after {
    display: none; }
  #ctas .cta-box h4 {
    margin: 40px 0; }
  #ctas .col-xs-6:first-child {
    padding-bottom: 40px;
    border-bottom: 1px solid #ddd; }
  #testimonial .quote-box {
    width: auto;
    left: 50px; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  #testimonial .quote-box {
    padding: 20px; }
  #testimonial .quote-box .quote {
    font-size: 18px;
    line-height: 24px; }
  #testimonial .quote-box .name-wrap .avatar {
    display: none; }
  #testimonial .quote-box .name-wrap .name {
    width: 100%;
    padding-left: 0;
    line-height: 24px; }
  #ctas .cta-box h4 {
    padding: 0 20px;
    font-size: 18px;
    line-height: 26px; }
  .home-reveal-cols .sec-1 .col-title,
  .home-reveal-cols .sec-2 .col-title,
  .home-reveal-cols .sec-3 .col-title,
  .home-reveal-cols .sec-4 .col-title {
    font-size: 24px;
    line-height: 32px;
    left: 20px; }
  #ctas .cta-box {
    margin: 0 auto; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  .home-reveal-cols .sec-1 .col-title,
  .home-reveal-cols .sec-2 .col-title,
  .home-reveal-cols .sec-3 .col-title,
  .home-reveal-cols .sec-4 .col-title {
    font-size: 18px;
    line-height: 22px;
    left: 20px; }
  .home-reveal-cols .sec-1:hover .col-title:after,
  .home-reveal-cols .sec-2:hover .col-title:after,
  .home-reveal-cols .sec-3:hover .col-title:after,
  .home-reveal-cols .sec-4:hover .col-title:after {
    width: calc(100% + 20px); }
  .home-reveal-cols .sec-1:focus .col-title:after,
  .home-reveal-cols .sec-2:focus .col-title:after,
  .home-reveal-cols .sec-3:focus .col-title:after,
  .home-reveal-cols .sec-4:focus .col-title:after {
    width: calc(100% + 20px); } }

.home-banner {
  font-size: 0;
  position: relative; }
  .home-banner #slider {
    display: inline-block;
    position: relative;
    width: 75%;
    height: 650px;
    vertical-align: top;
    animation-play-state: running; }
    .home-banner #slider #bgPhoto {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 650px;
      z-index: 1;
      transform: scale(1.1);
      background-size: cover !important; }
    .home-banner #slider .swiper-container {
      height: 100%;
      width: 100%; }
    .home-banner #slider .swiper-wrapper {
      z-index: 9; }
    .home-banner #slider .swiper-slide {
      transform-style: preserve-3d; }

@keyframes scale_animation {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.1); } }

@-webkit-keyframes scale_animation {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.1); } }
      .home-banner #slider .swiper-slide .cnt {
        height: 100%; }
      .home-banner #slider .swiper-slide .sec-1 {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip: rect(0px, 0, 650px, 0px);
        transform: perspective(0);
        transition: transform 1s 100ms;
        transform: translate3d(0, -100%, 0); }
        .home-banner #slider .swiper-slide .sec-1 .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover !important; }
      .home-banner #slider .swiper-slide .sec-2 {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip: rect(0px, 0px, 650px, 0px);
        transform: perspective(0);
        transition: transform 1s 200ms;
        transform: translate3d(0, 100%, 0); }
        .home-banner #slider .swiper-slide .sec-2 .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover !important; }
      .home-banner #slider .swiper-slide .sec-3 {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip: rect(0px, 0px, 650px, 0px);
        transform: perspective(0);
        transition: transform 1s 300ms;
        transform: translate3d(0, -100%, 0); }
        .home-banner #slider .swiper-slide .sec-3 .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover !important; }
      .home-banner #slider .swiper-slide .sec-4 {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip: rect(0px, 0px, 650px, 0px);
        transform: perspective(0);
        transition: transform 1s 400ms;
        transform: translate3d(0, 100%, 0); }
        .home-banner #slider .swiper-slide .sec-4 .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover !important; }
      .home-banner #slider .swiper-slide-active .sec-1,
      .home-banner #slider .swiper-slide-active .sec-2,
      .home-banner #slider .swiper-slide-active .sec-3,
      .home-banner #slider .swiper-slide-active .sec-4 {
        transform: translate3d(0, 0, 0); }
      .home-banner #slider .swiper-slide .cnt.active .bg {
        animation-name: scale_animation;
        animation-duration: 8000ms;
        transform-origin: 50% 50%;
        animation-timing-function: linear; }
    .home-banner #slider.paused * {
      animation-play-state: paused; }
  .home-banner #slider-content {
    display: inline-block;
    position: relative;
    width: 25%;
    height: 650px;
    background-image: linear-gradient(145deg, rgba(224, 232, 238, 0.25), #e0e8ee);
    vertical-align: top; }
    .home-banner #slider-content .content-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 100px 50px;
      transform: translate3d(0, -80px, 0);
      opacity: 0;
      z-index: 1;
      transition: .4s; }
      .home-banner #slider-content .content-box.on {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        z-index: 9; }
      .home-banner #slider-content .content-box .secondary {
        letter-spacing: 3px;
        margin-bottom: 30px; }
      .home-banner #slider-content .content-box .intro {
        color: #4e2a84;
        font-family: "AkkuratPro-Regular";
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 30px; }
      .home-banner #slider-content .content-box .body-link {
        font-size: 16px;
        text-decoration: none; }
    .home-banner #slider-content #content-1 {
      display: block; }
    .home-banner #slider-content .next {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60px;
      height: 60px;
      z-index: 99;
      border: 1px solid #4e2a84; }
      .home-banner #slider-content .next:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(-90deg); }
      .home-banner #slider-content .next:hover {
        background: #3f2367; }
        .home-banner #slider-content .next:hover:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
    .home-banner #slider-content .pause {
      border-radius: 50%;
      position: absolute;
      bottom: 15px;
      height: 50px;
      width: 50px;
      left: -65px;
      position: a;
      z-index: 100;
      background: url(../images/pause.svg) no-repeat center;
      background-size: contain;
      opacity: 0.8; }
      .home-banner #slider-content .pause:focus {
        color: #fff; }
      .home-banner #slider-content .pause.play {
        background: url(../images/play.svg) no-repeat center;
        background-size: contain; }
    .home-banner #slider-content .prev {
      position: absolute;
      bottom: 0;
      right: 59px;
      width: 60px;
      height: 60px;
      z-index: 99;
      border: 1px solid #4e2a84; }
      .home-banner #slider-content .prev:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(90deg); }
      .home-banner #slider-content .prev:hover {
        background: #3f2367; }
        .home-banner #slider-content .prev:hover:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
  .home-banner .pagination {
    display: inline-block;
    position: absolute;
    z-index: 99;
    bottom: 20px;
    left: 50px;
    width: auto;
    height: 30px; }
    .home-banner .pagination span {
      display: inline-block;
      width: 10px;
      height: 10px;
      cursor: pointer;
      margin-right: 20px;
      border-radius: 10px;
      background-color: rgba(78, 42, 132, 0.75); }
      .home-banner .pagination span.swiper-pagination-bullet-active {
        background-color: #4e2a84; }
  .home-banner .slider-lines {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 650px;
    z-index: 9999; }
    .home-banner .slider-lines .line1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background: #fff;
      opacity: .3; }
    .home-banner .slider-lines .line2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background: #fff;
      opacity: .3; }
    .home-banner .slider-lines .line3 {
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background: #fff;
      opacity: .3; }

.banner {
  display: block;
  position: relative;
  width: 100%;
  height: 400px; }
  .banner .banner-cnt {
    display: block;
    position: absolute;
    z-index: 9;
    bottom: 35px;
    left: 50px; }
    .banner .banner-cnt .page-title {
      position: relative;
      bottom: 0;
      left: 0;
      transform: translate(0, 0); }
    .banner .banner-cnt .section-title {
      display: inline-block;
      color: #ffffff;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      text-transform: uppercase;
      padding: 8px 20px 6px 20px;
      margin: 0 0 15px 0; }
      .banner .banner-cnt .section-title.violet, .banner .banner-cnt .section-title.bienen-ensembles {
        background-color: #bd10e0; }
      .banner .banner-cnt .section-title.blue, .banner .banner-cnt .section-title.recitals-visiting-artists, .banner .banner-cnt .section-title.recitals-lectures {
        background-color: #4e93ce; }
      .banner .banner-cnt .section-title.yellow, .banner .banner-cnt .section-title.festivals-series {
        background-color: #f1951c; }
      .banner .banner-cnt .section-title.green, .banner .banner-cnt .section-title.student-recitals {
        background-color: #79cc19; }
  .banner .page-title {
    display: block;
    position: absolute;
    z-index: 9;
    bottom: 35px;
    left: 50px;
    color: #ffffff;
    font-family: "PeriodicoDisplay-XtLg";
    font-size: 52px;
    letter-spacing: -1px;
    line-height: 62px; }
  .banner .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 2;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); }
  .banner .photo-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden; }
    .banner .photo-wrap .photo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 600px;
      z-index: 1;
      background-size: cover !important;
      transform: translate3d(0, 0, 0); }
  .banner .purple-box-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 460px;
    height: 100%;
    z-index: 99;
    background: #3f2367; }
    .banner .purple-box-right .cnt {
      position: relative;
      top: 50%;
      padding: 0 60px;
      transform: translate(0, -50%); }
      .banner .purple-box-right .cnt p {
        font-size: 16px;
        color: #fff;
        font-family: "AkkuratPro-Regular"; }
      .banner .purple-box-right .cnt a {
        font-size: 16px;
        color: #fff;
        font-family: "AkkuratPro-Bold"; }
        .banner .purple-box-right .cnt a span {
          width: calc(100%);
          background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);
          background-repeat: no-repeat;
          background-size: 0% 3px;
          background-position: left bottom;
          transition: background-size .4s; }
        .banner .purple-box-right .cnt a span {
          background-size: 100% 3px; }
        .banner .purple-box-right .cnt a:hover {
          color: #fff; }

.event-banner {
  display: block;
  position: relative;
  width: 100%;
  height: 400px; }
  .event-banner .page-title {
    display: block;
    position: absolute;
    z-index: 9;
    bottom: 35px;
    left: 50px;
    color: #ffffff;
    font-family: "PeriodicoDisplay-XtLg";
    font-size: 52px;
    letter-spacing: -1px;
    line-height: 62px; }
  .event-banner .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 2;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); }
  .event-banner .photo-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden; }
    .event-banner .photo-wrap .photo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 600px;
      z-index: 1;
      background-size: cover !important;
      transform: translate3d(0, 0, 0); }
  .event-banner .event-box {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 460px;
    height: 100%;
    z-index: 99;
    padding: 30px 40px;
    background-color: #3f2367; }
    .event-banner .event-box .row {
      margin-bottom: 20px; }
    .event-banner .event-box .thumb-wrap {
      width: 120px;
      height: 120px;
      overflow: hidden; }
      .event-banner .event-box .thumb-wrap:hover .thumb {
        transform: scale(1.1); }
    .event-banner .event-box .thumb {
      width: 120px;
      height: 120px;
      transform: scale(1);
      background-size: cover !important;
      transition: .4s; }
      .event-banner .event-box .thumb a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    .event-banner .event-box .cnt {
      padding-left: 20px;
      text-align: left; }
      .event-banner .event-box .cnt .title {
        color: #ffffff;
        font-family: "AkkuratPro-Bold";
        font-size: 18px;
        line-height: 26px; }
        .event-banner .event-box .cnt .title span {
          width: calc(100%);
          background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);
          background-repeat: no-repeat;
          background-size: 0% 340px;
          background-position: left bottom;
          transition: background-size .4s; }
        .event-banner .event-box .cnt .title:hover span {
          background-size: 100% 3px; }
      .event-banner .event-box .cnt .date {
        color: #ffffff;
        font-family: "AkkuratPro-Bold";
        font-size: 12px;
        letter-spacing: 3px;
        line-height: 16px;
        text-transform: uppercase;
        margin: 5px 0 15px 0; }
      .event-banner .event-box .cnt .location {
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 14px;
        line-height: 20px;
        padding-left: 20px;
        background: url(../images/location-white.svg) left 3px no-repeat; }
    .event-banner .event-box .row.is_hover .thumb {
      transform: scale(1.1); }
    .event-banner .event-box .row.is_hover .title span {
      background-size: 100% 100%; }
    .event-banner .event-box .all {
      display: inline-block;
      position: relative;
      color: #ffffff;
      font-family: "AkkuratPro-Bold";
      font-size: 18px;
      line-height: 26px;
      padding-right: 20px;
      margin-top: 40px;
      text-decoration: none; }
      .event-banner .event-box .all:hover {
        opacity: .5; }
      .event-banner .event-box .all .ico {
        position: absolute;
        top: 4px;
        right: 0;
        width: 15px;
        height: 15px;
        background: url(../images/Up_Arrow1.svg) 50% no-repeat;
        transform: rotate(90deg); }

.split-banner {
  height: 500px;
  /* Fixing issue with image size being too large */ }
  .split-banner .row {
    margin: 0; }
  .split-banner .col-xs-6 {
    padding: 0; }
  .split-banner .breadcrumbs {
    list-style: none;
    padding: 30px 0 30px 50px;
    width: 50%; }
    .split-banner .breadcrumbs li {
      display: inline-block;
      position: relative;
      width: auto;
      padding-right: 40px;
      margin: 0; }
      .split-banner .breadcrumbs li:after {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        top: 4px;
        right: 6px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        transform: rotate(-90deg); }
      .split-banner .breadcrumbs li:last-child {
        padding-right: 0; }
      .split-banner .breadcrumbs li:last-child:after {
        display: none; }
      .split-banner .breadcrumbs li a {
        display: inline-block;
        font-family: "AkkuratPro-Bold";
        font-size: 16px;
        line-height: 28px;
        color: #3c1971;
        text-decoration: none; }
  .split-banner .purple-box {
    display: block;
    position: relative;
    margin: 0 0 0 50px;
    background: #4e2a84;
    width: 100%;
    height: 340px;
    transform: scale(0.9);
    transition: .4s; }
    .split-banner .purple-box.on {
      transform: scale(1); }
      .split-banner .purple-box.on .page-parent,
      .split-banner .purple-box.on .page-title,
      .split-banner .purple-box.on .faculty-name,
      .split-banner .purple-box.on .faculty-area,
      .split-banner .purple-box.on .faculty-title,
      .split-banner .purple-box.on .faculty-email,
      .split-banner .purple-box.on .faculty-phone {
        opacity: 1; }
    .split-banner .purple-box .page-parent {
      padding: 30px 0 0 30px;
      color: #ffffff;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      text-transform: uppercase;
      opacity: 0;
      transition: .4s;
      transition-delay: .4s; }
    .split-banner .purple-box .page-title {
      color: #ffffff;
      font-family: "PeriodicoDisplay-XtLg";
      font-size: 52px;
      letter-spacing: -1px;
      line-height: 62px;
      bottom: inherit;
      top: 50%;
      left: 60px;
      margin: 0;
      max-width: 550px;
      transform: translate(0, -50%);
      opacity: 0;
      transition: .4s;
      transition-delay: .4s; }
    .split-banner .purple-box .faculty-name {
      position: relative;
      color: #ffffff;
      font-family: "PeriodicoDisplay-XtLg";
      font-size: 52px;
      letter-spacing: -1px;
      line-height: 62px;
      left: 60px;
      margin: 0;
      max-width: 550px;
      opacity: 0;
      transition: .4s;
      transition-delay: .4s; }
    .split-banner .purple-box .faculty-area {
      position: relative;
      color: #ffffff;
      font-family: "Poppins-Medium";
      font-size: 32px;
      font-weight: 500;
      line-height: 42px;
      letter-spacing: -1px;
      left: 60px;
      margin: 0 0 10px 0;
      opacity: 0;
      transition: .4s;
      transition-delay: .4s; }
    .split-banner .purple-box .faculty-title {
      position: relative;
      color: #ffffff;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 3px;
      left: 60px;
      opacity: 0;
      transition: .4s;
      transition-delay: .4s; }
    .split-banner .purple-box .faculty-link-wrap {
      display: inline-block;
      width: 100%;
      margin: 0; }
    .split-banner .purple-box .faculty-email {
      display: inline-block;
      width: auto;
      position: relative;
      color: #ffffff;
      font-family: "AkkuratPro-Bold";
      font-size: 16px;
      line-height: 24px;
      left: 60px;
      opacity: 0;
      text-decoration: none;
      transition: .4s;
      transition-delay: .4s; }
      .split-banner .purple-box .faculty-email:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: #fff;
        transition: .4s; }
      .split-banner .purple-box .faculty-email:hover:after {
        width: 100%; }
    .split-banner .purple-box .faculty-phone {
      clear: both;
      display: inline-block;
      width: auto;
      position: relative;
      color: #ffffff;
      font-family: "AkkuratPro-Bold";
      font-size: 16px;
      line-height: 24px;
      left: 60px;
      opacity: 0;
      text-decoration: none;
      transition: .4s;
      transition-delay: .4s; }
      .split-banner .purple-box .faculty-phone:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: #fff;
        transition: .4s; }
      .split-banner .purple-box .faculty-phone:hover:after {
        width: 100%; }
  .split-banner .gray-box {
    display: block;
    position: relative;
    margin: 0 0 0 25px;
    padding: 0;
    border: none;
    background-color: #f2f5f8;
    width: 100%;
    height: 340px;
    transform: scale(0.9);
    transition: .4s; }
    .split-banner .gray-box.on {
      transform: scale(1); }
      .split-banner .gray-box.on .cnt .page-title,
      .split-banner .gray-box.on .cnt .date,
      .split-banner .gray-box.on .page-parent,
      .split-banner .gray-box.on .cnt p,
      .split-banner .gray-box.on .cnt .purple-btn {
        opacity: 1; }
    .split-banner .gray-box .page-parent {
      position: absolute;
      padding: 30px 0 0 30px;
      color: #000;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      text-transform: uppercase;
      opacity: 0;
      transition: .4s;
      transition-delay: .4s; }
    .split-banner .gray-box .cnt {
      position: relative;
      top: 50%;
      left: 25px;
      transform: translate(0, -50%); }
      .split-banner .gray-box .cnt .page-title {
        position: relative;
        color: #000;
        font-family: "PeriodicoDisplay-XtLg";
        font-size: 48px;
        letter-spacing: -1px;
        line-height: 62px;
        bottom: inherit;
        top: inherit;
        left: 0;
        margin: 0 0 20px 0;
        max-width: 40%;
        transform: translate(0, 0);
        opacity: 0;
        transition: .4s;
        transition-delay: .4s;
        word-wrap: break-word; }
      .split-banner .gray-box .cnt p {
        color: #000;
        font-family: "AkkuratPro-Regular";
        font-size: 16px;
        line-height: 28px;
        opacity: 0;
        width: 45%;
        transition: .4s;
        transition-delay: .4s; }
      .split-banner .gray-box .cnt .date {
        color: #000;
        font-family: "AkkuratPro-Bold";
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 3px; }
      .split-banner .gray-box .cnt .purple-btn {
        min-width: 190px;
        margin-right: 50px;
        height: auto;
        padding: 15px 20px;
        opacity: 0;
        transition: .4s;
        transition-delay: opacity .4s; }
  .split-banner .photo {
    position: absolute;
    max-width: 50%;
    max-height: 80%;
    left: inherit;
    right: 0;
    top: -1px;
    opacity: 0;
    transform: scale(0.8);
    transition: .4s; }
    .split-banner .photo img.fb-offscreen {
      position: relative; }
    .split-banner .photo.on {
      opacity: 1;
      transform: scale(1); }
    .split-banner .photo .caption {
      position: absolute;
      top: 520px;
      left: 0;
      color: #4f7796;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 20px;
      padding: 0 80px; }
  .split-banner.pdf-banner .photo {
    width: 360px; }
  .split-banner .faculty-photo.photo {
    max-height: 560px; }
    .split-banner .faculty-photo.photo img {
      max-height: inherit; }
  .split-banner .language {
    padding: 30px 0 0 50px; }
  .split-banner .news-share {
    position: relative;
    padding: 20px 0 0 80px; }
    .split-banner .news-share ul {
      list-style: none;
      padding: 0; }
      .split-banner .news-share ul li {
        display: inline-block;
        width: auto;
        margin: 0 5px;
        vertical-align: top; }
        .split-banner .news-share ul li a {
          display: inline-block;
          width: 40px;
          height: 30px;
          text-indent: -9999px; }
        .split-banner .news-share ul li.facebook {
          background: url(../images/facebook-single.svg) 50% no-repeat;
          background-size: auto; }
        .split-banner .news-share ul li.twitter {
          background: url(../images/twitter-single.svg) 50% no-repeat;
          background-size: auto; }
        .split-banner .news-share ul li.email {
          background: url(../images/email-single.svg) 50% no-repeat;
          background-size: auto; }
      .split-banner .news-share ul .title {
        display: inline-block;
        color: #507796;
        font-family: "AkkuratPro-Bold";
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 3px;
        vertical-align: top; }

.firefox .split-banner .news-share ul li.facebook,
.firefox .split-banner .news-share ul li.twitter,
.firefox .split-banner .news-share ul li.email {
  background-size: auto !important; }

.drop-banner {
  position: relative;
  width: 100%;
  z-index: 9;
  height: 580px; }
  .drop-banner .photo-wrap {
    display: block;
    position: absolute;
    width: 100%;
    height: 400px;
    overflow: hidden; }
    .drop-banner .photo-wrap .photo {
      display: block;
      position: absolute;
      width: 100%;
      height: 600px;
      background-size: cover !important;
      transform: translate3d(0, 0, 0); }
  .drop-banner .overlay {
    position: absolute;
    bottom: 180px;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 2;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%); }

.drop-box {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 1240px;
  padding: 30px;
  z-index: 99;
  transform: translate(-50%, 0) scale(0.9);
  transition: .4s; }
  .drop-box.on {
    transform: translate(-50%, 0) scale(1); }
    .drop-box.on .page,
    .drop-box.on .title,
    .drop-box.on .subtitle {
      opacity: 1; }
  .drop-box .page {
    color: #ffffff;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase;
    transition: .4s;
    opacity: 0;
    transition-delay: .4s; }
  .drop-box .title {
    color: #ffffff;
    font-family: "PeriodicoDisplay-XtLg";
    font-size: 52px;
    letter-spacing: -1px;
    line-height: 62px;
    margin: 40px 0 40px 0;
    padding: 0 30px;
    transition: .4s;
    opacity: 0;
    transition-delay: .4s; }
  .drop-box .subtitle {
    color: #ffffff;
    font-family: "AkkuratPro-Regular";
    font-size: 24px;
    line-height: 36px;
    padding: 0 30px 30px 30px;
    transition: .4s;
    opacity: 0;
    transition-delay: .4s; }

.purple-90 {
  background: rgba(63, 35, 103, 0.9); }

.slider-banner {
  position: relative;
  height: 600px; }
  .slider-banner .next {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    z-index: 999;
    border: 1px solid #4e2a84; }
    .slider-banner .next:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 25px;
      height: 25px;
      background: url(../images/purple-arrow.svg) 50% no-repeat;
      background-size: 100%;
      transform: translate(-50%, -50%) rotate(-90deg); }
    .slider-banner .next:hover {
      background: #3f2367; }
      .slider-banner .next:hover:after {
        background: url(../images/white-arrow.svg) 50% no-repeat; }
  .slider-banner .prev {
    position: absolute;
    bottom: 0;
    right: 59px;
    width: 60px;
    height: 60px;
    z-index: 999;
    border: 1px solid #4e2a84; }
    .slider-banner .prev:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      width: 25px;
      height: 25px;
      background: url(../images/purple-arrow.svg) 50% no-repeat;
      background-size: 100%;
      transform: translate(-50%, -50%) rotate(90deg); }
    .slider-banner .prev:hover {
      background: #3f2367; }
      .slider-banner .prev:hover:after {
        background: url(../images/white-arrow.svg) 50% no-repeat; }
  .slider-banner .progress-bar {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 12px;
    background: #fff;
    z-index: 9999; }
    .slider-banner .progress-bar .swiper-pagination-progressbar {
      background: #819eb5; }
  .slider-banner .photo-wrap {
    display: block;
    position: relative;
    width: 50%;
    height: 600px;
    overflow: hidden; }
    .slider-banner .photo-wrap .photo {
      display: block;
      position: absolute;
      width: 100%;
      height: 600px;
      background-size: cover !important;
      transform: translate3d(0, 0, 0); }
  .slider-banner .news-box {
    background-color: #f2f5f8;
    padding: 0 120px;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 50%;
    height: 100%;
    z-index: 999; }
    .slider-banner .news-box .cnt {
      position: relative;
      top: 50%;
      transform: translate(0, -50%); }
      .slider-banner .news-box .cnt h1 {
        color: #000; }
      .slider-banner .news-box .cnt p {
        color: #819eb5; }
      .slider-banner .news-box .cnt .secondary {
        letter-spacing: 3px; }
      .slider-banner .news-box .cnt .purple-btn {
        margin-top: 50px !important;
        min-width: 220px;
        max-width: 220px; }

.video-banner {
  display: block;
  position: relative;
  width: 100%;
  height: 580px;
  overflow: hidden; }
  .video-banner .cnt {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    z-index: 9;
    text-align: center;
    transform: translate(-50%, -50%); }
    .video-banner .cnt h1 {
      color: #fff;
      font-size: 72px;
      line-height: 82px;
      margin: 0 0 60px 0; }
  .video-banner .overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: #000;
    opacity: .1; }
  .video-banner .photo-wrap {
    display: block;
    position: absolute;
    width: 100%;
    height: 600px;
    overflow: hidden; }
    .video-banner .photo-wrap .photo {
      display: block;
      position: absolute;
      width: 100%;
      height: 900px;
      background-size: cover !important;
      transform: translate3d(0, 0, 0); }
  .video-banner .video-wrapper {
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    height: auto;
    z-index: 0;
    object-fit: cover !important;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .video-banner .video-wrapper #banner-video {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      background-color: black;
      background-position: center center;
      background-size: contain;
      background-attachment: fixed;
      object-fit: cover;
      z-index: 3;
      overflow: hidden; }
  .video-banner .sound-btn {
    display: inline-block;
    position: absolute;
    bottom: 40px;
    left: 50%;
    z-index: 9;
    transform: translate(-50%, 0);
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: "Poppins-Medium";
    letter-spacing: 2px;
    text-decoration: none; }
    .video-banner .sound-btn:hover {
      opacity: .5; }
    .video-banner .sound-btn .song-title {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      padding-left: 20px;
      text-align: center;
      width: calc(100% - 60px); }
    .video-banner .sound-btn .ico {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      width: 40px;
      height: 40px;
      background: url(../images/volume.svg) 50% no-repeat;
      background-size: 100% !important; }
    .video-banner .sound-btn.off .ico:before {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      background: url(../images/volume_line.svg) 50% no-repeat;
      background-size: 100% !important; }
  .video-banner .video-btn {
    position: relative;
    display: block;
    left: 50%;
    top: -30px;
    width: 100px;
    height: 65px;
    color: #ffffff;
    font-family: "AkkuratPro-Bold";
    font-size: 12px;
    line-height: 125px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transform: translate(-50%, 0);
    text-decoration: none; }
    .video-banner .video-btn:before {
      position: absolute;
      top: 0;
      left: 50%;
      content: '';
      width: 43px;
      height: 43px;
      border-radius: 43px;
      background: url(../images/large-play.png) 50% no-repeat;
      transform: translate(-50%, 0);
      background-size: 100% !important; }

.banner-half-photo-tall {
  width: 100%;
  max-width: 510px;
  height: 500px;
  background-size: cover !important; }

.banner-half-photo-short {
  width: 100%;
  max-width: 510px;
  height: 350px;
  background-size: cover !important; }

@media only screen and (max-width: 1340px) and (min-width: 1px) {
  .split-banner .purple-box .page-title {
    max-width: 450px; } }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .drop-box {
    width: calc(100% - 120px); }
  .split-banner .gray-box .cnt .page-title {
    max-width: 45%; }
  .split-banner .photo {
    max-width: 50%; } }

@media only screen and (max-width: 1180px) and (min-width: 1px) {
  .banner-half-photo {
    width: 33%; }
  .slider-banner .news-box {
    padding: 0 50px; }
  .home-banner #slider {
    width: 66.666666%; }
  .home-banner #slider-content {
    width: 33.333333%; } }

@media only screen and (max-width: 1080px) and (min-width: 1px) {
  .event-banner .event-box {
    position: relative;
    max-width: 100%; }
  .event-banner {
    height: auto; }
  .event-banner .photo-wrap {
    height: 400px;
    position: relative; }
  .event-box .col-xs-4 {
    flex-basis: 20%;
    max-width: 20%; }
  .event-box .col-xs-8 {
    flex-basis: 80%;
    max-width: 80%; } }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  .drop-box .title {
    font-size: 32px;
    line-height: 40px; }
  .drop-box .subtitle {
    font-size: 18px;
    line-height: 26px; }
  #page-banner .col-xs-6 {
    flex-basis: 100%;
    max-width: 100%; }
  .banner-half-photo-tall {
    width: 100%;
    max-width: 100%;
    margin: 30px 0; }
  /* News page */
  .split-banner {
    height: inherit; }
    .split-banner .gray-box {
      margin: 0 0 0 5px; }
      .split-banner .gray-box .cnt .page-title {
        font-size: 32px;
        max-width: 45%; }
    .split-banner .photo {
      max-width: 50%;
      height: 80%;
      top: inherit;
      bottom: 0;
      background-size: cover !important; }
  /*.split-banner .gray-box {
    height: auto;
    padding: 30px 0;
  }
  .split-banner .gray-box .cnt {
    top: 0;
    transform: translate(0, 0);
  }*/ }

@media only screen and (max-width: 1023px) and (min-width: 1px) {
  .home-banner #slider {
    width: 50%; }
  .home-banner #slider-content {
    width: 50%; }
  .split-banner .blue-box .page-parent {
    position: relative; }
  .split-banner .photo .caption {
    padding: 0 20px 0 0; }
  .split-banner .btns-cnt {
    padding-bottom: 20px; }
  .slider-banner {
    height: auto; }
  .slider-banner .news-box {
    position: relative;
    max-width: 100%; }
  .slider-banner .photo-wrap {
    width: 100%;
    height: 300px; }
  .slider-banner .news-box {
    padding: 30px 50px;
    height: auto; }
    .slider-banner .news-box .secondary {
      margin-bottom: 30px !important; }
  .slider-banner .news-box .cnt {
    top: 0;
    transform: translate(0, 0); }
  .slider-banner .prev {
    top: 240px;
    left: 0;
    right: inherit; }
  .slider-banner .next {
    top: 240px; }
  .slider-banner .progress-bar {
    width: 100%; } }

@media only screen and (max-width: 880px) and (min-width: 1px) {
  .half-purple-banner {
    height: auto; }
    .half-purple-banner .photo-wrap {
      position: relative;
      height: 300px; }
    .half-purple-banner .purple-box-right {
      position: relative;
      max-width: 100%;
      padding: 30px; }
      .half-purple-banner .purple-box-right .cnt {
        top: 0;
        transform: translate(0, 0); }
    .half-purple-banner .banner-cnt {
      bottom: inherit;
      top: 220px; }
  /* News page */
  .split-banner .photo {
    max-width: 50%;
    height: 80%;
    top: inherit;
    bottom: 0;
    background-size: cover; }
  .split-banner .gray-box {
    height: auto;
    padding: 30px 0; }
    .split-banner .gray-box .cnt {
      left: 30px;
      top: 0;
      transform: translate(0, 0); }
    .split-banner .gray-box .cnt .page-title {
      font-size: 28px;
      max-width: 45%;
      line-height: 52px; }
    .split-banner .gray-box .page-parent {
      position: relative; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .split-banner .gray-box .cnt .page-title {
    max-width: 100%; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .home-banner #slider {
    width: 100%;
    height: 350px; }
  .home-banner .slider-lines {
    height: 350px; }
  .home-banner #slider-content {
    width: 100%;
    height: 350px; }
  .home-banner #slider-content .content-box {
    padding: 50px; }
  .home-banner #slider .swiper-slide .sec-1,
  .home-banner #slider .swiper-slide .sec-2,
  .home-banner #slider .swiper-slide .sec-3,
  .home-banner #slider .swiper-slide .sec-4 {
    height: 350px;
    clip: rect(0, 0, 350px, 0); }
  .banner .photo-wrap .photo {
    height: 400px; }
  /*  .split-banner .gray-box {
      margin: 0 0 0 15px;
    }*/
  /*  .split-banner .photo {
      max-width: 300px;
      height: 350px;
    }*/ }

@media only screen and (max-width: 767px) and (min-width: 1px) {
  .split-banner {
    height: auto;
    margin-bottom: 50px; }
  .split-banner .purple-box,
  .split-banner .gray-box {
    margin: 0; }
  .split-banner .btns-cnt,
  .split-banner .gray-box .cnt p {
    width: 100% !important; }
  .split-banner .breadcrumbs {
    width: 100%;
    padding: 30px 0 30px 30px; }
  .split-banner .purple-box .page-title {
    max-width: calc(100% - 40px);
    left: 20px; }
  .split-banner .language {
    padding: 30px 0 0 20px; }
  .full-banner {
    height: 300px; }
  .video-banner {
    height: 350px; }
  .video-banner .cnt h1 {
    font-size: 36px;
    line-height: 44px; }
  .event-box .col-xs-4 {
    flex-basis: 100%;
    max-width: 100%; }
  .event-box .col-xs-8 {
    flex-basis: 100%;
    max-width: 100%; }
  .event-banner .event-box .cnt {
    padding-left: 0;
    padding-top: 20px;
    padding-bottom: 20px; }
  .banner-half-photo-tall {
    height: 300px; }
  .half-purple-banner {
    height: auto !important; }
  .split-banner .news-share {
    padding: 20px 0 20px 20px; }
  .split-banner .gray-box .cnt {
    left: 15px; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .drop-box .title {
    font-size: 24px;
    line-height: 34px;
    padding: 0; }
  .drop-box .subtitle {
    padding: 0; }
  .drop-box {
    width: calc(100% - 40px); }
  .drop-banner .photo {
    height: 150px; }
  .drop-box .title {
    margin: 30px 0; }
  .split-banner {
    height: auto; }
    .split-banner img.fb-offscreen {
      position: relative;
      left: 0px; }
    .split-banner .photo {
      position: relative;
      left: 20px;
      max-width: 100%;
      height: auto; }
    .split-banner .purple-box {
      height: auto;
      padding: 0 0 50px 0; }
      .split-banner .purple-box .page-title {
        font-size: 24px;
        line-height: 34px;
        position: relative;
        left: 30px;
        margin-top: 20px;
        transform: translate(0, 0); }
      .split-banner .purple-box .language {
        padding: 30px 0 0 20px; }
  .full-banner {
    height: 150px; }
    .full-banner .page-title {
      font-size: 24px;
      line-height: 34px;
      bottom: 20px;
      left: 20px; }
  .video-banner .sound-btn {
    width: 100%;
    bottom: 10px; }
  .banner-half-photo-short {
    height: 250px; }
  .event-banner .page-title {
    font-size: 24px;
    line-height: 34px;
    left: 20px;
    max-width: 580px;
    width: 80%; }
  .event-banner .photo-wrap {
    height: 250px; }
  .event-banner .photo-wrap .photo {
    height: 600px; }
  .event-banner .event-box {
    padding: 30px 20px; }
  .banner .photo-wrap .photo {
    height: 400px; }
  .video-banner .sound-btn .song-title {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0; }
  .video-banner .cnt h1 {
    margin: 0 0 20px 0; }
  .split-banner .gray-box .cnt .purple-btn {
    margin: 10px auto; }
  .split-banner .gray-box .cnt {
    left: 0;
    padding: 0 30px; }
  .split-banner .purple-box {
    padding: 0 20px 50px 20px; }
  .split-banner .purple-box * {
    left: 0 !important; }
  .split-banner .purple-box .page-parent {
    padding: 30px 0 0 0; }
  .video-banner .video-btn {
    top: 0; }
  .half-purple-banner .purple-box-right .cnt {
    padding: 0; }
  .half-purple-banner .photo-wrap {
    height: 150px; }
  .half-purple-banner .banner-cnt {
    top: 100px; }
  .slider-banner .photo-wrap {
    height: 150px; }
  .slider-banner .photo-wrap .photo {
    height: 300px; }
  .slider-banner .prev {
    top: 90px; }
  .slider-banner .next {
    top: 90px; }
  .slider-banner .news-box .cnt .white-btn {
    min-width: 100%; }
  .split-banner .gray-box {
    height: auto;
    padding: 30px 0; }
    .split-banner .gray-box .cnt {
      top: 0;
      transform: translate(0, 0); }
      .split-banner .gray-box .cnt .page-title {
        font-size: 32px;
        line-height: 40px; }
  .events-slider .slider-header .transparent-btn {
    margin: 20px auto !important; }
  #events-slider .swiper-container, #featured-events-slider .swiper-container, #upcoming-events-slider .swiper-container {
    margin: 0 20px !important; }
  .banner .banner-cnt {
    left: 20px; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  #banner .drop-box .white-btn {
    min-width: 100%; }
  .home-banner #slider {
    width: 100%;
    height: 200px; }
  .home-banner .slider-lines {
    height: 200px; }
  .home-banner #slider-content {
    width: 100%; }
  .home-banner #slider-content .content-box .intro {
    font-size: 18px;
    line-height: 24px; }
  .home-banner #slider-content .content-box {
    padding: 50px; }
  .home-banner #slider .swiper-slide .sec-1,
  .home-banner #slider .swiper-slide .sec-2,
  .home-banner #slider .swiper-slide .sec-3,
  .home-banner #slider .swiper-slide .sec-4 {
    height: 200px;
    clip: rect(0, 0, 200px, 0); }
  .home-banner #slider-content .prev {
    display: none;
    left: 0;
    right: inherit;
    bottom: inherit;
    top: 50%;
    transform: translate(0, -50%); }
  .home-banner #slider-content .next {
    display: none;
    top: 50%;
    bottom: inherit;
    transform: translate(0, -50%); }
  .home-banner .pagination {
    left: 50%;
    transform: translate(-50%, 0); }
  .split-banner .photo {
    left: 0;
    max-width: 100%; } }

@media only screen and (max-width: 380px) and (min-width: 1px) {
  .home-banner #slider-content .content-box {
    padding: 50px 20px; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .home-banner #slider-content .pause {
    top: -65px;
    left: unset;
    right: 15px; } }

#visit {
  display: block;
  position: relative;
  width: 100%;
  height: 500px; }
  #visit .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: #000;
    opacity: .5; }
  #visit .photo-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden; }
    #visit .photo-wrap .photo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 900px;
      z-index: 1;
      background-size: cover !important;
      transform: translate3d(0, 0, 0) !important; }
  #visit .cnt {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 9;
    padding: 0 150px;
    transform: translate(0, -50%); }
  #visit .header1 {
    color: #fff; }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  #visit .cnt {
    padding: 0 50px; } }

@media only screen and (max-width: 380px) and (min-width: 1px) {
  #visit {
    height: 300px; }
    #visit .photo {
      height: 500px !important; }
  #visit .cnt {
    padding: 0 20px; } }

.events-slider {
  display: block;
  position: relative;
  width: 100%;
  padding: 70px 0 70px 150px;
  background-color: #f2f5f8; }
  .events-slider .slider-header {
    padding-right: 150px;
    margin-bottom: 40px; }
    .events-slider .slider-header .all-btn {
      position: absolute;
      bottom: 0;
      right: 128px;
      max-width: 140px;
      min-width: 140px;
      margin: 0; }
    .events-slider .slider-header .transparent-btn {
      position: absolute;
      bottom: 0;
      right: 128px;
      margin: 0; }
    .events-slider .slider-header .next,
    .events-slider .slider-header .video-next {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60px;
      height: 60px;
      border: 1px solid #4e2a84; }
      .events-slider .slider-header .next:after,
      .events-slider .slider-header .video-next:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(-90deg); }
      .events-slider .slider-header .next:hover, .events-slider .slider-header .next:focus,
      .events-slider .slider-header .video-next:hover,
      .events-slider .slider-header .video-next:focus {
        background: #3f2367; }
        .events-slider .slider-header .next:hover:after, .events-slider .slider-header .next:focus:after,
        .events-slider .slider-header .video-next:hover:after,
        .events-slider .slider-header .video-next:focus:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
    .events-slider .slider-header .prev,
    .events-slider .slider-header .video-prev {
      position: absolute;
      bottom: 0;
      right: 59px;
      width: 60px;
      height: 60px;
      border: 1px solid #4e2a84; }
      .events-slider .slider-header .prev:after,
      .events-slider .slider-header .video-prev:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(90deg); }
      .events-slider .slider-header .prev:hover, .events-slider .slider-header .prev:focus,
      .events-slider .slider-header .video-prev:hover,
      .events-slider .slider-header .video-prev:focus {
        background: #3f2367; }
        .events-slider .slider-header .prev:hover:after, .events-slider .slider-header .prev:focus:after,
        .events-slider .slider-header .video-prev:hover:after,
        .events-slider .slider-header .video-prev:focus:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
  .events-slider .event-slide .video-thumb {
    position: relative;
    width: 100%;
    height: 230px;
    background: #000;
    overflow: hidden; }
    .events-slider .event-slide .video-thumb .play {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9; }
      .events-slider .event-slide .video-thumb .play:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 51px;
        height: 51px;
        background: url(../images/play-btn.png) 50% no-repeat;
        z-index: 99;
        transform: translate(-50%, -50%); }
    .events-slider .event-slide .video-thumb .thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 8;
      opacity: .6;
      background-size: cover !important; }
  .events-slider .event-slide .thumb-wrap {
    position: relative;
    width: 100%;
    height: 230px;
    overflow: hidden; }
    .events-slider .event-slide .thumb-wrap span {
      position: absolute;
      bottom: 0;
      left: 0;
      width: auto;
      height: auto;
      padding: 8px 10px 5px 10px;
      color: #ffffff;
      z-index: 9;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 4px;
      text-transform: uppercase; }
      .events-slider .event-slide .thumb-wrap span.violet, .events-slider .event-slide .thumb-wrap span.bienen-ensembles {
        background-color: #bd10e0; }
      .events-slider .event-slide .thumb-wrap span.blue, .events-slider .event-slide .thumb-wrap span.recitals-visiting-artists, .events-slider .event-slide .thumb-wrap span.recitals-lectures {
        background-color: #4e93ce; }
      .events-slider .event-slide .thumb-wrap span.yellow, .events-slider .event-slide .thumb-wrap span.festivals-series {
        background-color: #f1951c; }
      .events-slider .event-slide .thumb-wrap span.green, .events-slider .event-slide .thumb-wrap span.student-recitals {
        background-color: #79cc19; }
    .events-slider .event-slide .thumb-wrap:hover .thumb, .events-slider .event-slide .thumb-wrap:focus .thumb {
      transform: scale(1.1); }
    .events-slider .event-slide .thumb-wrap .thumb a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .events-slider .event-slide .thumb {
    position: relative;
    width: 100%;
    height: 230px;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s; }
    .events-slider .event-slide .thumb .play {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 51px;
      height: 51px;
      background: url(../images/play-btn.png) 50% no-repeat;
      z-index: 99;
      transform: translate(-50%, -50%); }
  .events-slider .event-slide .date {
    color: #819eb5;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 20px 0 0 0; }
  .events-slider .event-slide .event-title {
    color: #4e2a84;
    font-family: "Poppins-Medium";
    font-size: 22px;
    line-height: 32px;
    margin: 10px 0; }
    .events-slider .event-slide .event-title a {
      position: relative; }
      .events-slider .event-slide .event-title a span {
        width: calc(100%);
        background-image: linear-gradient(transparent calc(100% - 2px), #4e2a84 2px);
        background-repeat: no-repeat;
        background-size: 0% 3px;
        background-position: left bottom;
        transition: background-size .4s; }
      .events-slider .event-slide .event-title a:hover span, .events-slider .event-slide .event-title a:focus span {
        background-size: 100% 3px; }
      .events-slider .event-slide .event-title a:hover, .events-slider .event-slide .event-title a:focus {
        color: #4e2a84; }
  .events-slider .event-slide .players {
    display: inline-block;
    width: 100%;
    color: #819eb5;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin: 0; }
  .events-slider .event-slide .location {
    display: inline-block;
    width: 100%;
    color: #819eb5;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 20px;
    padding-left: 20px;
    background: url(../images/location-icon.svg) left center no-repeat;
    text-align: left;
    margin: 0; }
  .events-slider .event-slide .event-btns {
    margin: 40px 0 0 0;
    font-size: 0;
    text-align: left; }
    .events-slider .event-slide .event-btns a {
      width: calc(50% - 10px);
      min-width: 140px;
      height: 40px;
      line-height: 12px !important; }
    .events-slider .event-slide .event-btns .purple-btn {
      margin-right: 5px; }
      .events-slider .event-slide .event-btns .purple-btn:hover, .events-slider .event-slide .event-btns .purple-btn:focus {
        box-shadow: none !important; }
    .events-slider .event-slide .event-btns .btn {
      margin-left: 5px; }
  .events-slider .event-slide.is_hover .thumb {
    transform: scale(1.1); }
  .events-slider .event-slide.is_hover .event-title span {
    background-size: 100% 3px; }
  .events-slider .swiper-slide {
    opacity: .5; }
  .events-slider .swiper-slide-next,
  .events-slider .swiper-slide-active,
  .events-slider .swiper-slide-next + div {
    opacity: 1 !important; }

.video-slider .event-slide .video-credit {
  display: inline-block;
  width: 100%;
  color: #819eb5;
  font-family: "AkkuratPro-Regular";
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  margin: 0; }

.pdf-slider {
  display: block;
  position: relative;
  width: 100%;
  padding: 70px 0 70px 0; }
  .pdf-slider a.permalink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999; }
  .pdf-slider .slider-header {
    padding: 0 150px;
    margin-bottom: 40px; }
    .pdf-slider .slider-header .all-btn {
      position: absolute;
      bottom: 0;
      right: 128px;
      max-width: 140px;
      min-width: 140px;
      margin: 0; }
    .pdf-slider .slider-header .pdf-next {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60px;
      height: 60px;
      border: 1px solid #4e2a84; }
      .pdf-slider .slider-header .pdf-next:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(-90deg); }
      .pdf-slider .slider-header .pdf-next:hover, .pdf-slider .slider-header .pdf-next:focus {
        background: #3f2367; }
        .pdf-slider .slider-header .pdf-next:hover:after, .pdf-slider .slider-header .pdf-next:focus:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
    .pdf-slider .slider-header .pdf-prev {
      position: absolute;
      bottom: 0;
      right: 59px;
      width: 60px;
      height: 60px;
      border: 1px solid #4e2a84; }
      .pdf-slider .slider-header .pdf-prev:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(90deg); }
      .pdf-slider .slider-header .pdf-prev:hover, .pdf-slider .slider-header .pdf-prev:focus {
        background: #3f2367; }
        .pdf-slider .slider-header .pdf-prev:hover:after, .pdf-slider .slider-header .pdf-prev:focus:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
  .pdf-slider .news-post h3 a {
    text-decoration: none; }
  .pdf-slider .event-slide .thumb {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 360px;
    height: 520px;
    background-size: cover !important; }
    .pdf-slider .event-slide .thumb span {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
      padding: 8px 10px 5px 10px;
      color: #ffffff;
      z-index: 9;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 4px;
      text-transform: uppercase; }
      .pdf-slider .event-slide .thumb span.purple {
        background-color: #4e2a84; }
      .pdf-slider .event-slide .thumb span.violet, .pdf-slider .event-slide .thumb span.bienen-ensembles {
        background-color: #bd10e0; }
      .pdf-slider .event-slide .thumb span.blue, .pdf-slider .event-slide .thumb span.recitals-visiting-artists, .pdf-slider .event-slide .thumb span.recitals-lectures {
        background-color: #4e93ce; }
      .pdf-slider .event-slide .thumb span.yellow, .pdf-slider .event-slide .thumb span.festivals-series {
        background-color: #f1951c; }
      .pdf-slider .event-slide .thumb span.green, .pdf-slider .event-slide .thumb span.student-recitals {
        background-color: #79cc19; }
  .pdf-slider .event-slide .tertiary-btn {
    display: block;
    margin: 20px auto;
    max-width: 120px; }
  .pdf-slider .swiper-slide {
    opacity: .5; }
  .pdf-slider .swiper-slide-prev,
  .pdf-slider .swiper-slide-active,
  .pdf-slider .swiper-slide-next {
    opacity: 1 !important; }

#related-news-slider .news-post,
#home-news-slider .news-post {
  margin: 10px 0; }

#related-news-slider .news-post .cnt,
#home-news-slider .news-post .cnt {
  top: 0;
  transform: translate(0, 0); }

#related-news-slider .swiper-wrapper,
#home-news-slider .swiper-wrapper {
  padding-bottom: 20px; }

#related-news-slider .swiper-slide,
#home-news-slider .swiper-slide {
  height: auto; }

.photo-content-slider {
  display: block;
  position: relative;
  width: 100%;
  padding: 70px 0 70px 0; }
  .photo-content-slider .slider-header {
    padding-right: calc(33.3333% + .85rem);
    margin-bottom: 0; }
    .photo-content-slider .slider-header h1 {
      padding: 0 50px 30px 50px; }
    .photo-content-slider .slider-header .pagination {
      display: none;
      width: auto; }
      .photo-content-slider .slider-header .pagination .swiper-pagination-bullet {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        margin: 0 5px;
        background-color: #f6f4f9;
        border: 1px solid #aaa; }
      .photo-content-slider .slider-header .pagination .swiper-pagination-bullet-active {
        background: #4e2a84;
        border: 1px solid #4e2a84; }
    .photo-content-slider .slider-header .next,
    .photo-content-slider .slider-header .events-next {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60px;
      height: 60px;
      border: 1px solid #4e2a84; }
      .photo-content-slider .slider-header .next:after,
      .photo-content-slider .slider-header .events-next:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(-90deg); }
      .photo-content-slider .slider-header .next:hover, .photo-content-slider .slider-header .next:focus,
      .photo-content-slider .slider-header .events-next:hover,
      .photo-content-slider .slider-header .events-next:focus {
        background: #3f2367; }
        .photo-content-slider .slider-header .next:hover:after, .photo-content-slider .slider-header .next:focus:after,
        .photo-content-slider .slider-header .events-next:hover:after,
        .photo-content-slider .slider-header .events-next:focus:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
    .photo-content-slider .slider-header .prev,
    .photo-content-slider .slider-header .events-prev {
      position: absolute;
      bottom: 0;
      right: 59px;
      width: 60px;
      height: 60px;
      border: 1px solid #4e2a84; }
      .photo-content-slider .slider-header .prev:after,
      .photo-content-slider .slider-header .events-prev:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(90deg); }
      .photo-content-slider .slider-header .prev:hover, .photo-content-slider .slider-header .prev:focus,
      .photo-content-slider .slider-header .events-prev:hover,
      .photo-content-slider .slider-header .events-prev:focus {
        background: #3f2367; }
        .photo-content-slider .slider-header .prev:hover:after, .photo-content-slider .slider-header .prev:focus:after,
        .photo-content-slider .slider-header .events-prev:hover:after,
        .photo-content-slider .slider-header .events-prev:focus:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
  .photo-content-slider .event-slide a.permalink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999; }
    .photo-content-slider .event-slide a.permalink:hover + .thumb-wrap .thumb, .photo-content-slider .event-slide a.permalink:focus + .thumb-wrap .thumb {
      transform: scale(1.1); }
  .photo-content-slider .event-slide.is_hover .slide-caption h3 a span {
    background-size: 100% 3px; }
  .photo-content-slider .event-slide .thumb-wrap {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden; }
  .photo-content-slider .event-slide .thumb {
    position: relative;
    width: 100%;
    height: 500px;
    max-height: 500px;
    z-index: 1;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s; }
    .photo-content-slider .event-slide .thumb .play {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 51px;
      height: 51px;
      background: url(../images/play-btn.png) 50% no-repeat;
      z-index: 99;
      transform: translate(-50%, -50%); }
    .photo-content-slider .event-slide .thumb span.cat {
      position: absolute;
      bottom: 0;
      right: 0;
      width: auto;
      height: auto;
      padding: 8px 30px 5px 30px;
      color: #ffffff;
      z-index: 9;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 4px;
      text-transform: uppercase; }
      .photo-content-slider .event-slide .thumb span.cat.purple {
        background-color: #4e2a84; }
      .photo-content-slider .event-slide .thumb span.cat.violet, .photo-content-slider .event-slide .thumb span.cat.bienen-ensembles {
        background-color: #bd10e0; }
      .photo-content-slider .event-slide .thumb span.cat.blue, .photo-content-slider .event-slide .thumb span.cat.recitals-visiting-artists, .photo-content-slider .event-slide .thumb span.cat.recitals-lectures {
        background-color: #4e93ce; }
      .photo-content-slider .event-slide .thumb span.cat.yellow, .photo-content-slider .event-slide .thumb span.cat.festivals-series {
        background-color: #f1951c; }
      .photo-content-slider .event-slide .thumb span.cat.green, .photo-content-slider .event-slide .thumb span.cat.student-recitals {
        background-color: #79cc19; }
  .photo-content-slider .event-slide .slide-caption {
    display: inline-block;
    position: absolute;
    bottom: -50px;
    right: 0;
    max-width: 80%;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    z-index: 9999; }
    .photo-content-slider .event-slide .slide-caption h3 {
      margin-bottom: 10px; }
      .photo-content-slider .event-slide .slide-caption h3 a {
        color: #4e2a84;
        font-family: "Poppins-Medium";
        font-size: 24px;
        font-weight: 500;
        line-height: 32px; }
  .photo-content-slider .event-slide .cnt {
    padding: 0 30px; }
    .photo-content-slider .event-slide .cnt .secondary {
      letter-spacing: 3px; }
    .photo-content-slider .event-slide .cnt .btn {
      width: 100%;
      min-width: 180px; }
  .photo-content-slider .event-slide .event-desc {
    color: #000000;
    font-family: "AkkuratPro-Regular";
    font-size: 24px;
    line-height: 36px; }
  .photo-content-slider .event-slide .event-copy {
    color: #000000;
    font-family: "AkkuratPro-Regular";
    font-size: 16px;
    line-height: 28px; }

#home-events-slider {
  padding-right: 50px; }
  #home-events-slider .slider-header {
    padding-right: 0; }
    #home-events-slider .slider-header .events-prev {
      right: 67px; }
    #home-events-slider .slider-header .events-next {
      right: 8px; }
  #home-events-slider .event-slide {
    padding-bottom: 55px; }
  @media only screen and (max-width: 580px) and (min-width: 1px) {
    #home-events-slider .row {
      margin-left: 0;
      margin-right: 0;
      width: 100%; }
      #home-events-slider .row .col-xs-12 {
        padding: 0; } }
  #home-events-slider .slide-caption {
    width: 80%;
    right: 8px; }
    @media only screen and (max-width: 580px) and (min-width: 1px) {
      #home-events-slider .slide-caption {
        right: 0; } }
    #home-events-slider .slide-caption .date {
      position: absolute;
      top: -34px;
      left: 0;
      width: auto;
      background-color: #4f7796;
      color: #ffffff;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 3px;
      text-align: center;
      padding: 5px 15px; }
    #home-events-slider .slide-caption .location {
      display: inline-block;
      width: 100%;
      color: #819eb5;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 20px;
      padding-left: 20px;
      background: url(../images/location-icon.svg) left center no-repeat;
      text-align: left;
      margin: 0; }

#post-slider .slider-header {
  padding-right: .5rem; }

#upcoming-events-slider {
  background-image: linear-gradient(145deg, rgba(224, 232, 238, 0.25) 0%, #e0e8ee 100%); }

#featured-events-slider,
#davee-media-library {
  background: #fff; }

.events-slider .ensembles-single,
.page-template-template-faculty-detail .transparent-btn {
  display: none; }

.page-template-template-performing-ensembles-single .events-slider .ensemble-single {
  display: block;
  position: absolute;
  right: 150px;
  bottom: 0;
  margin: 0; }

.page-template-template-resources-students .events-slider .resource-all {
  display: block;
  position: absolute;
  right: 125px;
  bottom: 0;
  margin: 0;
  min-width: 140px; }

.page-template-template-performing-ensembles-single .events-slider {
  background: #fff;
  padding: 0 0 0 150px; }

#series-slider {
  position: relative;
  height: 600px;
  background: transparent;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0; }
  #series-slider .col-xs-7,
  #series-slider .col-xs-5,
  #series-slider .col-xs-8,
  #series-slider .col-xs-4 {
    padding-left: 0;
    padding-right: 0; }
  #series-slider .swiper-container {
    width: 100%;
    height: 100%;
    background: #4e2a84; }
  #series-slider .swiper-slide {
    padding: 100px 65px;
    text-align: left; }
    #series-slider .swiper-slide .slider-title {
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      color: #fff;
      text-transform: uppercase; }
    #series-slider .swiper-slide .series-copy {
      color: #ffffff;
      font-size: 24px;
      line-height: 36px; }
    #series-slider .swiper-slide .event-title {
      font-family: "AkkuratPro-Bold";
      font-size: 36px;
      line-height: 44px;
      color: #fff;
      margin: 40px 0; }
    #series-slider .swiper-slide a {
      display: inline-block;
      position: relative;
      font-family: "AkkuratPro-Bold";
      color: #fff;
      font-size: 16px;
      padding-right: 30px;
      text-decoration: none; }
      #series-slider .swiper-slide a:hover, #series-slider .swiper-slide a:focus {
        opacity: .5; }
      #series-slider .swiper-slide a .ico {
        display: inline-block;
        position: absolute;
        width: 16px;
        height: 16px;
        top: 4px;
        right: 0;
        background: url(../images/white-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: rotate(-90deg); }
  #series-slider .series-pagination {
    position: absolute;
    width: auto;
    bottom: 25px;
    left: 65px;
    z-index: 999; }
    #series-slider .series-pagination .swiper-pagination-bullet {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #fff;
      margin: 0 15px 0 0;
      opacity: .5; }
    #series-slider .series-pagination .swiper-pagination-bullet-active {
      opacity: 1; }
  #series-slider .prev,
  #series-slider .series-prev {
    position: absolute;
    bottom: 0;
    right: 59px;
    width: 60px;
    height: 60px;
    z-index: 999;
    border: 1px solid #fff;
    opacity: .5; }
    #series-slider .prev:after,
    #series-slider .series-prev:after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      width: 25px;
      height: 25px;
      background: url(../images/white-arrow.svg) 50% no-repeat;
      background-size: 100%;
      transform: translate(-50%, -50%) rotate(90deg); }
    #series-slider .prev:hover, #series-slider .prev:focus,
    #series-slider .series-prev:hover,
    #series-slider .series-prev:focus {
      background: #fff;
      opacity: 1; }
      #series-slider .prev:hover:after, #series-slider .prev:focus:after,
      #series-slider .series-prev:hover:after,
      #series-slider .series-prev:focus:after {
        background: url(../images/purple-arrow.svg) 50% no-repeat; }
  #series-slider .next,
  #series-slider .series-next {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    z-index: 999;
    border: 1px solid #fff;
    opacity: .5; }
    #series-slider .next:after,
    #series-slider .series-next:after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      width: 25px;
      height: 25px;
      background: url(../images/white-arrow.svg) 50% no-repeat;
      background-size: 100%;
      transform: translate(-50%, -50%) rotate(-90deg); }
    #series-slider .next:hover, #series-slider .next:focus,
    #series-slider .series-next:hover,
    #series-slider .series-next:focus {
      background: #fff;
      opacity: 1; }
      #series-slider .next:hover:after, #series-slider .next:focus:after,
      #series-slider .series-next:hover:after,
      #series-slider .series-next:focus:after {
        background: url(../images/purple-arrow.svg) 50% no-repeat; }
  #series-slider .series-photos {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #000; }
    #series-slider .series-photos .photo {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      opacity: 0;
      transition: .4s;
      background-size: cover !important;
      background-attachment: fixed !important; }
      #series-slider .series-photos .photo.active {
        opacity: 1; }

@media only screen and (max-width: 1180px) and (min-width: 1px) {
  #photo-content-slider .btn {
    min-width: 100%; }
  #series-slider .swiper-slide .series-copy {
    font-size: 18px;
    line-height: 24px; } }

@media only screen and (max-width: 1080px) and (min-width: 1px) {
  .page-template-template-performing-ensembles-single .events-slider .header2 {
    position: relative;
    width: 100%;
    display: inline-block;
    margin-bottom: 20px; }
  .page-template-template-performing-ensembles-single .events-slider .ensemble-single {
    position: relative;
    right: inherit;
    max-width: 220px; } }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  .events-slider {
    padding: 70px 0 70px 50px; }
  .events-slider .slider-header {
    padding-right: 50px; }
  .photo-content-slider .slider-header {
    padding-right: 0;
    text-align: center; }
  .photo-content-slider .slider-header .prev,
  .photo-content-slider .slider-header .next {
    position: relative;
    right: 0;
    display: inline-block; }
  .photo-content-slider .event-slide .cnt {
    padding: 0 20px; }
  .photo-content-slider .slider-header .pagination {
    display: none; } }

@media only screen and (max-width: 960px) and (min-width: 1px) {
  #events-slider .slider-header,
  #featured-events-slider .slider-header,
  #upcoming-events-slider .slider-header {
    padding-right: 30px; }
  .pdf-slider .slider-header {
    padding: 0 50px; }
  #video-slider {
    padding: 70px 20px; }
  #video-slider.events-slider .slider-header {
    padding-right: 0; }
  #series-slider > .col-xs-8 {
    flex-basis: 50%;
    max-width: 50%; }
  #series-slider > .col-xs-4 {
    flex-basis: 50%;
    max-width: 50%; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  #resources-slider.events-slider {
    margin-bottom: 50px; }
  #resources-slider .swiper-container {
    margin-right: 50px; }
  .photo-content-slider .event-slide .cnt {
    padding: 50px 20px 0 20px; }
  #photo-content-slider {
    margin-bottom: 0; }
  #series-slider > .col-xs-8,
  #series-slider > .col-xs-4 {
    flex-basis: 100%;
    max-width: 100%; }
  #home-events-slider {
    padding: 0; }
  #home-events-slider .slide-caption {
    width: 90%;
    max-width: 90%;
    bottom: -30px; }
  .photo-content-slider .slider-header h1 {
    line-height: 120px; } }

@media only screen and (max-width: 767px) and (min-width: 1px) {
  #events-slider,
  #featured-events-slider,
  #upcoming-events-slider {
    padding: 50px 40px; }
  #events-slider .slider-header,
  #featured-events-slider .slider-header,
  #upcoming-events-slider .slider-header {
    padding-right: 0; }
  .event-btns a {
    width: 100% !important;
    margin: 0 0 10px 0 !important; }
  .events-slider h2 {
    margin-bottom: 30px; }
  .events-slider .slider-header .all-btn {
    position: relative;
    right: inherit;
    vertical-align: top; }
  .events-slider .slider-header .prev {
    position: relative;
    right: inherit;
    display: inline-block;
    vertical-align: top; }
  .events-slider .slider-header .next {
    position: relative;
    right: inherit;
    display: inline-block;
    vertical-align: top; }
  #faculty-conductor {
    padding: 0 20px; }
  .page-template-template-performing-ensembles-single .events-slider .ensemble-single {
    margin-bottom: 20px; }
  .photo-content-slider .slider-header h1 {
    padding: 0 20px; }
  .events-slider .slider-header .transparent-btn {
    right: 0; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  #events-slider .slider-header .prev,
  #featured-events-slider .slider-header .prev,
  #upcoming-events-slider .slider-header .prev {
    position: relative; }
  #events-slider .slider-header .next,
  #featured-events-slider .slider-header .next,
  #upcoming-events-slider .slider-header .next {
    position: relative; }
  #events-slider .slider-header,
  #featured-events-slider .slider-header,
  #upcoming-events-slider .slider-header {
    position: static;
    text-align: center; }
  #events-slider .slider-header h2,
  #featured-events-slider .slider-header h2,
  #upcoming-events-slider .slider-header h2 {
    padding: 0 20px; }
  #events-slider,
  #featured-events-slider,
  #upcoming-events-slider {
    padding: 50px 0; }
  #events-slider .swiper-container,
  #featured-events-slider .swiper-container,
  #upcoming-events-slider .swiper-container {
    margin: 0 50px; }
  .page-template-template-resources-students .events-slider .resource-all {
    position: relative;
    right: 0;
    margin-bottom: 30px; }
  #resources-slider.events-slider .slider-header {
    text-align: center; }
  .pdf-slider .slider-header .all-btn {
    position: relative;
    right: 0; }
  .photo-content-slider .event-slide .thumb {
    height: 300px; }
  .photo-content-slider .event-slide .thumb .slide-caption {
    max-width: 100%; }
  #photo-content-slider {
    padding: 30px 0; }
  .photo-content-slider .event-slide .thumb .slide-caption {
    box-shadow: none; }
  #series-slider .series-prev,
  #series-slider .series-next {
    display: none; }
  #series-slider .series-pagination {
    left: 30px;
    width: auto !important; }
  #series-slider .series-pagination .swiper-pagination-bullet {
    margin: 0 10px; }
  #home-events-slider .slider-header .events-prev {
    right: inherit;
    left: 0; }
  .pdf-slider .slider-header {
    padding: 0 20px; }
  #home-events-slider .slide-caption {
    width: 100%;
    max-width: 100%; }
  .events-slider .slider-header .transparent-btn {
    position: relative; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  .events-slider {
    padding: 70px 20px; }
  .events-slider .slider-header .all-btn {
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 30px; }
  #events-slider .transparent-btn {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    margin-left: 20px; }
  .events-slider .slider-header {
    padding-right: 0; }
  #resources-slider .swiper-container {
    margin-right: 0; }
  .pdf-slider .slider-header {
    padding: 0 20px; }
  .photo-content-slider .slider-header h1 {
    margin-bottom: 30px; }
  .photo-content-slider .event-slide .thumb .slide-caption {
    padding: 20px; }
  .events-slider h2 {
    text-align: center; }
  .events-slider .slider-header .transparent-btn {
    margin-bottom: 20px; }
  #events-slider .swiper-container, #featured-events-slider .swiper-container, #upcoming-events-slider .swiper-container {
    margin: 0 20px; }
  .events-slider .slider-header {
    text-align: center; }
  .events-slider .slider-header .next, .events-slider .slider-header .video-next,
  .events-slider .slider-header .prev, .events-slider .slider-header .video-prev {
    position: relative;
    display: inline-block;
    right: inherit; } }

.page-node-type-faculty-details #upcoming-events-slider {
  background: #fff; }

.widget {
  display: block;
  position: relative;
  border: 1px solid #e0e7ed;
  background-color: #f2f5f8;
  padding: 25px;
  margin: 0 0 50px 0;
  max-width: 265px; }
  .widget .header3 {
    border-bottom: 5px solid #e0e7ed;
    padding-bottom: 5px; }
  .widget a {
    display: inline-block;
    margin: 10px 0;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word; }
  .widget .title {
    margin: 10px 0 0 0;
    font-family: "AkkuratPro-Bold"; }
  .widget .phone {
    display: block;
    width: 100%;
    padding-left: 20px;
    background: url(../images/phone-icon.svg) left center no-repeat;
    font-family: "AkkuratPro-Bold"; }
  .widget.fixed {
    position: fixed;
    top: 50px; }
  .widget.bottom {
    position: absolute;
    bottom: 0;
    left: .5rem !important;
    margin-bottom: 0; }
  .widget .body-link {
    font-size: 15px; }

article #questions {
  max-width: 100%;
  margin: 80px 0 60px 0;
  padding: 45px 50px; }

@media only screen and (max-width: 960px) and (min-width: 1px) {
  #questions.fixed,
  #questions.bottom {
    position: relative;
    top: inherit;
    bottom: inherit; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .widget {
    max-width: 100%; }
  article #questions {
    padding: 25px;
    margin: 30px 0 !important; }
  #questions-bottom #questions {
    margin: 0 0 50px 0; } }

.table-wrapper {
  display: block;
  padding: 20px 0 0 0; }
  .table-wrapper .table .table-header {
    visibility: hidden;
    height: 0; }
    .table-wrapper .table .table-header.show {
      visibility: visible;
      height: auto;
      color: #507796;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      text-transform: uppercase;
      padding: 10px 0;
      margin-bottom: 20px;
      border-bottom: 5px solid #e0e7ed; }
  .table-wrapper .table .table-body {
    position: relative; }
    .table-wrapper .table .table-body .download {
      position: absolute;
      top: -10px;
      right: 0;
      z-index: 999; }
    .table-wrapper .table .table-body .row {
      display: table;
      margin: 0;
      width: 100%;
      border: 1px solid #e0e7ed; }
    .table-wrapper .table .table-body .table-blue {
      display: table-cell;
      width: 25%;
      background-color: #819eb5;
      color: #ffffff;
      font-family: "AkkuratPro-Bold";
      font-size: 18px;
      line-height: 26px;
      padding: 20px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      vertical-align: middle; }
      .table-wrapper .table .table-body .table-blue p {
        color: #ffffff;
        font-family: "AkkuratPro-Bold";
        font-size: 18px;
        line-height: 26px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        vertical-align: middle; }
    .table-wrapper .table .table-body .table-gray {
      display: table-cell;
      width: 75%;
      color: #000000;
      font-family: "AkkuratPro-Regular";
      font-size: 16px;
      line-height: 28px;
      padding: 20px;
      background-color: #f2f5f8;
      vertical-align: middle; }
      .table-wrapper .table .table-body .table-gray p {
        color: #000000;
        font-family: "AkkuratPro-Regular";
        font-size: 16px;
        line-height: 28px;
        vertical-align: middle; }
  .table-wrapper .table .table-footer {
    padding: 20px 0; }

.list-table-header {
  color: #507796;
  font-family: "AkkuratPro-Bold";
  font-size: 12px;
  letter-spacing: 3px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 10px 0;
  border-bottom: 5px solid #e0e7ed; }

.list-table-header + .list-table {
  border-top: none; }

.list-table {
  padding: 20px 0;
  border-top: 1px solid #aec1d0; }
  .list-table .title {
    display: inline-block;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold";
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px; }
  .list-table .secondary {
    letter-spacing: 3px; }
  .list-table p {
    margin: 5px 0; }
  .list-table h2 {
    font-size: 16px;
    line-height: 28px; }
  .list-table a {
    color: #4e2a84;
    font-family: "AkkuratPro-Regular";
    font-size: 16px;
    line-height: 28px;
    text-decoration: none; }
  .list-table .date span {
    display: inline-block;
    width: 100%; }

.list-table-footer {
  padding: 30px 0;
  border-top: 1px solid #aec1d0; }

.data-table-header {
  color: #507796;
  font-family: "AkkuratPro-Bold";
  font-size: 12px;
  letter-spacing: 3px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 10px 0; }

.data-table {
  padding: 15px 0;
  border-top: 1px solid #aec1d0;
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 28px; }
  .data-table .null {
    display: inline-block;
    width: 20px;
    height: 2px;
    background: #000;
    vertical-align: middle; }
  .data-table .label {
    display: none; }

.data-table-footer {
  padding: 15px 0;
  border-top: 1px solid #aec1d0; }

.tabbed-table .tab-link {
  display: inline-block;
  position: relative;
  font-family: "AkkuratPro-Bold";
  font-size: 16px;
  line-height: 24px;
  color: #4e2a84;
  width: 100%;
  margin: 10px 0;
  text-decoration: none; }
  .tabbed-table .tab-link:first-child {
    margin-top: 0; }
  .tabbed-table .tab-link .ico {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    right: 0;
    background: url(../images/purple-arrow.svg) 50% no-repeat;
    transition: .4s; }
  .tabbed-table .tab-link.active {
    color: #000; }
    .tabbed-table .tab-link.active .ico {
      transform: rotate(180deg); }

.tabbed-table .tab-cnt {
  display: none; }
  .tabbed-table .tab-cnt .table-wrapper {
    padding: 0; }

.tabbed-table #tab1 {
  display: block; }

/*table:not(.calendar-table) {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%;

  thead {
    visibility:visible;
    height:auto;
    color: #507796;
    text-transform: uppercase;
    padding:10px 0;
    margin-bottom:20px;
    border-bottom: 5px solid #e0e7ed;

    tr {
      th {
        font-family: $akkurat_bold;
        font-size: 16px;
        letter-spacing: 3px;
        line-height: 16px;
      }
    }
  }

   tbody {
    tr {
      border: 1px solid #e0e7ed;
      color: #000000;
      font-family: $akkurat_reg;
      font-size: 16px;
      line-height: 28px;
      vertical-align:middle;

      td {
        border: 1px solid #e0e7ed;
      }

      td+td {
        //border: none;
      }
    }
  }

   tfoot {

  }
}*/
@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .data-table-header .col-xs-4,
  .data-table-header .col-xs-2,
  .data-table .col-xs-4,
  .data-table .col-xs-2 {
    flex-basis: 100%;
    max-width: 100%; }
  .data-table-header {
    display: none; }
  .data-table .label {
    display: inline-block;
    width: 250px; } }

@media only screen and (max-width: 1023px) and (min-width: 1px) {
  .table-wrapper .table .table-body .download {
    position: relative; } }

@media only screen and (max-width: 1023px) and (min-width: 1px) {
  .table-blue,
  .table-gray {
    width: 50% !important; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .table-wrapper {
    padding: 10px 0 0 0; }
  .table-wrapper .table .table-body .table-blue,
  .table-wrapper .table .table-body .table-gray {
    padding: 5px 10px; }
  .table-wrapper .table .table-body .row {
    display: flex; }
  .table-blue,
  .table-gray {
    width: 100% !important;
    display: flex; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  .data-table .label {
    width: 100%; }
  .data-table-header .col-xs-4,
  .data-table-header .col-xs-2,
  .data-table .col-xs-4,
  .data-table .col-xs-2 {
    text-align: center; } }

.gray-box {
  display: block;
  position: relative;
  border: 1px solid #e0e7ed;
  background-color: #f2f5f8;
  padding: 50px;
  margin: 40px 0; }
  .gray-box.shadow {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
  .gray-box .title {
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    line-height: 16px; }
  .gray-box .col-xs-4 .purple-btn {
    max-width: 300px;
    display: block;
    margin: 20px auto; }
  .gray-box .download {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0; }
  .gray-box .header3 {
    padding-bottom: 10px;
    border-bottom: 5px solid #e0e7ed; }
  .gray-box hr {
    margin: 20px 0; }
  .gray-box .score {
    padding: 40px 0; }
    .gray-box .score .title {
      color: #000000;
      font-family: "AkkuratPro-Bold";
      font-size: 18px;
      line-height: 26px; }
    .gray-box .score p {
      color: #000000;
      font-size: 16px;
      line-height: 28px;
      font-family: "AkkuratPro-Regular";
      margin: 0; }
    .gray-box .score .number {
      color: #000000;
      font-family: "Poppins-Medium";
      font-size: 32px;
      letter-spacing: -1px;
      line-height: 42px;
      margin-bottom: 20px; }
  .gray-box ul.col-4, .gray-box div.col-4 {
    display: inline-block;
    width: 24%;
    list-style: none;
    padding: 0; }
    .gray-box ul.col-4 li, .gray-box div.col-4 li {
      display: inline-block;
      width: 100%;
      margin: 10px 0;
      padding: 0 10px; }
  .gray-box .toggle-box {
    display: none;
    width: 100%;
    max-width: 100%;
    height: auto;
    border: none;
    padding: 0; }
    .gray-box .toggle-box .subtitle {
      color: #4f7796;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      text-transform: uppercase; }
    .gray-box .toggle-box p {
      margin: 0; }
  .gray-box .toggle-link {
    position: relative;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold";
    font-size: 14px;
    line-height: 20px;
    padding-right: 15px;
    text-decoration: none; }
    .gray-box .toggle-link .ico {
      position: absolute;
      top: 2px;
      right: 0;
      width: 10px;
      height: 10px;
      background: url(../images/Down_Arrow5.svg) 50% no-repeat;
      transform: rotate(0deg);
      transition: .4s; }
    .gray-box .toggle-link.active .ico {
      transform: rotate(180deg); }

aside nav .gray-box {
  max-width: 265px; }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .gray-bar {
    padding: 10px 60px; } }

@media only screen and (max-width: 1180px) and (min-width: 1px) {
  .gray-box .col-xs-4 .purple-btn {
    min-width: 100%; } }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  .gray-box ul.col-4, .gray-box dev.col-4 {
    width: 49%;
    margin-bottom: 0; }
  #board-members .fixed-width {
    padding: 0; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .gray-box .toggle-box .col-xs-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%; }
  .gray-box .toggle-box .col-xs-6 {
    flex-basis: 50%;
    max-width: 50%; }
  #honor-roll {
    margin: 0; }
  .gray-box ul.col-4 li, .gray-box div.col-4 ul li {
    margin: 10px 0; }
  #honor-roll .toggle-box .col-xs-6 {
    flex-basis: 100% !important;
    max-width: 100% !important; }
  .gray-box .fixed-width {
    padding: 0 60px !important; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .gray-box {
    padding: 50px 20px; }
  .gray-box .toggle-box .col-xs-4 {
    flex-basis: 100%;
    max-width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .gray-box .toggle-box .col-xs-4.end-xs {
    margin-bottom: 30px; }
  .gray-box .fixed-width.top {
    padding: 0; }
  .gray-box ul.col-4, .gray-box dev.col-4 {
    width: 100%; }
  #area-of-study .gray-box {
    margin-bottom: 0 !important; }
  .gray-bar {
    padding: 10px 20px; }
  .gray-box .fixed-width {
    padding: 0 0px !important; } }

ul {
  margin: -10px 0 20px 0; }
  ul li {
    margin: 0; }

article ul li {
  margin: 15px 0; }

ul.hours {
  list-style: none;
  padding: 0 40px 0 0;
  margin: 0; }
  ul.hours li {
    margin: 0; }
    ul.hours li span {
      float: right; }

ol {
  margin: -10px 0 40px 0; }
  ol li {
    margin: 0 0 10px 0; }

.list-title {
  margin: 0; }

.blue-list {
  list-style: none;
  padding: 0;
  margin: 10px 0; }
  .blue-list li {
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 10px 0; }

.audition-list .head {
  padding-bottom: 10px;
  color: #819eb5;
  font-family: "AkkuratPro-Regular";
  font-size: 12px;
  letter-spacing: 3px;
  line-height: 16px;
  text-transform: uppercase; }

.audition-list > [class*=col-] {
  border-bottom: 5px solid #e0e7ed; }

.audition-list .body {
  color: #4e2a84;
  font-family: "AkkuratPro-Regular";
  font-size: 24px;
  line-height: 36px;
  padding: 25px 0;
  border-bottom: 1px solid #aec1d0;
  transition: .4s; }
  .audition-list .body:hover, .audition-list .body:focus {
    background: #f2f5f8; }
    .audition-list .body:hover a:hover span, .audition-list .body:hover a:focus span, .audition-list .body:focus a:hover span, .audition-list .body:focus a:focus span {
      background-size: 100% 3px; }
  .audition-list .body a {
    position: relative; }
    .audition-list .body a span {
      width: calc(100%);
      background-image: linear-gradient(transparent calc(100% - 2px), #4e2a84 2px);
      background-repeat: no-repeat;
      background-size: 0% 3px;
      background-position: left bottom;
      transition: background-size .4s; }
    .audition-list .body a:hover, .audition-list .body a:focus {
      color: #4e2a84; }

.expense-list .row {
  padding: 20px 0 0 0;
  border-bottom: 1px solid #aec1d0; }

.expense-list p {
  padding-bottom: 20px; }

.expense-list .header3 {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 10px; }

.expense-list .header3 span {
  float: right;
  font-family: "AkkuratPro-Regular"; }

.expense-list .total p {
  border: 1px solid #e0e7ed;
  background-color: #f2f5f8;
  padding: 25px; }

.link-list {
  list-style: none;
  padding: 0; }
  .link-list li {
    width: 100%;
    margin: 10px 0; }

.alpha-list {
  list-style: none;
  padding: 0;
  text-align: center; }
  .alpha-list li {
    display: inline-block;
    width: auto;
    margin: 0;
    text-align: center; }
    .alpha-list li a {
      display: inline-block;
      position: relative;
      color: #4e2a84;
      font-family: "AkkuratPro-Bold";
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
      text-align: center;
      padding: 0 8px;
      text-decoration: none; }
      .alpha-list li a:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background: #4e2a84;
        transition: .4s; }
      .alpha-list li a:hover:after, .alpha-list li a:focus:after {
        width: 100%; }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .alpha-list li a {
    font-size: 18px;
    line-height: 26px; }
  ul {
    margin: 0 0 20px 0; } }

.question {
  display: block;
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 24px;
  line-height: 36px;
  margin: 50px 0 30px 0; }

.answer {
  border: 1px solid #e0e7ed;
  background-color: #f2f5f8;
  padding: 30px;
  height: 100px;
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 28px; }
  .answer .toggle .close {
    display: none; }
  .answer.opened {
    height: auto; }
    .answer.opened .toggle .close {
      display: inline-block; }
    .answer.opened .toggle .open {
      display: none; }
  .answer p {
    color: #000000;
    font-family: "AkkuratPro-Regular";
    font-size: 16px;
    line-height: 28px;
    margin: 0; }
  .answer a {
    position: relative;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold";
    font-size: 14px;
    line-height: 20px; }
    .answer a span {
      display: inline-block;
      position: relative;
      padding-right: 20px; }
      .answer a span:after {
        position: absolute;
        content: '';
        top: 3px;
        right: 0;
        width: 10px;
        height: 10px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: rotate(0deg); }

.toggle-box {
  width: 100%;
  height: auto;
  padding: 20px 30px;
  margin: 0 0 50px 0;
  border: 1px solid #e0e7ed;
  background-color: #f2f5f8; }
  .toggle-box.active .toggle-link .ico {
    transform: rotate(180deg); }
  .toggle-box .title {
    color: #000000;
    font-family: "AkkuratPro-Bold";
    font-size: 18px;
    line-height: 26px; }
  .toggle-box .toggle-cnt {
    display: none;
    width: 100%;
    color: #000000;
    font-family: "AkkuratPro-Regular";
    font-size: 16px;
    line-height: 28px;
    max-width: 100%; }
  .toggle-box .toggle-link {
    position: relative;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold";
    font-size: 14px;
    line-height: 20px;
    padding-right: 15px;
    text-decoration: none; }
    .toggle-box .toggle-link:hover:after, .toggle-box .toggle-link:focus:after {
      width: calc(100% - 15px); }
    .toggle-box .toggle-link:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: #4e2a84;
      transition: .4s; }
    .toggle-box .toggle-link .ico {
      position: absolute;
      top: 2px;
      right: 0;
      width: 10px;
      height: 10px;
      background: url(../images/Down_Arrow5.svg) 50% no-repeat;
      transform: rotate(0deg);
      transition: .4s; }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .answer {
    height: 200px; }
  #search-faq {
    width: 100%; }
    #search-faq input[type="text"] {
      padding: 0 42px 0 22px; } }

#alert {
  display: none;
  position: relative;
  z-index: 9999999;
  width: 100%;
  padding: 0 30px;
  background: #ed3024;
  color: #ffffff;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 28px;
  color: #fff; }
  #alert p {
    color: #fff;
    padding: 10px 40px 10px 0; }
  #alert span {
    font-family: "AkkuratPro-Bold"; }
  #alert .close {
    position: absolute;
    top: 50%;
    right: 30px;
    width: 30px;
    height: 30px;
    background: url(../images/white-close.png) 50% no-repeat;
    transform: translate(0, -50%); }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  #alert {
    padding: 10px 20px; }
  #alert .close {
    right: 20px; } }

.box-office .col-xs-4 {
  background: #4e2a84; }

.box-office .col-xs-8 {
  position: relative; }

.box-office .cnt {
  padding: 100px 65px; }
  .box-office .cnt .title {
    color: #ffffff;
    font-family: "Poppins-Medium";
    font-size: 24px;
    line-height: 32px; }
  .box-office .cnt .location {
    color: #ffffff;
    font-family: "AkkuratPro-Regular";
    font-size: 16px;
    line-height: 28px;
    padding-left: 20px;
    background: url(../images/footer-location.svg) left 7px no-repeat; }
  .box-office .cnt .phone {
    color: #ffffff;
    font-family: "AkkuratPro-Regular";
    font-size: 16px;
    line-height: 28px;
    padding-left: 20px;
    background: url(../images/footer-phone.svg) left 7px no-repeat; }
    .box-office .cnt .phone a {
      color: #ffffff;
      font-family: "AkkuratPro-Regular";
      font-size: 16px;
      line-height: 28px;
      text-decoration: underline; }
      .box-office .cnt .phone a:hover {
        opacity: .5; }
  .box-office .cnt .hours, .box-office .cnt .email {
    color: #ffffff;
    font-family: "AkkuratPro-Bold";
    font-size: 16px;
    line-height: 28px;
    padding-left: 20px;
    margin: 20px 0 0 0;
    background: url(../images/clock-icon.svg) left 7px no-repeat; }
    .box-office .cnt .hours.email, .box-office .cnt .email.email {
      background: url(../images/footer-email.svg) left 11px no-repeat; }
      .box-office .cnt .hours.email a, .box-office .cnt .email.email a {
        color: #fff;
        text-decoration: underline; }
        .box-office .cnt .hours.email a:hover, .box-office .cnt .email.email a:hover {
          opacity: 0.5; }
  .box-office .cnt ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0 20px; }
    .box-office .cnt ul li {
      color: #ffffff;
      font-family: "AkkuratPro-Regular";
      font-size: 16px;
      line-height: 28px;
      margin: 0; }
      .box-office .cnt ul li span {
        float: right; }

.box-office .photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 150px;
  z-index: 2;
  background-size: cover !important; }

#performance-venues .col-xs-6:nth-child(2),
#ticket-office .col-xs-6:first-child {
  padding-right: calc(.5rem + 10px); }

#performance-venues .col-xs-6:last-child,
#ticket-office .col-xs-6:last-child {
  padding-left: calc(.5rem + 10px); }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .box-office .col-xs-4 {
    flex-basis: 50%;
    max-width: 50%; }
  .box-office .col-xs-8 {
    flex-basis: 50%;
    max-width: 50%; } }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  .spaces .col-xs-4 {
    flex-basis: 50%;
    max-width: 50%;
    padding-left: .5rem;
    padding-right: .5rem;
    margin-bottom: 40px; }
  .spaces .col-xs-4:first-child {
    padding-right: .5rem; }
  .spaces .col-xs-4:last-child {
    padding-left: .5rem; }
  .spaces .col-xs-4:last-child {
    margin-bottom: 0; } }

@media only screen and (max-width: 880px) and (min-width: 1px) {
  .box-office .col-xs-4 {
    flex-basis: 60%;
    max-width: 60%; }
  .box-office .col-xs-8 {
    flex-basis: 40%;
    max-width: 40%; }
  #series-slider .col-xs-5 {
    flex-basis: 50%;
    max-width: 50%; }
  #series-slider .col-xs-7 {
    flex-basis: 50%;
    max-width: 50%; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .box-office .col-xs-4 {
    flex-basis: 100%;
    max-width: 100%;
    order: 1; }
  .box-office .col-xs-8 {
    flex-basis: 100%;
    max-width: 100%;
    order: 0; }
  .box-office .photo {
    display: none;
    position: relative;
    height: 200px;
    min-height: 200px; }
  #series-slider .col-xs-5 {
    flex-basis: 100%;
    max-width: 100%; }
  #series-slider .col-xs-7 {
    flex-basis: 100%;
    max-width: 100%; }
  #series-slider {
    height: auto; }
  #series-slider .series-photos {
    position: relative;
    height: 350px; }
  #series-slider .series-photos .photo {
    height: 350px; }
  #performance-venues .col-xs-6:nth-child(2),
  #ticket-office .col-xs-6:first-child {
    padding-right: .5rem; }
  #performance-venues .col-xs-6:last-child,
  #ticket-office .col-xs-6:last-child {
    padding-left: .5rem; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .box-office .photo {
    min-height: 300px; }
  .box-office .cnt {
    padding: 40px; }
  .box-office .reverse-btn {
    min-width: 100%; }
  #series-slider .series-photos,
  #series-slider .series-photos .photo {
    height: 250px; }
  #series-slider .series-photos .photo {
    background-attachment: scroll !important; }
  #series-slider .swiper-slide {
    padding: 40px 40px 80px 40px; }
  #series-slider .swiper-slide .event-title {
    font-size: 24px;
    line-height: 32px; }
  .spaces .col-xs-4 {
    flex-basis: 100%;
    max-width: 100%; } }

#area-of-study {
  max-width: 1240px; }
  #area-of-study .degrees {
    padding-left: 100px; }
    #area-of-study .degrees .title {
      color: #507796;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      text-transform: uppercase; }
    #area-of-study .degrees ul {
      list-style: none;
      padding: 0;
      margin: 20px 0; }
      #area-of-study .degrees ul li {
        margin: 10px 0; }
        #area-of-study .degrees ul li a {
          position: relative;
          color: #4e2a84;
          font-family: "AkkuratPro-Bold";
          font-size: 16px;
          line-height: 24px; }

#studies {
  margin: auto;
  padding: 100px 0 50px 0; }
  #studies .studies-box .photo-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 230px;
    overflow: hidden; }
  #studies .studies-box .photo {
    display: block;
    position: relative;
    width: 100%;
    height: 230px;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s; }
    #studies .studies-box .photo:hover, #studies .studies-box .photo:focus {
      transform: scale(1.1); }
    #studies .studies-box .photo a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  #studies .studies-box .study-title {
    color: #000000;
    font-family: "Poppins-Medium";
    font-size: 24px;
    line-height: 32px;
    margin: 30px 0 20px 0; }
    #studies .studies-box .study-title a {
      position: relative;
      padding-bottom: 10px;
      color: #000000;
      text-decoration: none; }
      #studies .studies-box .study-title a:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 0;
        height: 3px;
        background: #4e2a84;
        transition: .4s; }
      #studies .studies-box .study-title a:hover, #studies .studies-box .study-title a:focus {
        color: #4e2a84; }
        #studies .studies-box .study-title a:hover:after, #studies .studies-box .study-title a:focus:after {
          width: 100%; }
  #studies .studies-box.is_hover .photo {
    transform: scale(1.1); }
  #studies .studies-box.is_hover .study-title a {
    color: #4e2a84; }
  #studies .studies-box.is_hover .study-title a:after {
    width: 100%; }
  #studies .studies-box .toggle-btns {
    max-width: 100%;
    margin-bottom: 40px; }

#directory a {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word; }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  #studies {
    margin: 40px auto 0 auto;
    padding: 100px 0; } }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  #area-of-study {
    padding: 0 50px; }
  #area-of-study .degrees {
    padding-left: 50px; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  #area-of-study .degrees {
    padding: 50px 0 0 0; }
  #studies {
    padding: 30px 50px; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  #studies .studies-box .study-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700; }
  #studies {
    padding: 30px 20px; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  .box-office .cnt ul li span {
    width: 100%;
    margin-bottom: 20px; }
  .box-office .cnt .hours {
    display: inline-block; } }

.select-study {
  display: block;
  font-size: 0; }
  .select-study.top-layer {
    z-index: 999; }
  .select-study form {
    display: inline-block;
    width: calc(65% + 5px);
    margin: 0;
    vertical-align: top; }
    .select-study form .cs-select span {
      transition: .4s; }
    .select-study form .cs-select li span {
      padding: 5px 22px !important; }
    .select-study form .cs-select > span:hover, .select-study form .cs-select > span:focus {
      background: #3f2367;
      color: #fff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.6); }
  .select-study form.no-labels .cs-select span {
    padding: 17px 22px; }
  .select-study .btn {
    width: calc(30% - 40px);
    margin: 10px 0;
    vertical-align: top;
    color: #000000;
    font-family: "AkkuratPro-Bold";
    font-size: 18px;
    line-height: 28px;
    border-radius: 2px;
    text-align: left;
    border: 1px solid #e0e7ed;
    background-color: #f2f5f8; }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .select-study .btn {
    min-width: 200px;
    width: 200px;
    font-size: 14px; }
  .select-study form {
    width: calc(100% - 200px); } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .select-study form {
    width: 100%; }
  .select-study .btn {
    width: 100%; } }

#home-events > .row > .col-xs-8 {
  border-right: 1px solid #aec1d0; }

#home-events .event-sidebar {
  padding: 100px 50px 0 50px; }
  #home-events .event-sidebar .btn {
    min-width: 100%;
    max-width: 100%; }

#home-events .event {
  display: block;
  width: 100%;
  padding: 20px 0;
  margin: 0; }
  #home-events .event .thumb-wrap {
    display: block;
    width: 100%;
    max-width: 120px;
    height: 120px;
    overflow: hidden; }
    #home-events .event .thumb-wrap:hover .thumb, #home-events .event .thumb-wrap:focus .thumb {
      transform: scale(1.1); }
  #home-events .event .thumb {
    display: block;
    width: 100%;
    max-width: 120px;
    height: 120px;
    background-size: cover !important;
    transform: scale(1); }
  #home-events .event .date {
    display: inline-block;
    width: 100%;
    color: #819eb5;
    font-family: "AkkuratPro-Bold";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: left;
    margin: 0; }
  #home-events .event .event-title {
    margin: 5px 0; }
  #home-events .event .event-title {
    display: inline-block;
    width: 100%;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold";
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    text-decoration: none; }
  #home-events .event .location {
    display: inline-block;
    width: 100%;
    color: #819eb5;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 20px;
    padding-left: 20px;
    background: url(../images/location-icon.svg) left center no-repeat;
    text-align: left;
    margin: 0; }
  #home-events .event .row.is_hover .thumb {
    transform: scale(1.1); }
  #home-events .event .row.is_hover .event-title span {
    background-size: 100% 3px; }

#calendar {
  display: inline-block;
  position: relative;
  margin-bottom: 80px;
  width: 100%; }
  #calendar .fc-toolbar.fc-header-toolbar {
    display: block; }
  #calendar .fc-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 80px;
    background-color: #f2f5f8; }
  #calendar .fc-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 80px;
    background-color: #f2f5f8; }
    #calendar .fc-right .fc-button-group {
      width: 100%;
      text-align: right;
      margin-top: 20px; }
    #calendar .fc-right .btn {
      float: none;
      min-width: 170px;
      max-width: 170px;
      border-radius: 0;
      padding: 5px 20px;
      height: auto;
      margin-right: 20px;
      text-shadow: none;
      box-shadow: none;
      background-image: none;
      background: #f6f4f9;
      color: #4e2a84;
      transition: background .4s; }
      #calendar .fc-right .btn:hover, #calendar .fc-right .btn:focus {
        background: #4e2a84;
        color: #fff; }
    #calendar .fc-right .fc-state-active {
      background: #4e2a84;
      color: #fff; }
  #calendar #calendar-search {
    display: block;
    position: relative;
    margin: 20px 0 0 20px;
    min-width: 400px; }
    #calendar #calendar-search input[type="text"] {
      border-radius: 2px;
      border: 1px solid #aec1d0;
      background-color: #ffffff;
      max-width: 400px;
      line-height: 40px;
      margin: 0; }
    #calendar #calendar-search input[type="submit"] {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      min-width: 40px;
      border: none;
      margin: 0;
      text-indent: -9999px;
      background: url(../images/calendar-search-icon.svg) 50% no-repeat; }
  #calendar .fc-center {
    position: absolute;
    width: 100%;
    top: 80px;
    left: 0;
    padding: 50px 150px 0 150px; }
    #calendar .fc-center .fc-button-group {
      margin-top: 20px; }
      #calendar .fc-center .fc-button-group .fc-prev-button {
        background: transparent;
        border: none;
        box-shadow: none; }
        #calendar .fc-center .fc-button-group .fc-prev-button span {
          display: block;
          width: 20px;
          height: 20px;
          background: url(../images/purple-arrow.svg) 50% no-repeat;
          transform: rotate(90deg); }
          #calendar .fc-center .fc-button-group .fc-prev-button span:after {
            display: none; }
      #calendar .fc-center .fc-button-group .fc-next-button {
        position: relative;
        background: transparent;
        border: none;
        box-shadow: none; }
        #calendar .fc-center .fc-button-group .fc-next-button:before {
          position: absolute;
          content: '';
          top: -4px;
          left: 0;
          width: 2px;
          height: 40px;
          background: #a18fbc; }
        #calendar .fc-center .fc-button-group .fc-next-button span {
          display: block;
          width: 20px;
          height: 20px;
          background: url(../images/purple-arrow.svg) 50% no-repeat;
          transform: rotate(-90deg); }
          #calendar .fc-center .fc-button-group .fc-next-button span:after {
            display: none; }
    #calendar .fc-center h2 {
      width: auto;
      color: #000000;
      font-family: "PeriodicoDisplay-XtLg";
      font-size: 52px;
      letter-spacing: -1px;
      line-height: 62px;
      margin-left: 20px; }
  #calendar .fc-view-container {
    padding-top: 200px;
    padding-left: 150px;
    padding-right: 150px; }
  #calendar ul#filters {
    display: inline-block;
    list-style: none;
    width: 55%;
    float: right;
    padding: 0;
    margin: 0;
    position: relative;
    top: 20px;
    text-align: right; }
    #calendar ul#filters .filter-title {
      display: none;
      color: #000000;
      font-family: "Poppins-Medium";
      font-size: 32px;
      letter-spacing: -1px;
      line-height: 42px;
      text-transform: none; }
    #calendar ul#filters li {
      display: inline-block;
      vertical-align: top;
      width: auto;
      color: #507796;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 50px;
      text-transform: uppercase;
      text-align: left;
      margin-right: 20px; }
      #calendar ul#filters li:first-child {
        padding-right: 20px; }
      #calendar ul#filters li label {
        margin-top: 0 !important;
        color: #4f7796;
        font-family: "AkkuratPro-Regular";
        font-size: 14px;
        line-height: 20px; }
      #calendar ul#filters li input[type=checkbox] {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100%;
        -webkit-appearance: none; }
      #calendar ul#filters li #festivals-series {
        background-image: none;
        background-color: transparent;
        border: 3px solid #f1951c; }
      #calendar ul#filters li #recitals-visiting-artists, #calendar ul#filters li #recitals-lectures {
        background-image: none;
        background-color: transparent;
        border: 3px solid #4a90e2; }
      #calendar ul#filters li #bienen-ensembles {
        background-image: none;
        background-color: transparent;
        border: 3px solid #bd10e0; }
      #calendar ul#filters li #student-recitals {
        background-image: none;
        background-color: transparent;
        border: 3px solid #79cc19; }
      #calendar ul#filters li #festivals-series:checked {
        background-image: none;
        background-color: #f1951c;
        border: 3px solid #f1951c; }
      #calendar ul#filters li #recitals-visiting-artists:checked, #calendar ul#filters li #recitals-lectures:checked {
        background-image: none;
        background-color: #4a90e2;
        border: 3px solid #4a90e2; }
      #calendar ul#filters li #bienen-ensembles:checked {
        background-image: none;
        background-color: #bd10e0;
        border: 3px solid #bd10e0; }
      #calendar ul#filters li #student-recitals:checked {
        background-image: none;
        background-color: #79cc19;
        border: 3px solid #79cc19; }
      #calendar ul#filters li input[type=checkbox] + label:before {
        display: none;
        border-radius: 20px;
        top: 50%;
        width: 20px;
        height: 20px;
        border: 3px solid #819eb5;
        transform: translate(0, -50%); }
      #calendar ul#filters li input[type=checkbox]:checked + label.bienen-ensembles:before {
        content: '';
        background-image: none;
        background-color: #bd10e0;
        border: 3px solid #bd10e0; }
      #calendar ul#filters li input[type=checkbox]:checked + label.festivals-series:before {
        content: '';
        background-image: none;
        background-color: #f1951c;
        border: 3px solid #f1951c; }
      #calendar ul#filters li input[type=checkbox]:checked + label.recitals-lectures:before,
      #calendar ul#filters li input[type=checkbox]:checked + label.recitals-visiting-artists:before {
        content: '';
        background-image: none;
        background-color: #4a90e2;
        border: 3px solid #4a90e2; }
      #calendar ul#filters li input[type=checkbox]:checked + label.student-recitals:before {
        content: '';
        background-image: none;
        background-color: #79cc19;
        border: 3px solid #79cc19; }
  #calendar .fc-row {
    border: none;
    min-height: 0; }
  #calendar .fc-head-container {
    border-top: 1px solid #aec1d0;
    border-bottom: 5px solid #e0e7ed;
    border-left: none;
    border-right: none; }
    #calendar .fc-head-container th {
      border: none;
      color: #000000;
      font-family: "AkkuratPro-Bold";
      font-size: 18px;
      line-height: 26px;
      padding: 25px 0;
      text-align: left; }
  #calendar .fc-widget-content {
    border: none; }
  #calendar .fc-row td {
    text-align: left;
    color: #000000;
    font-family: "PeriodicoDisplay-XtLg";
    font-size: 52px;
    line-height: 62px;
    border: none; }
    #calendar .fc-row td span {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      padding: 0;
      margin: 40px auto 0 auto;
      float: none;
      z-index: 1; }
  #calendar .fc-scroller {
    overflow: visible !important;
    height: auto !important; }
  #calendar .fc-today {
    background: transparent; }
  #calendar .fc-event {
    border: none;
    background: transparent; }
  #calendar .fc-event span {
    background: #e0e7ed;
    border-radius: 30px;
    color: #fff; }
  #calendar .fc-event .fc-content {
    text-align: left;
    margin-bottom: 20px; }
    #calendar .fc-event .fc-content .fc-time {
      display: inline;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 20px;
      margin: 0 5px 0 0;
      width: auto;
      background: transparent;
      vertical-align: top; }
    #calendar .fc-event .fc-content .fc-title {
      display: inline;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      width: auto;
      background: transparent;
      color: #3f6384;
      white-space: pre-wrap;
      vertical-align: top;
      text-align: left; }
  #calendar .fc-content-skeleton tbody td {
    font-size: 12px; }
  #calendar .fc-event-container {
    position: relative; }
    #calendar .fc-event-container .past-event {
      opacity: .7; }
  #calendar .bienen-ensembles .fc-time, #calendar .violet .fc-time {
    color: #ac00d9; }
  #calendar .festivals-series .fc-time, #calendar .yellow .fc-time {
    color: #f1951c; }
  #calendar .recitals-lectures .fc-time, #calendar .recitals-visiting-artists .fc-time, #calendar .blue .fc-time {
    color: #4a90e2; }
  #calendar .student-recitals .fc-time, #calendar .green .fc-time {
    color: #79cc19; }
  #calendar .fc-bg .tooltip-wrapper,
  #calendar .fc-bg .fc-day-number {
    display: none; }
  #calendar .fc-content-skeleton .has-events {
    position: relative; }
  #calendar .fc-content-skeleton .has-events .tooltip-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    width: 300px;
    height: auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    z-index: 9999; }
    #calendar .fc-content-skeleton .has-events .tooltip-wrapper .fc-day-number {
      display: none; }
  #calendar .fc-content-skeleton .has-events:hover .tooltip-wrapper,
  #calendar .fc-content-skeleton .has-events:focus .tooltip-wrapper {
    display: block; }
  #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip {
    display: inline-block;
    max-width: 100%;
    width: 100%;
    text-align: left;
    line-height: 16px; }
    #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date {
      display: inline;
      width: auto;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      padding-right: 10px;
      text-transform: uppercase; }
      #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.bienen-ensembles, #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.violet {
        color: #ac00d9; }
      #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.festivals-series, #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.yellow {
        color: #f1951c; }
      #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.recitals-visiting-artists, #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.blue, #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.recitals-lectures {
        color: #4a90e2; }
      #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.student-recitals, #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.green {
        color: #79cc19; }
    #calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip a.title {
      display: inline;
      width: auto;
      color: #4f7796;
      font-size: 14px;
      line-height: 20px; }

#mini-calendar {
  display: block;
  position: relative;
  z-index: 999;
  max-width: 295px;
  margin-bottom: 50px; }
  #mini-calendar .fc-left {
    position: relative;
    width: 10px;
    height: 10px;
    padding: 0;
    background: transparent; }
    #mini-calendar .fc-left .fc-prev-button {
      background: none;
      border: none;
      box-shadow: none;
      padding: 0; }
      #mini-calendar .fc-left .fc-prev-button span {
        display: block;
        width: 10px;
        height: 10px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        transform: rotate(90deg);
        background-size: 100%; }
        #mini-calendar .fc-left .fc-prev-button span:after {
          display: none; }
  #mini-calendar .fc-right {
    position: relative;
    width: 10px;
    height: 10px;
    padding: 0;
    background: transparent; }
    #mini-calendar .fc-right .fc-next-button {
      background: none;
      border: none;
      box-shadow: none;
      padding: 0; }
      #mini-calendar .fc-right .fc-next-button span {
        display: block;
        width: 10px;
        height: 10px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        transform: rotate(-90deg);
        background-size: 100%; }
        #mini-calendar .fc-right .fc-next-button span:after {
          display: none; }
  #mini-calendar .fc-center {
    position: relative;
    top: inherit;
    padding: 0;
    width: auto; }
    #mini-calendar .fc-center h2 {
      color: #000000;
      font-family: "AkkuratPro-Bold";
      font-size: 18px;
      line-height: 26px;
      margin: 0; }
  #mini-calendar .fc-view-container {
    padding-top: 0 !important;
    padding-left: 0;
    padding-right: 0; }
  #mini-calendar .fc-toolbar.fc-header-toolbar {
    margin: 0; }
  #mini-calendar .fc-row {
    border: none;
    min-height: 0; }
  #mini-calendar .fc-head-container {
    border-top: 1px solid #aec1d0;
    border-bottom: 5px solid #e0e7ed;
    border-left: none;
    border-right: none; }
    #mini-calendar .fc-head-container th {
      border: none;
      color: #4f7796;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 20px;
      padding: 10px 0; }
  #mini-calendar .fc-widget-content {
    border: none; }
  #mini-calendar .fc-row td {
    text-align: center;
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 30px;
    border: none; }
    #mini-calendar .fc-row td span {
      display: block;
      position: relative;
      width: 30px;
      height: 30px;
      padding: 0;
      margin: 0 auto;
      float: none;
      z-index: 1; }
  #mini-calendar .fc-scroller {
    overflow: visible !important;
    height: auto !important; }
  #mini-calendar .fc-today {
    background: transparent; }
  #mini-calendar .fc-day-grid-event {
    background: #e0e7ed;
    border-radius: 30px; }
  #mini-calendar .fc-event {
    border: none;
    background: transparent; }
  #mini-calendar .fc-event span {
    background: #e0e7ed;
    border-radius: 30px;
    color: #fff; }
  #mini-calendar .fc-event .fc-content {
    display: none; }
  #mini-calendar .fc-event-container a:hover .fc-content,
  #mini-calendar .fc-event-container a:focus .fc-content {
    display: block; }
  #mini-calendar .fc-content-skeleton tbody {
    display: none; }
  #mini-calendar .fc-content-skeleton td span {
    border-radius: 30px;
    background-color: transparent;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 2px solid transparent; }
  #mini-calendar .fc-content-skeleton .has-events span {
    border-radius: 30px;
    background-color: #e0e7ed;
    cursor: pointer;
    width: 30px;
    height: 30px;
    transition: .4s; }
    #mini-calendar .fc-content-skeleton .has-events span:hover, #mini-calendar .fc-content-skeleton .has-events span:focus, #mini-calendar .fc-content-skeleton .has-events span.active {
      background: #4e2a84;
      color: #fff; }
  #mini-calendar .fc-bg .tooltip-wrapper,
  #mini-calendar .fc-bg .fc-day-number {
    display: none; }
  #mini-calendar .fc-content-skeleton .has-events {
    position: relative; }
  #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    width: 300px;
    height: auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    z-index: 9999; }
    #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .fc-day-number {
      display: none; }
  #mini-calendar .fc-content-skeleton .has-events:hover .tooltip-wrapper,
  #mini-calendar .fc-content-skeleton .has-events:focus .tooltip-wrapper {
    display: block; }
  #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip {
    display: inline-block;
    max-width: 100%;
    width: 100%;
    text-align: left;
    line-height: 16px; }
    #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date {
      display: inline;
      width: auto;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      padding-right: 10px;
      text-transform: uppercase; }
      #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.bienen-ensembles, #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.violet {
        color: #ac00d9; }
      #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.festivals-series, #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.yellow {
        color: #f1951c; }
      #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.recitals-visiting-artists, #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.blue, #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.recitals-lectures {
        color: #4a90e2; }
      #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.student-recitals, #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.green {
        color: #79cc19; }
    #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip a.title {
      display: inline;
      width: auto;
      color: #4f7796;
      font-size: 14px;
      line-height: 20px; }
  #mini-calendar .fc-content-skeleton .has-future-events span {
    border-radius: 30px;
    background-color: #e0e7ed;
    cursor: pointer;
    width: 30px;
    height: 30px; }
  #mini-calendar .fc-today span {
    border: 2px solid #4e2a84 !important;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold"; }
  #mini-calendar .fc-event-container a {
    display: block;
    width: 30px;
    height: 30px;
    margin: 5px auto;
    background: #e0e7ed;
    border-radius: 30px; }

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em; }

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(63, 35, 103, 0.2);
  border-right: 1.1em solid rgba(63, 35, 103, 0.2);
  border-bottom: 1.1em solid rgba(63, 35, 103, 0.2);
  border-left: 1.1em solid #3f2367;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.no-events {
  display: none;
  width: 100%;
  padding: 0 150px 0 0;
  text-align: center;
  font-size: 16px;
  color: #819eb5;
  font-family: "AkkuratPro-Bold"; }

.list-view {
  position: relative; }
  .list-view .col-xs-4 {
    position: absolute;
    top: 220px;
    left: 150px;
    z-index: 99; }
  .list-view .col-xs-8 {
    flex-basis: 100%;
    max-width: 100%; }
  .list-view .end-xs .col-xs-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%; }
  .list-view .list-events {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
    margin-left: 525px;
    padding-left: .5rem;
    padding-right: .5rem;
    margin-right: 150px; }
  .list-view .fc-view-container {
    padding-top: 0; }
  .list-view .fc-toolbar {
    padding-top: 150px;
    padding-left: 150px;
    padding-right: 150px;
    text-align: right; }
  .list-view .fc-center {
    position: relative;
    top: 0;
    right: 0;
    left: inherit;
    padding: 0;
    width: 66.6666666667%; }
  .list-view .fc-center .fc-button-group,
  .list-view .fc-center h2 {
    display: none; }
  .list-view aside {
    margin-top: -60px; }
  .list-view #mini-calendar .fc-center h2 {
    display: inline-block; }
  .list-view #mini-calendar .fc-toolbar {
    padding: 0;
    text-align: center; }
  .list-view #mini-calendar .fc-center {
    width: auto;
    text-align: center; }
  .list-view #filters {
    width: 70% !important; }
  .list-view ul#filters .filter-title {
    display: inline-block !important;
    float: left; }
  .list-view .col-xs-12:first-child .fc-button-group,
  .list-view .col-xs-12:first-child h2 {
    display: none; }
  .list-view ul#filters {
    width: calc(100% - 385px) !important;
    top: inherit;
    float: none;
    text-align: left;
    margin: 0 0 40px 0;
    padding: 0 0 20px 0;
    border-bottom: 5px solid #e0e7ed; }

.month-view .col-xs-4 {
  display: none; }

.month-view .col-xs-8 {
  flex-basis: 100%;
  max-width: 100%; }

.month-view #filters {
  width: 60%; }

.month-view ul#filters .filter-title {
  display: none; }

.month-view .fc-center .fc-button-group {
  display: inline-block; }

.month-view .fc-center h2 {
  display: inline-block; }

.month-view .fc-widget-content {
  height: auto !important; }

#listDay .col-xs-8 {
  position: relative; }

#aside-filters {
  max-width: 295px;
  padding: 25px 20px; }
  #aside-filters p {
    color: #507796;
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase; }
  #aside-filters ul {
    list-style: none;
    padding: 0;
    margin: 30px 0 0 0;
    opacity: .6; }
    #aside-filters ul li {
      position: relative; }
      #aside-filters ul li input {
        z-index: 999;
        left: 0;
        -webkit-appearance: none;
        transition: 0s; }
      #aside-filters ul li ul {
        padding-left: 30px;
        margin: 0; }
      #aside-filters ul li.parent label {
        position: relative;
        font-family: "AkkuratPro-Bold"; }
        #aside-filters ul li.parent label .ico {
          position: absolute;
          top: 50%;
          right: 0;
          width: 20px;
          height: 20px;
          cursor: pointer;
          background: url(../images/purple-arrow.svg) 50% no-repeat;
          transform: translate(0, -50%);
          transition: .4s; }
      #aside-filters ul li.parent ul li label {
        font-family: "AkkuratPro-Regular"; }
      #aside-filters ul li.parent.open .ico {
        transform: translate(0, -50%) rotate(180deg); }
    #aside-filters ul.checked {
      opacity: 1; }
      #aside-filters ul.checked ul {
        opacity: 1; }

.event {
  position: relative;
  margin-bottom: 40px; }
  .event .thumb-wrap {
    position: relative;
    width: 100%;
    height: 230px;
    overflow: hidden; }
    .event .thumb-wrap span {
      position: absolute;
      bottom: 0;
      left: 0;
      width: auto;
      height: auto;
      padding: 8px 10px 5px 10px;
      color: #ffffff;
      z-index: 9;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 4px;
      text-transform: uppercase; }
      .event .thumb-wrap span.violet, .event .thumb-wrap span.bienen-ensembles {
        background-color: #bd10e0; }
      .event .thumb-wrap span.blue, .event .thumb-wrap span.recitals-visiting-artists, .event .thumb-wrap span.recitals-lectures {
        background-color: #4e93ce; }
      .event .thumb-wrap span.yellow, .event .thumb-wrap span.festivals-series {
        background-color: #f1951c; }
      .event .thumb-wrap span.green, .event .thumb-wrap span.student-recitals {
        background-color: #79cc19; }
    .event .thumb-wrap:hover .thumb, .event .thumb-wrap:focus .thumb {
      transform: scale(1.1); }
  .event .thumb {
    position: relative;
    width: 100%;
    height: 230px;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s; }
    .event .thumb a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .event .cnt {
    padding: 0 20px; }
  .event .date {
    color: #819eb5;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0; }
  .event .event-title {
    color: #4e2a84;
    font-family: "Poppins-Medium";
    font-size: 24px;
    line-height: 32px;
    margin: 10px 0; }
  .event .location {
    display: inline-block;
    width: 100%;
    color: #819eb5;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 20px;
    padding-left: 20px;
    background: url(../images/location-icon.svg) left center no-repeat;
    text-align: left;
    margin: 0; }
  .event .event-btns {
    margin: 20px 0 0 0;
    font-size: 0;
    text-align: left; }
    .event .event-btns a {
      vertical-align: top;
      width: calc(50% - 50px);
      min-width: 140px;
      height: 40px;
      padding: 0 20px;
      line-height: 40px; }
    .event .event-btns .purple-btn {
      margin-right: 5px; }
      .event .event-btns .purple-btn:hover, .event .event-btns .purple-btn:focus {
        box-shadow: none !important; }
    .event .event-btns .btn {
      margin-left: 5px; }
  .event .row.is_hover .thumb {
    transform: scale(1.1); }
  .event .row.is_hover .event-title span {
    background-size: 100% 3px; }

.past-events .col-xs-4 {
  padding-left: calc(.5rem + 10px);
  padding-right: calc(.5rem + 10px); }

.past-events .col-xs-4:first-child {
  padding-left: .5rem;
  padding-right: calc(.5rem + 10px); }

.past-events .col-xs-4:last-child {
  padding-left: calc(.5rem + 10px);
  padding-right: .5rem; }

.past-events .thumb-wrap {
  position: relative;
  width: 100%;
  height: 165px;
  overflow: hidden; }
  .past-events .thumb-wrap span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
    padding: 8px 10px 5px 10px;
    color: #ffffff;
    z-index: 9;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 4px;
    text-transform: uppercase; }
    .past-events .thumb-wrap span.violet, .past-events .thumb-wrap span.bienen-ensembles {
      background-color: #bd10e0; }
    .past-events .thumb-wrap span.blue, .past-events .thumb-wrap span.recitals-visiting-artists, .past-events .thumb-wrap span.recitals-lectures {
      background-color: #4e93ce; }
    .past-events .thumb-wrap span.yellow, .past-events .thumb-wrap span.festivals-series {
      background-color: #f1951c; }
    .past-events .thumb-wrap span.green, .past-events .thumb-wrap span.student-recitals {
      background-color: #79cc19; }

.past-events .thumb {
  position: relative;
  width: 100%;
  height: 165px;
  background-size: cover !important;
  transform: scale(1);
  transition: .4s; }
  .past-events .thumb a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.past-events .date {
  color: #819eb5;
  font-family: "AkkuratPro-Regular";
  font-size: 12px;
  letter-spacing: 3px;
  line-height: 16px;
  text-transform: uppercase;
  margin: 20px 0 0 0; }

.past-events .title {
  color: #4e2a84;
  font-family: "AkkuratPro-Bold";
  font-size: 18px;
  line-height: 26px;
  margin: 10px 0; }

.past-events .location {
  display: inline-block;
  width: 100%;
  color: #819eb5;
  font-family: "AkkuratPro-Regular";
  font-size: 14px;
  line-height: 20px;
  padding-left: 20px;
  background: url(../images/location-icon.svg) left center no-repeat;
  text-align: left;
  margin: 0 0 20px 0; }

.past-events .col-xs-4.is_hover .thumb {
  transform: scale(1.1); }

.past-events .col-xs-4.is_hover .title span {
  background-size: 100% 3px; }

.share {
  display: inline-block;
  position: absolute;
  top: -50px;
  right: 20px;
  padding: 8px 15px;
  background-color: #e0e7ed;
  border-radius: 3px;
  text-align: center; }
  .share li {
    display: inline-block;
    width: auto;
    margin: 0 2px;
    color: #507796;
    font-family: "AkkuratPro-Bold";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    vertical-align: middle;
    text-transform: uppercase;
    transition: .4s; }
    .share li span {
      position: relative;
      top: 1px; }
    .share li a {
      display: block;
      width: 20px;
      height: 16px;
      text-indent: -9999px; }
    .share li.facebook {
      background: url(../images/facebook-share.svg) 50% no-repeat; }
    .share li.twitter {
      background: url(../images/twitter-share.svg) 50% no-repeat; }
    .share li.linkedin {
      background: url(../images/linkedin-share.svg) 50% no-repeat; }
    .share li.google {
      background: url(../images/google-share.svg) 50% no-repeat; }
    .share li.facebook:hover, .share li.facebook:focus {
      background: url(../images/facebook-share-purple.svg) 50% no-repeat; }
    .share li.twitter:hover, .share li.twitter:focus {
      background: url(../images/twitter-share-purple.svg) 50% no-repeat; }
    .share li.linkedin:hover, .share li.linkedin:focus {
      background: url(../images/linkedin-share-purple.svg) 50% no-repeat; }
    .share li.google:hover, .share li.google:focus {
      background: url(../images/google-share-purple.svg) 50% no-repeat; }

.event-category {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  padding: 8px 10px 5px 10px;
  color: #ffffff;
  z-index: 9;
  font-family: "AkkuratPro-Regular";
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 4px;
  text-transform: uppercase; }
  .event-category.bienen-ensembles, .event-category.violet {
    background-color: #ac00d9; }
  .event-category.festivals-series, .event-category.yellow {
    background-color: #f1951c; }
  .event-category.recitals-visiting-artists, .event-category.blue, .event-category.recitals-lectures {
    background-color: #4a90e2; }
  .event-category.student-recitals, .event-category.green {
    background-color: #79cc19; }
  .event-category.free-event {
    background-color: #3f2367;
    font-weight: bold; }

#event-single-calendar {
  display: block;
  margin: 80px 0 60px 0; }
  #event-single-calendar .fc-prev-button {
    background: transparent;
    border: none;
    box-shadow: none; }
    #event-single-calendar .fc-prev-button span {
      display: block;
      width: 20px;
      height: 20px;
      background: url(../images/purple-arrow.svg) 50% no-repeat;
      transform: rotate(90deg); }
      #event-single-calendar .fc-prev-button span:after {
        display: none; }
  #event-single-calendar .fc-next-button {
    background: transparent;
    border: none;
    box-shadow: none; }
    #event-single-calendar .fc-next-button span {
      display: block;
      width: 20px;
      height: 20px;
      background: url(../images/purple-arrow.svg) 50% no-repeat;
      transform: rotate(-90deg); }
      #event-single-calendar .fc-next-button span:after {
        display: none; }
  #event-single-calendar .fc-center h2 {
    color: #000000;
    font-family: "AkkuratPro-Bold";
    font-size: 18px;
    line-height: 35px; }
  #event-single-calendar .fc-toolbar.fc-header-toolbar {
    margin: 0; }
  #event-single-calendar .fc-row {
    border: none;
    min-height: 0; }
  #event-single-calendar .fc-head-container {
    border-top: 1px solid #aec1d0;
    border-bottom: 5px solid #e0e7ed;
    border-left: none;
    border-right: none; }
    #event-single-calendar .fc-head-container th {
      border: none;
      color: #4f7796;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 20px;
      padding: 10px 0; }
  #event-single-calendar .fc-widget-content {
    border: none; }
  #event-single-calendar .fc-row td {
    text-align: center;
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 30px;
    border: none; }
    #event-single-calendar .fc-row td span {
      display: block;
      position: relative;
      width: 30px;
      height: 30px;
      padding: 0;
      margin: 0 auto;
      float: none;
      z-index: 1;
      border: 2px solid transparent; }
  #event-single-calendar .has-events span {
    border-radius: 30px;
    background-color: #e0e7ed;
    cursor: pointer;
    width: 30px;
    height: 30px;
    transition: .4s; }
    #event-single-calendar .has-events span:hover, #event-single-calendar .has-events span:focus, #event-single-calendar .has-events span.active {
      background: #4e2a84;
      color: #fff; }
  #event-single-calendar .fc-scroller {
    overflow: visible !important;
    height: auto !important; }
  #event-single-calendar .fc-today {
    background: transparent; }
  #event-single-calendar .fc-today span {
    border: 2px solid #4e2a84 !important;
    border-radius: 30px;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold"; }
  #event-single-calendar .fc-event {
    border: none;
    background: transparent; }
  #event-single-calendar .fc-event span {
    background: #e0e7ed;
    border-radius: 30px;
    color: #fff; }
  #event-single-calendar .fc-event .fc-content .fc-time,
  #event-single-calendar .fc-event .fc-content .fc-title {
    display: none; }
  #event-single-calendar .fc-event-container {
    position: relative; }
  #event-single-calendar .fc-event-container a {
    position: absolute;
    z-index: 9;
    background: transparent;
    color: #fff;
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    left: 50%;
    top: -31px;
    transform: translate(-50%, 0); }
  #event-single-calendar .fc-content-skeleton .has-events span:hover,
  #event-single-calendar .fc-content-skeleton .has-events span:focus {
    background: #4e2a84;
    color: #fff; }

.event-details {
  padding: 80px 0 0 40px; }
  .event-details.hidden {
    display: none;
    visibility: hidden; }
  .event-details .location {
    display: inline-block;
    width: 100%;
    color: #819eb5;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 20px;
    padding-left: 20px;
    background: url(../images/location-icon.svg) left center no-repeat;
    text-align: left;
    margin: 10px 0 0 0; }
  .event-details ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0; }
    .event-details ul li span {
      color: #4e2a84;
      font-family: "AkkuratPro-Bold"; }

span.tag-label, h4.tags-header {
  display: inline-block;
  position: relative;
  width: auto;
  font-family: "AkkuratPro-Bold";
  font-size: 12px;
  letter-spacing: 3px;
  line-height: 16px;
  color: #507796;
  text-transform: uppercase;
  padding-right: 4px; }

.tags {
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0 !important; }
  .tags li {
    display: inline-block;
    position: relative;
    width: auto;
    font-family: "AkkuratPro-Bold";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    color: #507796;
    text-transform: uppercase;
    padding-right: 4px; }
    .tags li:after {
      position: absolute;
      content: ',';
      bottom: 0;
      right: -2px; }
    .tags li:last-child:after {
      display: none; }
    .tags li a {
      color: #4e2a84; }

.more-dates {
  list-style: none;
  padding: 0;
  margin: 20px 0; }
  .more-dates li {
    color: #507796;
    font-family: "AkkuratPro-Bold";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase; }
    .more-dates li:first-child {
      margin-bottom: 10px; }
    .more-dates li a {
      color: #4e2a84;
      font-family: "AkkuratPro-Bold";
      font-size: 16px;
      line-height: 24px;
      text-transform: none;
      letter-spacing: 0; }

.event-location .photo {
  display: block;
  width: 100%;
  height: 290px;
  margin: 30px 0 0 0;
  background-size: cover !important; }

@media only screen and (max-width: 1500px) and (min-width: 1px) {
  .list-view .col-xs-4 {
    left: 50px; }
  .list-view .list-events {
    margin-right: 50px; }
  .list-view .list-events {
    margin-left: 425px; }
  .list-view #calendar ul#filters {
    width: calc(100% - 285px) !important; }
  .list-view #calendar .fc-center {
    padding: 50px 50px 0 150px; }
  .no-events {
    padding: 0 50px 0 0; } }

@media only screen and (max-width: 1420px) and (min-width: 1px) {
  .list-view #calendar ul#filters li.filter-title {
    width: 100% !important; }
  .list-view #calendar {
    margin-bottom: 130px; } }

@media only screen and (max-width: 1360px) and (min-width: 1px) {
  .month-view #calendar .fc-view-container {
    padding: 200px 50px 0 50px; }
  .month-view #calendar .fc-center {
    padding: 50px 50px 0 50px; } }

@media only screen and (max-width: 1210px) and (min-width: 1px) {
  #listDay .event .event-btns .btn {
    margin: 10px 0; }
  .list-view .list-events {
    margin-top: 20px; }
  #faculty-calendar {
    min-height: 220px !important; } }

@media only screen and (max-width: 1180px) and (min-width: 1px) {
  .month-view #calendar ul#filters {
    width: 100%; }
  .month-view #calendar .fc-view-container {
    padding: 250px 50px 0; }
  .list-view #calendar {
    margin-bottom: 160px; }
  .list-view #calendar ul#filters {
    padding-bottom: 20px; }
  .list-view #calendar ul#filters li {
    margin: 0; }
  .list-view #calendar ul#filters li:first-child {
    width: 100%; }
  .list-view #calendar ul#filters li:nth-child(2) {
    width: 100%; }
  .list-view #calendar ul#filters li:nth-child(3),
  .list-view #calendar ul#filters li:nth-child(4),
  .list-view #calendar ul#filters li:nth-child(5),
  .list-view #calendar ul#filters li:nth-child(6) {
    width: 25%; } }

@media only screen and (max-width: 1080px) and (min-width: 1px) {
  #listDay .col-xs-4 {
    display: none; }
  #listDay .col-xs-8 {
    flex-basis: 100%;
    max-width: 100%; }
  .list-view .list-events {
    margin-left: 380px; }
  .list-view #calendar ul#filters {
    width: calc(100% - 238px) !important; }
  #mini-calendar .fc-head-container th {
    text-align: center; }
  .list-events .event .col-xs-5 {
    flex-basis: 100%;
    max-width: 100%; }
    .list-events .event .col-xs-5 .thumb {
      max-width: 320px; }
  .list-events .event .col-xs-7 {
    flex-basis: 100%;
    max-width: 100%; }
    .list-events .event .col-xs-7 .cnt {
      padding: 20px 0; } }

@media only screen and (max-width: 980px) and (min-width: 1px) {
  #calendar .fc-left, #calendar .fc-right {
    width: 100%;
    position: relative;
    text-align: center; }
  .list-view .fc-toolbar {
    padding: 0; }
  .month-view #calendar .fc-center {
    top: 150px !important; }
  #calendar #calendar-search {
    margin: 20px auto !important; }
  #calendar .fc-right .fc-button-group {
    text-align: center;
    padding-right: 0 !important; }
  #calendar .fc-right .fc-listDay-button {
    margin: 0 10px !important; }
  .past-events .col-xs-4 {
    flex-basis: 50%;
    max-width: 50%;
    margin-bottom: 40px;
    padding-left: .5rem !important;
    padding-right: .5rem !important; }
  .past-events .col-xs-4:last-child {
    margin-bottom: 0; }
  .list-view #calendar {
    margin-bottom: 180px; }
  #calendar ul#filters {
    width: 100%;
    top: 20px;
    vertical-align: top;
    text-align: center; }
  .list-view #calendar .fc-center {
    top: 160px; }
  .month-view #calendar .fc-center {
    top: 50px; }
  .month-view #calendar .fc-view-container {
    padding: 250px 50px 0 50px; }
  #mini-calendar .fc-center {
    top: 0; }
  #calendar .fc-toolbar.fc-header-toolbar,
  #calendar #calendar-search {
    float: none; }
  #calendar .fc-view-container {
    padding-top: 280px; }
  #calendar .fc-center h2 {
    float: none;
    margin-left: 0; }
  #calendar .fc-left, #calendar .fc-right {
    text-align: center; }
  #calendar .fc-right .fc-button-group {
    padding-right: 50px; }
  #calendar #calendar-search {
    max-width: 400px;
    margin: 20px 0 0 50px; }
  #calendar .fc-right .btn {
    margin: 0 10px; }
  #calendar .fc-right .fc-listDay-button {
    margin-right: 0; }
  #calendar .fc-head-container th {
    font-size: 14px;
    line-height: 20px; }
  .list-view .col-xs-4 {
    display: none; }
  .list-view #calendar .fc-center {
    padding: 0 50px 0 50px; }
  .list-view #calendar ul#filters {
    width: 100% !important; }
  .list-view .list-events {
    margin-left: calc(50px - .5rem);
    margin-right: calc(50px - .5rem);
    flex-basis: 100%;
    max-width: 100%; }
  .list-view aside {
    margin-top: 50px;
    padding: 0 50px; }
  #mini-calendar,
  #aside-filters {
    max-width: 100%; }
  #events-calendar {
    height: auto !important; }
  .list-view .end-xs .col-xs-8 {
    flex-basis: 100%;
    max-width: 100%; }
  .no-events {
    padding: 0 50px; }
  #mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper,
  #mini-calendar .fc-content-skeleton .has-events:hover .tooltip-wrapper,
  #mini-calendar .fc-content-skeleton .has-events:focus .tooltip-wrapper {
    display: none !important; }
  .list-view .list-events {
    margin-left: 50px; }
  .list-view #calendar ul#filters {
    width: 100% !important; }
  .month-view #calendar ul#filters li {
    margin: 0; }
  .month-view #calendar ul#filters li:nth-child(2) {
    width: 100%; }
  .month-view #calendar ul#filters li:nth-child(3),
  .month-view #calendar ul#filters li:nth-child(4),
  .month-view #calendar ul#filters li:nth-child(5),
  .month-view #calendar ul#filters li:nth-child(6) {
    width: 25%; }
  .month-view #calendar .fc-view-container {
    padding: 220px 50px 0; }
  .list-events .event .col-xs-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%; }
    .list-events .event .col-xs-5 .thumb {
      max-width: 100%; }
  .list-events .event .col-xs-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%; }
    .list-events .event .col-xs-7 .cnt {
      padding: 0 20px; } }

@media only screen and (max-width: 880px) and (min-width: 1px) {
  #mini-calendar .fc-right,
  #mini-calendar .fc-left {
    width: 10px;
    top: inherit; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .list-events .event .col-xs-5 {
    flex-basis: 100%;
    max-width: 100%; }
    .list-events .event .col-xs-5 .thumb {
      max-width: 320px; }
  .list-events .event .col-xs-7 {
    flex-basis: 100%;
    max-width: 100%; }
    .list-events .event .col-xs-7 .cnt {
      padding: 20px 0; }
  .event .cnt {
    padding: 30px 0; }
  .event .event-btns a {
    width: calc(50% - 10px);
    min-width: 140px; }
  .event .event-btns .purple-btn {
    margin-right: 5px; }
  .event .event-btns .btn {
    margin-left: 5px; }
  .past-events .col-xs-4 {
    flex-basis: 100%;
    max-width: 100%; }
  .past-events .thumb {
    max-width: 265px; }
  .event .thumb {
    max-width: 230px; }
  .event .event-btns a {
    width: 50% !important; }
  #calendar .fc-center {
    padding: 50px 20px 0 20px;
    position: relative;
    top: inherit; }
  #calendar .fc-view-container {
    padding: 280px 20px 0 20px; }
  #mini-calendar .fc-center {
    padding: 0; }
  #calendar {
    margin-bottom: 20px; }
  #calendar ul#filters .filter-title {
    margin-bottom: 20px; }
  #calendar ul#filters li {
    width: 100%;
    margin: 10px 0; }
    #calendar ul#filters li br {
      display: none; }
  #calendar ul#filters li:nth-child(2) {
    margin: 0; }
  .list-view #calendar {
    margin-bottom: 30px; }
  #calendar ul#filters {
    padding-bottom: 20px; }
  .event {
    border-bottom: 1px solid #e0e7ed; }
  .list-view #calendar .fc-center {
    top: 0; }
  #calendar ul#filters li:nth-child(3),
  #calendar ul#filters li:nth-child(4),
  #calendar ul#filters li:nth-child(5),
  #calendar ul#filters li:nth-child(6) {
    width: 50% !important; }
  .month-view #calendar .fc-center {
    top: 0 !important; }
  .month-view #calendar .fc-view-container {
    padding: 20px 50px 0; }
  .event-details {
    padding: 80px 0 0 0; }
  #event-single-calendar {
    display: none; }
  .section-events .fixed-width .col-xs-4 {
    order: 1;
    margin-top: 1.5rem; }
  .section-events .fixed-width .col-xs-8 {
    order: 0;
    margin-top: 2rem; }
  .share {
    top: -30px;
    padding: 5px 15px; }
  .event-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 650px;
    max-height: 520px;
    height: 520px;
    padding: 20px 10px;
    background: #fff;
    z-index: 9999999;
    transform: translate(-50%, -50%); }
    .event-modal .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      background: url(../images/close.svg) 50% no-repeat #fff;
      border: 1px solid #637a91; }
    .event-modal .row .col-xs-6:last-child {
      padding-left: 30px; }
    .event-modal .row .thumb {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 150px;
      background-size: cover !important;
      margin: 0 0 10px 0; }
      .event-modal .row .thumb .cat {
        position: absolute;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto;
        padding: 8px 10px 5px;
        color: #fff;
        z-index: 9;
        font-family: "AkkuratPro-Regular";
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 4px;
        text-transform: uppercase; }
        .event-modal .row .thumb .cat.violet {
          background-color: #bd10e0; }
        .event-modal .row .thumb .cat.blue {
          background-color: #4e93ce; }
        .event-modal .row .thumb .cat.yellow {
          background-color: #f1951c; }
        .event-modal .row .thumb .cat.green {
          background-color: #79cc19; }
    .event-modal .row .hide-on-desktop {
      display: block; }
    .event-modal .row .hide-on-mobile {
      display: none; }
    .event-modal .row p {
      margin: 0 0 10px 0; }
    .event-modal .row .purple-btn {
      padding: 10px 20px;
      margin-top: 5px; }
    .event-modal .row .date {
      color: #637a91;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      text-transform: uppercase;
      margin: 0; }
    .event-modal .row h3.post-title {
      color: #4e2a84;
      font-family: "AkkuratPro-Bold";
      font-size: 18px;
      line-height: 24px;
      margin: 5px 0; }
    .event-modal .row p.location {
      display: inline-block;
      width: 100%;
      color: #637a91;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 18px;
      padding-left: 20px;
      background: url(../images/location-icon.svg) 0 no-repeat;
      text-align: left;
      margin: 0px; }
    .event-modal .row .excerpt {
      margin-top: 10px; }
      .event-modal .row .excerpt a.body-link {
        padding: 5px 5px 5px 0; } }

@media only screen and (max-width: 680px) and (min-width: 1px) {
  #calendar ul#filters li {
    width: 50%;
    margin: 0 0 10px 0; }
  #calendar ul#filters li:nth-child(2) {
    width: 100%; }
  #calendar .fc-view-container {
    padding-top: 420px; }
  #calendar ul#filters li label br {
    display: none; }
  #calendar .fc-button-group {
    float: none; }
  #calendar .fc-event-container .fc-content {
    display: none; }
  #calendar .fc-event-container .bienen-ensembles, #calendar .fc-event-container .violet {
    width: 20px;
    height: 20px;
    margin: 0 0 5px 0;
    border-radius: 20px;
    background: #bd10e0; }
  #calendar .fc-event-container .festivals-series, #calendar .fc-event-container .yellow {
    width: 20px;
    height: 20px;
    margin: 0 0 5px 0;
    border-radius: 20px;
    background: #f1951c; }
  #calendar .fc-event-container .recitals-lectures,
  #calendar .fc-event-container .recitals-visiting-artists, #calendar .fc-event-container .blue {
    width: 20px;
    height: 20px;
    margin: 0 0 5px 0;
    border-radius: 20px;
    background: #4a90e2; }
  #calendar .fc-event-container .student-recitals, #calendar .fc-event-container .green {
    width: 20px;
    height: 20px;
    margin: 0 0 5px 0;
    border-radius: 20px;
    background: #79cc19; }
  #calendar .fc-head-container th {
    font-size: 12px; }
  #faculty-calendar .fc-event:hover .fc-content,
  #calendar .fc-event:hover .fc-content
#faculty-calendar .fc-event:focus .fc-content,
  #calendar .fc-event:focus .fc-content {
    display: block;
    position: absolute;
    z-index: 9999;
    bottom: -10px;
    top: inherit;
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4); }
  #faculty-calendar .fc-event.left .fc-content,
  #calendar .fc-event.left .fc-content {
    right: inherit;
    left: 25px; }
  #faculty-calendar .fc-event.right .fc-content,
  #calendar .fc-event.right .fc-content {
    left: inherit;
    right: 25px; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  #calendar ul#filters li:nth-child(3),
  #calendar ul#filters li:nth-child(4),
  #calendar ul#filters li:nth-child(5),
  #calendar ul#filters li:nth-child(6) {
    width: 100% !important; }
  #calendar .fc-right .fc-listDay-button {
    margin: 10px 0 !important; }
  .month-view #calendar .fc-view-container {
    padding: 20px; }
  .month-view #calendar .fc-center {
    padding: 50px 20px 0 20px; }
  .event .event-btns a {
    width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    margin: 5px 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .past-events .thumb {
    max-width: 100%; }
  .event .thumb {
    max-width: 100%; }
  .event-details .purple-btn {
    min-width: 100%; }
  #calendar .fc-right .btn {
    width: 100%;
    margin: 10px 0;
    max-width: 100%; }
  #calendar .fc-right .fc-button-group {
    max-width: 400px; }
  #calendar .fc-left, #calendar .fc-right {
    height: auto; }
  #mini-calendar {
    margin-top: 60px; }
  #mini-calendar .fc-center {
    top: 0; }
  #calendar .fc-right,
  #calendar .fc-left {
    padding: 0 20px; }
  #calendar #calendar-search {
    min-width: 100%; }
  #calendar .fc-head-container th {
    font-size: 10px; }
  #calendar ul#filters li {
    width: 100%; }
  #calendar .fc-view-container {
    padding-top: 540px; }
  #calendar .fc-center h2 {
    font-size: 32px;
    line-height: 42px; }
  #mini-calendar .fc-center h2 {
    font-size: 18px;
    line-height: 26px; }
  #calendar .fc-head-container th {
    font-size: 6px; }
  #calendar .fc-row td {
    font-size: 20px; }
  #calendar .fc-row td span {
    margin: 10px auto 0 auto; }
  #mini-calendar .fc-view-container {
    padding: 280px 0 0 0; }
  #calendar #calendar-search input[type=text] {
    max-width: 100%; }
  #calendar .fc-right .fc-button-group {
    max-width: 100%; }
  .list-view #calendar .fc-center {
    padding: 0 20px; }
  .list-view .list-events {
    margin-left: calc(20px - .5rem);
    margin-right: calc(20px - .5rem); }
  .event .event-btns a {
    width: 100% !important; }
  .list-view aside {
    padding: 0 20px; }
  .month-view #calendar .fc-center h2 {
    width: 100%; } }

/* Event mobile stuff */
.event-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 650px;
  height: auto;
  padding: 40px;
  background: #fff;
  z-index: 9999999;
  transform: translate(-50%, -50%); }
  .event-modal .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: url(../images/close.svg) 50% no-repeat; }
  .event-modal .row .col-xs-6:last-child {
    padding-left: 30px; }
  .event-modal .row .thumb {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 165px;
    background-size: cover !important;
    margin: 0 0 40px 0; }
    .event-modal .row .thumb .cat {
      position: absolute;
      bottom: 0;
      left: 0;
      width: auto;
      height: auto;
      padding: 8px 10px 5px;
      color: #fff;
      z-index: 9;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 4px;
      text-transform: uppercase; }
      .event-modal .row .thumb .cat.violet {
        background-color: #bd10e0; }
      .event-modal .row .thumb .cat.blue {
        background-color: #4e93ce; }
      .event-modal .row .thumb .cat.yellow {
        background-color: #f1951c; }
      .event-modal .row .thumb .cat.green {
        background-color: #79cc19; }
  .event-modal .row p {
    margin: 0 0 10px 0; }
  .event-modal .row .hide-on-desktop {
    display: none; }
  .event-modal .row .hide-on-mobile {
    display: block; }
  .event-modal .row .btn {
    min-width: 130px;
    padding: 10px 20px;
    margin-top: 20px; }
  .event-modal .row .date {
    color: #819eb5;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0; }
  .event-modal .row h3 {
    color: #4e2a84;
    font-family: "AkkuratPro-Bold";
    font-size: 24px;
    line-height: 36px;
    margin: 5px 0; }
  .event-modal .row .location {
    display: inline-block;
    width: 100%;
    color: #819eb5;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 20px;
    padding-left: 20px;
    background: url(../images/location-icon.svg) 0 no-repeat;
    text-align: left;
    margin: 0 0 20px; }
  .event-modal .row .excerpt {
    margin-top: 10px; }

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(78, 42, 132, 0.5);
  z-index: 999999; }

#media-library {
  padding: 100px 50px 100px 0;
  background-color: #f2f5f8; }
  #media-library .all {
    min-width: 140px;
    max-width: 140px;
    margin-bottom: 20px; }
  #media-library h1 {
    text-align: center; }
  #media-library .featured-video a.permalink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99; }
  #media-library .featured-video:hover .video-thumb, #media-library .featured-video:focus .video-thumb {
    transform: scale(1.1); }
  #media-library .featured-video:hover h3 a span, #media-library .featured-video:focus h3 a span {
    background-size: 100% 3px; }
  #media-library .featured-video .video-thumb-wrap {
    display: inline-block;
    width: 100%;
    height: 435px;
    overflow: hidden; }
    #media-library .featured-video .video-thumb-wrap .video-btn.play {
      position: relative;
      display: block;
      top: 50%;
      width: 100px;
      height: 100px;
      color: #fff;
      font-family: AkkuratPro-Bold;
      font-size: 12px;
      line-height: 125px;
      text-transform: uppercase;
      letter-spacing: 2px;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      #media-library .featured-video .video-thumb-wrap .video-btn.play:before {
        position: absolute;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: url("/themes/basic/dist/images/large-play.png") 50% no-repeat;
        background-color: rgba(0, 0, 0, 0);
        background-image: url("/themes/basic/dist/images/large-play.png");
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-clip: border-box;
        background-origin: padding-box;
        background-position-x: 50%;
        background-position-y: center;
        background-size: auto auto;
        background-size: 100% !important; }
  #media-library .featured-video .video-thumb {
    display: inline-block;
    width: 100%;
    height: 435px;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s; }
  #media-library .featured-video .video-caption {
    position: relative;
    top: -20px;
    float: right;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 40px;
    width: 90%; }
    #media-library .featured-video .video-caption .play {
      position: absolute;
      top: 50%;
      left: 40px;
      width: 55px;
      height: 55px;
      border-radius: 55px;
      border: 5px solid #4e2a84;
      transform: translate(0, -50%); }
      #media-library .featured-video .video-caption .play:after {
        position: absolute;
        content: '';
        top: 50%;
        left: calc(50% + 2px);
        width: 30px;
        height: 30px;
        transform: translate(-50%, -50%);
        background: url(../images/purple-play.svg) 50% no-repeat; }
    #media-library .featured-video .video-caption .info {
      padding-left: 75px; }
      #media-library .featured-video .video-caption .info p {
        color: #4f7796;
        font-size: 14px;
        line-height: 20px; }
  #media-library .media-video {
    margin-bottom: 50px; }
    #media-library .media-video.is_hover .video-thumb {
      transform: scale(1.1); }
    #media-library .media-video.is_hover a span {
      background-size: 100% 3px; }
    #media-library .media-video .video-thumb-link {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 155px;
      overflow: hidden; }
      #media-library .media-video .video-thumb-link .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .4;
        z-index: 9; }
      #media-library .media-video .video-thumb-link .video-thumb {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-size: cover !important;
        transform: scale(1);
        transition: .4s; }
      #media-library .media-video .video-thumb-link:hover .video-thumb, #media-library .media-video .video-thumb-link:focus .video-thumb {
        transform: scale(1.1); }
      #media-library .media-video .video-thumb-link .play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        z-index: 99;
        transform: translate(-50%, -50%);
        background: url(../images/play-btn.png) 50% no-repeat; }
    #media-library .media-video p {
      color: #4f7796;
      font-size: 14px;
      line-height: 24px !important;
      margin-top: 10px; }
  #media-library .col-xs-6:first-child .media-video {
    padding: 0 15px 0 0; }
  #media-library .col-xs-6:last-child .media-video {
    padding: 0 0 0 15px; }

#media-library-search .all {
  min-width: 140px;
  max-width: 140px;
  margin-bottom: 20px; }

#media-library-search h1 {
  text-align: center; }

#media-library-search .featured-video.video-thumbnail-sm {
  max-width: 480px; }
  #media-library-search .featured-video.video-thumbnail-sm .video-thumb-wrap {
    max-height: 320px; }
    #media-library-search .featured-video.video-thumbnail-sm .video-thumb-wrap .video-thumb {
      height: 100% !important; }

#media-library-search .featured-video.video-thumbnail-med {
  max-width: 640px; }
  #media-library-search .featured-video.video-thumbnail-med .video-thumb-wrap {
    max-height: 320px; }
    #media-library-search .featured-video.video-thumbnail-med .video-thumb-wrap .video-thumb {
      height: 100% !important; }

#media-library-search .featured-video.video-thumbnail-lg {
  max-width: 1024px; }
  #media-library-search .featured-video.video-thumbnail-lg .video-thumb-wrap {
    max-height: 620px; }
    #media-library-search .featured-video.video-thumbnail-lg .video-thumb-wrap .video-thumb {
      height: 100% !important; }

#media-library-search .featured-video a.permalink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99; }

#media-library-search .featured-video:hover .video-thumb, #media-library-search .featured-video:focus .video-thumb {
  transform: scale(1.1); }

#media-library-search .featured-video:hover h3 a span, #media-library-search .featured-video:focus h3 a span {
  background-size: 100% 3px; }

#media-library-search .featured-video .video-thumb-wrap {
  display: inline-block;
  width: 100%;
  height: 435px;
  overflow: hidden; }
  #media-library-search .featured-video .video-thumb-wrap .video-btn.play {
    position: relative;
    display: block;
    top: 50%;
    width: 100px;
    height: 100px;
    color: #fff;
    font-family: AkkuratPro-Bold;
    font-size: 12px;
    line-height: 125px;
    text-transform: uppercase;
    letter-spacing: 2px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    #media-library-search .featured-video .video-thumb-wrap .video-btn.play:before {
      position: absolute;
      top: 0;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background: url(/themes/basic/dist/images/large-play.png) 50% no-repeat;
      background-color: rgba(0, 0, 0, 0);
      background-image: url("/themes/basic/dist/images/large-play.png");
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-clip: border-box;
      background-origin: padding-box;
      background-position-x: 50%;
      background-position-y: center;
      background-size: auto auto;
      background-size: 100% !important; }

#media-library-search .featured-video .video-thumb {
  display: inline-block;
  width: 100%;
  height: 435px;
  background-size: cover !important;
  transform: scale(1);
  transition: .4s; }

#media-library-search .featured-video .video-caption {
  position: relative;
  top: -20px;
  float: right;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 40px;
  width: 90%; }
  #media-library-search .featured-video .video-caption .play {
    position: absolute;
    top: 50%;
    left: 40px;
    width: 55px;
    height: 55px;
    border-radius: 55px;
    border: 5px solid #4e2a84;
    transform: translate(0, -50%); }
    #media-library-search .featured-video .video-caption .play:after {
      position: absolute;
      content: '';
      top: 50%;
      left: calc(50% + 2px);
      width: 30px;
      height: 30px;
      transform: translate(-50%, -50%);
      background: url(../images/purple-play.svg) 50% no-repeat; }
  #media-library-search .featured-video .video-caption .info {
    padding-left: 75px; }
    #media-library-search .featured-video .video-caption .info p {
      color: #4f7796;
      font-size: 14px;
      line-height: 24px !important; }

#media-library-search .media-video {
  margin-bottom: 50px; }
  #media-library-search .media-video.is_hover .video-thumb {
    transform: scale(1.1); }
  #media-library-search .media-video.is_hover a span {
    background-size: 100% 3px; }
  #media-library-search .media-video .video-thumb-link {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 205px;
    overflow: hidden; }
    #media-library-search .media-video .video-thumb-link .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: .4;
      z-index: 9; }
    #media-library-search .media-video .video-thumb-link .video-thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-size: cover !important;
      transform: scale(1);
      transition: .4s; }
    #media-library-search .media-video .video-thumb-link:hover .video-thumb, #media-library-search .media-video .video-thumb-link:focus .video-thumb {
      transform: scale(1.1); }
    #media-library-search .media-video .video-thumb-link .play {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      z-index: 99;
      transform: translate(-50%, -50%);
      background: url(../images/play-btn.png) 50% no-repeat; }
  #media-library-search .media-video p {
    color: #4f7796;
    font-size: 14px;
    line-height: 24px !important;
    margin-top: 10px; }

#media-library-search .col-xs-6:first-child .media-video {
  padding: 0 15px 0 0; }

#media-library-search .col-xs-6:last-child .media-video {
  padding: 0 0 0 15px; }

#about-video .lightbox .cnt {
  padding: 10px 0px; }

.featured-video .link {
  display: inline-block;
  position: absolute;
  bottom: 30px;
  right: 0;
  color: #4e2a84;
  font-family: "AkkuratPro-Bold";
  font-size: 16px;
  line-height: 24px;
  padding-right: 40px; }
  .featured-video .link:hover:after, .featured-video .link:focus:after {
    width: calc(100% - 40px); }
  .featured-video .link:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: #4e2a84;
    transition: .4s; }
  .featured-video .link .ico {
    position: absolute;
    top: 6px;
    right: 0;
    width: 20px;
    height: 20px;
    background: url(../images/purple-arrow.svg) right center no-repeat;
    transform: rotate(-90deg);
    background-size: 60%; }

.featured-video .video-cnt {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  height: auto !important; }
  .featured-video .video-cnt:hover .video-thumb, .featured-video .video-cnt:focus .video-thumb {
    transform: scale(1.1); }
  .featured-video .video-cnt:hover .meta .wrap .video-title span, .featured-video .video-cnt:focus .meta .wrap .video-title span {
    background-size: 100% 3px; }
  .featured-video .video-cnt .permalink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99; }
  .featured-video .video-cnt .video-thumb-wrap {
    position: relative;
    width: 100%;
    height: 515px;
    overflow: hidden; }
  .featured-video .video-cnt .video-thumb {
    position: relative;
    width: 100%;
    height: 515px;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s; }
  .featured-video .video-cnt .meta {
    position: relative !important;
    padding: 35px !important; }
    .featured-video .video-cnt .meta .wrap {
      display: inline-block;
      position: static !important;
      width: 100%; }
    .featured-video .video-cnt .meta .play {
      display: block;
      position: absolute;
      top: 50%;
      left: 35px !important;
      width: 50px !important;
      height: 50px !important;
      border-radius: 50px !important;
      border: 5px solid #4e2a84 !important;
      background: url(../images/purple-play.svg) 16px 50% no-repeat !important;
      transform: translate(0, -50%); }
    .featured-video .video-cnt .meta .video-title {
      display: block;
      color: #000000 !important;
      font-family: "Poppins-Medium";
      font-size: 24px;
      line-height: 32px;
      padding-left: 80px !important;
      margin: 0 0 0 0 !important;
      text-decoration: none; }
      .featured-video .video-cnt .meta .video-title span {
        width: calc(100%);
        background-image: linear-gradient(transparent calc(100% - 2px), #000 2px) !important;
        background-repeat: no-repeat;
        background-size: 0% 3px;
        transition: background-size .4s;
        background-position: bottom; }
      .featured-video .video-cnt .meta .video-title:hover span, .featured-video .video-cnt .meta .video-title:focus span {
        background-size: 0 !important; }
    .featured-video .video-cnt .meta p {
      color: #4f7796 !important;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 24px !important;
      padding-left: 80px !important;
      margin-top: 20px !important; }

.video-grid-item {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 0 40px 0; }
  .video-grid-item p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 24px !important; }
  .video-grid-item:hover .video-thumb, .video-grid-item:focus .video-thumb {
    transform: scale(1.1); }
  .video-grid-item:hover .video-title span, .video-grid-item:focus .video-title span {
    background-size: 100% 3px; }
  .video-grid-item .video-cnt {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0; }
    .video-grid-item .video-cnt a.permalink {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99; }
    .video-grid-item .video-cnt .video-thumb-wrap {
      display: block;
      position: relative;
      width: 100%;
      height: 210px;
      overflow: hidden; }
    .video-grid-item .video-cnt .video-thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 210px;
      background-size: cover !important;
      transform: scale(1);
      transition: .4s; }
    .video-grid-item .video-cnt .meta {
      position: absolute;
      padding: 35px;
      bottom: 0;
      left: 0;
      z-index: 9; }
      .video-grid-item .video-cnt .meta .play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        z-index: 99;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: url(../images/play-btn.png) 50% no-repeat; }
      .video-grid-item .video-cnt .meta .video-title {
        display: block;
        position: relative;
        color: #fff;
        font-family: "Poppins-Medium";
        font-size: 24px;
        line-height: 32px;
        padding-left: 120px;
        margin: 0 0 20px 0; }
        .video-grid-item .video-cnt .meta .video-title span {
          width: calc(100%);
          background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);
          background-repeat: no-repeat;
          background-size: 0% 3px;
          transition: background-size .4s; }
        .video-grid-item .video-cnt .meta .video-title:hover span, .video-grid-item .video-cnt .meta .video-title:focus span {
          background-size: 100% 3px; }

.video-thumbnail-link {
  position: relative;
  width: 90%; }
  .video-thumbnail-link .video-cnt {
    position: relative;
    width: 100%;
    height: 515px; }
    .video-thumbnail-link .video-cnt:hover .video-thumb, .video-thumbnail-link .video-cnt:focus .video-thumb {
      transform: scale(1.1); }
    .video-thumbnail-link .video-cnt:hover .meta .video-title span,
    .video-thumbnail-link .video-cnt:hover .meta .video-title, .video-thumbnail-link .video-cnt:focus .meta .video-title span,
    .video-thumbnail-link .video-cnt:focus .meta .video-title {
      background-size: 100% 3px;
      text-decoration: none; }
    .video-thumbnail-link .video-cnt a.permalink {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99; }
    .video-thumbnail-link .video-cnt .video-thumb-wrap {
      display: block;
      position: relative;
      width: 100%;
      height: 515px;
      overflow: hidden;
      background: #000; }
      .video-thumbnail-link .video-cnt .video-thumb-wrap .video-btn.play {
        position: relative;
        display: block;
        top: 50%;
        width: 100px;
        height: 100px;
        color: #fff;
        font-family: AkkuratPro-Bold;
        font-size: 12px;
        line-height: 125px;
        text-transform: uppercase;
        letter-spacing: 2px;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
        .video-thumbnail-link .video-cnt .video-thumb-wrap .video-btn.play:before {
          position: absolute;
          top: 0;
          content: "";
          width: 100%;
          height: 100%;
          border-radius: 100%;
          background: url(../images/large-play.png) 50% no-repeat;
          background-color: black;
          background-image: url("../images/large-play.png");
          background-repeat: no-repeat;
          background-attachment: scroll;
          background-clip: border-box;
          background-origin: padding-box;
          background-position-x: 50%;
          background-position-y: center;
          background-size: auto auto;
          background-size: 100% !important; }
    .video-thumbnail-link .video-cnt .video-thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 515px;
      background-size: cover !important;
      transform: scale(1);
      transition: .4s;
      opacity: .75; }
    .video-thumbnail-link .video-cnt .meta {
      position: absolute;
      padding: 35px;
      bottom: 0;
      left: 0;
      z-index: 9; }
      .video-thumbnail-link .video-cnt .meta .play {
        display: block;
        position: absolute;
        top: 50%;
        left: 35px;
        width: 103px;
        height: 103px;
        border-radius: 103px;
        border: 5px solid #fff;
        background: url(../images/large-play.png) 50% no-repeat;
        transform: translate(0, -50%); }
      .video-thumbnail-link .video-cnt .meta .video-title {
        display: block;
        position: relative;
        color: #fff;
        font-family: "Poppins-Medium";
        font-size: 24px;
        line-height: 32px;
        padding-left: 120px;
        margin: 0 0 20px 0; }
        .video-thumbnail-link .video-cnt .meta .video-title span {
          width: calc(100%);
          background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);
          background-repeat: no-repeat;
          background-size: 0% 3px;
          transition: background-size .4s; }
        .video-thumbnail-link .video-cnt .meta .video-title:hover span, .video-thumbnail-link .video-cnt .meta .video-title:focus span {
          background-size: 0 !important; }
      .video-thumbnail-link .video-cnt .meta p {
        color: #fff;
        font-family: "AkkuratPro-Regular";
        font-size: 14px;
        line-height: 20px;
        padding-left: 120px; }
  .video-thumbnail-link.video-thumbnail-sm {
    height: 280px;
    max-width: 480px;
    margin: 20px auto !important; }
    .video-thumbnail-link.video-thumbnail-sm .video-cnt {
      height: 280px; }
    .video-thumbnail-link.video-thumbnail-sm .video-thumb-wrap {
      height: 100%; }
      .video-thumbnail-link.video-thumbnail-sm .video-thumb-wrap .video-thumb {
        height: 100% !important; }
  .video-thumbnail-link.video-thumbnail-med {
    height: 420px;
    max-width: 640px;
    margin: 20px auto !important; }
    .video-thumbnail-link.video-thumbnail-med .video-cnt {
      height: 420px; }
    .video-thumbnail-link.video-thumbnail-med .video-thumb-wrap {
      height: 100%; }
      .video-thumbnail-link.video-thumbnail-med .video-thumb-wrap .video-thumb {
        height: 100% !important; }
  .video-thumbnail-link.video-thumbnail-lg {
    max-width: 1024px;
    margin: 20px auto !important; }
    .video-thumbnail-link.video-thumbnail-lg .video-cnt {
      height: 515px; }
    .video-thumbnail-link.video-thumbnail-lg .video-thumb-wrap {
      height: 100%; }
      .video-thumbnail-link.video-thumbnail-lg .video-thumb-wrap .video-thumb {
        height: 100% !important; }

.video-cnt {
  position: relative;
  width: 100%;
  height: 515px; }
  .video-cnt:hover .video-thumb, .video-cnt:focus .video-thumb {
    transform: scale(1.1); }
  .video-cnt:hover .meta .video-title span,
  .video-cnt:hover .meta .video-title, .video-cnt:focus .meta .video-title span,
  .video-cnt:focus .meta .video-title {
    background-size: 100% 3px;
    text-decoration: none; }
  .video-cnt a.permalink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99; }
  .video-cnt .video-thumb-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 515px;
    overflow: hidden;
    background: #000; }
    .video-cnt .video-thumb-wrap .video-btn.play {
      position: relative;
      display: block;
      top: 50%;
      width: 100px;
      height: 100px;
      color: #fff;
      font-family: AkkuratPro-Bold;
      font-size: 12px;
      line-height: 125px;
      text-transform: uppercase;
      letter-spacing: 2px;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      .video-cnt .video-thumb-wrap .video-btn.play:before {
        position: absolute;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: url(/themes/basic/dist/images/play-btn.png) 50% no-repeat;
        background-image: url("/themes/basic/dist/images/play-btn.png");
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-clip: border-box;
        background-origin: padding-box;
        background-position-x: 50%;
        background-position-y: center;
        background-size: auto auto; }
  .video-cnt .video-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 515px;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s;
    opacity: .75; }
  .video-cnt .meta {
    position: absolute;
    padding: 35px;
    bottom: 0;
    left: 0;
    z-index: 9; }
    .video-cnt .meta .play {
      display: block;
      position: absolute;
      top: 50%;
      left: 35px;
      width: 103px;
      height: 103px;
      border-radius: 103px;
      border: 5px solid #fff;
      background: url(../images/large-play.png) 50% no-repeat;
      transform: translate(0, -50%); }
    .video-cnt .meta .video-title {
      display: block;
      position: relative;
      color: #fff;
      font-family: "Poppins-Medium";
      font-size: 24px;
      line-height: 32px;
      padding-left: 120px;
      margin: 0 0 20px 0; }
      .video-cnt .meta .video-title span {
        width: calc(100%);
        background-image: linear-gradient(transparent calc(100% - 2px), #fff 2px);
        background-repeat: no-repeat;
        background-size: 0% 3px;
        transition: background-size .4s; }
      .video-cnt .meta .video-title:hover span, .video-cnt .meta .video-title:focus span {
        background-size: 0 !important; }
    .video-cnt .meta p {
      color: #fff;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 24px !important;
      padding-left: 120px; }

.video-thumbnail-link .video-cnt:hover .meta .video-title span {
  background-size: 0 !important; }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .video-thumbnail .video-cnt {
    height: 350px; }
    .video-thumbnail .video-cnt .video-thumb-wrap, .video-thumbnail .video-cnt .video-thumb {
      height: 350px; } }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  .featured-video .col-xs-6 {
    flex-basis: 100%;
    max-width: 100%; }
  .featured-video .link {
    position: relative;
    bottom: inherit;
    margin-bottom: 20px; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .featured-video .video-cnt .video-thumb,
  .featured-video .video-cnt .video-thumb-wrap {
    height: 350px; }
  .video-banner .sound-btn {
    left: 20px;
    width: calc(100% - 40px);
    transform: translate(0, 0);
    padding-left: 0; }
  #about-video .lightbox {
    min-height: 320px; }
    #about-video .lightbox .cnt {
      padding: 10px 0px; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .video-thumbnail .video-cnt .meta .play {
    position: relative;
    top: inherit;
    transform: translate(-50%, 0);
    left: 50%;
    margin-bottom: 30px; }
  .video-thumbnail .video-cnt .meta .video-title {
    padding-left: 0; }
  .video-thumbnail .video-cnt .meta p {
    padding-left: 0; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  .featured-video .video-cnt .meta .play {
    position: relative;
    top: inherit;
    transform: translate(-50%, 0);
    left: 50%; }
  .featured-video .video-cnt .meta .video-title,
  .featured-video .video-cnt .meta p {
    padding-left: 0; }
  .featured-video .video-cnt .video-thumb-wrap,
  .featured-video .video-cnt .video-thumb {
    height: 250px; } }

#faculty {
  background-color: #f2f5f8;
  max-width: 100%;
  padding: 100px 150px;
  margin: 100px auto 0 auto; }
  #faculty .header2 {
    margin-bottom: 20px; }
  #faculty .col-xs-3 {
    padding-left: calc(.5rem + 10px);
    padding-right: calc(.5rem + 10px); }
  #faculty .col-xs-3:first-child {
    padding-left: .5rem;
    padding-right: calc(.5rem + 10px); }
  #faculty .col-xs-3:last-child {
    padding-left: calc(.5rem + 10px);
    padding-right: .5rem; }

#students {
  background-color: #fff;
  max-width: 100%;
  margin: 0 auto; }
  #students .header2 {
    margin-bottom: 20px; }
  #students .col-xs-3 {
    padding-left: calc(.5rem + 10px);
    padding-right: calc(.5rem + 10px); }
  #students .col-xs-3:first-child {
    padding-left: .5rem;
    padding-right: calc(.5rem + 10px); }
  #students .col-xs-3:last-child {
    padding-left: calc(.5rem + 10px);
    padding-right: .5rem; }

#students.no-popup {
  padding: 100px 150px; }

#students.with-popup {
  padding: 100px 0 0 0; }
  #students.with-popup > .row {
    padding-left: 150px;
    padding-right: 150px; }

.student-popup {
  display: none;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 999;
  background-color: #f2f5f8; }
  .student-popup .close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #507796;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    z-index: 999;
    text-transform: uppercase; }
  .student-popup .photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 560px;
    background-size: cover !important;
    transform: scale(1) !important; }
  .student-popup .cnt {
    padding: 50px 90px; }
    .student-popup .cnt .title {
      color: #507796;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      text-transform: uppercase;
      margin: 0 0 10px 0; }
    .student-popup .cnt .email {
      display: block;
      color: #4e2a84;
      font-family: "AkkuratPro-Bold";
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 40px 0;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word; }
  .student-popup .scroll-wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 300px;
    padding-right: 20px; }
    .student-popup .scroll-wrapper::-webkit-scrollbar {
      width: 5px; }
    .student-popup .scroll-wrapper::-webkit-scrollbar-track {
      background: transparent; }
    .student-popup .scroll-wrapper::-webkit-scrollbar-thumb {
      background: #4e2a84;
      border-radius: 30px; }

.faculty-popup {
  display: none;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 999;
  background-color: #f2f5f8;
  margin-bottom: 30px; }
  .faculty-popup .close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-family: "AkkuratPro-Regular";
    letter-spacing: 3px;
    z-index: 999;
    text-transform: uppercase;
    display: block;
    color: #4e2a84;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 40px; }
  .faculty-popup .photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 380px;
    background-size: cover !important;
    transform: scale(1) !important; }
  .faculty-popup .cnt {
    padding: 40px 50px; }
    .faculty-popup .cnt .title {
      color: #507796;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 16px;
      text-transform: uppercase;
      margin: 0 0 10px 0; }
    .faculty-popup .cnt .email {
      display: block;
      color: #4e2a84;
      font-family: "AkkuratPro-Bold";
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 40px 0; }
  .faculty-popup .scroll-wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 300px;
    padding-right: 20px; }
    .faculty-popup .scroll-wrapper::-webkit-scrollbar {
      width: 5px; }
    .faculty-popup .scroll-wrapper::-webkit-scrollbar-track {
      background: transparent; }
    .faculty-popup .scroll-wrapper::-webkit-scrollbar-thumb {
      background: #4e2a84;
      border-radius: 30px; }

.faculty, .students, .people-bio {
  margin: 0 -.5rem 50px -.5rem; }
  .faculty .col-xs-4, .students .col-xs-4, .people-bio .col-xs-4 {
    padding-left: calc(.5rem + 10px);
    padding-right: calc(.5rem + 10px); }
  .faculty .col-xs-4:first-child, .students .col-xs-4:first-child, .people-bio .col-xs-4:first-child {
    padding-left: .5rem;
    padding-right: calc(.5rem + 10px); }
  .faculty .col-xs-4:last-child, .students .col-xs-4:last-child, .people-bio .col-xs-4:last-child {
    padding-left: calc(.5rem + 10px);
    padding-right: .5rem; }
  .faculty .photo-wrap, .students .photo-wrap, .people-bio .photo-wrap {
    position: relative;
    overflow: hidden; }
    .faculty .photo-wrap .photo, .students .photo-wrap .photo, .people-bio .photo-wrap .photo {
      position: relative;
      width: 100%;
      height: 260px;
      background-size: cover !important;
      transform: scale(1);
      transition: .4s; }
      .faculty .photo-wrap .photo a, .students .photo-wrap .photo a, .people-bio .photo-wrap .photo a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    .faculty .photo-wrap:hover .photo, .faculty .photo-wrap:focus .photo, .students .photo-wrap:hover .photo, .students .photo-wrap:focus .photo, .people-bio .photo-wrap:hover .photo, .people-bio .photo-wrap:focus .photo {
      transform: scale(1.1); }
  .faculty .thumb-wrap, .students .thumb-wrap, .people-bio .thumb-wrap {
    position: relative;
    width: 100%;
    height: 260px;
    overflow: hidden; }
  .faculty .thumb, .students .thumb, .people-bio .thumb {
    position: relative;
    width: 100%;
    height: 260px;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s; }
    .faculty .thumb a, .students .thumb a, .people-bio .thumb a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .faculty .name, .students .name, .people-bio .name {
    display: inline-block;
    position: relative;
    color: #4e2a84;
    font-family: "Poppins-Medium";
    font-size: 24px;
    line-height: 32px;
    margin: 15px 0 10px 0;
    text-decoration: none; }
  .faculty .title, .students .title, .people-bio .title {
    color: #4f7796;
    font-family: "AkkuratPro-Bold";
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 2px; }
  .faculty .email, .students .email, .people-bio .email {
    display: block;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold";
    font-size: 14px;
    line-height: 20px;
    margin-top: -15px;
    margin-bottom: 15px;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word; }
  .faculty .col-xs-3.is_hover .photo, .faculty .col-xs-3.is_hover .thumb,
  .faculty .col-xs-4.is_hover .photo,
  .faculty .col-xs-4.is_hover .thumb, .students .col-xs-3.is_hover .photo, .students .col-xs-3.is_hover .thumb,
  .students .col-xs-4.is_hover .photo,
  .students .col-xs-4.is_hover .thumb, .people-bio .col-xs-3.is_hover .photo, .people-bio .col-xs-3.is_hover .thumb,
  .people-bio .col-xs-4.is_hover .photo,
  .people-bio .col-xs-4.is_hover .thumb {
    transform: scale(1.1); }
  .faculty .col-xs-3.is_hover .name span,
  .faculty .col-xs-4.is_hover .name span, .students .col-xs-3.is_hover .name span,
  .students .col-xs-4.is_hover .name span, .people-bio .col-xs-3.is_hover .name span,
  .people-bio .col-xs-4.is_hover .name span {
    background-size: 100% 3px; }

.faculty .photo-excerpts,
.people-bio .photo-excerpts {
  z-index: 9999; }

.faculty .photo-excerpts .col-xs-4:after,
.people-bio.with-popup .photo-excerpts .col-xs-4:after {
  display: block;
  position: absolute;
  content: '';
  bottom: -20px;
  left: 50%;
  width: 0;
  height: 0;
  z-index: 9999;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-top: 30px solid #fff;
  transform: translate3d(-50%, 100%, 0);
  opacity: 0;
  transition: .4s; }

.faculty .photo-excerpts .col-xs-4.active:after,
.people-bio.with-popup .photo-excerpts .col-xs-4.active:after {
  opacity: 0; }

.students .col-xs-3:after {
  display: block;
  position: absolute;
  content: '';
  bottom: -50px;
  left: 50%;
  width: 0;
  height: 0;
  z-index: 9999;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-top: 30px solid #fff;
  transform: translate3d(-50%, 100%, 0);
  opacity: 0;
  transition: .4s; }

.students .col-xs-3.active:after {
  opacity: 1; }

#faculty-conductor .photo {
  width: 100%;
  height: 440px;
  background-size: cover !important; }

#faculty-conductor .cnt {
  padding-left: 40px; }

#faculty-conductor .title {
  color: #507796;
  font-family: "AkkuratPro-Regular";
  font-size: 12px;
  letter-spacing: 3px;
  line-height: 16px;
  margin-top: 20px;
  text-transform: uppercase; }

.faculty-title {
  color: #507796;
  font-family: "AkkuratPro-Bold";
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 3px; }

.faculty-social {
  list-style: none;
  padding: 0; }
  .faculty-social li {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px; }
    .faculty-social li a {
      display: inline-block;
      width: 30px;
      height: 30px;
      text-indent: -9999px; }
    .faculty-social li.facebook {
      background: url(../images/facebook-single.svg) 50% no-repeat;
      background-size: 30px 30px; }
    .faculty-social li.twitter {
      background: url(../images/twitter-single.svg) 50% no-repeat;
      background-size: 30px 30px; }
    .faculty-social li.youtube {
      background: url(../images/youtube-single.svg) 50% no-repeat;
      background-size: 30px 30px; }
    .faculty-social li.instagram {
      background: url(../images/instagram-single.svg) 50% no-repeat;
      background-size: 30px 30px; }
    .faculty-social li.soundcloud {
      background: url(../images/soundcloud-single.svg) 50% no-repeat;
      background-size: 30px 30px; }

#people-cards {
  position: relative;
  max-width: 1240px;
  margin: 20px auto; }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  #students.with-popup > .row {
    padding-left: 0;
    padding-right: 0; } }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  #faculty {
    padding: 100px 50px; }
  #faculty-conductor .photo {
    height: 300px; }
  #faculty-conductor .cnt {
    padding-left: 0; }
  #faculty-conductor .btn {
    min-width: 100%; }
  #faculty-detail-resources {
    margin: 0 auto; }
  #faculty-detail-resources > .row > .col-xs-6 {
    flex-basis: 100%;
    max-width: 100%; }
  #students .col-xs-3 {
    flex-basis: 50%;
    max-width: 50%;
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    margin-bottom: 50px; }
  .students {
    margin-bottom: 0; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .faculty .col-xs-4, .people-bio .col-xs-4 {
    flex-basis: 100%;
    max-width: 100%; }
  .faculty .thumb, .students .thumb, .people-bio .thumb {
    max-width: 260px; }
  #faculty .col-xs-3,
  #students .col-xs-3 {
    margin-bottom: 50px; }
  #faculty .col-xs-3,
  #faculty .col-xs-3:first-child,
  #faculty .col-xs-3:last-child,
  #faculty .col-xs-4,
  #faculty .col-xs-4:first-child,
  #faculty .col-xs-4:last-child,
  #students .col-xs-3,
  #students .col-xs-3:first-child,
  #students .col-xs-3:last-child {
    padding-left: .5rem;
    padding-right: .5rem; }
  .faculty, .students, .people-bio {
    margin: 0; }
  #faculty-conductor .photo {
    max-width: 380px;
    margin-bottom: 30px; }
  #faculty-conductor .btn {
    max-width: 280px;
    min-width: 280px; }
  #about-faculty img {
    margin-bottom: 30px; }
  .faculty-popup, .bio-popup {
    margin-bottom: 30px; }
  #admin .faculty-popup {
    margin-left: .5rem;
    margin-right: .5rem; }
  .faculty-popup .col-xs-4, .bio-popup .col-xs-4 {
    flex-basis: 50% !important;
    max-width: 50% !important; }
  .faculty-popup .col-xs-8, .bio-popup .col-xs-8 {
    flex-basis: 50% !important;
    max-width: 50% !important; }
  .faculty-popup .photo, .bio-popup .photo {
    height: 100% !important;
    max-width: 100% !important; }
  .faculty .col-xs-4:first-child,
  .students .col-xs-4:first-child,
  .people-bio .col-xs-4:first-child {
    padding: 0 .5rem !important; }
  .faculty .photo-excerpts .col-xs-4:after,
  .people-bio .photo-excerpts .col-xs-4:after {
    display: none !important; }
  .student-popup .photo {
    position: relative; }
  .section-faculty .fixed-width .col-xs-4 {
    order: 1; }
  .section-faculty .fixed-width .col-xs-8 {
    order: 0; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  #faculty-conductor {
    padding: 0; }
  #faculty-conductor .btn {
    max-width: 100%;
    min-width: 100%; }
  .faculty-popup .col-xs-4 {
    flex-basis: 100% !important;
    max-width: 100% !important; }
  .faculty-popup .col-xs-8 {
    flex-basis: 100% !important;
    max-width: 100% !important; }
  .faculty-popup .photo {
    position: relative; }
  .faculty-popup .cnt {
    padding: 30px !important; }
  .student-popup .photo {
    min-height: 460px; }
  .student-popup .cnt {
    padding: 30px; }
  #students .col-xs-3 {
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  #faculty {
    padding: 50px 20px;
    margin: 20px auto 0 auto; }
  #students.with-popup > .row {
    padding-left: 0px;
    padding-right: 0px; }
  .faculty .thumb, .students .thumb {
    max-width: 100%; }
  #area-of-study {
    padding: 50px 20px 0 20px;
    margin: 0 auto 20px auto; }
  .student-popup .photo {
    min-height: 360px; } }

.recital {
  display: inline-block;
  padding: 20px 0 40px 0;
  width: 100%; }
  .recital .date {
    color: #507796;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0; }
  .recital h3 {
    margin: 10px 0; }
  .recital .location {
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 20px;
    padding-left: 20px;
    background: url(../images/location-icon.svg) left 4px no-repeat; }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .recital .btns-cnt .btn,
  .recital .btns-cnt .purple-btn {
    width: 100%;
    min-width: 100%;
    margin: 5px 0; } }

.pagination {
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: right; }
  .pagination .prev, .pagination .news-prev {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: top;
    background: url(../images/purple-arrow.svg) 50% no-repeat;
    background-size: 100%;
    transform: rotate(90deg); }
    .pagination .prev:hover, .pagination .news-prev:hover {
      opacity: .5; }
  .pagination .next, .pagination .news-next {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: top;
    background: url(../images/purple-arrow.svg) 50% no-repeat;
    background-size: 100%;
    transform: rotate(-90deg); }
    .pagination .next:hover, .pagination .news-next:hover {
      opacity: .5; }
  .pagination ul {
    display: inline-block;
    list-style: none;
    vertical-align: top;
    padding: 0 40px;
    margin: 0; }
    .pagination ul li {
      display: inline-block;
      position: relative;
      width: auto;
      color: #4e2a84;
      font-family: "AkkuratPro-Bold";
      font-size: 16px;
      line-height: 24px;
      margin: 0; }
      .pagination ul li.current {
        padding: 0 10px; }
      .pagination ul li.current:after {
        position: absolute;
        content: '';
        width: 4px;
        height: 4px;
        background: #4e2a84;
        border-radius: 4px;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0); }
      .pagination ul li a {
        display: inline-block;
        width: 100%;
        font-family: "AkkuratPro-Regular";
        font-size: 16px;
        line-height: 24px;
        color: #6f8ca6;
        padding: 0 10px;
        margin: 0; }
        .pagination ul li a:hover, .pagination ul li a:focus {
          color: #4e2a84;
          font-family: "AkkuratPro-Bold"; }
      .pagination ul li:hover:after, .pagination ul li:focus:after {
        position: absolute;
        content: '';
        width: 4px;
        height: 4px;
        background: #4e2a84;
        border-radius: 4px;
        bottom: -5px;
        left: 50%;
        transform: translate(-50%, 0); }

.pager__item a {
  text-decoration: none; }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .pagination {
    text-align: center; }
  .pagination ul {
    padding: 0 20px; }
  .pagination ul li {
    margin: 0 2px; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .pagination .prev,
  .pagination .next,
  .pagination .news-prev,
  .pagination .news-next {
    display: none; }
  .pagination ul li {
    margin-bottom: 10px; } }

.jobs .col-xs-4 {
  padding-left: calc(.5rem + 10px);
  padding-right: calc(.5rem + 10px);
  margin-bottom: 30px; }

.jobs .col-xs-4:first-child {
  padding-left: .5rem;
  padding-right: calc(.5rem + 10px); }

.jobs .col-xs-4:last-child {
  padding-left: calc(.5rem + 10px);
  padding-right: .5rem; }

.jobs .thumb-wrap {
  position: relative;
  width: 100%;
  height: 230px;
  overflow: hidden; }
  .jobs .thumb-wrap .thumb {
    position: relative;
    width: 100%;
    height: 230px;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s; }
    .jobs .thumb-wrap .thumb:hover, .jobs .thumb-wrap .thumb:focus {
      transform: scale(1.1); }
    .jobs .thumb-wrap .thumb a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }

.jobs a.job-title {
  display: block;
  color: #4e2a84;
  font-family: "Poppins-Medium";
  font-size: 24px;
  line-height: 32px;
  margin: 20px 0 0 0; }

.jobs .col-xs-4.is_hover .thumb {
  transform: scale(1.1); }

.jobs .col-xs-4.is_hover .job-title span {
  background-size: 100% 3px; }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  .jobs a.job-title {
    font-size: 18px;
    line-height: 26px; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .jobs .col-xs-4,
  .jobs .col-xs-4:first-child,
  .jobs .col-xs-4:last-child {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: .5rem;
    padding-right: .5rem; }
  .jobs .thumb-wrap {
    max-width: 230px; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  .jobs .thumb-wrap {
    max-width: 100%; }
  .page-template-template-student-employment-position .btn {
    width: 100%; } }

#academics .col-xs-6 {
  padding-left: calc(.5rem + 10px);
  padding-right: calc(.5rem + 10px); }

#academics .col-xs-4 {
  padding-left: calc(.5rem + 10px);
  padding-right: calc(.5rem + 10px); }

.academic-box {
  position: relative;
  width: 100%;
  height: 280px;
  background: #000;
  margin-bottom: 40px;
  overflow: hidden; }
  .academic-box:hover .bg {
    transform: scale(1.1);
    filter: grayscale(0);
    filter: none; }
  .academic-box a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9; }
  .academic-box .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 7;
    opacity: .6;
    background-size: cover !important;
    transform: scale(1);
    filter: grayscale(100%);
    filter: gray;
    transition: .4s; }
  .academic-box span {
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 8;
    color: #ffffff;
    font-family: "Poppins-Medium";
    font-size: 24px;
    line-height: 32px; }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  .academic-box span {
    font-size: 18px;
    line-height: 26px;
    padding-right: 20px; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .academic-box span {
    font-size: 24px;
    line-height: 32px; }
  #academics .col-xs-6,
  #academics .col-xs-6:first-child,
  #academics .col-xs-6:last-child {
    padding-left: .5rem;
    padding-right: .5rem; }
  #academics .col-xs-4,
  #academics .col-xs-4:first-child,
  #academics .col-xs-4:last-child {
    padding-left: .5rem;
    padding-right: .5rem; } }

.photo-excerpts .col-xs-3 {
  padding-left: calc(.5rem + 10px);
  padding-right: calc(.5rem + 10px); }

.photo-excerpts .col-xs-3:first-child {
  padding-left: .5rem;
  padding-right: calc(.5rem + 10px); }

.photo-excerpts .col-xs-3:last-child {
  padding-left: calc(.5rem + 10px);
  padding-right: .5rem; }

.photo-excerpts .col-xs-4 {
  padding-left: calc(.5rem + 10px);
  padding-right: calc(.5rem + 10px); }

.photo-excerpts .col-xs-4:nth-child(1) {
  padding-left: .5rem;
  padding-right: calc(.5rem + 10px); }

.photo-excerpts .col-xs-4:nth-child(3) {
  padding-left: calc(.5rem + 10px);
  padding-right: .5rem; }

.photo-excerpts .cnt {
  padding-bottom: 50px; }

.photo-excerpts .col-xs-4 .photo-wrap, .photo-excerpts .col-xs-3 .photo-wrap {
  display: block;
  width: 100%;
  height: 260px;
  overflow: hidden; }
  .photo-excerpts .col-xs-4 .photo-wrap:hover .photo, .photo-excerpts .col-xs-3 .photo-wrap:hover .photo {
    transform: scale(1.1); }

.photo-excerpts .col-xs-4 .photo, .photo-excerpts .col-xs-3 .photo {
  display: block;
  width: 100%;
  height: 260px;
  background-size: cover !important;
  transition: .4s;
  transform: scale(1); }
  .photo-excerpts .col-xs-4 .photo a, .photo-excerpts .col-xs-3 .photo a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.photo-excerpts .col-xs-4 .excerpt-title, .photo-excerpts .col-xs-3 .excerpt-title {
  display: inline-block;
  width: 100%;
  color: #4e2a84;
  font-family: "Poppins-Medium";
  font-size: 24px;
  line-height: 32px;
  text-decoration: none; }

.photo-excerpts .col-xs-4 .excerpt-area, .photo-excerpts .col-xs-3 .excerpt-area {
  display: inline-block;
  position: relative;
  top: -15px;
  width: 100%;
  color: #4f7796;
  font-family: "AkkuratPro-Bold";
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 0 35px 0; }

.photo-excerpts .col-xs-4 .excerpt-area + .tertiary-btn, .photo-excerpts .col-xs-3 .excerpt-area + .tertiary-btn {
  position: relative;
  top: -30px;
  margin-bottom: -30px; }

.photo-excerpts .col-xs-4 .read-link, .photo-excerpts .col-xs-3 .read-link {
  display: inline-block;
  position: relative;
  top: -40px;
  margin-bottom: -40px;
  color: #4e2a84;
  font-family: "AkkuratPro-Bold";
  font-size: 16px;
  line-height: 24px;
  padding-right: 30px;
  text-decoration: none; }
  .photo-excerpts .col-xs-4 .read-link .ico, .photo-excerpts .col-xs-3 .read-link .ico {
    position: absolute;
    top: 50%;
    right: 0;
    width: 15px;
    height: 15px;
    background: url(../images/purple-arrow.svg) right top no-repeat;
    transform: translate(0, -50%) rotate(-90deg);
    background-size: 100% !important; }

.photo-excerpts p {
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 16px;
  line-height: 28px; }

.photo-excerpts a {
  margin-bottom: 20px;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word; }
  .photo-excerpts a.body-link.email {
    font-size: 14px !important; }

.photo-excerpts .tertiary-btn {
  margin-bottom: 40px; }

.photo-excerpts .col-xs-6 {
  padding-left: calc(.5rem + 10px);
  padding-right: calc(.5rem + 10px); }

.photo-excerpts .col-xs-6:nth-child(1) {
  padding-left: .5rem;
  padding-right: calc(.5rem + 10px); }

.photo-excerpts .col-xs-6:nth-child(2) {
  padding-left: calc(.5rem + 10px);
  padding-right: .5rem; }

.photo-excerpts .col-xs-6 .photo {
  display: block;
  width: 100%;
  height: 360px;
  background-size: cover !important; }

.photo-excerpts .col-xs-6 .excerpt-title {
  color: #4e2a84;
  font-family: "Poppins-Medium";
  font-size: 24px;
  line-height: 32px;
  text-decoration: none; }

.photo-excerpts .col-xs-4.is_hover .photo {
  transform: scale(1.1); }

.photo-excerpts .col-xs-4.is_hover .excerpt-title span {
  background-size: 100% 3px; }

.photo-excerpts .cnt.is_hover .photo {
  transform: scale(1.1); }

.photo-excerpts .cnt.is_hover .excerpt-title span {
  background-size: 100% 3px; }

.photo-excerpts + hr {
  margin-top: 40px; }

.photo-excerpts-sm .col-xs-6 {
  padding-left: calc(.5rem + 10px);
  padding-right: calc(.5rem + 10px); }

.photo-excerpts-sm .col-xs-6:first-child {
  padding-left: .5rem;
  padding-right: calc(.5rem + 10px); }

.photo-excerpts-sm .col-xs-6:last-child {
  padding-left: calc(.5rem + 10px);
  padding-right: .5rem; }

.photo-excerpts-sm .col-xs-6 .photo-wrap {
  display: block;
  width: 100%;
  height: 230px;
  overflow: hidden; }

.photo-excerpts-sm .col-xs-6 .photo {
  display: block;
  width: 100%;
  height: 230px;
  background-size: cover !important;
  transform: scale(1);
  transition: .4s; }
  .photo-excerpts-sm .col-xs-6 .photo a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.photo-excerpts-sm .col-xs-6 .excerpt-title {
  display: inline-block;
  width: 100%;
  color: #4e2a84;
  font-family: "Poppins-Medium";
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
  text-decoration: none; }

.photo-excerpts-sm .col-xs-6.is_hover .photo {
  transform: scale(1.1); }

.photo-excerpts-sm .col-xs-6.is_hover .excerpt-title span {
  background-size: 100% 3px; }

.photo-cta {
  background-image: linear-gradient(145deg, rgba(224, 232, 238, 0.25) 0%, #e0e8ee 100%);
  border-bottom: 1px solid #fff; }
  .photo-cta .photo {
    position: relative;
    width: 100%;
    height: 500px;
    background-size: cover !important; }
  .photo-cta .cnt {
    padding: 0 80px; }

.right-photo-cta {
  padding: 40px 0; }
  .right-photo-cta .photo {
    position: relative;
    width: 100%;
    max-width: 350px;
    height: 350px;
    float: right;
    background-size: cover !important; }
  .right-photo-cta.shadow .photo {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }
  .right-photo-cta .cnt {
    padding: 0 80px 0 0; }
  .right-photo-cta .btn-cnt {
    position: relative;
    width: 100%; }
    .right-photo-cta .btn-cnt a:before {
      position: absolute;
      content: '';
      top: 50%;
      right: 0;
      left: inherit;
      width: 0;
      height: 1px;
      background: #4e2a84;
      transform: translate(0, -50%);
      transition: 1.4s; }
    .right-photo-cta .btn-cnt .btn:hover {
      background: #4e2a84 !important; }

.left-photo-cta {
  padding: 40px 0; }
  .left-photo-cta .photo {
    position: relative;
    width: 100%;
    max-width: 350px;
    height: 350px;
    background-size: cover !important; }
  .left-photo-cta.shadow .photo {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }
  .left-photo-cta .cnt {
    padding: 0 0 0 80px; }
  .left-photo-cta .btn-cnt {
    position: relative;
    width: 100%; }
    .left-photo-cta .btn-cnt a:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      right: inherit;
      width: 0;
      height: 1px;
      background: #4e2a84;
      transform: translate(0, -50%);
      transition: 1.4s; }
    .left-photo-cta .btn-cnt .btn:hover {
      background: #4e2a84 !important; }

.right-photo-cta-lg {
  padding: 40px 0; }
  .right-photo-cta-lg .photo {
    position: relative;
    width: 100%;
    height: 500px;
    background-size: cover !important; }
  .right-photo-cta-lg.shadow .photo {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }
  .right-photo-cta-lg .cnt {
    padding: 0 80px 0 0; }
  .right-photo-cta-lg.on .btn-cnt a:before {
    width: 200px;
    right: -200px; }
  .right-photo-cta-lg .btn-cnt {
    position: relative;
    width: 100%; }
    .right-photo-cta-lg .btn-cnt a:before {
      position: absolute;
      content: '';
      top: 50%;
      right: 0;
      left: inherit;
      width: 0;
      height: 1px;
      background: #4e2a84;
      transform: translate(0, -50%);
      transition: 1.4s; }

.left-photo-cta-lg {
  padding: 40px 0; }
  .left-photo-cta-lg .photo {
    position: relative;
    width: 100%;
    height: 500px;
    background-size: cover !important; }
  .left-photo-cta-lg.shadow .photo {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); }
  .left-photo-cta-lg .cnt {
    padding: 0 0 0 80px; }
  .left-photo-cta-lg.on .btn-cnt a:before {
    width: 200px;
    left: -200px; }
  .left-photo-cta-lg .btn-cnt {
    position: relative;
    width: 100%; }
    .left-photo-cta-lg .btn-cnt a:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      right: inherit;
      width: 0;
      height: 1px;
      background: #4e2a84;
      transform: translate(0, -50%);
      transition: 1.4s; }

.right-thumb-cta {
  padding: 40px 0; }
  .right-thumb-cta .thumb {
    position: relative;
    width: 100%;
    height: 230px;
    background-size: cover !important; }
  .right-thumb-cta .cnt {
    padding-right: 20px; }

.left-thumb-cta {
  padding: 40px 0; }
  .left-thumb-cta .thumb {
    position: relative;
    width: 100%;
    height: 230px;
    background-size: cover !important; }
  .left-thumb-cta .cnt {
    padding-left: 20px; }

.workshop .date {
  color: #000000;
  font-family: "Poppins-Medium";
  font-size: 32px;
  line-height: 42px; }

.workshop .time {
  color: #000000;
  font-family: "AkkuratPro-Regular";
  font-size: 24px;
  line-height: 36px; }

.workshop .photo {
  position: relative;
  width: 100%;
  height: 500px;
  background-size: cover !important; }
  .workshop .photo .caption {
    position: absolute;
    left: 0;
    bottom: 50px;
    z-index: 999;
    padding: 40px;
    background: #4e2a84;
    width: 75%;
    color: #ffffff;
    font-family: "AkkuratPro-Regular";
    font-size: 24px;
    line-height: 36px; }

.event-cta {
  margin: 40px 0; }
  .event-cta .col-xs-6 {
    padding-left: calc(.5rem + 10px);
    padding-right: calc(.5rem + 10px); }
  .event-cta .col-xs-6:first-child {
    padding-left: 0;
    padding-right: calc(.5rem + 10px); }
  .event-cta .col-xs-6:last-child {
    padding-left: calc(.5rem + 10px);
    padding-right: 0; }
  .event-cta .col-xs-6 .thumb-wrap {
    display: block;
    width: 100%;
    height: 230px;
    overflow: hidden; }
  .event-cta .col-xs-6 .thumb {
    display: block;
    width: 100%;
    height: 230px;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s; }
    .event-cta .col-xs-6 .thumb a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .event-cta .date {
    color: #4f7796;
    font-family: "AkkuratPro-Bold";
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 30px 0 0 0; }
  .event-cta h3 {
    margin: 5px 0; }
  .event-cta .location {
    display: inline-block;
    width: 100%;
    color: #819eb5;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 20px;
    padding-left: 20px;
    background: url(../images/location-icon.svg) left center no-repeat;
    text-align: left;
    margin: 0 0 20px 0; }
  .event-cta .col-xs-6.is_hover .thumb {
    transform: scale(1.1); }
  .event-cta .col-xs-6.is_hover h3 a span {
    background-size: 100% 3px; }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .workshop .btn {
    max-width: 100%;
    min-width: 100%; }
  .left-photo-cta .cnt {
    padding-left: 20px; }
  .right-photo-cta .cnt {
    padding-right: 20px; }
  .left-photo-cta .btn,
  .right-photo-cta .btn {
    max-width: 100%;
    min-width: 100%; } }

@media only screen and (max-width: 1023px) and (min-width: 1px) {
  .photo-cta .header1 {
    font-size: 32px;
    line-height: 40px; }
  .photo-excerpts .col-xs-4 .excerpt-title {
    font-size: 18px;
    line-height: 26px; }
  .right-photo-cta .cnt {
    padding: 0 20px 0 0; }
  .right-photo-cta .btn {
    min-width: 100%;
    max-width: 100%; }
  .workshop .col-xs-5,
  .workshop .col-xs-7 {
    flex-basis: 100%;
    max-width: 100%; }
  .workshop .photo {
    margin-top: 30px; }
  .photo-cta .transparent-btn {
    min-width: 100%;
    width: 100%; }
  .photo-cta .cnt {
    padding: 0 20px; }
  .right-photo-cta-lg .btn,
  .left-photo-cta-lg .btn {
    min-width: 100%; }
  .right-photo-cta-lg .photo {
    margin-top: 50px; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .photo-excerpts .col-xs-4 {
    flex-basis: 100%;
    max-width: 100%; }
  .photo-excerpts .col-xs-4,
  .photo-excerpts .col-xs-4:first-child,
  .photo-excerpts .col-xs-4:last-child {
    padding-left: .5rem;
    padding-right: .5rem; }
  .photo-excerpts .col-xs-4 .photo {
    max-width: 230px; }
  .photo-excerpts .tertiary-btn {
    max-width: 160px;
    min-width: 160px; }
  .photo-excerpts .col-xs-6 .photo {
    max-width: 360px; }
  .photo-excerpts .col-xs-6 {
    margin-bottom: 30px; }
  .photo-excerpts .col-xs-6,
  .photo-excerpts .col-xs-6:first-child,
  .photo-excerpts .col-xs-6:last-child {
    padding-left: .5rem;
    padding-right: .5rem; }
  .right-photo-cta .cnt {
    padding: 0; }
  .right-photo-cta .col-xs-6:first-child {
    order: 1; }
  .right-photo-cta .col-xs-6:last-child {
    order: 0; }
  .right-photo-cta .photo,
  .left-thumb-cta .thumb {
    max-width: 100%;
    margin-bottom: 30px; }
  .left-photo-cta .btn,
  .right-photo-cta .btn {
    max-width: 280px;
    min-width: 280px; }
  .left-thumb-cta .cnt {
    padding-left: 0; }
  .left-photo-cta .cnt {
    padding: 0; }
  .left-photo-cta .photo {
    max-width: 100%;
    margin-bottom: 30px; }
  .photo-cta .photo {
    height: 300px; }
  .photo-cta .cnt {
    padding: 30px 20px; }
  .photo-excerpts-sm .col-xs-6:last-child {
    padding-left: .5rem; }
  .photo-excerpts-sm .col-xs-6:first-child {
    padding-right: .5rem; }
  .photo-excerpts-sm .col-xs-6 {
    margin-bottom: 30px; }
  .left-photo-cta .end-xs {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
    text-align: start; }
  .event-cta .col-xs-6 {
    margin-bottom: 40px; }
  .event-cta .col-xs-6:last-child {
    padding-right: .5rem;
    padding-left: .5rem; }
  .event-cta .col-xs-6:first-child {
    padding-right: .5rem;
    padding-left: .5rem; }
  .event-cta {
    margin: 0; }
  .right-photo-cta-lg.row.margin-top-lg {
    padding-top: 0 !important; }
  .right-photo-cta-lg .col-xs-4 {
    order: 1; }
  .right-photo-cta-lg .col-xs-8 {
    order: 0; }
    .right-photo-cta-lg .col-xs-8 .photo {
      margin-bottom: 30px; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .photo-cta .photo {
    height: 300px; }
  .photo-cta .btn {
    margin-top: 30px !important; }
  .photo-excerpts .col-xs-4 {
    flex-basis: 100%;
    max-width: 100%; }
  .left-photo-cta .btn,
  .right-photo-cta .btn,
  .left-thumb-cta .cnt .btn {
    max-width: 100% !important;
    min-width: 100% !important; }
  .left-thumb-cta .thumb,
  .right-photo-cta .photo,
  .left-photo-cta .photo {
    height: 200px; }
  .workshop .photo .caption {
    font-size: 18px;
    line-height: 24px;
    bottom: 0;
    width: 100%; }
  .right-photo-cta-lg .photo,
  .left-photo-cta-lg .photo {
    height: 300px; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  .photo-excerpts .tertiary-btn {
    max-width: 100%;
    min-width: 100%; }
  .photo-excerpts .col-xs-4 .photo {
    max-width: 100%; }
  .photo-cta .cnt {
    padding: 30px 20px; }
  .photo-excerpts .col-xs-6 .photo {
    height: 250px; }
  .left-photo-cta .btn, .right-photo-cta .btn {
    max-width: 100%;
    min-width: 100%; }
  .photo-excerpts .cnt {
    padding-bottom: 0; } }

#graduates {
  padding: 100px 0 100px 150px; }
  #graduates .photo {
    width: 100%;
    height: 500px;
    background-size: cover !important; }
  #graduates .cnt {
    padding-right: 150px; }
  #graduates ul {
    list-style: none;
    padding: 0;
    margin: 40px 0; }
    #graduates ul li {
      color: #000000;
      font-family: "AkkuratPro-Regular";
      font-size: 16px;
      line-height: 28px;
      margin: 10px 0; }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  #graduates {
    padding: 100px 0 0 50px; }
  #graduates .cnt {
    padding-right: 50px; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  #graduates {
    padding: 100px 50px; }
  #graduates .cnt {
    padding-right: 0; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  #graduates {
    padding: 100px 20px; }
  #graduates .photo {
    height: 300px; } }

#ensembles {
  padding: 100px 0 100px 150px; }

.resource {
  padding: 20px 0;
  border-bottom: 1px solid #aec1d0; }
  .resource .day {
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0 0 5px 0; }
  .resource .month {
    color: #000000;
    font-family: "AkkuratPro-Bold";
    font-size: 18px;
    line-height: 24px;
    margin: 0; }
  .resource .date {
    color: #000000;
    font-family: "PeriodicoDisplay-XtLg";
    font-size: 52px;
    letter-spacing: -1px;
    line-height: 62px; }
  .resource .time {
    margin: 5px 0 10px 0;
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    text-transform: uppercase; }
  .resource .location {
    margin: 0;
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 20px;
    padding: 0 0 0 20px;
    background: url(../images/location-icon.svg) left 3px no-repeat; }

#faculty-calendar {
  display: inline-block;
  position: relative;
  width: 100%;
  z-index: 99;
  min-height: 180px; }
  #faculty-calendar .fc-toolbar.fc-header-toolbar {
    display: block; }
  #faculty-calendar .fc-right {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0; }
  #faculty-calendar .fc-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 999; }
    #faculty-calendar .fc-left .fc-button-group {
      width: 100%;
      margin-top: 20px;
      text-align: left; }
    #faculty-calendar .fc-left .btn {
      float: none;
      min-width: 170px;
      max-width: 170px;
      border-radius: 0;
      padding: 5px 20px;
      height: auto;
      margin-right: 20px;
      text-shadow: none;
      box-shadow: none;
      background-image: none;
      background: #f6f4f9;
      color: #4e2a84;
      transition: background .4s; }
      #faculty-calendar .fc-left .btn:hover, #faculty-calendar .fc-left .btn:focus {
        background: #4e2a84;
        color: #fff; }
    #faculty-calendar .fc-left .fc-state-active {
      background: #4e2a84;
      color: #fff; }
  #faculty-calendar .fc-center {
    position: absolute;
    width: 100%;
    top: 80px;
    left: 0;
    padding: 50px 0 0 0; }
    #faculty-calendar .fc-center .fc-button-group {
      margin-top: 20px; }
      #faculty-calendar .fc-center .fc-button-group .fc-prev-button {
        background: transparent;
        border: none;
        box-shadow: none; }
        #faculty-calendar .fc-center .fc-button-group .fc-prev-button span {
          display: block;
          width: 20px;
          height: 20px;
          background: url(../images/purple-arrow.svg) 50% no-repeat;
          transform: rotate(90deg); }
          #faculty-calendar .fc-center .fc-button-group .fc-prev-button span:after {
            display: none; }
      #faculty-calendar .fc-center .fc-button-group .fc-next-button {
        position: relative;
        background: transparent;
        border: none;
        box-shadow: none; }
        #faculty-calendar .fc-center .fc-button-group .fc-next-button:before {
          position: absolute;
          content: '';
          top: -4px;
          left: 0;
          width: 2px;
          height: 40px;
          background: #a18fbc; }
        #faculty-calendar .fc-center .fc-button-group .fc-next-button span {
          display: block;
          width: 20px;
          height: 20px;
          background: url(../images/purple-arrow.svg) 50% no-repeat;
          transform: rotate(-90deg); }
          #faculty-calendar .fc-center .fc-button-group .fc-next-button span:after {
            display: none; }
    #faculty-calendar .fc-center h2 {
      width: auto;
      color: #000000;
      font-family: "PeriodicoDisplay-XtLg";
      font-size: 52px;
      letter-spacing: -1px;
      line-height: 62px;
      margin-left: 20px; }
  #faculty-calendar .fc-view-container {
    display: block !important;
    padding-top: 200px; }
  #faculty-calendar ul#filters {
    display: inline-block;
    list-style: none;
    width: 48%;
    float: right;
    padding: 0;
    margin: 0;
    position: relative;
    top: 10px;
    text-align: right; }
    #faculty-calendar ul#filters .filter-title {
      display: none;
      color: #000000;
      font-family: "Poppins-Medium";
      font-size: 32px;
      letter-spacing: -1px;
      line-height: 42px;
      text-transform: none; }
    #faculty-calendar ul#filters li {
      display: inline-block;
      vertical-align: top;
      width: 40%;
      color: #507796;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      letter-spacing: 3px;
      line-height: 50px;
      text-transform: uppercase;
      text-align: left;
      margin-right: 10px;
      margin-bottom: 10px;
      margin-top: 0; }
      #faculty-calendar ul#filters li:first-child {
        padding-right: 20px; }
      #faculty-calendar ul#filters li label {
        margin-top: 0 !important;
        color: #4f7796;
        font-family: "AkkuratPro-Regular";
        font-size: 14px;
        line-height: 20px; }
      #faculty-calendar ul#filters li input[type=checkbox] + label:before {
        display: inline-block;
        border-radius: 20px;
        top: 50%;
        width: 20px;
        height: 20px;
        border: 3px solid #819eb5;
        transform: translate(0, -50%); }
      #faculty-calendar ul#filters li input[type=checkbox]:checked + label.university:before {
        content: '';
        background-image: none;
        background-color: #bd10e0;
        border: 3px solid #bd10e0; }
      #faculty-calendar ul#filters li input[type=checkbox]:checked + label.deadlines:before {
        content: '';
        background-image: none;
        background-color: #f1951c;
        border: 3px solid #f1951c; }
      #faculty-calendar ul#filters li input[type=checkbox]:checked + label.meetings-events:before {
        content: '';
        background-image: none;
        background-color: #4a90e2;
        border: 3px solid #4a90e2; }
      #faculty-calendar ul#filters li input[type=checkbox]:checked + label.bienen-events:before {
        content: '';
        background-image: none;
        background-color: #79cc19;
        border: 3px solid #79cc19; }
  #faculty-calendar .fc-row {
    border: none;
    min-height: 0; }
  #faculty-calendar .fc-head-container {
    border-top: 1px solid #aec1d0;
    border-bottom: 5px solid #e0e7ed;
    border-left: none;
    border-right: none; }
    #faculty-calendar .fc-head-container th {
      border: none;
      color: #000000;
      font-family: "AkkuratPro-Bold";
      font-size: 18px;
      line-height: 26px;
      padding: 25px 0;
      text-align: left; }
  #faculty-calendar .fc-widget-content {
    border: none; }
  #faculty-calendar .fc-row td {
    text-align: left;
    color: #000000;
    font-family: "PeriodicoDisplay-XtLg";
    font-size: 52px;
    letter-spacing: -1px;
    line-height: 62px;
    border: none; }
    #faculty-calendar .fc-row td span {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      padding: 0;
      margin: 40px auto 0 auto;
      float: none;
      z-index: 1; }
  #faculty-calendar .fc-scroller {
    overflow: visible !important;
    height: auto !important; }
  #faculty-calendar .fc-today {
    background: transparent; }
  #faculty-calendar .fc-event {
    border: none;
    background: transparent; }
  #faculty-calendar .fc-event span {
    background: #e0e7ed;
    border-radius: 30px;
    color: #fff; }
  #faculty-calendar .fc-event .fc-content {
    text-align: left;
    margin-bottom: 20px; }
    #faculty-calendar .fc-event .fc-content .fc-time {
      display: inline;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 20px;
      margin: 0 5px 0 0;
      width: auto;
      background: transparent;
      vertical-align: top; }
    #faculty-calendar .fc-event .fc-content .fc-title {
      display: inline;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      width: auto;
      background: transparent;
      color: #3f6384;
      white-space: pre-wrap;
      vertical-align: top;
      text-align: left; }
  #faculty-calendar .fc-content-skeleton tbody td {
    font-size: 12px; }
  #faculty-calendar .fc-event-container {
    position: relative; }
    #faculty-calendar .fc-event-container .past-event {
      opacity: .33; }
  #faculty-calendar .university .fc-time {
    color: #ac00d9; }
  #faculty-calendar .deadlines .fc-time {
    color: #f1951c; }
  #faculty-calendar .meetings-events .fc-time {
    color: #4a90e2; }
  #faculty-calendar .bienen-events .fc-time {
    color: #79cc19; }

.faculty-list-view {
  position: relative; }
  .faculty-list-view .end-xs .col-xs-8 {
    flex-basis: 100%;
    max-width: 100%; }
  .faculty-list-view #faculty-calendar .fc-view-container {
    padding-top: 0; }
  .faculty-list-view #faculty-calendar .fc-center .fc-button-group,
  .faculty-list-view #faculty-calendar .fc-center h2 {
    display: none; }
  .faculty-list-view #faculty-calendar .fc-center {
    padding: 20px 0 0 0; }
  .faculty-list-view #faculty-calendar ul#filters .filter-title {
    display: inline-block !important;
    float: left;
    top: 0; }
  .faculty-list-view #faculty-calendar .col-xs-12:first-child .fc-button-group,
  .faculty-list-view #faculty-calendar .col-xs-12:first-child h2 {
    display: none; }
  .faculty-list-view #faculty-calendar ul#filters {
    width: 100%;
    top: 20px;
    float: none;
    text-align: left;
    border-bottom: 5px solid #e0e7ed; }
  .faculty-list-view #faculty-calendar ul#filters li {
    width: auto;
    position: relative;
    top: 10px;
    margin-right: 20px; }

.faculty-month-view #faculty-calendar .col-xs-4 {
  display: none; }

.faculty-month-view #faculty-calendar .col-xs-8 {
  flex-basis: 100%;
  max-width: 100%; }

.faculty-month-view #faculty-calendar ul#filters .filter-title {
  display: none; }

.faculty-month-view #faculty-calendar .fc-center .fc-button-group {
  display: inline-block; }

.faculty-month-view #faculty-calendar .fc-center h2 {
  display: inline-block; }

.faculty-month-view #faculty-calendar .fc-widget-content {
  height: auto !important; }

.faculty-event {
  padding: 20px 0;
  border-bottom: 1px solid #aec1d0; }
  .faculty-event .day {
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    text-transform: uppercase;
    margin: 0 0 5px 0; }
  .faculty-event .month {
    color: #000000;
    font-family: "AkkuratPro-Bold";
    font-size: 18px;
    line-height: 24px;
    margin: 0; }
  .faculty-event .date {
    color: #000000;
    font-family: "PeriodicoDisplay-XtLg";
    font-size: 52px;
    letter-spacing: -1px;
    line-height: 62px; }
  .faculty-event .title {
    color: #000000;
    font-family: "Poppins-Medium";
    font-size: 24px;
    line-height: 32px;
    margin: 0; }
  .faculty-event .time {
    margin: 5px 0 10px 0;
    color: #4f7796;
    font-family: "AkkuratPro-Bold";
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 16px;
    text-transform: uppercase; }
  .faculty-event.university .time {
    color: #ac00d9; }
  .faculty-event.deadlines .time {
    color: #f1951c; }
  .faculty-event.meetings-events .time {
    color: #4a90e2; }
  .faculty-event.bienen-events .time {
    color: #79cc19; }
  .faculty-event .location {
    margin: 0;
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 20px;
    padding: 0 0 0 20px;
    background: url(../images/location-icon.svg) left 3px no-repeat; }
  .faculty-event .body-link {
    float: right; }

#faculty-mini-calendar {
  display: block;
  position: relative;
  z-index: 999;
  max-width: 295px;
  margin-bottom: 50px; }
  #faculty-mini-calendar .fc-left {
    position: relative;
    width: 10px;
    height: 10px;
    padding: 0;
    background: transparent; }
    #faculty-mini-calendar .fc-left .fc-prev-button {
      background: none;
      border: none;
      box-shadow: none;
      padding: 0; }
      #faculty-mini-calendar .fc-left .fc-prev-button span {
        display: block;
        width: 10px;
        height: 10px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        transform: rotate(90deg);
        background-size: 100%; }
        #faculty-mini-calendar .fc-left .fc-prev-button span:after {
          display: none; }
  #faculty-mini-calendar .fc-right {
    position: relative;
    width: 10px;
    height: 10px;
    padding: 0;
    background: transparent; }
    #faculty-mini-calendar .fc-right .fc-next-button {
      background: none;
      border: none;
      box-shadow: none;
      padding: 0; }
      #faculty-mini-calendar .fc-right .fc-next-button span {
        display: block;
        width: 10px;
        height: 10px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        transform: rotate(-90deg);
        background-size: 100%; }
        #faculty-mini-calendar .fc-right .fc-next-button span:after {
          display: none; }
  #faculty-mini-calendar .fc-center {
    position: relative;
    top: inherit;
    padding: 0;
    width: auto; }
    #faculty-mini-calendar .fc-center h2 {
      color: #000000;
      font-family: "AkkuratPro-Bold";
      font-size: 18px;
      line-height: 26px;
      margin: 0; }
  #faculty-mini-calendar .fc-view-container {
    padding-top: 0 !important;
    padding-left: 0;
    padding-right: 0; }
  #faculty-mini-calendar .fc-toolbar.fc-header-toolbar {
    margin: 0; }
  #faculty-mini-calendar .fc-row {
    border: none;
    min-height: 0; }
  #faculty-mini-calendar .fc-head-container {
    border-top: 1px solid #aec1d0;
    border-bottom: 5px solid #e0e7ed;
    border-left: none;
    border-right: none; }
    #faculty-mini-calendar .fc-head-container th {
      border: none;
      color: #4f7796;
      font-family: "AkkuratPro-Regular";
      font-size: 14px;
      line-height: 20px;
      padding: 10px 0; }
  #faculty-mini-calendar .fc-widget-content {
    border: none; }
  #faculty-mini-calendar .fc-row td {
    text-align: center;
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 30px;
    border: none; }
    #faculty-mini-calendar .fc-row td span {
      display: block;
      position: relative;
      width: 30px;
      height: 30px;
      padding: 0;
      margin: 0 auto;
      float: none;
      z-index: 1; }
  #faculty-mini-calendar .fc-scroller {
    overflow: visible !important;
    height: auto !important; }
  #faculty-mini-calendar .fc-today {
    background: transparent; }
  #faculty-mini-calendar .fc-day-grid-event {
    background: #e0e7ed;
    border-radius: 30px; }
  #faculty-mini-calendar .fc-event {
    border: none;
    background: transparent; }
  #faculty-mini-calendar .fc-event span {
    background: #e0e7ed;
    border-radius: 30px;
    color: #fff; }
  #faculty-mini-calendar .fc-event .fc-content {
    display: none; }
  #faculty-mini-calendar .fc-event-container {
    display: none; }
  #faculty-mini-calendar .fc-event-container a:hover .fc-content,
  #faculty-mini-calendar .fc-event-container a:focus .fc-content {
    display: block; }
  #faculty-mini-calendar .fc-content-skeleton tbody {
    display: none; }
  #faculty-mini-calendar .fc-content-skeleton td span {
    border-radius: 30px;
    background-color: transparent;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 2px solid transparent; }
  #faculty-mini-calendar .fc-content-skeleton .has-events span {
    border-radius: 30px;
    background-color: #e0e7ed;
    cursor: pointer;
    width: 30px;
    height: 30px;
    transition: .4s; }
    #faculty-mini-calendar .fc-content-skeleton .has-events span:hover, #faculty-mini-calendar .fc-content-skeleton .has-events span:focus, #faculty-mini-calendar .fc-content-skeleton .has-events span.active {
      background: #4e2a84;
      color: #fff; }
  #faculty-mini-calendar .fc-bg .tooltip-wrapper,
  #faculty-mini-calendar .fc-bg .fc-day-number {
    display: none; }
  #faculty-mini-calendar .fc-content-skeleton .has-events {
    position: relative; }
  #faculty-mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    width: 300px;
    height: auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    z-index: 9999; }
    #faculty-mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .fc-day-number {
      display: none; }
  #faculty-mini-calendar .fc-content-skeleton .has-events:hover .tooltip-wrapper {
    display: block; }
  #faculty-mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip {
    display: inline-block;
    max-width: 100%;
    width: 100%;
    text-align: left;
    line-height: 16px; }
    #faculty-mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date {
      display: inline;
      width: auto;
      font-family: "AkkuratPro-Bold";
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 16px;
      padding-right: 10px;
      text-transform: uppercase; }
      #faculty-mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.university {
        color: #ac00d9; }
      #faculty-mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.deadlines {
        color: #f1951c; }
      #faculty-mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.meetings-events {
        color: #4a90e2; }
      #faculty-mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .date.bienen-events {
        color: #79cc19; }
    #faculty-mini-calendar .fc-content-skeleton .has-events .tooltip-wrapper .tooltip .title {
      display: inline;
      width: auto;
      color: #4f7796;
      font-size: 14px;
      line-height: 20px; }
  #faculty-mini-calendar .fc-content-skeleton .has-future-events span {
    border-radius: 30px;
    background-color: #e0e7ed;
    cursor: pointer;
    width: 30px;
    height: 30px; }
  #faculty-mini-calendar .fc-today span {
    border: 2px solid #4e2a84 !important;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold"; }
  #faculty-mini-calendar .fc-event-container a {
    display: block;
    width: 30px;
    height: 30px;
    margin: 5px auto;
    background: #e0e7ed;
    border-radius: 30px; }

.faculty-list-view .no-events {
  display: none;
  width: 100%;
  padding: 20px 0 0 0;
  text-align: center;
  font-size: 16px;
  color: #819eb5;
  font-family: "AkkuratPro-Bold"; }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  .faculty-list-view #faculty-calendar ul#filters li {
    width: 25%; }
  .faculty-list-view #faculty-calendar ul#filters {
    padding-bottom: 20px; }
  .faculty-month-view #faculty-calendar ul#filters {
    width: 100%; }
  .faculty-month-view #faculty-calendar ul#filters li {
    width: auto; }
  .faculty-month-view #faculty-calendar .fc-view-container {
    padding-top: 250px; }
  .faculty-month-view #faculty-calendar ul#filters {
    float: left;
    text-align: left;
    top: 25px; } }

@media only screen and (max-width: 1180px) and (min-width: 1px) {
  #faculty-calendar .fc-head-container th {
    font-size: 14px; } }

@media only screen and (max-width: 1080px) and (min-width: 1px) {
  .faculty-list-view #faculty-calendar ul#filters .filter-title {
    width: 100%; }
  .faculty-list-view #faculty-calendar ul#filters li {
    width: 50%;
    margin-right: 0;
    padding-right: 0; }
  .faculty-list-view .list-events {
    padding-top: 50px; } }

@media only screen and (max-width: 980px) and (min-width: 1px) {
  #faculty-resources > .col-xs-4 {
    display: none; }
  #faculty-resources > .col-xs-8 {
    flex-basis: 100%;
    max-width: 100%; }
  #resources .col-xs-9,
  #resources .col-xs-3 {
    flex-basis: 100%;
    max-width: 100%; }
  #resources .col-xs-3 aside {
    margin-top: 50px; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .faculty-event .body-link {
    float: none;
    display: inline-block;
    margin-top: 20px; }
  #resources .col-xs-4 {
    margin-top: 30px; }
  #resources .col-xs-4:first-child {
    margin-top: 0; }
  #submit-news form .col-xs-6:last-child {
    margin-top: 50px; } }

@media only screen and (max-width: 660px) and (min-width: 1px) {
  .faculty-month-view #faculty-calendar ul#filters li {
    width: 50%; }
  .faculty-month-view #faculty-calendar .fc-view-container {
    padding-top: 350px; }
  #faculty-calendar .fc-head-container th {
    font-size: 12px; }
  #faculty-calendar .fc-event-container .university {
    background: #bd10e0;
    width: 20px;
    height: 20px;
    margin: 0 0 5px;
    border-radius: 20px; }
  #faculty-calendar .fc-event-container .deadlines {
    background: #f1951c;
    width: 20px;
    height: 20px;
    margin: 0 0 5px;
    border-radius: 20px; }
  #faculty-calendar .fc-event-container .meetings-events {
    background: #4a90e2;
    width: 20px;
    height: 20px;
    margin: 0 0 5px;
    border-radius: 20px; }
  #faculty-calendar .fc-event-container .bienen-events {
    background: #79cc19;
    width: 20px;
    height: 20px;
    margin: 0 0 5px;
    border-radius: 20px; }
  #faculty-calendar .fc-event-container .fc-content {
    display: none; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  #faculty-calendar .fc-head-container th {
    font-size: 10px; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  #faculty-calendar .fc-left .btn {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 5px 0; }
  .faculty-list-view #faculty-calendar ul#filters li {
    width: 100%; }
  .faculty-list-view .list-events {
    padding-top: 120px; }
  .faculty-list-view #faculty-calendar ul#filters .filter-title {
    margin-top: 20px; }
  #faculty-calendar .fc-right .btn {
    width: 100%;
    margin: 10px 0;
    max-width: 100%; }
  #faculty-calendar .fc-right .fc-button-group {
    max-width: 400px; }
  #faculty-calendar .fc-left, #calendar .fc-right {
    height: auto; }
  #faculty-calendar .fc-right,
  #faculty-calendar .fc-left {
    padding: 0; }
  #faculty-calendar #calendar-search {
    min-width: 100%; }
  #faculty-calendar .fc-head-container th {
    font-size: 10px; }
  #faculty-calendar ul#filters li {
    width: 100%; }
  #faculty-calendar .fc-view-container {
    padding-top: 540px; }
  #faculty-calendar .fc-center h2 {
    font-size: 32px;
    line-height: 42px;
    margin-top: 15px;
    margin-left: 0; }
  #faculty-calendar .fc-center .fc-button-group {
    float: none; }
  #faculty-calendar .fc-head-container th {
    font-size: 6px; }
  #faculty-calendar .fc-row td {
    font-size: 20px; }
  #faculty-calendar .fc-row td span {
    margin: 10px auto 0 auto; }
  #faculty-calendar #calendar-search input[type=text] {
    max-width: 100%; }
  #faculty-calendar .fc-right .fc-button-group {
    max-width: 100%; }
  #faculty-calendar .fc-center h2 {
    width: 100%; }
  .faculty-month-view #faculty-calendar .fc-view-container {
    padding-top: 400px; }
  .faculty-month-view #faculty-calendar ul#filters li {
    width: 100%; } }

#main-content #questions-bottom {
  display: none; }

#search-faq,
#search-resources,
#search-faculty,
#results-search,
#search-news,
.search-filter-section,
#views-exposed-form-news-list-main-archive,
#search-events {
  position: relative;
  margin: 0;
  width: 400px; }
  #search-faq input[type="text"],
  #search-resources input[type="text"],
  #search-faculty input[type="text"],
  #results-search input[type="text"],
  #search-news input[type="text"],
  .search-filter-section input[type="text"],
  #views-exposed-form-news-list-main-archive input[type="text"],
  #search-events input[type="text"] {
    height: 40px;
    border-radius: 2px;
    border: 1px solid #aec1d0;
    background-color: #f2f5f8; }
  #search-faq input[type="submit"],
  #search-resources input[type="submit"],
  #search-faculty input[type="submit"],
  #results-search input[type="submit"],
  #search-news input[type="submit"],
  .search-filter-section input[type="submit"],
  #views-exposed-form-news-list-main-archive input[type="submit"],
  #search-events input[type="submit"] {
    position: absolute;
    top: 20px;
    right: 15px;
    width: 20px;
    min-width: 20px;
    padding: 0;
    margin: 0;
    height: 20px;
    border: none;
    background: url(../images/search-icon.svg) 50% no-repeat;
    background-size: 100%; }
  #search-faq:hover input[type="text"], #search-faq:focus input[type="text"],
  #search-resources:hover input[type="text"],
  #search-resources:focus input[type="text"],
  #search-faculty:hover input[type="text"],
  #search-faculty:focus input[type="text"],
  #results-search:hover input[type="text"],
  #results-search:focus input[type="text"],
  #search-news:hover input[type="text"],
  #search-news:focus input[type="text"],
  .search-filter-section:hover input[type="text"],
  .search-filter-section:focus input[type="text"],
  #views-exposed-form-news-list-main-archive:hover input[type="text"],
  #views-exposed-form-news-list-main-archive:focus input[type="text"],
  #search-events:hover input[type="text"],
  #search-events:focus input[type="text"] {
    box-shadow: 0 0 10px rgba(78, 42, 132, 0.2); }
  #search-faq:hover input[type="submit"], #search-faq:focus input[type="submit"],
  #search-resources:hover input[type="submit"],
  #search-resources:focus input[type="submit"],
  #search-faculty:hover input[type="submit"],
  #search-faculty:focus input[type="submit"],
  #results-search:hover input[type="submit"],
  #results-search:focus input[type="submit"],
  #search-news:hover input[type="submit"],
  #search-news:focus input[type="submit"],
  .search-filter-section:hover input[type="submit"],
  .search-filter-section:focus input[type="submit"],
  #views-exposed-form-news-list-main-archive:hover input[type="submit"],
  #views-exposed-form-news-list-main-archive:focus input[type="submit"],
  #search-events:hover input[type="submit"],
  #search-events:focus input[type="submit"] {
    background: url(../images/search-icon.svg) 50% no-repeat;
    background-size: 100%; }

#search-resources {
  margin: 10px 0; }

.result {
  display: inline-block;
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px solid #aec1d0; }
  .result:first-child {
    border-top: 5px solid #e0e7ed; }
  .result .link {
    color: #4e2a84;
    font-family: "AkkuratPro-Bold";
    font-size: 16px;
    line-height: 24px; }

.filter-bar {
  display: inline-block;
  position: relative;
  z-index: 999;
  width: 100%;
  background: #4e2a84;
  padding: 60px 0; }
  .filter-bar h2,
  .filter-bar label {
    color: #fff; }
  .filter-bar #search-news,
  .filter-bar .search-filter-section,
  .filter-bar #views-exposed-form-news-list-main-archive {
    width: 100%;
    font-size: 0; }
    .filter-bar #search-news > ul,
    .filter-bar .search-filter-section > ul,
    .filter-bar #views-exposed-form-news-list-main-archive > ul {
      width: 100%; }
      .filter-bar #search-news > ul > li,
      .filter-bar .search-filter-section > ul > li,
      .filter-bar #views-exposed-form-news-list-main-archive > ul > li {
        width: calc(50% - 10px);
        display: inline-block; }
      .filter-bar #search-news > ul > li:first-child,
      .filter-bar .search-filter-section > ul > li:first-child,
      .filter-bar #views-exposed-form-news-list-main-archive > ul > li:first-child {
        margin-right: 15px; }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  #search-news,
  .search-filter-section,
  #views-exposed-form-news-list-main-archive {
    margin-bottom: 30px; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  #search-faq,
  #search-resources {
    width: 100%; }
    #search-faq input[type="text"],
    #search-resources input[type="text"] {
      padding: 0 42px 0 22px; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  #search-faculty,
  #search-events,
  #results-search {
    width: 100%; } }

.post {
  display: inline-block;
  width: 100%;
  padding: 10px 0; }
  .post .title {
    display: inline-block;
    width: 100%;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold";
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 10px 0; }
  .post .date {
    color: #507796;
    font-family: "AkkuratPro-Bold";
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 3px; }

#news {
  padding-top: 40px; }
  #news .col-xs-4 {
    padding-left: calc(10px + .5rem);
    padding-right: calc(10px + .5rem);
    margin-bottom: 40px; }
  #news .col-xs-4:first-child {
    padding-left: .5rem;
    padding-right: calc(10px + .5rem); }
  #news .col-xs-4:last-child {
    padding-left: calc(10px + .5rem);
    padding-right: .5rem; }

.news-post {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 40px;
  margin: 0;
  vertical-align: top;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
  .news-post .cnt {
    position: relative; }
  .news-post .thumb-wrap {
    display: inline-block;
    width: 100%;
    height: 180px;
    overflow: hidden; }
  .news-post .thumb {
    display: inline-block;
    width: 100%;
    height: 180px;
    background-size: cover !important;
    transform: scale(1);
    transition: .4s; }
    .news-post .thumb a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .news-post h3 a {
    display: inline-block;
    width: 100%;
    color: #4e2a84;
    font-family: "AkkuratPro-Bold";
    margin: 20px 0 10px 0; }
  .news-post .date {
    color: #507796;
    font-family: "AkkuratPro-Bold";
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 3px; }
  .news-post.is_hover {
    outline: 1px solid #4e2a84;
    box-shadow: 0 0 20px rgba(78, 42, 132, 0.5); }
    .news-post.is_hover .thumb {
      transform: scale(1.1); }
    .news-post.is_hover h3 a span {
      background-size: 100% 3px; }

.course {
  display: inline-block;
  width: 100%;
  padding: 10px 0; }
  .course .title {
    display: inline-block;
    width: 100%;
    color: #4e2a84;
    font-family: "Poppins-Medium";
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin: 0 0 10px 0; }
  .course p {
    color: #000000;
    font-family: "AkkuratPro-Regular";
    font-size: 16px;
    font-weight: 400;
    line-height: 28px; }

#single {
  padding: 100px 0 0 0; }
  #single img {
    width: 100%;
    max-width: 100%;
    margin: 30px 0; }

.caption {
  color: #4f7796;
  font-family: "AkkuratPro-Regular";
  font-size: 14px;
  line-height: 20px;
  padding: 0 50px; }

blockquote {
  display: block;
  position: relative; }
  blockquote.left {
    width: 50%;
    float: left;
    padding: 80px 30px 80px 0; }
  blockquote.right {
    width: 50%;
    float: right;
    padding: 80px 0 80px 30px; }
  blockquote .quote {
    position: relative;
    color: #000000;
    font-family: "Poppins-Medium";
    font-size: 24px;
    font-weight: 500;
    line-height: 32px; }
    blockquote .quote:before {
      position: absolute;
      content: '\201c';
      top: -30px;
      left: 0;
      font-family: "Poppins-Medium"; }
  blockquote .name {
    color: #4f7796;
    font-family: "AkkuratPro-Regular";
    font-size: 14px;
    line-height: 20px; }

.post-video {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 375px;
  margin: 30px 0;
  background-size: cover !important; }
  .post-video a {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%; }
    .post-video a:before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 140px;
      height: 140px;
      transform: translate(-50%, -50%);
      background: url(../images/large-play.png) 50% no-repeat; }

.pdf-box {
  margin-bottom: 80px; }
  .pdf-box .thumb {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 360px;
    height: 520px;
    background-size: cover !important; }
    .pdf-box .thumb span {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
      padding: 8px 10px 5px 10px;
      color: #ffffff;
      z-index: 8;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 4px;
      text-transform: uppercase; }
      .pdf-box .thumb span.purple {
        background-color: #4e2a84; }
  .pdf-box .tertiary-btn {
    display: block;
    margin: 20px auto;
    max-width: 120px; }

#filterby {
  z-index: 9999; }

@media only screen and (max-width: 1330px) and (min-width: 1px) {
  #single {
    padding: 150px 30px 0 30px; } }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  #fanfare .col-xs-4 .pdf-box .thumb {
    height: 380px; } }

@media only screen and (max-width: 880px) and (min-width: 1px) {
  .split-banner .btns-cnt {
    width: 50%; }
  .split-banner .btns-cnt .white-btn {
    min-width: 100%; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  #fanfare .col-xs-4 .pdf-box .thumb {
    height: 580px; }
  #news .col-xs-4,
  #news .col-xs-4:last-child,
  #news .col-xs-4:first-child {
    padding-left: .5rem;
    padding-right: .5rem; }
  #single {
    padding: 50px 30px 0 30px; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  #fanfare .col-xs-4 .pdf-box .thumb {
    height: 480px; }
  .news-post {
    padding: 20px; }
  .filter-bar #search-news > ul > li,
  .filter-bar #views-exposed-form-news-list-main-archive > ul > li {
    width: 100%; }
  blockquote {
    width: 100% !important;
    padding: 80px 30px !important; }
  .caption {
    padding: 0; }
  .post-video {
    height: 275px; } }

@media only screen and (max-width: 380px) and (min-width: 1px) {
  #fanfare .col-xs-4 .pdf-box .thumb {
    height: 360px; } }

.class-row {
  display: inline-block;
  width: 100%;
  padding: 50px 0; }
  .class-row ul li {
    margin: 15px 0; }
  .class-row img {
    display: block;
    margin: 0 auto; }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .class-row img {
    margin: 30px 0; } }

#about {
  max-width: 1660px;
  margin: 0 auto; }
  #about .row {
    margin-left: 0;
    margin-right: 0; }
  #about .row > [class*=col-] {
    padding-left: 0;
    padding-right: 0; }
  #about #dean {
    padding: 100px 0 100px 0; }
    #about #dean .cnt {
      padding-right: 150px;
      padding-left: 150px; }
    #about #dean img {
      width: 100%; }
  #about .located-box {
    display: block;
    position: relative;
    width: 100%;
    height: 480px; }
    #about .located-box .blue-box {
      position: absolute;
      bottom: 0;
      left: 50px;
      z-index: 999;
      padding: 30px;
      width: 260px;
      height: 300px;
      background-color: rgba(129, 158, 181, 0.9); }
      #about .located-box .blue-box p {
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 2px; }
      #about .located-box .blue-box .stat {
        color: #ffffff;
        font-family: "PeriodicoDisplay-XtLg";
        font-size: 52px;
        font-weight: 200;
        line-height: 62px;
        text-transform: none;
        letter-spacing: -1px;
        margin: 0; }
    #about .located-box .bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover !important; }
  #about .events-box {
    display: block;
    position: relative;
    width: 100%;
    height: 720px;
    top: -240px;
    margin-bottom: -240px; }
    #about .events-box .blue-box {
      position: absolute;
      bottom: 120px;
      right: 0;
      z-index: 999;
      padding: 30px;
      width: 260px;
      height: 145px;
      background-color: rgba(129, 158, 181, 0.9); }
      #about .events-box .blue-box p {
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 2px; }
      #about .events-box .blue-box .stat {
        color: #ffffff;
        font-family: "PeriodicoDisplay-XtLg";
        font-size: 52px;
        font-weight: 200;
        line-height: 62px;
        text-transform: none;
        letter-spacing: -1px;
        margin: 0; }
    #about .events-box .bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover !important; }
  #about .founded-box {
    display: block;
    position: relative;
    width: 100%;
    height: 920px; }
    #about .founded-box .blue-box {
      position: absolute;
      top: 320px;
      left: 0;
      z-index: 999;
      padding: 30px;
      width: 460px;
      height: 160px;
      background-color: rgba(129, 158, 181, 0.9); }
      #about .founded-box .blue-box p {
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 2px; }
      #about .founded-box .blue-box .stat {
        color: #ffffff;
        font-family: "PeriodicoDisplay-XtLg";
        font-size: 52px;
        font-weight: 200;
        line-height: 62px;
        text-transform: none;
        letter-spacing: -1px;
        margin: 0; }
    #about .founded-box .bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover !important; }
  #about .founded-copy {
    padding: 80px 120px 0 120px; }
  #about .students-box {
    display: block;
    position: relative;
    width: 100%;
    height: 840px; }
    #about .students-box .blue-box {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 999;
      padding: 200px 30px 30px 30px;
      width: 260px;
      height: 450px;
      background-color: rgba(129, 158, 181, 0.9); }
      #about .students-box .blue-box p {
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 2px; }
      #about .students-box .blue-box .stat {
        color: #ffffff;
        font-family: "PeriodicoDisplay-XtLg";
        font-size: 52px;
        font-weight: 200;
        line-height: 62px;
        text-transform: none;
        letter-spacing: -1px;
        margin: 0; }
    #about .students-box .bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover !important; }
  #about .faculty-box {
    display: block;
    position: relative;
    width: 100%;
    height: 480px;
    top: -280px;
    margin-bottom: -280px; }
    #about .faculty-box .blue-box {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      padding: 30px;
      width: 360px;
      height: 140px;
      background-color: rgba(129, 158, 181, 0.9); }
      #about .faculty-box .blue-box p {
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 2px; }
      #about .faculty-box .blue-box .stat {
        color: #ffffff;
        font-family: "PeriodicoDisplay-XtLg";
        font-size: 52px;
        font-weight: 200;
        line-height: 62px;
        text-transform: none;
        letter-spacing: -1px;
        margin: 0; }
    #about .faculty-box .bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover !important; }
  #about .history-cnt {
    padding: 80px 120px 0 120px; }
  #about #history {
    padding: 120px 0 0 0; }
  #about .visit-cnt {
    padding: 80px 120px 0 120px; }
  #about .programs-box {
    display: block;
    position: relative;
    width: 100%;
    height: 580px;
    margin-bottom: 120px; }
    #about .programs-box .blue-box {
      position: absolute;
      bottom: 80px;
      right: 0;
      z-index: 999;
      padding: 30px;
      width: 250px;
      height: 160px;
      background-color: rgba(129, 158, 181, 0.9); }
      #about .programs-box .blue-box p {
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 2px; }
      #about .programs-box .blue-box .stat {
        color: #ffffff;
        font-family: "PeriodicoDisplay-XtLg";
        font-size: 52px;
        font-weight: 200;
        line-height: 62px;
        text-transform: none;
        letter-spacing: -1px;
        margin: 0; }
    #about .programs-box .bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover !important; }
  #about .guests-box {
    display: block;
    position: relative;
    width: 100%;
    height: 480px; }
    #about .guests-box .blue-box {
      position: absolute;
      top: 0;
      right: 80px;
      z-index: 999;
      padding: 30px;
      width: 180px;
      height: 300px;
      background-color: rgba(129, 158, 181, 0.9); }
      #about .guests-box .blue-box p {
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 2px; }
      #about .guests-box .blue-box .stat {
        color: #ffffff;
        font-family: "PeriodicoDisplay-XtLg";
        font-size: 52px;
        font-weight: 200;
        line-height: 62px;
        text-transform: none;
        letter-spacing: -1px;
        margin: 0; }
    #about .guests-box .bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover !important; }
  #about .degrees-box {
    display: block;
    position: relative;
    width: 100%;
    height: 600px;
    top: -120px;
    margin-bottom: -120px; }
    #about .degrees-box .blue-box {
      position: absolute;
      bottom: 80px;
      right: 0;
      z-index: 999;
      padding: 30px;
      width: 240px;
      height: 160px;
      background-color: rgba(129, 158, 181, 0.9); }
      #about .degrees-box .blue-box p {
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 2px; }
      #about .degrees-box .blue-box .stat {
        color: #ffffff;
        font-family: "PeriodicoDisplay-XtLg";
        font-size: 52px;
        font-weight: 200;
        line-height: 62px;
        text-transform: none;
        letter-spacing: -1px;
        margin: 0; }
    #about .degrees-box .bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover !important; }
  #about .prize-box {
    display: block;
    position: relative;
    width: 100%;
    height: 880px; }
    #about .prize-box .blue-box {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      padding: 30px;
      width: 520px;
      height: 180px;
      background-color: rgba(129, 158, 181, 0.9); }
      #about .prize-box .blue-box p {
        color: #ffffff;
        font-family: "AkkuratPro-Regular";
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 2px; }
      #about .prize-box .blue-box .stat {
        color: #ffffff;
        font-family: "PeriodicoDisplay-XtLg";
        font-size: 52px;
        font-weight: 200;
        line-height: 62px;
        text-transform: none;
        letter-spacing: -1px;
        margin: 0; }
    #about .prize-box .bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-size: cover !important; }
  #about .contact-cnt {
    padding: 0 120px; }

@media only screen and (max-width: 1280px) and (min-width: 1px) {
  #about #dean .cnt {
    padding-left: 50px;
    padding-right: 50px; }
  #about .founded-copy {
    padding: 30px 30px 0 30px; }
  #about .history-cnt,
  #about .visit-cnt {
    padding: 30px 30px 0 30px; }
  #about .contact-cnt {
    padding: 0 30px; }
  #about .prize-box .blue-box {
    width: 100%; } }

@media only screen and (max-width: 980px) and (min-width: 1px) {
  #about .founded-box .blue-box {
    width: 100%; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  #about #dean img {
    width: calc(100% - 100px);
    margin: 50px 50px 0 50px; }
  #dean a {
    margin: 0 50px; }
  #about .events-box {
    top: 0;
    margin-bottom: 0; }
  #about .students-box {
    margin-top: 50px; }
  #about .faculty-box,
  #about .degrees-box {
    top: 0;
    margin-bottom: 0; }
  #about .contact-cnt {
    padding: 30px; }
  #about .programs-box {
    margin-bottom: 0; }
  #about .visit-cnt {
    padding: 30px; }
  #about #history {
    padding: 30px 0 0 0; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  #about .founded-box {
    height: 700px; }
  #about .prize-box {
    height: 680px; } }

@media only screen and (max-width: 480px) and (min-width: 1px) {
  #about .students-box {
    height: 550px; }
  #about .degrees-box {
    height: 400px; }
  #about .students-box .blue-box {
    padding: 100px 30px 30px 30px;
    height: 350px; }
  #about #dean .cnt {
    padding-left: 20px;
    padding-right: 20px; }
  #about #dean img {
    width: calc(100% - 40px);
    margin: 50px 20px 0 20px; }
  #dean a {
    margin: 0 20px; }
  #about #dean {
    padding: 30px 0; } }

@media only screen and (max-width: 380px) and (min-width: 1px) {
  #about .events-box {
    height: 520px; }
  #about .founded-box {
    height: 500px; }
  #history .btn {
    min-width: 100%; } }

#timeline {
  position: relative;
  margin-bottom: 120px; }

#rule {
  display: inline-block;
  padding: 80px 0;
  width: 100%; }

.history-btn {
  position: absolute;
  top: 20px;
  right: 20px; }

.down-line {
  position: absolute;
  left: calc(50% - 2.5px);
  top: 95px;
  width: 5px;
  height: 0;
  background: #e0e7ed;
  z-index: 9999;
  transform: translate(-50%, 0);
  transition: height 1s; }

.history-slider {
  position: relative; }
  .history-slider h1 {
    display: block;
    margin: 80px 0 80px 0; }
  .history-slider .swiper-container {
    padding-bottom: 120px;
    margin-bottom: 120px; }
  .history-slider.left.on .down-line {
    height: 100%; }
  .history-slider.left .slider-header {
    position: absolute;
    top: 62px;
    left: 0;
    width: calc(100% - 400px);
    height: 80px; }
    .history-slider.left .slider-header .prev-2000,
    .history-slider.left .slider-header .prev-1900,
    .history-slider.left .slider-header .prev-1800 {
      position: absolute;
      bottom: 0;
      right: 59px;
      width: 60px;
      height: 60px;
      border: 1px solid #4e2a84; }
      .history-slider.left .slider-header .prev-2000:after,
      .history-slider.left .slider-header .prev-1900:after,
      .history-slider.left .slider-header .prev-1800:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(90deg); }
      .history-slider.left .slider-header .prev-2000:hover, .history-slider.left .slider-header .prev-2000:focus,
      .history-slider.left .slider-header .prev-1900:hover,
      .history-slider.left .slider-header .prev-1900:focus,
      .history-slider.left .slider-header .prev-1800:hover,
      .history-slider.left .slider-header .prev-1800:focus {
        background: #3f2367;
        opacity: 1; }
        .history-slider.left .slider-header .prev-2000:hover:after, .history-slider.left .slider-header .prev-2000:focus:after,
        .history-slider.left .slider-header .prev-1900:hover:after,
        .history-slider.left .slider-header .prev-1900:focus:after,
        .history-slider.left .slider-header .prev-1800:hover:after,
        .history-slider.left .slider-header .prev-1800:focus:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
    .history-slider.left .slider-header .next-2000,
    .history-slider.left .slider-header .next-1900,
    .history-slider.left .slider-header .next-1800 {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60px;
      height: 60px;
      border: 1px solid #4e2a84; }
      .history-slider.left .slider-header .next-2000:after,
      .history-slider.left .slider-header .next-1900:after,
      .history-slider.left .slider-header .next-1800:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(-90deg); }
      .history-slider.left .slider-header .next-2000:hover, .history-slider.left .slider-header .next-2000:focus,
      .history-slider.left .slider-header .next-1900:hover,
      .history-slider.left .slider-header .next-1900:focus,
      .history-slider.left .slider-header .next-1800:hover,
      .history-slider.left .slider-header .next-1800:focus {
        background: #3f2367;
        opacity: 1; }
        .history-slider.left .slider-header .next-2000:hover:after, .history-slider.left .slider-header .next-2000:focus:after,
        .history-slider.left .slider-header .next-1900:hover:after,
        .history-slider.left .slider-header .next-1900:focus:after,
        .history-slider.left .slider-header .next-1800:hover:after,
        .history-slider.left .slider-header .next-1800:focus:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
  .history-slider.left .date-box {
    position: absolute;
    bottom: -100px;
    right: 0;
    width: 580px;
    height: auto;
    z-index: 999;
    background: #f2f5f8;
    padding: 80px 40px 80px 40px; }
    .history-slider.left .date-box h2 {
      margin-bottom: 10px; }
    .history-slider.left .date-box ul {
      list-style: none;
      padding: 0; }
      .history-slider.left .date-box ul li {
        display: inline-block;
        width: 100%;
        margin: 10px 0;
        font-size: 14px;
        line-height: 22px; }
    .history-slider.left .date-box p {
      font-size: 14px;
      line-height: 22px; }
    .history-slider.left .date-box .date-line {
      display: inline-block;
      position: absolute;
      top: 50px;
      right: 480px;
      width: 0;
      height: 1px;
      z-index: 999;
      background: #aec1d0;
      transition: .4s;
      transition-delay: .8s; }
      .history-slider.left .date-box .date-line:after {
        display: inline-block;
        position: absolute;
        right: 0;
        top: -7px;
        content: '';
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background: #aec1d0; }
  .history-slider.left .photo {
    display: block;
    position: relative;
    float: left;
    width: calc(100% - 400px);
    height: 580px;
    background-size: cover !important; }
    .history-slider.left .photo span {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
      padding: 8px 30px 5px 30px;
      color: #ffffff;
      background: #4e2a84;
      z-index: 9;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 4px;
      text-transform: uppercase; }
  .history-slider.right.on .down-line {
    height: 100%; }
  .history-slider.right .slider-header {
    position: absolute;
    top: 62px;
    right: 0;
    width: calc(100% - 400px);
    height: 80px; }
    .history-slider.right .slider-header .prev-2000,
    .history-slider.right .slider-header .prev-1900,
    .history-slider.right .slider-header .prev-1800 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 60px;
      height: 60px;
      border: 1px solid #4e2a84; }
      .history-slider.right .slider-header .prev-2000:after,
      .history-slider.right .slider-header .prev-1900:after,
      .history-slider.right .slider-header .prev-1800:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(90deg); }
      .history-slider.right .slider-header .prev-2000:hover, .history-slider.right .slider-header .prev-2000:focus,
      .history-slider.right .slider-header .prev-1900:hover,
      .history-slider.right .slider-header .prev-1900:focus,
      .history-slider.right .slider-header .prev-1800:hover,
      .history-slider.right .slider-header .prev-1800:focus {
        background: #3f2367;
        opacity: 1; }
        .history-slider.right .slider-header .prev-2000:hover:after, .history-slider.right .slider-header .prev-2000:focus:after,
        .history-slider.right .slider-header .prev-1900:hover:after,
        .history-slider.right .slider-header .prev-1900:focus:after,
        .history-slider.right .slider-header .prev-1800:hover:after,
        .history-slider.right .slider-header .prev-1800:focus:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
    .history-slider.right .slider-header .next-2000,
    .history-slider.right .slider-header .next-1900,
    .history-slider.right .slider-header .next-1800 {
      position: absolute;
      bottom: 0;
      left: 59px;
      width: 60px;
      height: 60px;
      border: 1px solid #4e2a84; }
      .history-slider.right .slider-header .next-2000:after,
      .history-slider.right .slider-header .next-1900:after,
      .history-slider.right .slider-header .next-1800:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 25px;
        height: 25px;
        background: url(../images/purple-arrow.svg) 50% no-repeat;
        background-size: 100%;
        transform: translate(-50%, -50%) rotate(-90deg); }
      .history-slider.right .slider-header .next-2000:hover, .history-slider.right .slider-header .next-2000:focus,
      .history-slider.right .slider-header .next-1900:hover,
      .history-slider.right .slider-header .next-1900:focus,
      .history-slider.right .slider-header .next-1800:hover,
      .history-slider.right .slider-header .next-1800:focus {
        background: #3f2367;
        opacity: 1; }
        .history-slider.right .slider-header .next-2000:hover:after, .history-slider.right .slider-header .next-2000:focus:after,
        .history-slider.right .slider-header .next-1900:hover:after,
        .history-slider.right .slider-header .next-1900:focus:after,
        .history-slider.right .slider-header .next-1800:hover:after,
        .history-slider.right .slider-header .next-1800:focus:after {
          background: url(../images/white-arrow.svg) 50% no-repeat; }
  .history-slider.right .date-box {
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 580px;
    height: auto;
    z-index: 999;
    background: #f2f5f8;
    padding: 120px 80px 80px 80px; }
    .history-slider.right .date-box h2 {
      margin-bottom: 10px; }
    .history-slider.right .date-box ul {
      list-style: none;
      padding: 0; }
      .history-slider.right .date-box ul li {
        display: inline-block;
        width: 100%;
        margin: 10px 0; }
    .history-slider.right .date-box .date-line {
      display: inline-block;
      position: absolute;
      top: 50px;
      left: 80px;
      width: 0;
      height: 1px;
      z-index: 999;
      background: #aec1d0;
      transition: .4s;
      transition-delay: .8s; }
      .history-slider.right .date-box .date-line:after {
        display: inline-block;
        position: absolute;
        left: 0;
        top: -7px;
        content: '';
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background: #aec1d0; }
  .history-slider.right .photo {
    display: block;
    position: relative;
    float: right;
    width: calc(100% - 400px);
    height: 580px;
    background-size: cover !important; }
    .history-slider.right .photo span {
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      height: auto;
      padding: 8px 30px 5px 30px;
      color: #ffffff;
      background: #4e2a84;
      z-index: 9;
      font-family: "AkkuratPro-Regular";
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 4px;
      text-transform: uppercase; }

.pagination-2000 {
  width: calc(100% - 700px) !important;
  padding: 20px 150px;
  max-width: 900px; }
  .pagination-2000 .swiper-pagination-bullet {
    display: inline-block;
    width: 14.2857142857%;
    height: auto;
    margin: 0 10px;
    color: #819eb5;
    background: transparent;
    opacity: 1;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "AkkuratPro-Bold"; }
    .pagination-2000 .swiper-pagination-bullet span {
      width: calc(100%);
      background-image: linear-gradient(transparent calc(100% - 2px), #4e2a84 2px);
      background-repeat: no-repeat;
      background-size: 0% 3px;
      background-position: left bottom;
      transition: background-size .4s; }
    .pagination-2000 .swiper-pagination-bullet:hover span, .pagination-2000 .swiper-pagination-bullet:focus span {
      background-size: 100% 3px; }
    .pagination-2000 .swiper-pagination-bullet:hover, .pagination-2000 .swiper-pagination-bullet:focus {
      color: #4e2a84; }
  .pagination-2000 .swiper-pagination-bullet-active {
    color: #4e2a84; }

.pagination-1900 {
  width: calc(100% - 700px) !important;
  padding: 20px 150px;
  float: right;
  max-width: 900px; }
  .pagination-1900 .swiper-pagination-bullet {
    display: inline-block;
    width: 14.2857142857%;
    height: auto;
    margin: 0 10px;
    color: #819eb5;
    background: transparent;
    opacity: 1;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "AkkuratPro-Bold"; }
  .pagination-1900 .swiper-pagination-bullet-active {
    color: #4e2a84; }

.pagination-1800 {
  width: calc(100% - 700px) !important;
  padding: 20px 150px;
  max-width: 900px; }
  .pagination-1800 .swiper-pagination-bullet {
    display: inline-block;
    width: 14.2857142857%;
    height: auto;
    margin: 0 10px;
    color: #819eb5;
    background: transparent;
    opacity: 1;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "AkkuratPro-Bold"; }
  .pagination-1800 .swiper-pagination-bullet-active {
    color: #4e2a84; }

@media only screen and (max-width: 1300px) and (min-width: 1px) {
  .pagination-2000,
  .pagination-1900,
  .pagination-1800 {
    padding: 20px 50px; } }

@media only screen and (max-width: 1200px) and (min-width: 1px) {
  .history-slider.right .date-box,
  .history-slider.left .date-box {
    padding: 80px 30px 30px;
    width: 400px;
    bottom: 0;
    height: 100%; }
  .history-slider.left .date-box .date-line {
    right: 300px; }
  .history-slider.right .slider-header,
  .history-slider.left .slider-header {
    width: 100%; }
  .history-slider .swiper-container {
    padding-bottom: 0; } }

@media only screen and (max-width: 1080px) and (min-width: 1px) {
  .pagination-1800, .pagination-1900, .pagination-2000 {
    width: calc(100% - 500px) !important; } }

@media only screen and (max-width: 880px) and (min-width: 1px) {
  .pagination-1800, .pagination-1900, .pagination-2000 {
    padding: 20px;
    width: 50% !important; }
  .history-slider.left .photo {
    width: 50%; }
  .history-slider.right .photo {
    width: 50%; }
  .history-slider.left .date-box {
    width: 50%; }
  .history-slider.right .date-box {
    width: 50%; }
  .history-slider .date-box .date-line {
    display: none !important; }
  .down-line {
    width: 6px; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .history-slider.left .slider-header,
  .history-slider.right .slider-header {
    top: 40px; }
  .pagination-2000 .swiper-pagination-bullet,
  .pagination-1900 .swiper-pagination-bullet,
  .pagination-1800 .swiper-pagination-bullet {
    width: 25%;
    margin: 0 !important; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  .history-slider.left .date-box,
  .history-slider.left .photo,
  .history-slider.right .date-box,
  .history-slider.right .photo {
    width: 100%;
    position: relative; }
  .down-line {
    display: none !important; }
  .history-slider.left .slider-header,
  .history-slider.right .slider-header {
    text-align: center; }
  .history-slider.left .slider-header .prev-1800,
  .history-slider.left .slider-header .prev-1900,
  .history-slider.left .slider-header .prev-2000,
  .history-slider.left .slider-header .next-1800,
  .history-slider.left .slider-header .next-1900,
  .history-slider.left .slider-header .next-2000,
  .history-slider.right .slider-header .prev-1800,
  .history-slider.right .slider-header .prev-1900,
  .history-slider.right .slider-header .prev-2000,
  .history-slider.right .slider-header .next-1800,
  .history-slider.right .slider-header .next-1900,
  .history-slider.right .slider-header .next-2000 {
    position: relative;
    display: inline-block;
    right: 0;
    left: 0; }
  .history-slider.left .date-box,
  .history-slider.left .photo,
  .history-slider.right .date-box,
  .history-slider.right .photo {
    padding: 30px; }
  .history-slider.right .photo,
  .history-slider.left .photo {
    height: 380px; }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    width: 100% !important; }
  .pagination-2000,
  .pagination-1900,
  .pagination-1800 {
    text-align: center; }
  #rule {
    display: none; } }

@media only screen and (max-width: 380px) and (min-width: 1px) {
  .history-slider.right .photo,
  .history-slider.left .photo {
    height: 280px; }
  .history-slider {
    border-bottom: 1px solid #aec1d0 !important; } }

@media only screen and (max-width: 1024px) and (min-width: 1px) {
  #home-events > .top-row > .col-xs-8,
  #home-events > .top-row > .col-xs-4 {
    flex-basis: 100%;
    max-width: 100%; }
  #home-events .event-sidebar {
    padding: 0 50px 50px 50px; } }

@media only screen and (max-width: 1023px) and (min-width: 1px) {
  #media-library .col-xs-6 {
    flex-basis: 100%;
    max-width: 100%; }
  #media-library .text-left .col-xs-6 {
    flex-basis: 50%;
    max-width: 50%; }
  #media-library {
    padding: 100px 50px 0 50px; }
  #media-library .all {
    display: block;
    max-width: 260px;
    margin: 10px auto; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .event .col-xs-4 {
    flex-basis: 25%;
    max-width: 25%; }
  .event .col-xs-8 {
    flex-basis: 75%;
    max-width: 75%; } }

@media only screen and (max-width: 768px) and (min-width: 1px) {
  .event .col-xs-4,
  .event .col-xs-8 {
    flex-basis: 100%;
    max-width: 100%; } }

@media only screen and (max-width: 580px) and (min-width: 1px) {
  #media-library {
    padding: 50px 0 0 0; }
  #media-library .text-left {
    padding: 0 20px; }
  #media-library .text-left .col-xs-6 {
    flex-basis: 100%;
    max-width: 100%; }
  #media-library .featured-video .video-caption {
    width: 100%; }
  #media-library .featured-video .video-thumb,
  #media-library .featured-video .video-thumb-wrap {
    height: 300px; }
  #media-library .col-xs-6 .media-video {
    padding: 0 !important; }
  #media-library .featured-video .video-caption .info {
    padding-left: 0; }
  #media-library .featured-video .video-caption .play {
    display: block;
    position: relative;
    top: -10px;
    left: 50%;
    transform: translate(-50%, 0); }
  #home-events .event-sidebar {
    padding: 0 20px 50px 20px; }
  #home-events-slider {
    margin-bottom: 40px; } }
