//
// Name:            CTA Rows
//


// ========================================================================
// Component: CTA Rows
// ========================================================================


.photo-excerpts {

	.col-xs-3 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-3:first-child {
		padding-left:.5rem;
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-3:last-child {
		padding-left:calc(.5rem + 10px);
		padding-right:.5rem;
	}

	.col-xs-4 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-4:nth-child(1) {
		padding-left:.5rem;
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-4:nth-child(3) {
		padding-left:calc(.5rem + 10px);
		padding-right:.5rem;
	}
	.cnt {
		padding-bottom:50px;
	}
	.col-xs-4 .photo-wrap, .col-xs-3 .photo-wrap {
		display:block;
		width:100%;
		height:260px;
		overflow:hidden;

		&:hover .photo {
			transform:scale(1.1);
		}
		// &:hover + a span {
		//    	background-size: 100% 100%;
		// }
	}

	.col-xs-4 .photo, .col-xs-3 .photo {
		display:block;
		width:100%;
		height:260px;
		background-size:cover !important;
		transition:.4s;
		transform:scale(1);

		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
	.col-xs-4 .excerpt-title, .col-xs-3 .excerpt-title {
		display:inline-block;
		width:100%;
		color: #4e2a84;
		font-family: $campton;
		font-size: 24px;
		line-height: 32px;		
		text-decoration:none;
	}
	.col-xs-4 .excerpt-area, .col-xs-3 .excerpt-area {
		display:inline-block;
		position:relative;
		top:-15px;
		width:100%;
		color: #4f7796;
		font-family: $akkurat_bold;
		font-size: 12px;
		line-height: 16px;
		text-transform: uppercase;
		letter-spacing: 2px;
		margin:0 0 35px 0;
	}
	.col-xs-4 .excerpt-area + .tertiary-btn, .col-xs-3 .excerpt-area + .tertiary-btn {
		position:relative;
		top:-30px;
		margin-bottom:-30px;
	}
	.col-xs-4 .read-link, .col-xs-3 .read-link {
		display:inline-block;
		position:relative;
		top:-40px;
		margin-bottom:-40px;
		color: $purple;
		font-family: $akkurat_bold;
		font-size: 16px;
		line-height: 24px;
		padding-right:30px;
		text-decoration:none;

		.ico {
			position:absolute;
			top:50%;
			right:0;
			width:15px;
			height:15px;
			background:url(../images/purple-arrow.svg) right top no-repeat;
			transform:translate(0, -50%) rotate(-90deg);
			background-size:100% !important;
		}
	}
	p {
		color: #000000;
		font-family: $akkurat_reg;
		font-size: 16px;
		line-height: 28px;		
	}
	a {
		margin-bottom:20px;
		-ms-word-break: break-all;
		word-break: break-all;
		word-break: break-word;

		&.body-link.email {
			font-size: 14px !important;
		}
	}
	.tertiary-btn {
		margin-bottom:40px;
	}
	.col-xs-6 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-6:nth-child(1) {
		padding-left:.5rem;
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-6:nth-child(2) {
		padding-left:calc(.5rem + 10px);
		padding-right:.5rem;
	}
	.col-xs-6 .photo {
		display:block;
		width:100%;
		height:360px;
		background-size:cover !important;
	}
	.col-xs-6 .excerpt-title {
		color: #4e2a84;
		font-family: $campton;
		font-size: 24px;
		line-height: 32px;		
		text-decoration:none;
	}
	.col-xs-4.is_hover {

		.photo {
			transform:scale(1.1);
		}
		.excerpt-title span {
			background-size: 100% 3px;
		}
	}
	.cnt.is_hover {

		.photo {
			transform:scale(1.1);
		}
		.excerpt-title span {
			background-size: 100% 3px;
		}
	}
}
.photo-excerpts + hr {
	margin-top:40px;
}

.photo-excerpts-sm {

	.col-xs-6 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-6:first-child {
		padding-left:.5rem;
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-6:last-child {
		padding-left:calc(.5rem + 10px);
		padding-right:.5rem;
	}
	.col-xs-6 .photo-wrap {
		display:block;
		width:100%;
		height:230px;
		overflow:hidden;
	}
	.col-xs-6 .photo {
		display:block;
		width:100%;
		height:230px;
		background-size:cover !important;
		transform:scale(1);
		transition:.4s;

		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
	.col-xs-6 .excerpt-title {
		display:inline-block;
		width:100%;
		color: #4e2a84;
		font-family: $campton;
		font-size: 24px;
		line-height: 32px;
		margin-bottom:20px;		
		text-decoration:none;
	}
	.col-xs-6.is_hover {

		.photo {
			transform:scale(1.1);
		}
		.excerpt-title span {
			background-size: 100% 3px;
		}
	}
}

.photo-cta {
	background-image: linear-gradient(145deg, rgba(224, 232, 238, 0.25) 0%, #e0e8ee 100%);		
	border-bottom:1px solid #fff;

	.photo {
		position:relative;
		width:100%;
		height:500px;
		background-size:cover !important;
	}
	.cnt {
		padding:0 80px;
	}	
}

.right-photo-cta {
	padding:40px 0;

	.photo {
		position:relative;
		width:100%;
		max-width:350px;
		height:350px;
		float:right;
		background-size:cover !important;
	}
	&.shadow .photo {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
	}
	.cnt {
		padding:0 80px 0 0;
	}	
	.btn-cnt {
		position:relative;
		width:100%;

		a:before {
			position:absolute;
			content:'';
			top:50%;
			right:0;
			left:inherit;
			width:0;
			height:1px;
			background:$purple;
			transform:translate(0, -50%);
			transition:1.4s;
		}
		.btn:hover {
			background:$purple !important;
		}
	}
}

.left-photo-cta {
	padding:40px 0;

	.photo {
		position:relative;
		width:100%;
		max-width:350px;
		height:350px;
		background-size:cover !important;
	}
	&.shadow .photo {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
	}
	.cnt {
		padding:0 0 0 80px;
	}	
	.btn-cnt {
		position:relative;
		width:100%;

		a:before {
			position:absolute;
			content:'';
			top:50%;
			left:0;
			right:inherit;
			width:0;
			height:1px;
			background:$purple;
			transform:translate(0, -50%);
			transition:1.4s;
		}
		.btn:hover {
			background:$purple !important;
		}
	}
}

.right-photo-cta-lg {
	padding:40px 0;

	.photo {
		position:relative;
		width:100%;
		height:500px;
		background-size:cover !important;
	}
	&.shadow .photo {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
	}
	.cnt {
		padding:0 80px 0 0;
	}
	&.on .btn-cnt a:before {
		width:200px;
		right:-200px;
	}	
	.btn-cnt {
		position:relative;
		width:100%;

		a:before {
			position:absolute;
			content:'';
			top:50%;
			right:0;
			left:inherit;
			width:0;
			height:1px;
			background:$purple;
			transform:translate(0, -50%);
			transition:1.4s;
		}
	}
}

.left-photo-cta-lg {
	padding:40px 0;

	.photo {
		position:relative;
		width:100%;
		height:500px;
		background-size:cover !important;
	}
	&.shadow .photo {
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
	}
	.cnt {
		padding:0 0 0 80px;
	}
	&.on .btn-cnt a:before {
		width:200px;
		left:-200px;
	}	
	.btn-cnt {
		position:relative;
		width:100%;

		a:before {
			position:absolute;
			content:'';
			top:50%;
			left:0;
			right:inherit;
			width:0;
			height:1px;
			background:$purple;
			transform:translate(0, -50%);
			transition:1.4s;
		}
	}
}

.right-thumb-cta {
	padding:40px 0;

	.thumb {
		position:relative;
		width:100%;
		height:230px;
		background-size:cover !important;
	}
	.cnt {
		padding-right:20px;
	}
}

.left-thumb-cta {
	padding:40px 0;

	.thumb {
		position:relative;
		width:100%;
		height:230px;
		background-size:cover !important;
	}
	.cnt {
		padding-left:20px;
	}
}

.workshop {

	.date {
		color: #000000;
		font-family: $campton;
		font-size: 32px;
		line-height: 42px;		
	}
	.time {
		color: #000000;
		font-family: $akkurat_reg;
		font-size: 24px;
		line-height: 36px;		
	}
	.photo {
		position:relative;
		width:100%;
		height:500px;
		background-size:cover !important;

		.caption {
			position:absolute;
			left:0;
			bottom:50px;
			z-index:999;
			padding:40px;
			background:$purple;
			width:75%;
			color: #ffffff;
			font-family: $akkurat_reg;
			font-size: 24px;
			line-height: 36px;
		}
	}
}

.event-cta {
	margin:40px 0;

	.col-xs-6 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-6:first-child {
		padding-left:0;
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-6:last-child {
		padding-left:calc(.5rem + 10px);
		padding-right:0;
	}
	.col-xs-6 .thumb-wrap {
		display:block;
		width:100%;
		height:230px;
		overflow:hidden;
	}	
	.col-xs-6 .thumb {
		display:block;
		width:100%;
		height:230px;
		background-size:cover !important;
		transform:scale(1);
		transition:.4s;

		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
	.date {
		color: #4f7796;
		font-family: $akkurat_bold;
		font-size: 12px;
		line-height: 16px;
		text-transform: uppercase;
		letter-spacing: 2px;
		margin:30px 0 0 0;				
	}
	h3 {
		margin:5px 0;
	}
	.location {
		display:inline-block;
		width:100%;
		color: #819eb5;
		font-family: $akkurat_reg;
		font-size: 14px;
		line-height: 20px;
		padding-left:20px;
		background:url(../images/location-icon.svg) left center no-repeat;
		text-align:left;
		margin:0 0 20px 0;
	}
	.col-xs-6.is_hover {

		.thumb {
			transform:scale(1.1);
		}
		h3 a span {
			background-size:100% 3px;
		}
	}
}


@media only screen and (max-width: 1280px) and (min-width: 1px) {
	.photo-excerpts {
		//padding:0 30px;
	}
	.workshop .btn {
		max-width:100%;
		min-width:100%;
	}
	.left-photo-cta .cnt {
		padding-left:20px;
	}
	.right-photo-cta .cnt {
		padding-right:20px;
	}
	.left-photo-cta .btn,
	.right-photo-cta .btn {
		max-width:100%;
		min-width:100%;
	}
}

@media only screen and (max-width: 1023px) and (min-width: 1px) {
	.photo-cta .header1 {
		font-size:32px;
		line-height:40px;
	}
	.photo-excerpts .col-xs-4 .excerpt-title {
		font-size:18px;
		line-height:26px;
	}
	.right-photo-cta .cnt {
		padding:0 20px 0 0;
	}
	.right-photo-cta .btn {
		min-width:100%;
		max-width:100%;
	}
	.workshop .col-xs-5,
	.workshop .col-xs-7 {
	    flex-basis: 100%;
	    max-width: 100%;
	}
	.workshop .photo {
		margin-top:30px;
	}
	.photo-cta .transparent-btn {
		min-width:100%;
		width:100%;
	}
	.photo-cta .cnt {
		padding:0 20px;
	}
	.right-photo-cta-lg .btn,
	.left-photo-cta-lg .btn {
		min-width:100%;
	}
	.right-photo-cta-lg .photo {
		margin-top:50px;
	}
	.left-photo-cta-lg .photo {

	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.photo-excerpts {
		//padding:0 40px;
	}
	.photo-excerpts .col-xs-4 {
		flex-basis: 100%;
    	max-width: 100%;
	}
	.photo-excerpts .col-xs-4,
	.photo-excerpts .col-xs-4:first-child,
	.photo-excerpts .col-xs-4:last-child {
		padding-left: .5rem;
	    padding-right: .5rem;		
	}
	.photo-excerpts .col-xs-4 .photo {
		max-width:230px;
	}
	.photo-excerpts .tertiary-btn {
		max-width:160px;
		min-width:160px;
	}
	.photo-excerpts .col-xs-6 .photo {
		max-width:360px;
	}
	.photo-excerpts .col-xs-6 {
		margin-bottom:30px;
	}
	.photo-excerpts .col-xs-6,
	.photo-excerpts .col-xs-6:first-child,
	.photo-excerpts .col-xs-6:last-child {
	    padding-left: .5rem;
	    padding-right: .5rem;		
	}
	.right-photo-cta .cnt {
		padding:0;
	}
	.right-photo-cta .col-xs-6:first-child {
		order:1;
	}
	.right-photo-cta .col-xs-6:last-child {
		order:0;
	}
	.right-photo-cta .photo,
	.left-thumb-cta .thumb {
		max-width:100%;
		margin-bottom:30px;
	}
	.left-photo-cta .btn,
	.right-photo-cta .btn {
		max-width:280px;
		min-width:280px;
	}
	.left-thumb-cta .cnt {
		padding-left:0;
	}
	.left-photo-cta .cnt {
		padding:0;
	}
	.left-photo-cta .photo {
		max-width: 100%;
    	margin-bottom: 30px;
	}
	.photo-cta .photo {
		height:300px;
	}
	.photo-cta .cnt {
		padding:30px 20px;
	}
	.photo-excerpts-sm .col-xs-6:last-child {
		padding-left:.5rem;
	}
	.photo-excerpts-sm .col-xs-6:first-child {
		padding-right:.5rem;
	}
	.photo-excerpts-sm .col-xs-6 {
		margin-bottom:30px;
	}
	.left-photo-cta .end-xs {
		-webkit-box-pack: start;
	    -ms-flex-pack: start;
	    -moz-justify-content: flex-start;
	    justify-content: flex-start;
	    text-align: start;		
	}
	.event-cta .col-xs-6 {
		margin-bottom:40px;
	}
	.event-cta .col-xs-6:last-child {
		padding-right:.5rem;
		padding-left:.5rem;
	}
	.event-cta .col-xs-6:first-child {
		padding-right:.5rem;
		padding-left:.5rem;
	}
	.event-cta {
		margin:0;
	}
	.right-photo-cta-lg.row.margin-top-lg {
		padding-top:0 !important;
	}
	.right-photo-cta-lg .col-xs-4 {
		order:1;
	}
	.right-photo-cta-lg .col-xs-8 {
		order:0;

		.photo {
			margin-bottom:30px;
		}
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	.photo-cta .photo {
		height:300px;
	}
	.photo-cta .btn {
		margin-top:30px !important;
	}
	.photo-excerpts .col-xs-4 {
		flex-basis: 100%;
    	max-width: 100%;
	}
	.left-photo-cta .btn,
	.right-photo-cta .btn,
	.left-thumb-cta .cnt .btn {
		max-width:100% !important;
		min-width:100% !important;
	}
	.left-thumb-cta .thumb,
	.right-photo-cta .photo,
	.left-photo-cta .photo {
		height:200px;
	}
	.workshop .photo .caption {
		font-size:18px;
		line-height:24px;
		bottom:0;
		width:100%;
	}
	.right-photo-cta-lg .photo,
	.left-photo-cta-lg .photo {
		height:300px;
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	.photo-excerpts .tertiary-btn {
		max-width:100%;
		min-width:100%;
	}
	.photo-excerpts .col-xs-4 .photo {
		max-width:100%;
	}
	.photo-cta .cnt {
		padding:30px 20px;
	}
	.photo-excerpts .col-xs-6 .photo {
		height:250px;
	}
	.left-photo-cta .btn, .right-photo-cta .btn {
		max-width:100%;
		min-width:100%;
	}
	.photo-excerpts .cnt {
		padding-bottom:0;
	}
}
