//
// Name:            Search
//


// ========================================================================
// Component: Search
// ========================================================================


#search-faq,
#search-resources,
#search-faculty,
#results-search,
#search-news,
.search-filter-section,
#views-exposed-form-news-list-main-archive,
#search-events {
	position:relative;
	margin:0;
	width:400px;

	input[type="text"] {
		height:40px;
		border-radius: 2px;
		border: 1px solid #aec1d0;
		background-color: #f2f5f8;
	}
	input[type="submit"] {
		position:absolute;
		top:20px;
		right:15px;
		width:20px;
		min-width:20px;
		padding:0;
		margin:0;
		height:20px;
		border:none;
		background:url(../images/search-icon.svg) 50% no-repeat;
		background-size:100%;
	}
	&:hover, &:focus {
		input[type="text"] {
			//background-color: rgba(153, 153, 153, 0);
			//border: 1px solid #000000;
			box-shadow: 0 0 10px rgba(78, 42, 132, 0.2);
		}
		input[type="submit"] {
			background:url(../images/search-icon.svg) 50% no-repeat;
			background-size:100%;
		}
	}
}

#search-resources {
	margin:10px 0;
}

.result {
	display:inline-block;
	width:100%;
	padding:30px 0;
	border-bottom:1px solid #aec1d0;

	&:first-child {
		border-top: 5px solid #e0e7ed;
	}
	.link {
		color: #4e2a84;
		font-family: $akkurat_bold;
		font-size: 16px;
		line-height: 24px;		
	}
}

.filter-bar {
	display:inline-block;
	position:relative;
	z-index:999;
	width:100%;
	background:$purple;
	padding:60px 0;

	h2,
	label {
		color:#fff;
	}
	#search-news,
    .search-filter-section,
	#views-exposed-form-news-list-main-archive {
		width:100%;
		font-size:0;

		> ul {
			width:100%;

			> li {
				width:calc(50% - 10px);
				display:inline-block;
			}
			> li:first-child {
				margin-right:15px;
			}
		}
	}
}


@media only screen and (max-width: 768px) and (min-width: 1px) {
	#search-news,
    .search-filter-section,
	#views-exposed-form-news-list-main-archive {
		margin-bottom:30px;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	#search-faq,
	#search-resources {
		width:100%;

		input[type="text"] {
			padding:0 42px 0 22px;
		}
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	#search-faculty,
	#search-events,
	#results-search {
		width:100%;
	}
}
