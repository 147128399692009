//
// Name:            Resources
//


// ========================================================================
// Component: Resources
// ========================================================================


.resource {
	padding:20px 0;
	border-bottom: 1px solid #aec1d0;

	.day {
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 12px;
		letter-spacing: 2px;
		line-height: 16px;
		text-transform: uppercase;
		margin:0 0 5px 0;
	}
	.month {
		color: #000000;
		font-family: $akkurat_bold;
		font-size: 18px;
		line-height: 24px;
		margin:0;
	}
	.date {
		color: #000000;
		font-family: $periodico;
		font-size: 52px;
		letter-spacing: -1px;
		line-height: 62px;
	}
	.time {
		margin:5px 0 10px 0;
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 12px;
		letter-spacing: 2px;
		line-height: 16px;
		text-transform: uppercase;
	}
	.location {
		margin:0;
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 14px;
		line-height: 20px;
		padding:0 0 0 20px;
		background:url(../images/location-icon.svg) left 3px no-repeat;
	}
}

#faculty-calendar {
	display:inline-block;
	position:relative;
	//margin-bottom:80px;
	width:100%;
	z-index:99;
	min-height:180px;

	.fc-toolbar.fc-header-toolbar {
		display:block;
	}
	.fc-right {
		position:absolute;
		top:0;
		left:0;
		width:0;
		height:0;
	}
	.fc-left {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:80px;
		z-index:999;

		.fc-button-group {
			width:100%;
			margin-top:20px;
			text-align:left;
		}
		.btn {
			float:none;
			min-width:170px;
			max-width:170px;
			border-radius:0;
			padding:5px 20px;
			height:auto;
			margin-right:20px;
			text-shadow:none;
			box-shadow:none;
			background-image:none;
			background:#f6f4f9;
			color:$purple;
			transition:background .4s;

			&:hover, &:focus {
				background:$purple;
				color:#fff;
			}
		}
		.fc-state-active {
			background:$purple;
			color:#fff;
		}
	}
	.fc-center {
		position:absolute;
		width:100%;
		top:80px;
		left:0;
		padding:50px 0 0 0;

		.fc-button-group {
			margin-top:20px;

			.fc-prev-button {
				background:transparent;
				border:none;
				box-shadow:none;

				span {
					display:block;
					width:20px;
					height:20px;
					background:url(../images/purple-arrow.svg) 50% no-repeat;
					transform:rotate(90deg);

					&:after {
						display:none;
					}
				}
			}
			.fc-next-button {
				position:relative;
				background:transparent;
				border:none;
				box-shadow:none;

				&:before {
					position:absolute;
					content:'';
					top:-4px;
					left:0;
					width:2px;
					height:40px;
					background:#a18fbc;
				}
				span {
					display:block;
					width:20px;
					height:20px;
					background:url(../images/purple-arrow.svg) 50% no-repeat;
					transform:rotate(-90deg);

					&:after {
						display:none;
					}
				}
			}
		}
		h2 {
			width:auto;
			color: #000000;
			font-family: $periodico;
			font-size: 52px;
			letter-spacing: -1px;
			line-height: 62px;
			margin-left:20px;				
		}			
	}
	.fc-view-container {
		display:block !important;
		padding-top:200px;
	}
	ul#filters {
		display:inline-block;
		list-style:none;
		width:48%;
		float:right;
		padding:0;
		margin:0;
		position:relative;
		top:10px;
		text-align:right;

		.filter-title {
			display:none;
			color: #000000;
			font-family: $campton;
			font-size: 32px;
			letter-spacing: -1px;
			line-height: 42px;
			text-transform:none;
		}
		li {
			display:inline-block;
			vertical-align:top;
			width:40%;
			color: #507796;
			font-family: $akkurat_bold;
			font-size: 12px;
			letter-spacing: 3px;
			line-height: 50px;
			text-transform: uppercase;
			text-align:left;
			margin-right:10px;
			margin-bottom:10px;
			margin-top:0;

			&:first-child {
				padding-right:20px;
			}
			label {
				margin-top:0 !important;
				color: #4f7796;
				font-family: $akkurat_reg;
				font-size: 14px;
				line-height: 20px;
			}
			input[type=checkbox]+label:before {
				display:inline-block;
				border-radius:20px;
				top:50%;
				width:20px;
				height:20px;
				border: 3px solid #819eb5;
				transform:translate(0, -50%);
			}
			input[type=checkbox]:checked+label.university:before {
				content:'';
				background-image:none;
				background-color: #bd10e0;
				border: 3px solid #bd10e0;
			}
			input[type=checkbox]:checked+label.deadlines:before {
				content:'';
				background-image:none;
				background-color: #f1951c;
				border: 3px solid #f1951c;
			}
			input[type=checkbox]:checked+label.meetings-events:before {
				content:'';
				background-image:none;
				background-color: #4a90e2;
				border: 3px solid #4a90e2;
			}
			input[type=checkbox]:checked+label.bienen-events:before {
				content:'';
				background-image:none;
				background-color: #79cc19;
				border: 3px solid #79cc19;
			}
		}
	}
	.fc-row {
		border:none;
		min-height:0;
	}
	.fc-head-container {
		border-top: 1px solid #aec1d0;
		border-bottom: 5px solid #e0e7ed;
		border-left:none;
		border-right:none;

		th {
			border:none;
			color: #000000;
			font-family: $akkurat_bold;
			font-size: 18px;
			line-height: 26px;
			padding:25px 0;
			text-align:left;
		}
	}
	.fc-widget-content {
		border:none;
	}
	.fc-row td {
		text-align:left;
		color: #000000;
		font-family: $periodico;
		font-size: 52px;
		letter-spacing: -1px;
		line-height: 62px;
		border:none;

		span {
			display:block;
			position:relative;
			width:100%;
			height:auto;
			padding:0;
			margin:40px auto 0 auto;
			float:none;
			z-index:1;
		}
	}
	.fc-scroller {
		overflow:visible !important;
		height:auto !important;
	}
	.fc-today {
		background:transparent;
	}
	.fc-event {
		border:none;
		background:transparent;
	}
	.fc-event span {
		background:#e0e7ed;
		border-radius:30px;
		color:#fff;
	}
	.fc-event .fc-content {
		text-align:left;
		margin-bottom:20px;

		.fc-time {
			display:inline;
			font-family: $akkurat_reg;
			font-size: 14px;
			line-height: 20px;
			margin:0 5px 0 0;
			width:auto;
			background:transparent;
			vertical-align:top;
		}
		.fc-title {
			display:inline;
			font-family: $akkurat_reg;
			font-size: 14px;
			line-height: 20px;
			margin:0;
			width:auto;
			background:transparent;
			color: #3f6384;
			white-space:pre-wrap;
			vertical-align:top;
			text-align:left;
		}
	}
	.fc-content-skeleton tbody td {
		font-size:12px;
	}
	.fc-event-container {
		position:relative;

		.past-event {
			opacity:.33;
		}
	}
	.fc-event-container a {

	}
	.university .fc-time {
		color: #ac00d9;
	}
	.deadlines .fc-time {
		color: #f1951c;
	}
	.meetings-events .fc-time {
		color: #4a90e2;
	}
	.bienen-events .fc-time {
		color: #79cc19;
	}
}

.faculty-list-view {
	position:relative;

	.end-xs .col-xs-8 {
	    flex-basis: 100%;
	    max-width: 100%;		
	}
	.list-events {
		//padding-top:100px;
	}
	#faculty-calendar {

		.fc-view-container {
			padding-top:0;
		}
		.fc-center .fc-button-group,
		.fc-center h2 {
			display:none;
		}
		.fc-center {
			padding:20px 0 0 0;
		}
		ul#filters .filter-title {
			display:inline-block !important;
			float:left;
			top:0;
		}
		.col-xs-12:first-child .fc-button-group,
		.col-xs-12:first-child h2 {
			display:none;
		}
		ul#filters {
			width:100%;
			top:20px;
			float:none;
			text-align:left;
			border-bottom:5px solid #e0e7ed;
		}
		ul#filters li {
			width:auto;
			position:relative;
			top:10px;
			margin-right:20px;
		}
	}

}

.faculty-month-view {

	#faculty-calendar {

		.col-xs-4 {
			display:none;
		}
		.col-xs-8 {
		    flex-basis: 100%;
		    max-width: 100%;		
		}
		ul#filters .filter-title {
			display:none;
		}
		.fc-center .fc-button-group {
			display:inline-block;
		}
		.fc-center h2 {
			display:inline-block;
		}
		.fc-widget-content {
			height:auto !important;
		}
	}
}

.faculty-event {
	padding:20px 0;
	border-bottom: 1px solid #aec1d0;

	.day {
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 12px;
		letter-spacing: 2px;
		line-height: 16px;
		text-transform: uppercase;
		margin:0 0 5px 0;
	}
	.month {
		color: #000000;
		font-family: $akkurat_bold;
		font-size: 18px;
		line-height: 24px;
		margin:0;
	}
	.date {
		color: #000000;
		font-family: $periodico;
		font-size: 52px;
		letter-spacing: -1px;
		line-height: 62px;
	}
	.title {
		color: #000000;
		font-family: $campton;
		font-size: 24px;
		line-height: 32px;
		margin:0;		
	}
	.time {
		margin:5px 0 10px 0;
		color: #4f7796;
		font-family: $akkurat_bold;
		font-size: 12px;
		letter-spacing: 2px;
		line-height: 16px;
		text-transform: uppercase;
	}
	&.university .time {
		color: #ac00d9;
	}
	&.deadlines .time {
		color: #f1951c;
	}
	&.meetings-events .time {
		color: #4a90e2;
	}
	&.bienen-events .time {
		color: #79cc19;
	}	
	.location {
		margin:0;
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 14px;
		line-height: 20px;
		padding:0 0 0 20px;
		background:url(../images/location-icon.svg) left 3px no-repeat;
	}
	.body-link {
		float:right;
	}
}

#faculty-mini-calendar {
	display:block;
	position:relative;
	z-index:999;
	max-width:295px;
	margin-bottom:50px;

	.fc-left {
		position:relative;
		width:10px;
		height:10px;
		padding:0;
		background:transparent;

		.fc-prev-button {
			background:none;
			border:none;
			box-shadow:none;
			padding:0;

			span {
				display:block;
				width:10px;
				height:10px;
				background:url(../images/purple-arrow.svg) 50% no-repeat;
				transform:rotate(90deg);
				background-size:100%;

				&:after {
					display:none;
				}
			}
		}
	}
	.fc-right {
		position:relative;
		width:10px;
		height:10px;
		padding:0;
		background:transparent;

		.fc-next-button {
			background:none;
			border:none;
			box-shadow:none;
			padding:0;

			span {
				display:block;
				width:10px;
				height:10px;
				background:url(../images/purple-arrow.svg) 50% no-repeat;
				transform:rotate(-90deg);
				background-size:100%;

				&:after {
					display:none;
				}
			}
		}
	}
	.fc-center {
		position:relative;
		top:inherit;
		padding:0;
		width:auto;

		h2 {
			color: #000000;
			font-family: $akkurat_bold;
			font-size: 18px;
			line-height: 26px;
			margin:0;			
		}
	}
	.fc-view-container {
		padding-top:0 !important;
		padding-left:0;
		padding-right:0;
	}
	.fc-toolbar.fc-header-toolbar {
		margin:0;
	}
	.fc-row {
		border:none;
		min-height:0;
	}
	.fc-head-container {
		border-top: 1px solid #aec1d0;
		border-bottom: 5px solid #e0e7ed;
		border-left:none;
		border-right:none;

		th {
			border:none;
			color: #4f7796;
			font-family: $akkurat_reg;
			font-size: 14px;
			line-height: 20px;
			padding:10px 0;
		}
	}
	.fc-widget-content {
		border:none;
	}
	.fc-row td {
		text-align:center;
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 14px;
		line-height: 30px;
		border:none;

		span {
			display:block;
			position:relative;
			width:30px;
			height:30px;
			padding:0;
			margin:0 auto;
			float:none;
			z-index:1;
		}
	}
	.fc-scroller {
		overflow:visible !important;
		height:auto !important;
	}
	.fc-today {
		background:transparent;
	}
	.fc-day-grid-event {
		background: #e0e7ed;
		border-radius:30px;
	}
	.fc-event {
		border:none;
		background:transparent;
	}
	.fc-event span {
		background:#e0e7ed;
		border-radius:30px;
		color:#fff;
	}
	.fc-event .fc-content {
		display:none;
	}
	.fc-event-container {
		display:none;
	}
	.fc-event-container a:hover .fc-content,
	.fc-event-container a:focus .fc-content {
		display:block;
	}
	.fc-content-skeleton tbody {
		display:none;
	}
	.fc-content-skeleton td span {
		border-radius:30px;
		background-color: transparent;
		cursor:pointer;
		width:30px;
		height:30px;
		border:2px solid transparent;
	}
	.fc-content-skeleton .has-events span {
		border-radius:30px;
		background-color: #e0e7ed;
		cursor:pointer;
		width:30px;
		height:30px;
		transition:.4s;

		&:hover,
		&:focus,
		&.active {
			background:$purple;
			color:#fff;
		}
	}
	.fc-bg .tooltip-wrapper,
	.fc-bg .fc-day-number {
		display:none;
	}
	.fc-content-skeleton .has-events {
		position:relative;
	}
	.fc-content-skeleton .has-events .tooltip-wrapper {
		display:none;
		position:absolute;
		top:0;
		left:100%;
		width:300px;
		height:auto;
		padding:20px;
		background:#fff;
		box-shadow:0 0 4px rgba(0, 0, 0, 0.4);
		z-index:9999;

		.fc-day-number {
			display:none;
		}
	}
	.fc-content-skeleton .has-events:hover .tooltip-wrapper {
		display:block;
	}
	.fc-content-skeleton .has-events .tooltip-wrapper {

		.tooltip {
			display:inline-block;
			max-width:100%;
			width:100%;
			text-align:left;
			line-height:16px;

			.date {
				display:inline;
				width:auto;
				font-family: $akkurat_bold;
			    font-size: 12px;
			    letter-spacing: 2px;
			    line-height: 16px;
			    padding-right:10px;
			    text-transform: uppercase;

				&.university {
					color:#ac00d9;
				}
				&.deadlines {
					color:#f1951c;
				}
				&.meetings-events {
					color:#4a90e2;
				}
				&.bienen-events {
					color:#79cc19;
				}
			}
			.title {
				display:inline;
				width:auto;
			    color: #4f7796;
			    font-size: 14px;
			    line-height: 20px;
			}
		}
	}
	.fc-content-skeleton .has-future-events span {
		border-radius:30px;
		background-color: #e0e7ed;
		cursor:pointer;
		width:30px;
		height:30px;
	}
	.fc-today span {
		border:2px solid $purple !important;
		color:$purple;
		font-family: $akkurat_bold;
	}
	.fc-event-container a {
		display:block;
		width:30px;
		height:30px;
		margin:5px auto;
		background: #e0e7ed;
    	border-radius: 30px;
	}
}

.faculty-list-view .no-events {
	display:none;
	width:100%;
	padding:20px 0 0 0;
	text-align:center;
	font-size:16px;
	color: #819eb5;
	font-family: $akkurat_bold;
}


@media only screen and (max-width: 1280px) and (min-width: 1px) {
	.faculty-list-view #faculty-calendar ul#filters li {
		width:25%;
	}
	.faculty-list-view #faculty-calendar ul#filters {
		padding-bottom:20px;
	}
	.faculty-month-view #faculty-calendar ul#filters {
		width:100%;
	}
	.faculty-month-view #faculty-calendar ul#filters li {
		width:auto;
	}
	.faculty-month-view #faculty-calendar .fc-view-container {
		padding-top:250px;
	}
	.faculty-month-view #faculty-calendar ul#filters {
		float:left;
		text-align:left;
		top:25px;
	}
}

@media only screen and (max-width: 1180px) and (min-width: 1px) {
	#faculty-calendar .fc-head-container th {
		font-size:14px;
	}
}

@media only screen and (max-width: 1080px) and (min-width: 1px) {
	.faculty-list-view #faculty-calendar ul#filters .filter-title {
		width:100%;
	}
	.faculty-list-view #faculty-calendar ul#filters li {
		width:50%;
		margin-right:0;
		padding-right:0;
	}
	.faculty-list-view .list-events {
		padding-top:50px;
	}
}

@media only screen and (max-width: 980px) and (min-width: 1px) {
	#faculty-resources > .col-xs-4 {
		display:none;
	}
	#faculty-resources > .col-xs-8 {
	    flex-basis: 100%;
	    max-width: 100%;
	}
	#resources .col-xs-9,
	#resources .col-xs-3 {
	    flex-basis: 100%;
	    max-width: 100%;
	}
	#resources .col-xs-3 aside {
		margin-top:50px;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.faculty-event .body-link {
		float:none;
		display:inline-block;
		margin-top:20px;
	}
	#resources .col-xs-4 {
		margin-top:30px;
	}
	#resources .col-xs-4:first-child {
		margin-top:0;
	}
	#submit-news form .col-xs-6:last-child {
		margin-top:50px;
	}
}

@media only screen and (max-width: 660px) and (min-width: 1px) {
	.faculty-month-view #faculty-calendar ul#filters li {
		width:50%;
	}
	.faculty-month-view #faculty-calendar .fc-view-container {
		padding-top:350px;
	}
	#faculty-calendar .fc-head-container th {
		font-size:12px;
	}
	#faculty-calendar .fc-event-container .university {
		background:#bd10e0;
		width: 20px;
	    height: 20px;
	    margin: 0 0 5px;
	    border-radius: 20px;
	}
	#faculty-calendar .fc-event-container .deadlines {
		background:#f1951c;
		width: 20px;
	    height: 20px;
	    margin: 0 0 5px;
	    border-radius: 20px;
	}
	#faculty-calendar .fc-event-container .meetings-events {
		background:#4a90e2;
		width: 20px;
	    height: 20px;
	    margin: 0 0 5px;
	    border-radius: 20px;
	}
	#faculty-calendar .fc-event-container .bienen-events {
		background:#79cc19;
		width: 20px;
	    height: 20px;
	    margin: 0 0 5px;
	    border-radius: 20px;
	}
	#faculty-calendar .fc-event-container .fc-content {
		display:none;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	#faculty-calendar .fc-head-container th {
		font-size:10px;
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	#faculty-calendar .fc-left .btn {
		width:100%;
		min-width:100%;
		max-width:100%;
		margin:5px 0;
	}
	.faculty-list-view #faculty-calendar ul#filters li {
		width:100%;
	}
	.faculty-list-view .list-events {
		padding-top:120px;
	}
	.faculty-list-view #faculty-calendar ul#filters .filter-title {
		margin-top:20px;
	}
	#faculty-calendar .fc-right .btn {
		width:100%;
		margin:10px 0;
		max-width:100%;
	}
	#faculty-calendar .fc-right .fc-button-group {
		max-width:400px;
	}
	#faculty-calendar .fc-left, #calendar .fc-right {
		height:auto;
	}
	#faculty-calendar .fc-right,
	#faculty-calendar .fc-left {
		padding:0;
	}
	#faculty-calendar #calendar-search {
		min-width:100%;
	}
	#faculty-calendar .fc-head-container th {
		font-size:10px;
	}
	#faculty-calendar ul#filters li {
		width:100%;
	}
	#faculty-calendar .fc-view-container {
		padding-top:540px;
	}
	#faculty-calendar .fc-center h2 {
		font-size:32px;
		line-height:42px;
		margin-top:15px;
		margin-left:0;
	}
	#faculty-calendar .fc-center .fc-button-group {
		float:none;
	}
	#faculty-calendar .fc-head-container th {
		font-size:6px;
	}
	#faculty-calendar .fc-row td {
		font-size:20px;
	}
	#faculty-calendar .fc-row td span {
		margin:10px auto 0 auto;
	}
	#faculty-calendar #calendar-search input[type=text] {
		max-width:100%;
	}
	#faculty-calendar .fc-right .fc-button-group {
		max-width:100%;
	}
	#faculty-calendar .fc-center h2 {
		width:100%;
	}
	.faculty-month-view #faculty-calendar .fc-view-container {
		padding-top:400px;
	}
	.faculty-month-view #faculty-calendar ul#filters li {
		width:100%;
	}
}
#main-content #questions-bottom {
	display: none;
}