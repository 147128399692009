//
// Name:            Layout
//
// Description:     Contains all style definitions concerning basic
//					structure and layout
//

// ========================================================================
// Component: Base
// ========================================================================


.shell {
	overflow-x:hidden;
	margin:0 auto;
	display:block;
  //Removing to fix alignment and width
	max-width: none;
	box-shadow:0 5px 5px rgba(0, 0, 0, 0.4);

  #main-content {
    max-width: 1920px;
    margin: 0 auto;
  }
}

//For banners
.conditional-message {
	background-color: #e4e0ee;
	padding: 20px;
	span {
		position: absolute;
		right: 0;
		font-size: 2.5em;
		float: right;
		width: auto;
		//cursor: pointer;
		margin: auto 15px;
		padding: 5px;
		pointer-events: none;
		display: none;
	}
	h2, h3 {
		font-family: $akkurat_bold;
		color: #4e2a84;
	}
	p {
		font-size: 16px;
		color: #4e2a84;
		a:hover {
			color: #4e2a84;
		}
	}
}

//Hide specific conditional message on a page.
body.page-coronavirus {
	.conditional-message { display: none; }
}

.main {
	padding:0;
	position:relative;
	z-index:99;
}

.container {
	padding-left: 20px;
	padding-right: 20px;
	width:auto;
}

.wrapper {
	max-width: 1140px;
	margin: 0 auto;
}

#slate {
	padding:100px 120px;
	min-height:600px;
}

//SM - Removed .paragraph--type--text-content > *, causing content to not appear at all, even on scroll
article > *:not(.select-study):not(div),
aside > *,
.studies-box,
.cta-box,
.photo-cta,
.events-slider,
.photo-content-slider,
.box-office,
.events-wrapper .event,
.reveal {
	opacity:0;
	transform:translate3d(0, 25%, 0);
    transition: transform .4s cubic-bezier(.225,.46,.32,1), opacity .4s 0s, background .4s, color .4s;

	&.on:not(.select-study) {
		opacity:1;
		transform:translate3d(0, 0, 0);
	}
}
.select-study {
	position:relative;
	z-index:99;
	transform:translate3d(0, 0, 1px);
}

form {
	opacity:1 !important;
	transform:translate3d(0, 0, 0) !important;

	li {
		//Commenting out, looks like it causes li's to disappear if they're off the browser's viewport.
		opacity:1;
	    transition: transform .4s cubic-bezier(.225,.46,.32,1), opacity .4s 0s;

		&.on {
			opacity:1;
		}
	}
}

.wrap {
	position:relative;
	max-width: 1340px;
	margin: 0 auto;
}

.row {
	margin-left: -.5rem;
	margin-right: -.5rem;

	&,
	> [class*="col-"] {
		position: relative;
	}

	> [class*="col-"] {
		padding-left: .5rem;
		padding-right: .5rem;
	}

	.box {
		padding:20px;

		& .box {
		}
	}
}

.text-left {
	text-align:left;
}

.text-right {
	text-align:right;
}

.text-center {
	text-align:center;
}

.indent {
	display:inline-block;
	padding-left:30px;
}

.margin-top-lg {
	margin-top:70px !important;
}

.margin-top {
	margin-top:40px !important;
}

.margin-top-sm {
	margin-top:20px !important;
}

.margin-bottom-lg {
	margin-bottom:70px !important;
}

.margin-bottom {
	margin-bottom:40px !important;
}

.margin-bottom-sm {
	margin-bottom:20px !important;
}

.row.margin-top-lg {
	padding-top:70px !important;
	margin-top:0 !important;
}

.row.margin-top {
	padding-top:40px !important;
	margin-top:0 !important;
}

.row.margin-top-sm {
	padding-top:20px !important;
	margin-top:0 !important;
}

.row.margin-bottom-lg {
	padding-bottom:70px !important;
	margin-bottom:0 !important;
}

.row.margin-bottom {
	padding-bottom:40px !important;
	margin-bottom:0 !important;
}

.row.margin-bottom-sm {
	padding-bottom:20px !important;
	margin-bottom:0 !important;
}

.no-margin {
	margin:0 !important;
}

.no-padding {
	padding:0 !important;
}

.three-fourths {
	width:75%;
}

.label {
	text-transform:uppercase;
	letter-spacing:3px;
	color: #819eb5;
    font-family: $akkurat_reg;
    font-size: 12px;
    margin-top:20px;
}

.end-xs .label {
	float:right;
}

.video-cover {
	position:relative;
	z-index:9;
	background:#fff;
	width:100%;
}

.note {
	color: #819eb5 !important;
	font-family: $akkurat_reg !important;
	font-size: 14px !important;
	line-height: 20px !important;
	margin-top:20px !important;
}

.fixed-width {
	position:relative;
	max-width:1240px;
	margin:60px auto;

	&.top {
		margin:0 auto 100px auto;
	}
	&.bottom {
		margin:100px auto 0 auto;
	}
	&.top.bottom {
		margin:0 auto 0 auto;
	}
}

.fixed-width-lg {
	max-width:1140px;
	margin:50px auto;
}

#questions-bottom.fixed-width.top {
	margin-bottom:0 !important;
}

aside .header3 {
	max-width:265px;
	margin-bottom:20px !important;
}

.spaces {

	.col-xs-4 {
		padding-left:calc(.5rem + 10px);
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-4:first-child {
		padding-left:.5rem;
		padding-right:calc(.5rem + 10px);
	}
	.col-xs-4:last-child {
		padding-left:calc(.5rem + 10px);
		padding-right:.5rem;
	}
	.thumb {
		display:block;
		width:100%;
		height:160px;
		background-size:cover !important;
		margin-bottom:20px;
	}
}

hr {
	display:block;
	background:#e0e7ed;
	width:100%;
	height:5px;
	margin:80px 0;
	border:none;
}

.concert-photo {
	width:100%;
	height:290px;
	margin:40px 0;
	background-size:cover !important;
}

.regenstein-photo {
	width:100%;
	height:290px;
	margin:40px 0;
	background-size:cover !important;
}

.gray-bg {
	background-color: #f2f5f8;
}

.gray-bar {
	display:block;
	width:100%;
	background-color: #f2f5f8;
	padding:10px 0;

	.row {
		max-width:1240px;
		margin:0 auto;
	}
	#search-resources,
	#results-search {
		float:right;
	}
	#results-search input[type="text"] {
		background:#fff;
	}
	input[type="submit"] {
		text-indent:-9999px;
	}
}

.form-list-row {
	padding-top:20px;
	padding-bottom:20px;

	p {
		padding-right:20px;
	}
}

.map-cnt-full {
	display:block;
	position:relative;
	width:100%;
	height:400px;
	overflow:hidden;
	border: 1px solid #a18fbc;

	a {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:999;
	}
  div {
    //Adding interactive elements via client request - SM
    //position:absolute;
    //top:-30px;
    left:0;
    //width:100%;
    height:calc(100% + 30px);
  }
}
.map-cnt-half {
	display:block;
	position:relative;
	width:100%;
	height:200px;
	overflow:hidden;
	border: 1px solid #a18fbc;

	a {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:999;
	}
	div {
		//Adding interactive elements via client request - SM
		//position:absolute;
		//top:-30px;
		left:0;
		//width:100%;
		height:calc(100% + 30px);
	}
}

.lightbox {
	display:none;
	position:fixed;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
	background:#fff;
	box-shadow:0 0 10px rgba(0, 0, 0, 0.4);
	width:100%;
	height:auto;
	max-width:720px;
	min-height:480px;
	z-index:99999999999;

	.close {
		position:absolute;
		top:10px;
		right:10px;
		z-index:999;
		width:30px;
		height:30px;
		background:url(../images/close.svg) 50% no-repeat;
	}
	.cnt {
		position:absolute;
		padding:40px;
		width:100%;
		height:100%;

		iframe {
			width:100%;
			height:100%;
		}
	}
}
.lightbox-overlay {
	display:none;
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:rgba(0, 0, 0, 0.8);
	z-index:999999999;
}


@media only screen and (max-width: 1280px) and (min-width: 1px) {
	.fixed-width {
		padding:20px 60px !important;
	}
	.fixed-width-lg {
		padding:0 50px !important;
	}
}

@media only screen and (max-width: 1024px) and (min-width: 1px) {
	#application-content.col-xs-5 {
	    flex-basis: 100%;
	    max-width: 100%;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.fixed-width {
		margin:80px auto;
	}
	.fixed-width-lg {
		margin:50px 0 0 0;
		padding:0 20px !important;
	}
	.margin-top-lg {
		margin-top:40px !important;
	}
	.margin-top {
		margin-top:20px !important;
	}
	.margin-top-sm {
		margin-top:10px !important;
	}
	.margin-bottom-lg {
		margin-bottom:40px !important;
	}
	.margin-bottom {
		margin-bottom:20px !important;
	}
	.margin-bottom-sm {
		margin-bottom:10px !important;
	}
	.intro {
		margin:0;
	}
	hr {
		margin:50px auto;
	}
	aside {
		padding:0;
	}
	.map-cnt-full {
		margin:30px 0 0 0;
	}
	.map-cnt-half {
		margin:30px 0 0 0;
		height:400px;
	}
	.gray-bar h2 {
		text-align:center;
	}
	.gray-bar .btns-cnt {
		text-align:center;
	}
	.gray-bar #results-search,
	.gray-bar #search-resources {
		float:none;
		margin:0 auto;
	}
	.row > [class*="col-"] {
		flex-basis: 100%;
    	max-width: 100%;
	}

	.section.fixed-width > .row > .col-xs-4 {
		order: 1;
		margin-top: 2rem;
	}

	.section.fixed-width > .row > .col-xs-8 {
		order: 0;
		margin-top: 2rem;
	}

	.first-xs {
		order:1;
		-ms-flex-order: 1;
	}
	.last-xs {
		order:0;
		-ms-flex-order: 0;
	}
	.fixed-width {
		margin:30px auto;
		padding:0 20px !important;
	}
	aside .header3 {
		max-width:265px;
		margin-bottom:20px !important;
	}
	hr {
		margin:50px auto;
	}
	.table-wrapper .table .table-body .table-gray,
	.table-wrapper .table .table-body .table-blue {
		margin:0;
	}
	#questions .no-margin {
		margin-top:30px !important;
	}
	#questions .phone {
		margin-top:0 !important;
	}
	#library img {
		margin-top:50px;
	}
	#slate {
		padding:40px;
	}
}

@media only screen and (max-width: 767px) and (min-width: 1px) {
	article > *:not(.select-study):not(div),
	aside > *,
	.studies-box,
	.cta-box,
	.photo-cta,
	.events-slider,
	.photo-content-slider,
	.box-office,
	.events-wrapper .event,
	.reveal,
	.paragraph--type--text-content > * {
		opacity:1 !important;
		transform:translate3d(0, 0, 0) !important;
	}	
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	.fixed-width {
		padding:20px !important;
	}
	.fixed-width-lg {
		margin:40px auto !important;
	}
	.fixed-width.top {
		padding-top:30px !important;
	}
	.map-cnt-full,
	.map-cnt-half {
		margin:30px 0 0 0;
		height:200px;
	}
	.concert-photo,
	.regenstein-photo {
		height:200px;
	}
	.fixed-width.top {
		margin:0 auto 20px auto;
	}
}

/* Some extra stuff for sitewide alerts plugin */
.alert {
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
}
.alert h4 {
	margin-top: 0;
	color: inherit;
}
.alert .alert-link {
	font-weight: bold;
}
.alert > p,
.alert > ul {
	margin-bottom: 0;
}
.alert > p + p {
	margin-top: 5px;
}
.alert-dismissable,
.alert-dismissible {
	padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
	position: relative;
	top: -2px;
	right: -21px;
	color: inherit;
}
.alert-success {
	background-color: #dff0d8;
	border-color: #d6e9c6;
	color: #3c763d;
}
.alert-success hr {
	border-top-color: #c9e2b3;
}
.alert-success .alert-link {
	color: #2b542c;
}
.alert-info {
	background-color: #d9edf7;
	border-color: #bce8f1;
	color: #31708f;
}
.alert-info hr {
	border-top-color: #a6e1ec;
}
.alert-info .alert-link {
	color: #245269;
}
.alert-warning {
	background-color: #fcf8e3;
	border-color: #faebcc;
	color: #8a6d3b;
}
.alert-warning hr {
	border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
	color: #66512c;
}
.alert-danger {
	background-color: #f2dede;
	border-color: #ebccd1;
	color: #a94442;
}
.alert-danger hr {
	border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
	color: #843534;
}
