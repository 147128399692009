//
// Name:            Footer
//
// Description:     Contains all footer styles
//

// ========================================================================
// Component: Footer
// ========================================================================


.home-reveal-cols {
	display:block;
	position:relative;
	width:100%;
	height:500px;
	background:#000;

	.sec-1 {
		display:block;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:500px;
		background-size:cover !important;
		clip: rect(0px, 0px, 500px, 0px);
		border-right:1px solid #dfdfdf;
		transition: transform 1s, clip .4s;

		a, .col-title {
			display:none;
		}
	}
	.sec-2 {
		display:block;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:500px;
		background-size:cover !important;
		clip: rect(0px, 0px, 500px, 0px);
		border-right:1px solid #dfdfdf;
		transition: transform 1s, clip .4s;

		a, .col-title {
			display:none;
		}
	}
	.sec-3 {
		display:block;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:500px;
		background-size:cover !important;
		clip: rect(0px, 0px, 500px, 0px);
		border-right:1px solid #dfdfdf;
		transition: transform 1s, clip .4s;

		a, .col-title {
			display:none;
		}
	}
	.sec-4 {
		display:block;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:500px;
		background-size:cover !important;
		clip: rect(0px, 0px, 500px, 0px);
		transition: transform 1s, clip .4s;

		a, .col-title {
			display:none;
		}
	}
	.trigger-reveal {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:500px;
		z-index:99999;

		.row {
			height:500px;
		}
		.col-xs-3 {
			height:100%;
			transition:.4s;
		}
		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			z-index:999;
		}
		.col-title {
			position:absolute;
			bottom:100px;
			left:50px;
			z-index:5;
			color: #ffffff;
			font-family: $akkurat_bold;
			font-size: 18px;
			line-height: 26px;
			opacity:1;
			transition:.4s;

			&.on {
				opacity:1;

				&:after {
					width:calc(100% + 100px);
				}
			}
			&.off {
				opacity:0;
			}
			&:after {
				position:absolute;
				content:'';
				bottom:-10px;
				left:0;
				width:100%;
				height:3px;
				background:#fff;
				transition:.4s;
			}
		}
	}
	.lines {
		display:none;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:500px;
		z-index:999;

		.line1 {
			position:absolute;
			top:0;
			left:0;
			width:2px;
			height:100%;
			background:#fff;
			opacity:.3;
		}
		.line2 {
			position:absolute;
			top:0;
			left:0;
			width:2px;
			height:100%;
			background:#fff;
			opacity:.3;
		}
		.line3 {
			position:absolute;
			top:0;
			left:0;
			width:2px;
			height:100%;
			background:#fff;
			opacity:.3;
		}
	}
}


footer {
	position:relative;
	width:100%;
	background:$purple;
	padding:80px 50px 45px 50px;

	.row + .row  {
		padding-top:80px;
	}
	.footer-logo {
		display:inline-block;
		width:235px;
		height:30px;
		text-indent:-9999px;
		background:url(../images/footer-logo.png) 50% no-repeat;
	}
	.school {
		display:inline-block;
		width:100%;
		margin:50px 0 20px 0;
		color: #ffffff;
		font-family: $akkurat_bold;
		font-size: 24px;
		letter-spacing: 2px;
		line-height: 30px;
		text-transform: uppercase;		
	}
	.location {
		display:inline-block;
		width:100%;
		position:relative;
		padding-left:25px;
		background:url(../images/footer-location.svg) left center no-repeat;
		color: #ffffff;
		font-family: $akkurat_bold;
		font-size: 16px;
		line-height: 28px;
		margin:0;
	}
	.address {
		display:inline-block;
		width:100%;
		color: #ffffff;
		font-family: $akkurat_reg;
		font-size: 16px;
		line-height: 28px;
		padding-left:25px;
		margin:0;
	}
	.phone {
		display:inline-block;
		width:100%;
		position:relative;
		padding-left:25px;
		background:url(../images/footer-phone.svg) left center no-repeat;
		color: #ffffff;
		font-family: $akkurat_reg;
		font-size: 16px;
		line-height: 28px;
		margin:5px 0 0 0;

		a {
			color: #ffffff;
			font-family: $akkurat_reg;
			font-size: 16px;
			line-height: 28px;
			text-decoration:underline;

			&:hover, &:focus {
				opacity:.5;
				//color:$lt-purple;
			}
		}
	}
	.email {
		display:inline-block;
		width:100%;
		position:relative;
		padding-left:25px;
		background:url(../images/footer-email.svg) left center no-repeat;
		color: #ffffff;
		font-family: $akkurat_reg;
		font-size: 16px;
		line-height: 28px;
		margin:5px 0 0 0;

		a {
			color: #ffffff;
			font-family: $akkurat_reg;
			font-size: 16px;
			line-height: 28px;
			text-decoration:underline;

			&:hover, &:focus {
				opacity:.5;
				//color:$lt-purple;
			}
		}
	}
	.social {
		list-style:none;
		padding:0;
		margin:0;

		li {
			display:inline-block;
			width:25px;
			height:25px;
			margin:0 30px 0 0;
			opacity:.6;
			transition:.4s;

			&:hover, &:focus {
				opacity:1;
			}
			a {
				display:inline-block;
				width:25px;
				height:25px;
				text-indent:-9999px;
			}
			&.facebook {
				background:url(../images/facebook-white.svg) 50% no-repeat;
			}
			&.twitter {
				background:url(../images/twitter-white.svg) 50% no-repeat;
			}
			&.x {
				background:url(../images/x-white.svg) 50% no-repeat;
			}
			&.youtube {
				background:url(../images/youtube-white.svg) 50% no-repeat;
			}
			&.instagram {
				background:url(../images/instagram-white.svg) 50% no-repeat;
			}
			&.linkedin {
				background:url(../images/icon-linkedin.svg) 50% no-repeat;
			}
		}
	}
	.footer-nav {

		ul {
			list-style:none;
			padding:0;
			margin:0;

			li {
				display:inline-block;
				width:100%;
				margin:0 0 15px 0;

				a {
					display:inline-block;
					width:100%;
					color: #ffffff;
					font-family: $akkurat_reg;
					font-size: 24px;
					line-height: 30px;
					letter-spacing: -1px;
					text-decoration:none;

					&:hover, &:focus {
						color:#A18FBC;
					}					
				}
			}
		}
	}
	.utility-footer {

		ul {
			list-style:none;
			padding:0;
			margin:0;

			li {
				display:inline-block;
				width:auto;
				margin:0 15px;

				a {
					display:inline-block;
					width:100%;
					color: #ffffff;
					font-family: $akkurat_reg;
					font-size: 14px;
					line-height: 20px;				
					text-decoration:none;

					&:hover, &:focus {
						color:#A18FBC;
					}					
				}
			}
		}
	}
}

.mobile-parent {
	display:none;
}

#ctas {
	position:relative;
	z-index:99;

	.cta-box {
		display:block;
		position:relative;
		margin:0 auto -30px auto;
		padding:0 0 80px 0;
		width:100%;
		max-width:1340px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		border: 1px solid #ffffff;
		background-image: linear-gradient(181deg, #f2f5f8 0%, #e0e7ed 100%);

		&:after {
			position:absolute;
			content:'';
			top:0;
			left:50%;
			width:2px;
			height:100%;
			background:#fff;
			transform:translate(-50%, 0);
		}
		h4 {
			color: #000000;
			font-family: $campton;
			font-size: 32px;
			letter-spacing: -1px;
			line-height: 42px;
			margin:80px 0 40px 0;
			padding:0 80px;	
		}
	}
}

#testimonial {
	position:relative;
	width:100%;
	height:500px;

	.row {
		height:500px;
	}
	.quote-box {
		position:absolute;
		right:50px;
		bottom:-40px;
		width:650px;
		padding:60px 50px;
		background:#fff;
		z-index:99;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

		.quote {
			color: #000000;
			font-family: $periodico;
			font-size: 24px;
			line-height: 34px;			
		}
		.name-wrap {
			display:inline-block;
			position:relative;
			width:100%;

			.avatar {
				display:inline-block;
				position:absolute;
				top:0;
				left:0;
				width:50px;
				height:50px;
				border-radius:50px;
				background-size:cover !important;
			}
			.name {
				display:inline-block;
				width:calc(100% - 70px);
				padding-left:70px;
				color: #000000;
				font-family: $akkurat_reg;
				font-size: 16px;
				line-height: 50px;				
			}
		}
	}
	.bg-wrap {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1;
		overflow:hidden;

		.bg {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:900px;
			z-index:1;
			background-size:cover !important;
			transform:translate3d(0, 0, 0);
		}
	}
}


@media only screen and (max-width: 1180px) and (min-width: 1px) {
	footer .footer-nav ul li a {
		font-size:18px;
		line-height:26px;
	}
	.photo-cta .cnt {
		padding:0 40px;
	}
	.home-reveal-cols .trigger-reveal .col-title.on:after {
		width:calc(100% + 50px);
	}
	.home-reveal-cols .trigger-reveal .col-title {
		left:20px;
	}
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
	#ctas .cta-box h4 {
		font-size:24px;
		line-height:32px;
	}
	#ctas .cta-box {
		margin:0 30px -20px 30px;
		width:calc(100% - 60px);
	}
}

@media only screen and (max-width: 1023px) and (min-width: 1px) {
	footer > .main-footer > .col-xs-6:first-child {
		order:1;
		flex-basis: 100%;
    	max-width: 100%;
	}
	footer > .main-footer > .col-xs-6:last-child {
		order:0;
		flex-basis: 100%;
    	max-width: 100%;
	}
	footer .school {
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
	}
	footer .footer-nav {
		margin-top:40px;
	}
	footer .footer-nav ul li {
		margin:0 0 10px 0;
	}
	footer > .row .start-xs.col-xs-6:first-child {
		flex-basis: 100%;
    	max-width: 100%;
	}
	footer > .row .end-xs.col-xs-6:last-child {
		flex-basis: 100%;
    	max-width: 100%;
	}
	.utility-footer {
		text-align:left;
		margin-top:60px;
	}
	footer .row + .row {
		padding-top:30px;
	}
	footer .utility-footer ul li:first-child {
		margin:0 15px 0 0;
	}
	.photo-cta .cnt {
		padding:30px 20px;
	}
	.home-reveal-cols .trigger-reveal .col-title {
		font-size:16px;
	}
	.home-reveal-cols {
		height:auto;
		position:relative;
	}
	.home-reveal-cols .trigger-reveal {
		display:none;
	}
	.home-reveal-cols .sec-1,
	.home-reveal-cols .sec-2,
	.home-reveal-cols .sec-3,
	.home-reveal-cols .sec-4 {
		position:relative;
		height:350px;
		border-right:none;

		&:hover, &:focus {
			.col-title:after {
				width:calc(100% + 100px);
			}
		}
	}
	.home-reveal-cols .trigger-reveal .col-xs-3 {
		flex-basis: 100%;
	    max-width: 100%;		
	}
	.home-reveal-cols .sec-1 .col-title,
	.home-reveal-cols .sec-2 .col-title,
	.home-reveal-cols .sec-3 .col-title,
	.home-reveal-cols .sec-4 .col-title {
		display:inline-block;
		position:absolute;
		bottom:50px;
		left:50px;
		font-size:32px;
		line-height:42px;
		color:#fff;
		font-family: $akkurat_bold;

		&:after {
			position:absolute;
			content:'';
			bottom:-10px;
			left:0;
			width:100%;
			height:3px;
			background:#fff;
			transition:.4s;
		}
	}
	.home-reveal-cols .sec-1 .col-title.on:after,
	.home-reveal-cols .sec-2 .col-title.on:after,
	.home-reveal-cols .sec-3 .col-title.on:after,
	.home-reveal-cols .sec-4 .col-title.on:after {
		width:calc(100% + 100px);
	}
	.home-reveal-cols .sec-1 a,
	.home-reveal-cols .sec-2 a,
	.home-reveal-cols .sec-3 a,
	.home-reveal-cols .sec-4 a {
		display:inline-block;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:999;
	}
}

@media only screen and (max-width: 767px) and (min-width: 1px) {
	footer {
		padding:40px 0 45px 0;
	}
	footer .footer-logo {
		display:block;
		margin:0 auto 40px auto;
	}
	.main-footer {
		border-top:1px solid $dark-purple;
	}
	.mobile-parent {
		display:block;
		position:relative;
		width:100%;
		color: #ffffff;
		font-family: $akkurat_bold;
		font-size: 16px;
		line-height: 24px;
		padding:8px 20px;
		border-bottom:1px solid $dark-purple;

		&:hover, &:focus {
			color:#fff;
		}
		.ico {
			position:absolute;
			top:0;
			right:0;
			width:40px;
			height:40px;
			border-left:1px solid $dark-purple;

			&.open:after {
				transform:rotate(180deg);
			}
			&:after {
				position:absolute;
				content:'';
				top:12px;
				left:12px;
				width:14px;
				height:14px;
				background:url(../images/white-arrow.svg) 50% no-repeat;
				background-size:100% !important;
				transition:.4s;
			}
		}
	}
	footer .footer-nav {
		margin-top:0;
	}
	footer .footer-nav ul,
	footer .mobile-wrapper {
		padding:20px;
		display:none;
		border-bottom:1px solid $dark-purple;
	}
	footer .col-xs-6 {
		margin:0 !important;
	}
	footer .school {
		margin:0;
	}
	footer .social {
		text-align:center;
	}
	footer .social li {
		margin:0 15px;
	}
	footer .utility-footer {
		margin-top:20px;
	}
	footer .utility-footer ul li {
		width:100%;
		text-align:center;
		margin:10px 0 !important;
	}
	.photo-cta .cnt {
		padding:30px 40px;
	}
	#testimonial .quote-box {
		bottom:-20px;
	}
	#ctas .cta-box:after {
		display:none;
	}
	#ctas .cta-box h4 {
		margin:40px 0;
	}
	#ctas .col-xs-6:first-child {
		padding-bottom:40px;
		border-bottom:1px solid #ddd;
	}
	#testimonial .quote-box {
		width:auto;
		left:50px;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	#testimonial .quote-box {
		padding:20px;
	}
	#testimonial .quote-box .quote {
		font-size:18px;
		line-height:24px;
	}
	#testimonial .quote-box .name-wrap .avatar {
		display:none;
	}
	#testimonial .quote-box .name-wrap .name {
		width:100%;
		padding-left:0;
		line-height:24px;
	}
	#ctas .cta-box h4 {
		padding:0 20px;
		font-size: 18px;
		line-height: 26px;
	}
	.home-reveal-cols .sec-1 .col-title,
	.home-reveal-cols .sec-2 .col-title,
	.home-reveal-cols .sec-3 .col-title,
	.home-reveal-cols .sec-4 .col-title {
		font-size:24px;
		line-height:32px;
		left:20px;
	}
	#ctas .cta-box {
		margin:0 auto;
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	.home-reveal-cols .sec-1 .col-title,
	.home-reveal-cols .sec-2 .col-title,
	.home-reveal-cols .sec-3 .col-title,
	.home-reveal-cols .sec-4 .col-title {
		font-size:18px;
		line-height:22px;
		left:20px;
	}
	.home-reveal-cols .sec-1:hover .col-title:after,
	.home-reveal-cols .sec-2:hover .col-title:after,
	.home-reveal-cols .sec-3:hover .col-title:after,
	.home-reveal-cols .sec-4:hover .col-title:after {
		width:calc(100% + 20px);
	}

	.home-reveal-cols .sec-1:focus .col-title:after,
	.home-reveal-cols .sec-2:focus .col-title:after,
	.home-reveal-cols .sec-3:focus .col-title:after,
	.home-reveal-cols .sec-4:focus .col-title:after {
		width:calc(100% + 20px);
	}
}
