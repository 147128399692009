//
// Name:            Form
//
// Description:     Contains all form styles
//


// ========================================================================
// Component: Form
// ========================================================================


// form
form {
	margin:40px 0;
	position:relative;
	z-index:9;
}

fieldset {
	border:none;
	padding:0;
	margin:50px 0 20px 0;

	legend {
		width:100%;
		//color: #507796;
		//font-family: $akkurat_bold;
		//font-size: 12px;
		//letter-spacing: 3px;
		//line-height: 16px;
		//text-transform: uppercase;
		border-bottom: 5px solid #e0e7ed;
		padding:0 0 10px 0;
		margin:10px 0 0 0;
		color: #000000;
		font-family: $campton;
		font-size: 24px;
		line-height: 32px;	
	}
}

.one-third-field {
	width:33.3333% !important;
}
.one-third-field:nth-child(2) {
	width:calc(33.3333% - 50px) !important;
	margin-right:50px;
}
.one-third-field:nth-child(3) {
	width:calc(33.3333% - 50px) !important;
	margin-right:25px;
	margin-left:25px;
}
.one-third-field:last-child {
	width:calc(33.3333% - 50px) !important;
	margin-left:50px;
}

// inputs
input[type="text"],
input[type="email"],
input[type="password"],
input[type="phone"],
input[type="tel"],
input[type="date"] {
	display:inline-block;
	width:100%;
	background-color: rgba(153, 153, 153, 0);
	border: 1px solid #819eb5;
	color: #000000;
	font-family: $akkurat_reg;
	font-size: 16px;
	line-height: 28px;
	margin:10px 0 10px 0;
	padding:0 22px;
}

textarea {
	display:inline-block;
	width:100%;
	height:220px;
	background-color: rgba(153, 153, 153, 0);
	border: 1px solid #819eb5;
	color: #000000;
	font-family: $akkurat_reg;
	font-size: 16px;
	line-height: 28px;
	margin:10px 0 10px 0;
	padding:12px 22px;
}

// active
input:focus {
	background-color: rgba(153, 153, 153, 0);
	border: 1px solid #000000;
	box-shadow: 0 0 10px rgba(78, 42, 132, 0.2);
}

// disabled
input:disabled {
	background-color: #ecedf1;
	border: 1px solid #b5bec5;
}

// error
input.error {
	background-color: rgba(153, 153, 153, 0);
	border: 1px solid #ed3024;
	box-shadow: 0 0 10px rgba(78, 42, 132, 0.2);
}

// success
input.success {
	background-color: rgba(153, 153, 153, 0);
	border: 1px solid #417505;
	box-shadow: 0 0 10px rgba(78, 42, 132, 0.2);
}

/* default custom select styles */
div.form-select,
div.cs-select {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	text-align: left;
	z-index: 100;
	width: 100%;
	margin:10px 0;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
div.form-select:focus,
div.cs-select:focus {
	outline: none; /* For better accessibility add a style for this in your skin */
}
div.form-select select,
.cs-select select {
	display: none;
}

div.form-select span,
.cs-select span {
	display: block;
	position: relative;
	cursor: pointer;
	padding: 5px 22px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* placeholder and selected option */
div.form-select > span,
.cs-select > span {
	padding-right: 3em;
}

div.form-select > span::after,
div.form-select .cs-selected span::after,
.cs-select > span::after,
.cs-select .cs-selected span::after {
	speak: none;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

div.cs-active {
	z-index: 200;
}

/* options */
div.form-select .cs-options,
.cs-select .cs-options {
	position: absolute;
	//overflow: hidden;
	width: 100%;
	background: transparent;
	visibility: hidden;
	opacity:0;
	transition:.4s;
	max-height: 200px;
	overflow: auto;
}
div.form-select.cs-active .cs-options,
.cs-select.cs-active .cs-options {
	visibility: visible;
	opacity:1;
}
div.form-select ul,
.cs-select ul {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	border-radius: 2px;
	border: 1px solid #aec1d0;
	background-color: #ffffff;
}
div.form-select ul span,
.cs-select ul span {
	padding: 5px 22px;
}

div.form-select ul li.cs-focus span,
.cs-select ul li.cs-focus span {
	background-color: #f2f5f8;
}

.cs-skin-elastic .cs-options ul li {
	transition:.4s;
}

.cs-skin-elastic .cs-options ul li:hover,
.cs-skin-elastic .cs-options ul li:focus {
	background-color: #f2f5f8;
}
div.form-select li.cs-optgroup > span,
.cs-select li.cs-optgroup > span {
	cursor: default;
}

.cs-skin-elastic > span::after {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.cs-skin-elastic.cs-active .cs-options {
	pointer-events: auto;
}

.cs-skin-elastic .cs-options > ul::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	-webkit-transform: scale3d(1,0,1);
	transform: scale3d(1,0,1);
	-webkit-transform-origin: 50% 0%;
	transform-origin: 50% 0%;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.cs-skin-elastic.cs-active .cs-options > ul::before {
	-webkit-transform: scale3d(1,1,1);
	transform: scale3d(1,1,1);
	-webkit-transition: none;
	transition: none;
	-webkit-animation: expand 0.6s ease-out;
  	animation: expand 0.6s ease-out;
}

.cs-skin-elastic .cs-options ul li {
	opacity: 0;
	-webkit-transform: translate3d(0,-25px,0);
	transform: translate3d(0,-25px,0);
	-webkit-transition: opacity 0.15s, -webkit-transform 0.15s;
	transition: opacity 0.15s, transform 0.15s;
}

.cs-skin-elastic.cs-active .cs-options ul li {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
	opacity: 1;
	-webkit-transition: none;
	transition: none;
	-webkit-animation: bounce 0.6s ease-out;
  	animation: bounce 0.6s ease-out;
}

.cs-skin-elastic .cs-options .cs-selected span::after {
	content: '';
}

@-webkit-keyframes expand { 
	0% { -webkit-transform: scale3d(1,0,1); }
	25% { -webkit-transform: scale3d(1,1.2,1); }
	50% { -webkit-transform: scale3d(1,0.85,1); }
	75% { -webkit-transform: scale3d(1,1.05,1) }
	100% { -webkit-transform: scale3d(1,1,1); }
}

@keyframes expand { 
	0% { -webkit-transform: scale3d(1,0,1); transform: scale3d(1,0,1); }
	25% { -webkit-transform: scale3d(1,1.2,1); transform: scale3d(1,1.2,1); }
	50% { -webkit-transform: scale3d(1,0.85,1); transform: scale3d(1,0.85,1); }
	75% { -webkit-transform: scale3d(1,1.05,1); transform: scale3d(1,1.05,1); }
	100% { -webkit-transform: scale3d(1,1,1); transform: scale3d(1,1,1); }
}


@-webkit-keyframes bounce { 
	0% { -webkit-transform: translate3d(0,-25px,0); opacity:0; }
	25% { -webkit-transform: translate3d(0,10px,0); }
	50% { -webkit-transform: translate3d(0,-6px,0); }
	75% { -webkit-transform: translate3d(0,2px,0); }
	100% { -webkit-transform: translate3d(0,0,0); opacity: 1; }
}

@keyframes bounce { 
	0% { -webkit-transform: translate3d(0,-25px,0); transform: translate3d(0,-25px,0); opacity:0; }
	25% { -webkit-transform: translate3d(0,10px,0); transform: translate3d(0,10px,0); }
	50% { -webkit-transform: translate3d(0,-6px,0); transform: translate3d(0,-6px,0); }
	75% { -webkit-transform: translate3d(0,2px,0); transform: translate3d(0,2px,0); }
	100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); opacity: 1; }
}


.js-form-item {
	position:relative;
}

// checkboxes
.checkboxes {
	
	li {
		position:relative;
	}
}

// input[type="checkbox"] {
// 	position: absolute;
// 	display: block;
// 	@include size(100%);
// 	top: 0;
// 	left: 0;
// 	z-index: 1;
// 	cursor: pointer;
// 	opacity: 0;
// }

input[type="checkbox"] + label {
	color: #000000;
	font-family: $akkurat_reg;
	font-size: 16px;
	line-height: 28px;
	letter-spacing:0px;
	padding-left:35px;
	text-transform:none;	
}

input[type="checkbox"] {
	position: absolute;
	content: '';
	transition: .3s cubic-bezier(.68,-.55,.265,1.55);
	top: 3px;
	left: 5px;
	width: 20px;
	height: 20px;
	line-height:20px;
	background:#fff;
	border-radius:0;
	background-clip: padding-box;
	border: 1px solid #819eb5;
	text-align:center;
}

input[type="checkbox"]:disabled {
	border: 1px solid #aec1d0;
	background-color: #f2f5f8;
}

input[type="checkbox"]:checked {
	content:'';
	background:url(../images/checkmark.svg) 50% no-repeat;
	color: #4e2a84;
}

input[type="checkbox"]:disabled:checked {
	content:'';
	background:url(../images/checkmark.svg) 50% no-repeat;
	color: #aec1d0;
}

// radios
.radios {

	li {
		position:relative;
	}
}

input[type="radio"] {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	z-index: 1;
	cursor: pointer;
	opacity: 0;
}

input[type="radio"] + label {
	padding-left:35px;
	line-height:20px;
	color: #000000;
	font-family: $akkurat_reg;
	font-size: 16px;
	line-height: 22px;
	padding-left:35px;
	text-transform:none;	
}

input[type="radio"] + label:before {
	top: 4px;
	right: 4px;
	top: 0;
	left: 4px;
	width:20px;
	height:20px;
	background:#fff;
	border-radius: 100%;
	position: absolute;
	content: '';
	text-align:center;
	line-height:20px;
	border: 1px solid #819eb5;
	transition: .3s cubic-bezier(.68,-.55,.265,1.55);
}

input[type="radio"]:disabled + label:before {
	border: 1px solid #aec1d0;
}

input[type="radio"]:checked + label:before {
	color: #819eb5;
	content: "\2022";
    font-size: 40px;
}

input[type="radio"]:disabled:checked + label:before {
	color: #aec1d0;
	opacity:.5;
}

// form global
form {

	ul {
		list-style:none;
		padding:0;
		margin:0;
	}
}

// no-labels form inputs
form.views-exposed-form,
form.no-labels, form .no-labels {

	li {
		position:relative;

		&:hover {
			background: #e7ebee;
		}
	}
	label {
		height:0;
		visibility:hidden;
		margin-top:0 !important;
	}
	.radios-label,
	.checkboxes-label {
		height:auto;
		visibility:visible;
		margin-top:20px !important;
	}
	.radios label,
	.checkboxes label {
		height:auto;
		visibility:visible;
		margin-top:20px !important;
	}
	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="phone"],
	input[type="tel"] {
		height:60px;
	}
	div.form-select,
	div.cs-select {
		height:60px;
		background:$purple;
	}
	div.form-select span,
	.cs-select span {
		padding:16px 22px;
	}
	div.form-select > span {
		color: #fff;
	}
	div.form-select > span::after,
	.cs-select > span::after {
		content:'';
		width:16px;
		height:16px;
		background:url(../images/white-arrow.svg) 50% no-repeat;
		background-size:100% !important;
		right: 17px;
		transform:translateY(-50%);
		transition:.4s;
	}
	div.form-select .cs-selected span,
	.cs-select .cs-selected span {
		background-color: #f2f5f8;
	}
	div.cs-skin-elastic {
		background: transparent;
		color: #fff;
		font-family: $akkurat_bold;
		font-size: 18px;
		line-height: 26px;
	}
	.cs-skin-elastic > span {
		background:$purple;
		z-index: 100;
	}
	div.form-select .cs-options span,
	.cs-select .cs-options span {
		color: #000000;
		font-family: $akkurat_reg;
		font-size: 16px;
		line-height: 28px;
		padding:5px 22px;	
	}
	div.form-select.cs-active > span::after,
	.cs-select.cs-active > span::after {
		-webkit-transform: translateY(-50%) rotate(180deg);
		transform: translateY(-50%) rotate(180deg);
	}
}

// labels form inputs
form.labels, form .labels {

	li {
		position:relative;
	}
	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="phone"],
	input[type="tel"] {
		height:40px;
	}
	div.cs-select {
		height:40px;
		border: 1px solid #aec1d0;
		background-image: linear-gradient(180deg, #ffffff 0%, #e0e8ee 100%);
	}
	.cs-select > span::after {
		content:'';
		width:14px;
		height:14px;
		background:url(../images/dropdown-arrow.svg) 50% no-repeat;
		background-size:100% !important;
		right: 17px;
		transform:translateY(-50%);
		transition:.4s;
	}
	.cs-select .cs-selected span {
		background-color: #f2f5f8;
	}
	div.cs-skin-elastic {
		background: transparent;
		color: #000000;
		font-family: $akkurat_reg;
		font-size: 16px;
		line-height: 28px;
	}
	.cs-skin-elastic > span {
		background-image: linear-gradient(180deg, #ffffff 0%, #e0e8ee 100%);
		z-index: 100;
	}
	.cs-select.cs-active > span::after {
		-webkit-transform: translateY(-50%) rotate(180deg);
		transform: translateY(-50%) rotate(180deg);
	}
}

#submit-news {

	textarea {
		height:380px;
	}
}

// tooltips
.tooltip {
	position:relative;
	max-width:220px;
}

.tooltip-hover {
	display:inline-block;
	width:20px;
	height:20px;
	background:$purple;
	border-radius:20px;
	text-align:center;
	line-height:23px;
	color:#fff;
	font-size:14px;
	font-family: $akkurat_bold;

	&:hover, &:focus {
		color:#fff;
	}
	&:hover + div, &:focus + div {
		display:block;
	}
}

.tooltip-box {
	display:none;
	position:absolute;
	top:35px;
	left:calc(50% - 100px);
	z-index:99;
	max-width:220px;
	width:100%;
	padding:22px;
	background-color: #ecedf1;
	border-radius: 3px;
	color: #819eb5;
	font-family: $akkurat_reg;
	font-size: 14px;
	line-height: 20px;
	transform:translate(-50%, 0);

	&:before {
		position:absolute;
		content:'';
		top:-10px;
		left:50%;
		width: 0;
		height: 0;
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;
		border-bottom: 15px solid #ecedf1;
		transform:translate(-50%, 0);
	}
}

.file-upload {
	height:auto !important;
	visibility:visible !important;
    width: 220px;
    font-size: 16px;
	font-family: $akkurat_bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 15px 30px;
    color: $purple;
    cursor:pointer;
    background:transparent;
    border: 1px solid #819eb5;
    transition:.4s;
}

.form-managed-file {
	position:relative;
}

.inputfile, input[type="file"] {
    width: 220px;
    height: 50px;
    opacity: 0;
    top:0;
    left:0;
    overflow: hidden;
    position: absolute;
    z-index: 9;
    cursor:pointer;
}

input[type="file"] + .file-upload,
.inputfile:hover + .file-upload,
.inputfile:focus + .file-upload {
	background:$purple;
    border: 1px solid $purple;
	color:#fff;
}

.js-form-type-managed-file .description {
	font-size:12px;
	line-height:16px;
	padding-top:10px;
}

input[type="submit"] {
	display:inline-block;
	position:relative;
	width:auto;
	min-width:280px;
	height:auto;
	background-color: #f6f4f9;
	border: 1px solid #a18fbc;
	color: #4e2a84;
	font-family: $akkurat_bold;
	font-size: 16px;
	line-height: 28px;
	text-align:center;
	padding:15px 20px;
	margin:10px 0;
	cursor:pointer;
	transition:.4s;

	&:hover, &:focus {
		background:$purple;
		color:#fff;
	}
}


@media only screen and (max-width: 768px) and (min-width: 1px) {
	.one-third-field,
	.one-third-field:nth-child(2),
	.one-third-field:nth-child(3),
	.one-third-field:nth-child(4) {
		width:100% !important;
		margin:10px 0 !important;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	input[type=submit] {
		min-width:100%;
	}
}

//To hide form labels
.hide-label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
#filterby {
	width: 100%;
    display: block;
	
	> .col-xs-4 {
		width: 100%;
		max-width: 100%;
	}
}
#filterby,
.field--name-field-embed-view {
	label[for=edit-field-issue-publication-date-value],
	label[for=edit-tid] {
		width: calc(30% - 40px);
		margin: 10px 0;
		vertical-align: top;
		color: #000;
		font-family: AkkuratPro-Bold;
		font-size: 18px;
		line-height: 28px;
		border-radius: 2px;
		text-align: left;
		border: 1px solid #e0e7ed;
		position: relative;
		background-color: #f2f5f8;
		min-width: 280px;
		height: auto;
		padding: 15px 20px;
		visibility: visible;
		margin-top: 10px !important;
		
		@media only screen and (max-width: 580px) and (min-width: 1px) {
			width: 100%;
		}
		
	}
	.js-form-type-select {
		width: 100%;
		display: flex;
		float: none;
		
		@media only screen and (max-width: 580px) and (min-width: 1px) {
			display: block;
		}
	}
	.cs-placeholder {
		font-family: AkkuratPro-Bold;
		font-size: 18px;
		line-height: 26px;
	}
	.form-select {
		@media only screen and (max-width: 580px) and (min-width: 1px) {
			width: 100%;
		}
	}
}
