//
// Name:            Buttons
//
// Description:     Contains all button styles
//

// ========================================================================
// Component
// ========================================================================


.breadcrumbs {
	list-style:none;
	padding:20px 0 40px 0;
	width:100%;

	li {
		display:inline-block;
		position:relative;
		width:auto;
		padding-right:40px;
		margin:0;
		max-width:180px;
		text-overflow:ellipsis;
		white-space:nowrap;
		overflow:hidden;
		vertical-align:top;

		&:after {
			position:absolute;
			content:'';
			width:20px;
			height:20px;
			top:4px;
			right:6px;
			opacity:.5;
			background:url(../images/purple-arrow.svg) 50% no-repeat;
			background-size:60% !important;
			transform:rotate(-90deg);
		}
		&:last-child:after {
			display:none;
		}
		a {
			display:inline-block;
			font-family: $akkurat_bold;
			font-size: 16px;
			line-height: 28px;
			color: #3c1971;
			max-width:180px;
			text-overflow:ellipsis;
			white-space:nowrap;
			overflow:hidden;
			text-decoration:none;
		}
	}
}

.btn {
	display:inline-block;
	position:relative;
	width:auto;
	min-width:280px;
	height:auto;
	background-color: #f6f4f9;
	border: 1px solid #a18fbc;
	color: #4e2a84;
	font-family: $akkurat_bold;
	font-size: 16px;
	line-height: 28px;
	text-align:center;
	padding:15px 20px;
	margin:10px 0;
	text-decoration:none;

	&:hover, &:focus {
		background:$purple !important;
		color:#fff !important;
	}
}

.transparent-btn {
	display:inline-block;
	position:relative;
	width:auto;
	min-width:280px;
	height:auto;
	background:transparent;
	border: 1px solid #a18fbc;
	color: #4e2a84;
	font-family: $akkurat_bold;
	font-size: 16px;
	line-height: 28px;
	text-align:center;
	padding:15px 20px;
	margin:10px 0;
	text-decoration:none;

	&:hover, &:focus {
		background:$purple !important;
		color:#fff !important;
	}
}

.reverse-btn {
	display:inline-block;
	position:relative;
	width:auto;
	min-width:280px;
	height:auto;
	background-color:$purple;
	border: 1px solid #a18fbc;
	color: #fff;
	font-family: $akkurat_bold;
	font-size: 16px;
	line-height: 28px;
	text-align:center;
	padding:15px 20px;
	margin:10px 0;
	text-decoration:none;

	&:hover, &:focus {
		color:#fff !important;
		background:$dark_purple !important;
	}
}

.purple-btn {
	display:inline-block;
	width:auto;
	min-width:280px;
	height:auto;
	background-color: $purple;
	border: 1px solid $purple;
	color: #fff;
	font-family: $akkurat_bold;
	font-size: 16px;
	line-height: 28px;
	text-align:center;
	padding:15px 20px;
	margin:10px 0;
	text-decoration:none;
}

.purple-btn:hover, .purple-btn:focus {
  background:$dark_purple !important;
  color:#fff !important;
  box-shadow: 0 0 20px rgba(0,0,0,.6);
}

.purple-btn.free-disabled {
  cursor: default;
  &:hover, &:focus {
    background: $purple !important;
    color: #fff !important;
    box-shadow: none !important;
    cursor: default;
  }
}

.white-btn {
	display:inline-block;
	width:auto;
	min-width:280px;
	height:60px;
	background-color: #fff;
	border: 1px solid $purple;
	color: $purple;
	font-family: $akkurat_bold;
	font-size: 16px;
	line-height: 28px;
	text-align:center;
	padding:15px 20px;
	margin:10px 0;
	text-decoration:none;

	&:hover, &:focus {
		background:$purple !important;
		color:#fff !important;
		box-shadow: 0 0 20px rgba(0,0,0,.6);
	}
}

.tertiary-btn {
	display:inline-block;
	width:auto;
	min-width:160px;
	height:auto;
	background-color: #e0e7ed;
	border: 1px solid #e0e7ed;
	color: $purple;
	font-family: $akkurat_bold;
	font-size: 16px;
	line-height: 20px;
	text-align:center;
	padding:10px 20px;
	margin:10px 0;
	text-decoration:none;

	&:hover, &:focus {
		background:$purple !important;
		color:#fff !important;
	}
}

.anchor-links {
	display:block;
	max-width:270px;
	border: 1px solid #e0e7ed;
	background-color: #f2f5f8;
	list-style:none;
	padding:25px 30px;
	margin:20px 0;

	li {
		display:inline-block;
		width:100%;
		margin:5px 0;

		a {
			color: #4e2a84;
			font-family: $akkurat_bold;
			font-size: 16px;
			line-height: 24px;
			text-decoration:none;
		}
	}
}

.toggle-cnt {
	max-width:265px;
	width:100%;
	margin:0 0 10px 0;

	.section-parent {
		display:inline-block;
		position:relative;
		width:100%;
		padding:8px 0 6px 40px;
		color: #507796;
		font-family: $akkurat_reg;
		font-size: 12px;
		letter-spacing: 3px;
		line-height: 16px;
		text-transform: uppercase;
		text-decoration:none;
		transition:.4s;

		.ico {
			position:absolute;
			width:15px;
			height:15px;
			top:50%;
			left:10px;
			background:url(../images/purple-arrow.svg) 50% no-repeat;
			transform:translate(0, -50%) rotate(-90deg);
			transition:.4s;
		}
	}
	.toggle-btns {
		margin:0 0 50px 0;
		display:none;
	}
	&.open .section-parent {
		background-color: #e0e7ed;

		.ico {
			transform:translate(0, -50%) rotate(0deg);
		}
	}
	&.open .toggle-btns {
		display:block;
	}
}

.toggle-btns {
	list-style:none;
	padding:0;
	margin:10px 0 50px 0;
	max-width:265px;
	width:100%;
	border-bottom: 1px solid #dcd2ea;

	> li {
		display:block;
		width:100%;
		height:auto;
		margin:0;

		> a {
			display:block;
			position:relative;
			width:100%;
			color: #4e2a84;
			font-family: $akkurat_bold;
			font-size: 16px;
			line-height: 24px;
			padding:8px 40px 8px 15px;
			border-top: 1px solid #dcd2ea;
			border-left: 1px solid #dcd2ea;
			border-right: 1px solid #dcd2ea;
			text-decoration:none;

			&:hover, &:focus {
				background-color: #f2f5f8;
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
			}
			.ico {
				display:block;
				position:absolute;
				top:0;
				right:0;
				width:40px;
				height:100%;
				border-left: 1px solid #dcd2ea;

				&:after {
					position:absolute;
					content:'';
					width:14px;
					height:14px;
					top:50%;
					left:50%;
					background:url(../images/purple-arrow.svg) 50% no-repeat;
					background-size:100% !important;
					transform:translate(-50%, -50%);
					transition:.4s;
				}
			}
			&.active, &.is-active {
				background-color: #f6f4f9;

				.ico:after {
					transform:translate(-50%, -50%) rotate(180deg);
				}
			}
		}
		&.active ul, &.is-active ul,
		&.active-child ul {
			display:block;
		}
		&.active > a, &.is-active > a {
			background:$purple;
			color:#fff;

			.ico {

				&:after {
					background:url(../images/white-arrow.svg) 50% no-repeat;
				}
			}
		}
		&.active > a.active .ico:after, &.is-active > a.is-active .ico:after {
			transform:translate(-50%, -50%) rotate(180deg);
		}
		> ul {
			display:none;
			list-style:none;
			padding:25px 0;
			margin:0;
			border-top: 1px solid #dcd2ea;
			border-left: 1px solid #dcd2ea;
			border-right: 1px solid #dcd2ea;
			background-color: #f6f4f9;

			> li {
				display:block;
				position:relative;
				width:100%;
				padding:0 25px;
				margin:10px 0;

				> a {
					display:block;
					position:relative;
					width:100%;
					color: #4e2a84;
					font-family: $akkurat_bold;
					font-size: 16px;
					line-height: 24px;
					text-decoration:none;

					span {
						width: calc(100%);
						background-image: linear-gradient(transparent calc(100% - 2px), $purple 2px);
						background-repeat: no-repeat;
						background-size: 0% 3px;
						background-position:left bottom;
						transition: background-size .4s;
					}
					&:hover, &:focus span {
					    background-size: 100% 3px;
					}
					&:hover, &:focus {
						color:$purple;
					}
				}
				&.active > a, &.is-active > a {
					color:#000;
					font-family: $akkurat_reg;
				}
				&.active > a:before, &.is-active > a:before {
					position:absolute;
					content:'';
					top:0;
					left:-25px;
					width:6px;
					height:100%;
					background-color: #819eb5;
				}
				> ul {
					list-style:none;

					> li {
						margin:15px 0;
						line-height: 20px;

						> a {
							position:relative;
							font-family: $akkurat_bold;
							font-size: 16px;
							line-height: 20px;
							color:$purple;
							text-decoration:none;

							span {
								width: calc(100%);
								background-image: linear-gradient(transparent calc(100% - 2px), $purple 2px);
								background-repeat: no-repeat;
								background-size: 0% 3px;
								background-position:left bottom;
								transition: background-size .4s;
							}
							&:hover, &:focus span {
							    background-size: 100% 3px;
							}
							&:hover, &:focus {
								color:$purple;
							}
						}
						&.active > a, &.is-active > a {
							color:#000;
							font-family: $akkurat_reg;
						}
						&.active > a:before, &.is-active > a:before {
							position:absolute;
							content:'';
							top:0;
							left:-55px;
							width:6px;
							height:100%;
							background-color: #819eb5;
						}
					}
				}
			}
		}
	}
}

.btn-cnt {
	position:relative;
	width:100%;

	.btn {
		background:transparent;
	}
	a:before {
		position:absolute;
		content:'';
		top:50%;
		left:0;
		width:0;
		height:1px;
		z-index:99;
		background:$purple;
		transform:translate(0, -50%);
		transition:1.4s;
	}
}

.photo-cta .btn-cnt.on a:before {
	width:200px;
	left:-200px;
}

.right-photo-cta.on .btn-cnt a:before {
	width:200px;
	right:-200px;
}

.left-photo-cta.on .btn-cnt a:before {
	width:200px;
	left:-200px;
}

.btns-cnt {
	display:block;
	width:100%;

	.purple-btn,
	.btn {
		//height:40px;
		//padding:6px 20px;
		//min-width:175px;
	}
}

.btns-cnt-lg {
	display:block;
	width:100%;

	.btn {
		margin-right:40px;
	}
	.btn:last-child {
		margin-right:0;
	}
}

.tab-btn.active {
	background:$purple;
	color:#fff;
}

.center-btn {
	position:relative;
	margin:0 auto;
	display:block;
	max-width:260px;
}


@media only screen and (max-width: 1024px) and (min-width: 1px) {
	.tertiary-btn {
		min-width:100%;
	}
	.history-btn {
		min-width:120px;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	.toggle-btns {
		max-width:100%;
	}
	.toggle-cnt {
		max-width:100%;
	}
	.anchor-links {
		max-width:100%;
	}
	.tertiary-btn {
		max-width:160px;
		min-width:160px;
	}
	.history-btn {
		min-width:200px !important;
		max-width:200px !important;
	}
	.breadcrumbs li {
		line-height:28px;
	}
	.breadcrumbs li a {
		font-size:14px;
	}
}

@media only screen and (max-width: 767px) and (min-width: 1px) {
	.btn-cnt a:before {
		display:none;
	}
	.breadcrumbs {
		padding:20px 0 40px 0;
	}
	#questions .tertiary-btn {
		max-width: 100%;
	    min-width: 100%;
	}
}

@media only screen and (max-width: 580px) and (min-width: 1px) {
	.btn,
	.purple-btn {
		min-width:220px;
	}
	.btn-cnt a {
		min-width:100%;
		margin-top:0 !important;
	}
	article .btn {
		width:100%;
	}
	.history-btn {
		position:relative !important;
		min-width:calc(100% - 40px) !important;
		max-width:calc(100% - 40px) !important;
		left:20px;
		top:0 !important;
		margin-bottom:30px;
	}
	.breadcrumbs {
		display:none;
	}
}
