//
// Name:            Post
//


// ========================================================================
// Component: Post
// ========================================================================


.post {
	display:inline-block;
	width:100%;
	padding:10px 0;

	.title {
		display:inline-block;
		width:100%;
		color: #4e2a84;
		font-family: $akkurat_bold;
		font-size: 16px;
		line-height: 24px;
		margin:0 0 10px 0;		
	}
	.date {
		color: #507796;
		font-family: $akkurat_bold;
		font-size: 12px;
		line-height: 16px;
		text-transform: uppercase;
		letter-spacing: 3px;		
	}
}

#news {
	padding-top:40px;

	.col-xs-4 {
		padding-left:calc(10px + .5rem);
		padding-right:calc(10px + .5rem);
		margin-bottom:40px;
	}
	.col-xs-4:first-child {
		padding-left:.5rem;
		padding-right:calc(10px + .5rem);
	}
	.col-xs-4:last-child {
		padding-left:calc(10px + .5rem);
		padding-right:.5rem;
	}
}

.news-post {
	display:inline-block;
	width:100%;
	height:100%;
	padding:40px;
	margin:0;
	vertical-align:top;
	background-color: #ffffff;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

	.cnt {
		position:relative;
		//top:50%;
		//transform:translate(0, -50%);
	}
	.thumb-wrap {
		display:inline-block;
		width:100%;
		height:180px;
		overflow:hidden;
	}
	.thumb {
		display:inline-block;
		width:100%;
		height:180px;
		background-size:cover !important;
		transform:scale(1);
		transition:.4s;

		a {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
	h3 a {
		display:inline-block;
		width:100%;
		color: #4e2a84;
		font-family: $akkurat_bold;
		margin:20px 0 10px 0;		
	}
	.date {
		color: #507796;
		font-family: $akkurat_bold;
		font-size: 12px;
		line-height: 16px;
		text-transform: uppercase;
		letter-spacing: 3px;		
	}
	&.is_hover {
		outline:1px solid $purple;
		box-shadow: 0 0 20px rgba(78, 42, 132, .5);

		.thumb {
			transform:scale(1.1);
		}
		h3 a span {
			background-size:100% 3px;
		}
	}
}

.course {
	display:inline-block;
	width:100%;
	padding:10px 0;

	.title {
		display:inline-block;
		width:100%;
		color: #4e2a84;
		font-family: $campton;
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
		margin:0 0 10px 0;
	}
	p {
		color: #000000;
		font-family: $akkurat_reg;
		font-size: 16px;
		font-weight: 400;
		line-height: 28px;		
	}
}

#single {
	padding:100px 0 0 0;

	img {
		width:100%;
		max-width:100%;
		margin:30px 0;
	}
}

.caption {
	color: #4f7796;
	font-family: $akkurat_reg;
	font-size: 14px;
	line-height: 20px;
	padding:0 50px;
}

blockquote {
	display:block;
	position:relative;

	&.left {
		width:50%;
		float:left;
		padding:80px 30px 80px 0;
	}
	&.right {
		width:50%;
		float:right;
		padding:80px 0 80px 30px;
	}
	.quote {
		position:relative;
		color: #000000;
		font-family: $campton;
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;

		&:before {
			position:absolute;
			content:'\201c';
			top:-30px;
			left:0;
			font-family: $campton;
		}		
	}
	.name {
		color: #4f7796;
		font-family: $akkurat_reg;
		font-size: 14px;
		line-height: 20px;		
	}
}

.post-video {
	display:inline-block;
	position:relative;
	width:100%;
	height:375px;
	margin:30px 0;
	background-size:cover !important;

	a {
		position:absolute;
		top:0;
		left:0;
		z-index:99;
		width:100%;
		height:100%;

		&:before {
			display:inline-block;
			content:'';
			position:absolute;
			top:50%;
			left:50%;
			width:140px;
			height:140px;
			transform:translate(-50%, -50%);
			background:url(../images/large-play.png) 50% no-repeat;
		}
	}
}

.pdf-box {
	margin-bottom:80px;

	.thumb {
		display:block;
		margin:0 auto;
		position:relative;
		width:100%;
		max-width:360px;
		height:520px;
		background-size:cover !important;

		span {
			position:absolute;
			top:0;
			left:0;
			width:auto;
			height:auto;
			padding:8px 10px 5px 10px;
			color: #ffffff;
			z-index:8;
			font-family: $akkurat_reg;
			font-size: 12px;
			line-height:16px;
			letter-spacing: 4px;
			text-transform: uppercase;

			&.purple {
				background-color: $purple;
			}
		}
	}
	.tertiary-btn {
		display:block;
		margin:20px auto;
		max-width:120px;
	}
}

#filterby {
	z-index:9999;
}


@media only screen and (max-width: 1330px) and (min-width: 1px) {
	#single {
		padding:150px 30px 0 30px;
	}
}

@media only screen and (max-width: 1024px) and (min-width: 1px) {
	#fanfare .col-xs-4 .pdf-box .thumb {
	    height:380px;
	}
}

@media only screen and (max-width: 880px) and (min-width: 1px) {
	.split-banner .btns-cnt {
		width:50%;
	}
	.split-banner .btns-cnt .white-btn {
		min-width:100%;
	}
}

@media only screen and (max-width: 768px) and (min-width: 1px) {
	#fanfare .col-xs-4 .pdf-box .thumb {
	    height:580px;
	}
	#news .col-xs-4,
	#news .col-xs-4:last-child,
	#news .col-xs-4:first-child {
		padding-left: .5rem;
		padding-right: .5rem;
	}
	#single {
		padding:50px 30px 0 30px;
	}
}

@media only screen and (max-width: 480px) and (min-width: 1px) {
	#fanfare .col-xs-4 .pdf-box .thumb {
	    height:480px;
	}
	.news-post {
		padding:20px;
	}
	.filter-bar #search-news > ul > li,
	.filter-bar #views-exposed-form-news-list-main-archive > ul > li {
		width:100%;
	}
	blockquote {
		width:100% !important;
		padding:80px 30px !important;
	}
	.caption {
		padding:0;
	}
	.post-video {
		height:275px;
	}
}

@media only screen and (max-width: 380px) and (min-width: 1px) {
	#fanfare .col-xs-4 .pdf-box .thumb {
	    height:360px;
	}
}
